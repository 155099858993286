import Request from '../../common/request';

export default class OrganizationApi extends Request {
  constructor(token, url) {
    super(url, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    this.endpoint = '/organizations';
  }

  all(params) {
    const url = `${this.endpoint}`.concat(params ? `?${params}` : '');
    return super.get(url);
  }

  info(id) {
    return super.get(`${this.endpoint}/${id}`);
  }

  create(params) {
    return super.post(this.endpoint, params);
  }

  update(id, params) {
    return super.put(`${this.endpoint}/${id}`, params);
  }

  updateUser(orgId, userId, params) {
    return super.put(`${this.endpoint}/${orgId}/users/${userId}`, params);
  }

  updateOrgProject(orgId, projectId, params) {
    return super.put(`${this.endpoint}/${orgId}/projects/${projectId}`, params);
  }

  delete(id) {
    return super.delete(`${this.endpoint}/${id}`);
  }

  deleteUser(id, params) {
    return super.delete(`${this.endpoint}/${id}/users`, params);
  }

  invite(id, params) {
    return super.post(`${this.endpoint}/${id}/users/invite`, params);
  }

  projectInvite(id, params) {
    return super.post(`${this.endpoint}/${id}/projects/invite`, params);
  }

  types() {
    return super.get(`${this.endpoint}/types`);
  }

  discAdd(params) {
    return super.post('/disciplines', params);
  }

  discUpdate(params, id) {
    const url = '/disciplines'.concat(id ? `/${id}` : '');
    return super.put(url, params);
  }

  getOrgMeetingTemplates(orgId) {
    return super.get(
      `${this.endpoint}/templates/meetings?organization_id=${orgId}`
    );
  }

  getMeetingTemplate(id) {
    return super.get(`${this.endpoint}/templates/meetings/${id}`);
  }

  createMeetingTemplate(params) {
    return super.post(`${this.endpoint}/templates/meetings`, params);
  }

  updateMeetingTemplate(id, params) {
    return super.put(`${this.endpoint}/templates/meetings/${id}`, params);
  }

  deleteMeetingTemplate(id) {
    return super.delete(`${this.endpoint}/templates/meetings/${id}`);
  }

  deleteMeetingTemplateAgenda(id) {
    return super.delete(`${this.endpoint}/agenda/${id}`);
  }
}
