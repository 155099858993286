import React from 'react';
import { FormattedMessage } from 'react-intl';
import { REGEX_EMAIL_VALIDATION } from 'common/constants';

export const emailSchema = {
  email: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="Email address is required"
          id="error.REQUIRED_EMAIL"
        />
      )
    },
    format: {
      pattern: REGEX_EMAIL_VALIDATION,
      message: (
        <FormattedMessage
          defaultMessage="Email is not a valid email"
          id="error.INVALID_EMAIL"
        />
      )
    },
    length: {
      maximum: 64
    }
  }
};
