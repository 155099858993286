import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';
import { Confirm } from 'components';
import { exportCreate } from 'redux/export/ExportAction';
import { formatDate } from 'common/helper';
import { DEFAULT_DATE_TIME_FORMAT, EXPORT_FEE } from 'common/constants';

const ExportConfirm = props => {
  const dispatch = useDispatch();
  const { onClose, projectId, projectName = '' } = props;

  const createExport = () => {
    dispatch(
      exportCreate({
        file: `export_${projectName}_${formatDate(
          new Date(),
          DEFAULT_DATE_TIME_FORMAT
        )}.zip`,
        project_id: projectId
      })
    );
  };

  return (
    <Confirm
      abortLabel={<FormattedMessage default="No" id="common.NO" />}
      confirmLabel={<FormattedMessage default="Yes" id="common.YES" />}
      message={
        <Typography>
          <FormattedMessage
            defaultMessage="You are about to export your project. The export will be subject to a fee of {export_fee} for your organization. Do you want to proceed with the export?"
            id="admin.EXPORT_FEE_MESSAGE"
            values={{
              export_fee: (
                <font color="red" size="14px">
                  <b>{EXPORT_FEE}</b>
                </font>
              )
            }}
          />
        </Typography>
      }
      onClose={onClose}
      onConfirm={createExport}
    />
  );
};

ExportConfirm.propTypes = {
  onClose: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  projectName: PropTypes.string
};

export default ExportConfirm;
