import Request from 'common/request';

export default class ExportApi extends Request {
  constructor(token, url) {
    super(url, { headers: { Authorization: 'Bearer ' + token } });
    this.endpoint = '/export';
  }

  all() {
    return super.get(this.endpoint);
  }

  create(params) {
    return super.post(this.endpoint, params);
  }

  delete(id) {
    return super.delete(`${this.endpoint}/${id}`);
  }
}
