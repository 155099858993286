import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { clearObjectMessages } from 'redux/object/ObjectAction';
import { clearDocumentMessages } from 'redux/document/DocumentAction';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none'
  }
}));

const Popup = props => {
  const { children, handleParentClose, setHasPopup, show } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(show);
    if (setHasPopup) setHasPopup(show);
  }, [show, setHasPopup]);

  const handleClose = () => {
    if (handleParentClose) handleParentClose();
    if (setHasPopup) setHasPopup(false);
    setOpen(false);
    dispatch(clearObjectMessages());
    dispatch(clearDocumentMessages());
  };

  const body = React.Children.map(children, child =>
    React.cloneElement(child, { ...child.props })
  );

  return (
    <div>
      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        className={classes.modal}
        onClose={handleClose}
        open={open}>
        <div className={classes.paper}>{body}</div>
      </Modal>
    </div>
  );
};

Popup.propTypes = {
  children: PropTypes.node,
  handleParentClose: PropTypes.func,
  setHasPopup: PropTypes.func,
  show: PropTypes.bool
};

export default Popup;
