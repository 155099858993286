import * as userTypes from './UserTypes';
const initialState = {
  loading: false,
  error: '',
  info: {},
  updated: false,
  roles: [],
  invited: [],
  invite_error: '',
  deleted: false,
  notifications: [],
  showOtp: false,
  setting: {}
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userTypes.USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        updated: false,
        roles: [],
        invited: [],
        invite_error: '',
        deleted: false
      };
    case userTypes.USER_SUCCESS:
      // for login, create, and get only, update is different
      return {
        ...state,
        loading: false,
        info: action.payload,
        error: '',
        updated: false
      };
    case userTypes.USER_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        showOtp: true
      };
    case userTypes.USER_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case userTypes.USER_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        updated: true,
        info: action.payload ? action.payload : state.info
      };
    case userTypes.USER_REFRESH:
      return { ...state, loading: false, error: '', updated: false };
    case userTypes.USER_ROLE_SUCCESS:
      return { ...state, loading: false, roles: action.payload };
    case userTypes.USER_DELETE_SUCCESS:
      return { ...state, ...initialState, deleted: true };
    case userTypes.USER_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        updated: true,
        invited: action.payload,
        invite_error: ''
      };
    case userTypes.USER_INVITE_FAILURE:
      return { ...state, loading: false, invite_error: action.payload };
    case userTypes.USER_CONFIGURATION_CREATE_SUCCESS:
      return {
        ...state,
        notifications: action.payload.reduce((acc, curr) => {
          const {
            project,
            project_id,
            settings: { notifications: settings }
          } = curr;
          const projectId = project?.id || project_id;
          const item = state.notifications.findIndex(
            notification => notification.id === projectId
          );

          acc.push({
            ...state.notifications[item],
            notification: {
              ...state.notifications[item].notification,
              settings
            }
          });

          return acc;
        }, [])
      };
    case userTypes.USER_CONFIGURATION_FETCH_SUCCESS:
      return {
        ...state,
        notifications: action.payload.map(data => {
          const {
            id: notificationId,
            project: { id: projectId, name: projectName },
            settings: { notifications: settings },
            user_id
          } = data;

          return {
            id: projectId,
            name: projectName,
            notification: {
              id: notificationId,
              settings
            },
            user_id
          };
        })
      };
    case userTypes.USER_CONFIGURATION_UPDATE_SUCCESS: {
      const {
        project: { id: projectId },
        settings: { notifications: settings }
      } = action.payload;
      const item = state.notifications.findIndex(
        notification => notification.id === projectId
      );

      state.notifications[item] = {
        ...state.notifications[item],
        notification: {
          ...state.notifications[item].notification,
          settings
        }
      };

      return state;
    }
    case userTypes.USER_SETTING_UPDATE_SUCCESS:
      return {
        ...state,
        setting: action.payload,
        updated: true
      };
    case userTypes.USER_SETTING_FETCH_SUCCESS:
      return {
        ...state,
        setting: action.payload
      };
    case userTypes.USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default userReducer;
