import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography, Paper, Button } from '@material-ui/core';
import { userRefresh } from '../../redux';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  contentPaper: {
    height: 'max-content',
    padding: 64
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 270
  },
  buttonText: {
    background: 'none !important',
    padding: '0 !important',
    cursor: 'pointer',
    textTransform: 'lowercase',
    fontWeight: 'normal',
    marginBottom: 2,
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

const EmailSent = props => {
  const classes = useStyles();
  const { email, message } = props;

  const dispatch = useDispatch();
  const handleRefresh = () => {
    dispatch(userRefresh());
  };

  return (
    <Paper className={classes.contentPaper} elevation={4}>
      <Typography data-testid="header-sent" variant="h2">
        Check your email!
      </Typography>
      <Typography color="textSecondary" gutterBottom>
        We've emailed a special link to {email}. {message}
        <br />
        <br />
        Wrong email? Please &nbsp;
        <Button
          className={classes.buttonText}
          color="primary"
          onClick={handleRefresh}>
          re-renter your address
        </Button>
      </Typography>
      <div className={classes.contentBody}>
        <img alt="" className={classes.image} src="/images/email_sent.png" />
      </div>
    </Paper>
  );
};

EmailSent.propTypes = {
  email: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired
};

export default withRouter(EmailSent);
