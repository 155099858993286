import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import classNames from 'classnames';
import {
  Box,
  Button,
  Collapse,
  DialogContent,
  Dialog,
  DialogActions,
  Divider,
  Grid,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { emailSchema } from 'common/schema';
import { settingUpdate, userDelete, userUpdate } from '../../../../../redux';
import { useStyles as compStyles } from 'components/styles';
import { useStyles } from '../../../styles';
import {
  EMAIL,
  ENABLED_2FA,
  ERROR,
  ERROR_CODE_401,
  ERROR_PASSWORD_UPDATE,
  NAME,
  CURRENT_PASSWORD,
  NEW_PASSWORD,
  NEW_PASSWORD_CONFIRMATION,
  SUCCESS
} from 'common/constants';

const schema = {
  ...emailSchema,
  name: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="Name is required"
          id="error.REQUIRED_NAME"
        />
      )
    },
    length: {
      maximum: 64
    }
  },
  [CURRENT_PASSWORD]: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="{name} is required"
          id="error.REQUIRED"
          values={{
            name: (
              <FormattedMessage
                defaultMessage="Current Password"
                id="main.CURRENT_PASSWORD"
              />
            )
          }}
        />
      )
    },
    length: {
      maximum: 128
    }
  },
  [NEW_PASSWORD]: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="{name} is required"
          id="error.REQUIRED"
          values={{
            name: (
              <FormattedMessage
                defaultMessage="New Password"
                id="main.NEW_PASSWORD"
              />
            )
          }}
        />
      )
    },
    length: {
      maximum: 128
    }
  },
  [NEW_PASSWORD_CONFIRMATION]: {
    equality: {
      attribute: 'new_password',
      message: (
        <FormattedMessage
          defaultMessage="{name} is not the same as {otherName}"
          id="error.NOT_SAME"
          values={{
            name: (
              <FormattedMessage
                defaultMessage="New Password Confirmation"
                id="main.NEW_PASSWORD_CONFIRMATION"
              />
            ),
            otherName: (
              <FormattedMessage
                defaultMessage="New Password"
                id="main.NEW_PASSWORD"
              />
            )
          }}
        />
      )
    },
    length: {
      maximum: 28
    }
  }
};

const UserDetails = ({ history, setting }) => {
  const intl = useIntl();
  const classes = useStyles();
  const compClasses = compStyles();
  const dispatch = useDispatch();
  const {
    user: { deleted: userDeleted, error: userError, info, updated: userUpdated }
  } = useSelector(state => state);
  const [formState, setState] = useState({
    showProceedDelete: false,
    showDeletedDialog: false,
    errors: {},
    settings: {
      [ENABLED_2FA]: false
    },
    touched: {},
    values: {
      [EMAIL]: info.email,
      [NAME]: info.name,
      [CURRENT_PASSWORD]: '',
      [NEW_PASSWORD]: '',
      [NEW_PASSWORD_CONFIRMATION]: ''
    }
  });
  const { settings, values } = formState;
  const isPasswordsEmpty =
    values[CURRENT_PASSWORD].length === 0 ||
    values[NEW_PASSWORD].length === 0 ||
    values[NEW_PASSWORD_CONFIRMATION].length === 0;
  const hasPasswordError =
    formState.errors[CURRENT_PASSWORD]?.length > 0 ||
    formState.errors[NEW_PASSWORD]?.length > 0 ||
    formState.errors[NEW_PASSWORD_CONFIRMATION]?.length > 0;
  const updatePasswordDisabled = isPasswordsEmpty || hasPasswordError;
  let errorMessage = intl.formatMessage({
    defaultMessage: 'Unexpected server response.',
    id: 'error.UNEXPECTED_RESPONSE'
  });
  const userErrors = Object.values(userError);
  if (!!userErrors) {
    errorMessage = userErrors
      .map(e => {
        if (e === ERROR_CODE_401) {
          return intl.formatMessage({
            defaultMessage:
              'You cannot delete your account unless you assign a new organization admin.',
            id: 'common.CANNOT_DELETE_USER'
          });
        } else if (e === ERROR_PASSWORD_UPDATE) {
          return intl.formatMessage({
            defaultMessage: 'Current password is incorrect',
            id: 'error.INVALID_CURRENT_PASSWORD'
          });
        }
        return e;
      })
      .flat()
      .join('\n');
  }

  useEffect(() => {
    const errors = validate(values, schema);

    setState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [values]);

  useEffect(() => {
    if (userDeleted)
      setState(b => ({
        ...b,
        showDeletedDialog: true
      }));
  }, [userDeleted]);

  useEffect(() => {
    setState(s => ({
      ...s,
      showProceedDelete: false
    }));
  }, [userError]);

  useEffect(() => {
    setState(s => ({
      ...s,
      settings: setting.settings
    }));
  }, [setting]);

  const setProceedDelete = val => {
    setState(s => ({
      ...s,
      showProceedDelete: val
    }));
  };

  const handleChange = event => {
    event.persist();
    setState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();
    dispatch(
      userUpdate(
        {
          [NAME]: values[NAME]
        },
        {
          id: info.user_id
        }
      )
    );
  };

  const handleUpdatePassword = event => {
    event.preventDefault();
    dispatch(
      userUpdate(
        {
          [CURRENT_PASSWORD]: values[CURRENT_PASSWORD],
          [NEW_PASSWORD]: values[NEW_PASSWORD],
          [NEW_PASSWORD_CONFIRMATION]: values[NEW_PASSWORD_CONFIRMATION]
        },
        {
          id: info.user_id,
          isUpdatePassword: true
        }
      )
    );
  };

  const handleDeleteAccount = event => {
    event.preventDefault();
    dispatch(userDelete(info.id));
  };

  const hasError = field => {
    switch (field) {
      case CURRENT_PASSWORD:
        return (formState.touched[CURRENT_PASSWORD] ||
          formState.touched[NEW_PASSWORD] ||
          formState.touched[NEW_PASSWORD_CONFIRMATION]) &&
          formState.errors[field]
          ? true
          : false;
      case NEW_PASSWORD:
        return (formState.touched[NEW_PASSWORD] ||
          formState.touched[NEW_PASSWORD_CONFIRMATION]) &&
          formState.errors[field]
          ? true
          : false;
      default:
        return formState.touched[field] && formState.errors[field]
          ? true
          : false;
    }
  };

  const handleSettingSwitch = evt => {
    const settings = {
      [evt.target.name]: evt.target.checked
    };

    dispatch(
      settingUpdate({
        id: setting.id,
        user_id: setting.user_id,
        settings
      })
    );

    setState(s => ({ ...s, settings }));
  };

  return (
    <>
      {userUpdated ? (
        <Collapse in timeout="auto">
          <Alert className={classes.alert} severity={SUCCESS}>
            <FormattedMessage
              defaultMessage="Your changes have been saved!"
              id="common.SAVED_CHANGES"
            />
          </Alert>
        </Collapse>
      ) : null}
      {userError ? (
        <Collapse in timeout="auto">
          <Alert className={classes.alert} severity={ERROR}>
            {errorMessage}
          </Alert>
        </Collapse>
      ) : null}
      <div className={classes.orgRoot}>
        <Grid container spacing={3} justifyContent="flex-end">
          <Grid item xs={12} md={3}>
            <Typography align="left" variant="h4">
              <FormattedMessage defaultMessage="Profile" id="admin.PROFILE" />
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              disabled
              fullWidth
              id={EMAIL}
              InputLabelProps={{
                shrink: true
              }}
              label={
                <FormattedMessage defaultMessage="E-mail" id="common.EMAIL" />
              }
              name={EMAIL}
              size="small"
              value={values.email}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              error={hasError(NAME)}
              fullWidth
              helperText={hasError(NAME) ? formState.errors[NAME][0] : null}
              id={NAME}
              InputLabelProps={{
                shrink: true
              }}
              label={
                <FormattedMessage defaultMessage="Name" id="common.NAME" />
              }
              name={NAME}
              onChange={handleChange}
              size="small"
              value={values.name || ''}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid alignItems="flex-end" container justifyContent="flex-end">
          <Button
            className={classNames(classes.buttonText, classes.marginTop2)}
            variant="contained"
            color="primary"
            data-testid="save"
            onClick={handleSubmit}>
            <FormattedMessage defaultMessage="Save" id="common.SAVE" />
          </Button>
        </Grid>
        {!info.sso_user && (
          <>
            <Divider className={classes.margin2} />
            <Grid container spacing={3} justifyContent="flex-end">
              <Grid item xs={12} md={3}>
                <Typography align="left" variant="h4">
                  <FormattedMessage
                    defaultMessage="Password"
                    id="main.PASSWORD"
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  error={hasError(CURRENT_PASSWORD)}
                  fullWidth
                  helperText={
                    hasError(CURRENT_PASSWORD)
                      ? formState.errors[CURRENT_PASSWORD][0]
                      : null
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                  label={
                    <FormattedMessage
                      defaultMessage="Current Password"
                      id="main.CURRENT_PASSWORD"
                    />
                  }
                  name={CURRENT_PASSWORD}
                  onChange={handleChange}
                  type="password"
                  size="small"
                  value={values[CURRENT_PASSWORD] || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  error={hasError(NEW_PASSWORD)}
                  fullWidth
                  helperText={
                    hasError(NEW_PASSWORD)
                      ? formState.errors[NEW_PASSWORD][0]
                      : null
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                  label={
                    <FormattedMessage
                      defaultMessage="New Password"
                      id="main.NEW_PASSWORD"
                    />
                  }
                  name={NEW_PASSWORD}
                  onChange={handleChange}
                  type="password"
                  size="small"
                  value={values[NEW_PASSWORD] || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  error={hasError(NEW_PASSWORD_CONFIRMATION)}
                  fullWidth
                  helperText={
                    hasError(NEW_PASSWORD_CONFIRMATION)
                      ? formState.errors[NEW_PASSWORD_CONFIRMATION][0]
                      : null
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                  label={
                    <FormattedMessage
                      defaultMessage="New Password Confirmation"
                      id="main.NEW_PASSWORD_CONFIRMATION"
                    />
                  }
                  name={NEW_PASSWORD_CONFIRMATION}
                  onChange={handleChange}
                  type="password"
                  size="small"
                  value={values[NEW_PASSWORD_CONFIRMATION] || ''}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid alignItems="flex-end" container justifyContent="flex-end">
              <Button
                disabled={updatePasswordDisabled}
                className={classNames(classes.buttonText, classes.marginTop2)}
                variant="contained"
                color="primary"
                data-testid="update-password"
                onClick={handleUpdatePassword}>
                <FormattedMessage
                  defaultMessage="Update Password"
                  id="main.UPDATE_PASSWORD"
                />
              </Button>
            </Grid>
            <Divider className={classes.margin2} />
            <Grid container spacing={3} justifyContent="flex-end">
              <Grid item xs={12} md={3}>
                <Typography align="left" variant="h4">
                  <FormattedMessage
                    defaultMessage="Security"
                    id="admin.SECURITY"
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between">
                  <Typography>
                    <FormattedMessage
                      defaultMessage="Two-factor Authentication"
                      id="admin.TWOFACTOR"
                    />
                  </Typography>
                  <Switch
                    checked={settings?.[ENABLED_2FA]}
                    color="primary"
                    name={ENABLED_2FA}
                    onChange={handleSettingSwitch}
                  />
                </Box>
              </Grid>
            </Grid>
          </>
        )}
        <Divider className={classes.margin2} />
        <Grid container spacing={3} justifyContent="flex-end">
          <Grid item xs={12} md={3}>
            <Typography align="left" variant="h4">
              <FormattedMessage
                defaultMessage="Delete Account"
                id="admin.DELETE_ACCOUNT"
              />
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Button
              className={classNames(compClasses.deleteButton, classes.noMargin)}
              variant="contained"
              data-testid="delete"
              onClick={() => setProceedDelete(true)}>
              <FormattedMessage
                defaultMessage="Delete Account"
                id="admin.DELETE_ACCOUNT"
              />
            </Button>
          </Grid>
        </Grid>
      </div>
      <Dialog
        onClose={() => setProceedDelete(false)}
        open={formState.showProceedDelete}>
        <DialogContent dividers>
          <Typography gutterBottom>
            <FormattedMessage
              defaultMessage="Are you sure you want to delete your account? Doing so will completely erase all your details from the system. Do you want to proceed?"
              id="main.PROCEED_DELETE"
            />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            color="primary"
            onClick={() => setProceedDelete(false)}>
            <FormattedMessage defaultMessage="No" id="common.NO" />
          </Button>
          <Button color="secondary" onClick={handleDeleteAccount}>
            <FormattedMessage defaultMessage="Yes" id="common.YES" />
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => history.push('/sign-in')}
        open={formState.showDeletedDialog}>
        <DialogContent dividers>
          <Typography gutterBottom>
            <FormattedMessage
              defaultMessage="We're sorry to see you go. Rest assured your account has been deleted. If you change your mind, feel free to sign up again."
              id="main.GOODBYE_MSG"
            />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            color="primary"
            onClick={() => history.push('/sign-in')}>
            <FormattedMessage defaultMessage="Ok" id="common.OK" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

UserDetails.propTypes = {
  history: PropTypes.object,
  setting: PropTypes.object
};

export default UserDetails;
