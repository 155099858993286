import * as standardTypes from './StandardTypes';
import Str8line from '../auth/AuthApi';

const standardSuccess = data => {
  return { type: standardTypes.STANDARD_SUCCESS, payload: data };
};

export const standardFetchFailure = error => {
  return { type: standardTypes.STANDARD_FAILURE, payload: error };
};

export const standardRequest = () => {
  return { type: standardTypes.STANDARD_REQUEST };
};

export const standardFetch = () => {
  return dispatch => {
    dispatch(standardRequest());

    const api = new Str8line();

    api.standard
      .fetch()
      .then(response => dispatch(standardSuccess(response.data.data)))
      .catch(error => {
        const errorMsg = error.message;
        dispatch(standardFetchFailure(errorMsg));
      });
  };
};
