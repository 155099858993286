import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { singleObjectFetch } from '../redux';

export const useFetch = (someFetchAction, obj, id) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!obj || obj.length === 0) dispatch(someFetchAction(id));
  }, [dispatch, id, obj, someFetchAction]);
};

export const useUserInfo = (someFetchAction, jwt, isUserDeleted) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (jwt && !isUserDeleted) dispatch(someFetchAction());
  }, [dispatch, jwt, someFetchAction, isUserDeleted]);
};

export const useTokenLogin = (someFetchAction, token) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (token) dispatch(someFetchAction(token));
  }, [dispatch, someFetchAction, token]);
};

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const useObjectInfo = (props, force = true) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (force) {
      const id =
        props.objectId ||
        (!!props.location?.state?.selectedTemplate?.metadata && // Dont fetch if global meeting template
          props.location?.state?.templateId) ||
        props.match?.params?.id;
      if (id && id !== 'new')
        dispatch(singleObjectFetch(id, false, props.view));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location, props.objectId, props.view, force, dispatch]);
};

export const useInterval = (handler, interval, isIntervalDisabled = false) => {
  const [intervalId, setIntervalId] = useState();
  useEffect(() => {
    if (!isIntervalDisabled) {
      const id = setInterval(handler, interval);
      setIntervalId(id);
      return () => clearInterval(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return () => clearInterval(intervalId);
};

export const useEvent = (event, handler, passive = false) => {
  useEffect(() => {
    // initiate the event handler
    window.addEventListener(event, handler, passive);

    // this will clean up the event every time the component is re-rendered
    return function cleanup() {
      window.removeEventListener(event, handler);
    };
  });
};
