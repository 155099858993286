import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Autocomplete } from '@material-ui/lab';
import { CircularProgress, InputAdornment, TextField } from '@material-ui/core';
import { brregFetch, brregUnrequest, organizationFetch } from '../../redux';
import { countryToFlag, getCompanyId, hasError } from 'common/helper';
import { useDebounce } from 'hooks';
import validate from 'validate.js';
import {
  ALL,
  BRREG,
  COUNTRY_NO,
  COUNTRY_NO_CODE,
  HYPHEN,
  NAME,
  REGEX_GI_MODIFIER
} from 'common/constants';

const getDefaultSchema = exclusionList => {
  return {
    name: {
      presence: {
        allowEmpty: false,
        message: (
          <FormattedMessage
            defaultMessage="Name is required"
            id="error.REQUIRED_NAME"
          />
        )
      },
      length: {
        maximum: 64
      },
      exclusion: {
        within: exclusionList,
        message: (
          <FormattedMessage
            defaultMessage="already exists"
            id="error.ALREADY_EXISTS"
          />
        )
      }
    }
  };
};

const BrregField = props => {
  const { index, label, modified, onChange, orgList, schema, value } = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  const ref = useRef();
  const {
    brreg,
    organization: { organizationsAll }
  } = useSelector(state => state);
  const [states, setStates] = useState({
    allOrgs: [],
    systemOrgs: [],
    errors: {},
    schema: {},
    orgName: '',
    touched: false,
    countryISOCode: COUNTRY_NO_CODE
  });
  const searchedOrgName = useDebounce(states.orgName, 1000);

  useEffect(() => {
    dispatch(organizationFetch(ALL.toLowerCase()));
  }, [dispatch]);

  useEffect(() => {
    if (organizationsAll?.length > 0) {
      const systemOrgs = organizationsAll.reduce((acc, org) => {
        if (org.org_tag?.split(HYPHEN)[0] === states.countryISOCode)
          acc.push(org);
        return acc;
      }, []);

      const systemOrgNames = systemOrgs.map(so =>
        so.name === value ? '' : so.name
      );

      setStates(states => ({
        ...states,
        systemOrgs,
        schema: schema || getDefaultSchema(systemOrgNames)
      }));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationsAll, states.countryISOCode, value]);

  useEffect(() => {
    if (states.touched && searchedOrgName?.length > 2) {
      const orgNum = getOrgNumForSearch(searchedOrgName);
      const params = orgNum ? { org_num: orgNum } : { name: searchedOrgName };
      dispatch(brregFetch(params));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchedOrgName]);

  useEffect(() => {
    if (states.touched && searchedOrgName?.length > 2) {
      const brregOrgs = brreg.organizations.map(org => {
        const address = org.postal_address || org.business_address;
        const country_code = states.countryISOCode || COUNTRY_NO_CODE;
        return {
          name: org.name,
          org_meta: {
            address: `${org.name}, ${address.adresse.join(', ')}, ${
              address.postnummer
            } ${address.poststed}`,
            company_id: org.org_num,
            country_code: country_code
          },
          org_tag: `${country_code}-${org.org_num}`,
          type: BRREG
        };
      });
      setStates(states => ({
        ...states,
        allOrgs: [...states.systemOrgs, ...brregOrgs]
      }));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brreg.organizations, searchedOrgName, states.countryISOCode]);

  //////////////////////////////////////////////////////////////////////////////
  //                        END OF USEEFFECT FUNCTIONS                        //
  //                         START OF LOCAL FUNCTIONS                         //
  //////////////////////////////////////////////////////////////////////////////

  const getOrgNumForSearch = str => {
    str = str.replace(/\s/g, ''); // remove all whitespace
    return !isNaN(parseInt(str)) && str.length === 9 ? str : '';
  };

  const handleChangeOrgName = event => {
    event.persist();
    ref.current = event.target;
    setStates(states => ({
      ...states,
      allOrgs: [],
      orgName: event.target.value,
      touched: true,
      errors: validate({ name: event.target.value }, states.schema)
    }));
  };

  const handleSelectClose = () => {
    dispatch(brregUnrequest());
    setStates(states => ({
      ...states,
      allOrgs: []
    }));
  };

  const handleSelectChange = (event, value) => {
    const name = value?.name;
    const errors = validate({ name }, states.schema);

    onChange(event, value, errors, index);
    setStates(states => ({
      ...states,
      allOrgs: [],
      errors: errors,
      orgName: name,
      touched: false
    }));
  };

  return (
    <Autocomplete
      data-testid={NAME}
      filterOptions={(org, data) =>
        /********
         * Remove already selected organizations from the list
         * Remove organizations that don't match the RegExp
         * Remove duplicate organizations based on name and org_tag
         ********/
        org.filter((org, idx, arr) => {
          const orgNum = getOrgNumForSearch(data.inputValue);
          return (
            !orgList?.some(
              item =>
                item.values.org_tag === org.org_tag &&
                item.values.organization_name === org.name
            ) &&
            ((orgNum && getCompanyId(org.org_tag) === orgNum) ||
              org.name.match(new RegExp(data.inputValue, REGEX_GI_MODIFIER))) &&
            arr.findIndex(
              elemIndex =>
                elemIndex.name.toUpperCase() === org.name.toUpperCase() &&
                elemIndex.org_tag === org.org_tag
            ) === idx
          );
        })
      }
      getOptionLabel={option =>
        (option.name || '').concat(
          option.org_tag ? ` (${getCompanyId(option.org_tag)})` : ''
        )
      }
      groupBy={option => option.type || ''}
      inputValue={
        (states.touched || modified || !value ? states.orgName : value) || ''
      }
      onChange={handleSelectChange}
      onClose={handleSelectClose}
      options={states.allOrgs}
      renderInput={params => (
        <TextField
          {...params}
          error={hasError(states.errors, NAME)}
          fullWidth
          helperText={
            hasError(states.errors, NAME) ? states.errors.name[0] : null
          }
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <span>{countryToFlag(COUNTRY_NO)}</span>
                {ref?.current === document.activeElement && brreg.loading && (
                  <InputAdornment position="start">
                    <CircularProgress size="20px" />
                  </InputAdornment>
                )}
              </>
            )
          }}
          label={label}
          onChange={handleChangeOrgName}
          placeholder={intl.formatMessage({
            defaultMessage: 'Type to search for Organization',
            id: 'organization.SEARCH'
          })}
          required
          variant="outlined"
        />
      )}
      size="small"
    />
  );
};

BrregField.propTypes = {
  index: PropTypes.number,
  label: PropTypes.object,
  modified: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  orgList: PropTypes.array,
  schema: PropTypes.object,
  value: PropTypes.string
};

BrregField.defaultProps = { modified: false };

export default BrregField;
