import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Typography } from '@material-ui/core';
import { CostTable } from '../../components';
import { WysWyg } from '../../../components';
import { useStyles } from '../../../styles';
import {
  koe_impact,
  koe_reason,
  koe_eo_settlement
} from '../../../components/data';
import { isObjectEmpty } from 'common/helper';
import * as Constants from 'common/constants';
import classNames from 'classnames';
import { getWyswygViewData } from 'views/Object/components/helper';

const Koe = props => {
  const {
    createdBy,
    displayObjectView,
    isSenderOrg,
    objectInfo,
    recipientName
  } = props;
  const classes = useStyles();
  const intl = useIntl();
  const objectKoe = objectInfo.metadata;

  let hasPrice = false,
    hasSchedule = false,
    impact_price = '',
    impact_schedule = '',
    comments = [],
    description = [],
    impact_price_description = [],
    impacts = [],
    reasons = [],
    settlements = [];
  if (!isObjectEmpty(objectKoe)) {
    if (objectKoe.comment) comments = objectKoe.comment;
    if (objectKoe.description) description = objectKoe.description;
    if (!isSenderOrg && objectKoe.new_comment_text)
      comments = getWyswygViewData(comments, objectKoe.new_comment);
    else if (isSenderOrg && objectKoe.new_description_text)
      description = getWyswygViewData(description, objectKoe.new_description);

    if (objectKoe.total_cost_nok) impact_price = objectKoe.total_cost_nok;
    if (objectKoe.impact_total_schedule_days)
      impact_schedule = objectKoe.impact_total_schedule_days;
    if (objectKoe.impact_price_description)
      impact_price_description = objectKoe.impact_price_description;
    if (objectKoe.impact && objectKoe.impact.length > 0)
      impacts = koe_impact
        .filter(ki => objectKoe.impact.includes(ki.value))
        .map(i => {
          if (i.name === Constants.IMPACT_PRICE) hasPrice = true;
          if (i.name === Constants.IMPACT_SCHEDULE) hasSchedule = true;
          return intl.formatMessage({
            defaultMessage: i.name,
            id: 'object.' + i.name.toUpperCase()
          });
        });
    if (objectKoe.reason && objectKoe.reason.length > 0)
      reasons = koe_reason
        .filter(kr => objectKoe.reason.includes(kr.value))
        .map(r =>
          intl.formatMessage({
            defaultMessage: r.name,
            id: 'object.' + r.name.toUpperCase()
          })
        );
    if (objectKoe.settlement && objectKoe.settlement.length > 0)
      settlements = koe_eo_settlement
        .filter(ks => objectKoe.settlement.includes(ks.value))
        .map(s =>
          intl.formatMessage({
            defaultMessage: s.name,
            id: 'object.' + s.name.toUpperCase()
          })
        );
  }

  return (
    <div>
      <Typography component="div">
        <Box
          fontSize="h4.fontSize"
          fontWeight="fontWeightBold"
          m={1}
          textAlign="left">
          {objectInfo.name} {objectInfo.title}
        </Box>
        <Box className={classes.noMarginBottom} m={1} textAlign="left">
          <FormattedMessage
            defaultMessage="Created by"
            id="common.CREATED_BY"
          />
          : {createdBy}
        </Box>
        <Box className={classes.noMarginBottom} m={1} textAlign="left">
          <FormattedMessage
            id="object.REASON_FOR_CHANGE"
            defaultMessage="Reason for change"
          />
          : {reasons.join(', ') || ''} <br />
          <FormattedMessage id="object.IMPACT" defaultMessage="Impact" />:{' '}
          {impacts.join(', ') || ''}
        </Box>
        {hasSchedule ? (
          <Box
            className={classNames(classes.noMarginBottom, classes.noMarginTop)}
            m={1}
            textAlign="left">
            <FormattedMessage
              id="common.TOTAL_SCHEDULE_IMPACT"
              defaultMessage="Total schedule impact (days)"
            />
            : {impact_schedule}
          </Box>
        ) : null}
        {hasPrice ? (
          <Box
            className={classNames(classes.noMarginBottom, classes.noMarginTop)}
            m={1}
            textAlign="left">
            <FormattedMessage
              id="common.TOTAL_COST"
              defaultMessage="Total cost (NOK)"
            />
            : {impact_price} <br />
          </Box>
        ) : null}
        <Box
          className={classNames(classes.noMarginBottom, classes.noMarginTop)}
          m={1}
          textAlign="left">
          <FormattedMessage
            id="object.SETTLEMENT"
            defaultMessage="Settlement"
          />
          : {settlements.join(', ')}
        </Box>
        <Box fontWeight="fontWeightBold" m={1} textAlign="left">
          <FormattedMessage
            id="common.DESCRIPTION"
            defaultMessage="Description"
          />
          :
        </Box>
      </Typography>
      {description.map((description, index) => (
        <div
          className={
            index > 0 ? classes.revisionEditorView : classes.paddingBottom
          }
          key={`div_description_${index}`}>
          <WysWyg
            defaultValue={description}
            displayObjectView={displayObjectView}
            key={`description_${index}`}
            name={`description_${index}`}
            readOnly
          />
        </div>
      ))}
      {hasPrice ? <CostTable data={impact_price_description} /> : null}
      <Typography component="div">
        <Box fontWeight="fontWeightBold" m={1} textAlign="left">
          <FormattedMessage
            defaultMessage="{name} Comments"
            id="object.ORG_NAME_COMMENTS"
            values={{
              name: recipientName
            }}
          />
          :
        </Box>
      </Typography>
      {(comments.length > 0 ? comments : ['']).map((comment, index) => (
        <div
          className={
            index > 0 ? classes.revisionEditorView : classes.paddingBottom
          }
          key={`div_comment_${index}`}>
          <WysWyg
            defaultValue={comment}
            displayObjectView={displayObjectView}
            key={`comment_${index}`}
            name={`comment_${index}`}
            readOnly
          />
        </div>
      ))}
    </div>
  );
};

Koe.propTypes = {
  createdBy: PropTypes.string,
  displayObjectView: PropTypes.func,
  objectInfo: PropTypes.object,
  recipientName: PropTypes.string
};

export default Koe;
