import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert } from '@material-ui/lab';
import { getFilenameWithoutID } from 'common/helper';
import { ERROR, SUCCESS } from 'common/constants';
import { useStyles } from '../styles';

const Layout = ({
  disableBtns,
  dropzoneProps,
  extra: { maxFiles },
  files,
  hide,
  input,
  previews,
  uploadedFiles
}) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div>
      {files.length < maxFiles && !hide ? (
        <div {...dropzoneProps}>{input}</div>
      ) : null}

      <div className={classes.attachmentPreviewList}>
        {previews.map((preview, index) => {
          const file = uploadedFiles[preview.key];
          let message = '',
            severity = SUCCESS;

          if (file) {
            if (file.error) {
              message = file.error;
              severity = ERROR;
            } else if (file.done)
              if (preview.props.meta.previewUrl)
                message = intl.formatMessage(
                  {
                    defaultMessage: 'Upload complete!',
                    id: 'attachment.UPLOAD_COMPLETE_WITH_NAME'
                  },
                  { name: getFilenameWithoutID(file.file) }
                );
              else
                message = (
                  <FormattedMessage
                    defaultMessage="Upload complete!"
                    id={'attachment.UPLOAD_COMPLETE'}
                  />
                );
          }
          if (disableBtns) preview.props.canRemove = false;

          // we disable preview(s)
          preview.props.meta.previewUrl = '';
          return (
            <div key={`preview_${index}`}>
              {message ? (
                <Alert
                  className={classes.attachmentAlert}
                  key={`err_${index}`}
                  severity={severity}>
                  {message}
                </Alert>
              ) : null}
              {preview}
            </div>
          );
        })}
      </div>
    </div>
  );
};

Layout.propTypes = {
  disableBtns: PropTypes.bool,
  dropzoneProps: PropTypes.object,
  extra: PropTypes.object,
  files: PropTypes.array,
  hide: PropTypes.bool,
  input: PropTypes.object,
  previews: PropTypes.object,
  uploadedFiles: PropTypes.object
};

export default Layout;
