import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert } from '@material-ui/lab';
import {
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography
} from '@material-ui/core';
import {
  Clear as ClearIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  ExpandLess,
  ExpandMore,
  Save as SaveIcon
} from '@material-ui/icons';
import CategoryTemplate from './CategoryTemplate';
import _ from 'lodash';
import classNames from 'classnames';
import validate from 'validate.js';
import {
  categoryTemplateCreateOrUpdate,
  categoryTemplateDelete,
  orgCategoryTemplatesFetch
} from 'redux/document/DocumentAction';
import {
  categoryNameSchema,
  processCategories
} from '../../../validations/schema';
import { hasError, isObjectEmpty } from 'common/helper';
import { useStyles } from '../../../styles';
import {
  DEFAULT_TEMPLATE_NAME,
  DOCUMENT_CATEGORIES,
  NAME,
  WIDTH_XS
} from 'common/constants';

const Category = ({ organizationId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    document: {
      categoryTemplates,
      categoryTemplateError,
      categoryTemplateUpdated
    },
    screen: { screenWidth }
  } = useSelector(state => state);
  const [state, setState] = useState({
    selected: {},
    templates: [],
    prevTemplates: []
  });
  const { prevTemplates, selected, templates } = state;

  useEffect(() => {
    if (organizationId) dispatch(orgCategoryTemplatesFetch(organizationId));
  }, [organizationId, dispatch]);

  useEffect(() => {
    if (organizationId && categoryTemplateUpdated)
      dispatch(orgCategoryTemplatesFetch(organizationId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryTemplateUpdated, dispatch]);

  useEffect(() => {
    setState(state => ({
      ...state,
      templates: _.cloneDeep(categoryTemplates),
      prevTemplates: _.cloneDeep(categoryTemplates)
    }));
  }, [categoryTemplates]);

  const addTemplate = event => {
    event.persist();
    setState(state => {
      const templates = state.templates.concat({
        name: DEFAULT_TEMPLATE_NAME,
        document_categories: [],
        errors: {},
        edit: true,
        touched: true
      });
      state.selected[templates.length - 1] = true;
      return {
        selected: selected,
        templates: templates,
        prevTemplates: _.cloneDeep(templates)
      };
    });
  };

  const handleDisplay = index => {
    setState(state => {
      state.selected[index] = !state.selected[index];
      return { ...state };
    });
  };

  const hasTouched = categories =>
    categories.some(cat => cat.touched || hasTouched(cat.children));

  const updateTemplateValues = (event, index, field, value, extra = {}) => {
    if (event) event.persist();
    setState(state => {
      state.templates[index] = {
        ...state.templates[index],
        [field]: value,
        touched:
          field === NAME ||
          (field === DOCUMENT_CATEGORIES && hasTouched(value)) ||
          !!extra.hasDeleted
      };

      let addErrors = true,
        errors = {};
      if (field === DOCUMENT_CATEGORIES) {
        errors[field] = processCategories(
          state.templates[index].document_categories
        );
      } else {
        errors = validate(state.templates[index], categoryNameSchema);
        if (!errors && NAME in state.templates[index].errors) {
          delete state.templates[index].errors[NAME];
          addErrors = false;
        }
      }

      if (addErrors)
        state.templates[index].errors = {
          ...state.templates[index].errors,
          ...errors
        };

      const updatedTemplates = [...state.templates];
      const prevTemplates = extra.updatePrev
        ? _.cloneDeep(updatedTemplates)
        : state.prevTemplates;
      return {
        ...state,
        templates: updatedTemplates,
        prevTemplates: prevTemplates
      };
    });
  };

  const handleEdit = (event, index) => {
    setState(state => {
      const prevName = state.prevTemplates[index].name;
      let currTemplate = state.templates[index];
      currTemplate = {
        ...currTemplate,
        edit: !currTemplate.edit,
        name: prevName,
        touched:
          currTemplate.name !== prevName ||
          hasTouched(currTemplate.document_categories)
      };
      currTemplate.errors = {
        ...validate(currTemplate, categoryNameSchema),
        [DOCUMENT_CATEGORIES]: processCategories(
          currTemplate.document_categories
        )
      };
      state.templates[index] = currTemplate;
      return { ...state, templates: [...state.templates] };
    });
  };

  const handleDelete = (event, index) => {
    event.persist();
    const del = window.confirm(
      intl.formatMessage({ id: 'object.DELETE_TEMPLATE_CONFIRMATION' }) +
        '\n' +
        intl.formatMessage({ id: 'common.ACTION_CANNOT_UNDONE' })
    );
    if (del) {
      const delTmpl = templates[index];
      if (delTmpl.id) dispatch(categoryTemplateDelete(delTmpl.id));
      else {
        templates.splice(index, 1);
        setState(state => ({ ...state, templates: [...templates] }));
      }
    }
  };

  const handleSave = (event, index) => {
    event.persist();
    const { document_categories, id, name } = templates[index];
    const params = {
      organization_id: organizationId,
      name: name,
      document_categories: document_categories
    };
    dispatch(categoryTemplateCreateOrUpdate(params, id));
  };

  return (
    <Grid container>
      <Grid item md={12} xs={12}>
        {categoryTemplateError ? (
          <Alert className={classes.alert} severity="error">
            {categoryTemplateError}
          </Alert>
        ) : null}
        <Button
          className={classNames(classes.floatRight, classes.marginRight2)}
          color="primary"
          data-testid="button-add-category"
          onClick={addTemplate}
          variant="contained">
          <Typography className={classes.buttonLabel} variant="body1">
            <FormattedMessage
              defaultMessage="Add Template"
              id="admin.ADD_TEMPLATE"
            />
          </Typography>
        </Button>
      </Grid>
      <Grid item md={12} xs={12}>
        <List>
          {templates.map(
            ({ document_categories, edit, errors, name, touched }, index) => {
              return (
                <Fragment key={index}>
                  <Divider className={classes.marginTop2} />
                  <ListItem button onClick={() => handleDisplay(index)}>
                    {selected[index] ? <ExpandLess /> : <ExpandMore />}
                    <ListItemText>
                      {edit ? (
                        <TextField
                          error={hasError(errors, NAME)}
                          helperText={
                            hasError(errors, NAME) ? errors.name[0] : null
                          }
                          inputProps={{ 'data-testid': 'input-template-name' }}
                          onChange={e =>
                            updateTemplateValues(e, index, NAME, e.target.value)
                          }
                          onClick={e => e.stopPropagation()}
                          size="small"
                          style={{
                            width:
                              screenWidth === WIDTH_XS
                                ? '75%'
                                : touched
                                ? '94%'
                                : '97%'
                          }}
                          value={name || ''}
                        />
                      ) : (
                        <span data-testid="span-template-name">{name}</span>
                      )}
                    </ListItemText>
                    <ListItemSecondaryAction edge="end">
                      <IconButton
                        onClick={e => handleEdit(e, index)}
                        size="small">
                        {edit ? <ClearIcon /> : <EditIcon />}
                      </IconButton>
                      <IconButton
                        className={classes.deleteIcon}
                        data-testid="button-delete-template"
                        onClick={e => handleDelete(e, index)}
                        size="small">
                        <DeleteIcon />
                      </IconButton>
                      {touched ? (
                        <IconButton
                          className={classes.saveIcon}
                          data-testid="button-save-template"
                          disabled={
                            hasError(errors, NAME) ||
                            !isObjectEmpty(errors.document_categories)
                          }
                          onClick={e => handleSave(e, index)}
                          size="small">
                          <SaveIcon />
                        </IconButton>
                      ) : null}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Collapse in={selected[index]} timeout="auto" unmountOnExit>
                    <CategoryTemplate
                      admin
                      categories={document_categories}
                      errors={errors?.document_categories || {}}
                      index={index}
                      prevCategories={prevTemplates[index].document_categories}
                      updateTemplateValues={updateTemplateValues}
                    />
                  </Collapse>
                </Fragment>
              );
            }
          )}
        </List>
      </Grid>
    </Grid>
  );
};

Category.propTypes = {
  organizationId: PropTypes.string
};

export default Category;
