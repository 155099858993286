import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { BoqTable, WysWyg } from '../../components';
import { BoqTable as BoqTableView } from '../../ObjectView/components';
import { formatDate } from 'common/helper';
import { formatForEditor, showBoQApprovalStatus } from '../helper';
import { useStyles } from '../../styles';
import {
  DEFAULT_DATE_TIME_FORMAT,
  REVISION_VALUES,
  STATUS_DRAFT,
  VALUES
} from 'common/constants';

const Boq = props => {
  const classes = useStyles();
  const {
    clearTimer,
    disableEdit,
    displayObjectView,
    formState,
    handleCommentChange,
    handleMentionItems,
    handleNewObject,
    isCreatorOrg,
    isLatestRevision,
    isRevision,
    projectId,
    setFormState,
    setHasPopup,
    suggestions
  } = props;

  const valsKey = isRevision ? REVISION_VALUES : VALUES;
  const {
    access_policy_comments,
    comments = [],
    id: objId,
    metadata: {
      approvedQuantity,
      boq_id,
      contractPercentage,
      contractQuantity,
      delmalebrev,
      measuredQuantity,
      text,
      unit
    },
    new_comment,
    status
  } = formState[valsKey];
  const disableComment =
    status?.name !== STATUS_DRAFT
      ? (objId && !access_policy_comments) || (!isLatestRevision && !isRevision)
      : false;
  const formattedText = formatForEditor(text);
  const showApprovalStatus = showBoQApprovalStatus(isCreatorOrg, status?.name);

  return (
    <>
      <Divider />
      <CardContent className={classes.grayContent}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <TextField
              className={classes.whiteBackground}
              disabled
              fullWidth
              label={
                <FormattedMessage
                  defaultMessage="Measured quantity"
                  id="object.MEASURED_QUANTITY"
                />
              }
              size="small"
              value={measuredQuantity}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={6}>
            <TextField
              className={classes.whiteBackground}
              disabled
              fullWidth
              label={
                <FormattedMessage defaultMessage="Unit" id="object.UNIT" />
              }
              size="small"
              value={unit}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={6}>
            <TextField
              className={classes.whiteBackground}
              disabled
              fullWidth
              label={
                <FormattedMessage
                  defaultMessage="Contract quantity"
                  id="object.CONTRACT_QUANTITY"
                />
              }
              size="small"
              value={contractQuantity}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              className={classes.whiteBackground}
              disabled
              fullWidth
              label={
                <FormattedMessage
                  defaultMessage="Approved quantity"
                  id="object.APPROVED_QUANTITY"
                />
              }
              size="small"
              value={approvedQuantity}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={6} />
          <Grid item md={3} xs={6}>
            <TextField
              className={classes.whiteBackground}
              disabled
              fullWidth
              InputLabelProps={{ shrink: true }}
              label={
                <FormattedMessage
                  defaultMessage="Per cent of Contract total"
                  id="object.CONTRACT_TOTAL"
                />
              }
              size="small"
              value={contractPercentage}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardContent className={classes.grayContent}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {disableEdit && !isRevision ? (
              <BoqTableView
                data={delmalebrev}
                showApprovalStatus={showApprovalStatus}
              />
            ) : (
              <BoqTable
                data={delmalebrev}
                disableEdit={!isCreatorOrg && !objId}
                errors={formState.errors}
                isCreatorOrg={isCreatorOrg}
                isRevision={isRevision}
                setFormState={setFormState}
                showApprovalStatus={showApprovalStatus}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <WysWyg
              defaultValue={formattedText}
              key="boq_text"
              name="boq_text"
              readOnly
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.boldLabel}>
              <FormattedMessage
                defaultMessage="Comments"
                id="object.COMMENTS"
              />
              :
            </Typography>
          </Grid>
          {comments.map((comment, index) => (
            <Fragment key={`comment_details_${index}`}>
              <Grid item md={12} style={{ paddingBottom: 0 }} xs={12}>
                <Grid container justifyContent="space-between">
                  <Typography>
                    {comment.user?.name
                      ? `${comment.user.name}:`
                      : comment.user?.email
                      ? `${comment.user.email}:`
                      : ''}
                  </Typography>
                  <Typography>
                    {formatDate(comment.date, DEFAULT_DATE_TIME_FORMAT)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item key={`grid_comment_${index}`} md={12} xs={12}>
                <WysWyg
                  defaultValue={comment.content}
                  displayObjectView={displayObjectView}
                  handleMentionItems={handleMentionItems}
                  ignoreExistingUserMentions
                  key={`${objId}_comment_${index}`}
                  mentionedObjs={formState.mentionedObjs}
                  mentionedUsers={formState.mentionedUsers}
                  name={`${objId}_comment_${index}`}
                  readOnly
                />
              </Grid>
            </Fragment>
          ))}
          {disableComment && comments.length > 0 ? null : (
            <Grid item md={12} xs={12}>
              <WysWyg
                boqId={boq_id}
                clearTimer={clearTimer}
                defaultValue={new_comment?.content}
                displayObjectView={displayObjectView}
                handleMentionItems={handleMentionItems}
                handleParentEditorChange={handleCommentChange}
                handleParentNewObject={handleNewObject}
                ignoreExistingUserMentions
                key={`${objId}_new_comment`}
                name={`${objId}_new_comment`}
                projectId={projectId}
                readOnly={disableComment}
                setHasPopup={setHasPopup}
                suggestions={suggestions}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </>
  );
};

Boq.propTypes = {
  clearTimer: PropTypes.func,
  disableEdit: PropTypes.bool,
  displayObjectView: PropTypes.func,
  formState: PropTypes.object,
  handleCommentChange: PropTypes.func,
  handleMentionItems: PropTypes.func,
  handleNewObject: PropTypes.func,
  isCreatorOrg: PropTypes.bool,
  isLatestRevision: PropTypes.bool,
  isRevision: PropTypes.bool,
  projectId: PropTypes.string,
  setFormState: PropTypes.func,
  setHasPopup: PropTypes.func,
  suggestions: PropTypes.array
};

export default Boq;
