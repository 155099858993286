import * as standardTypes from './StandardTypes';

const initialState = {
  error: null,
  loading: false,
  contracts: []
};

const standardReducer = (state = initialState, action) => {
  switch (action.type) {
    case standardTypes.STANDARD_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case standardTypes.STANDARD_REQUEST:
      return {
        ...state,
        error: null,
        loading: true
      };
    case standardTypes.STANDARD_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        contracts: action.payload
      };
    default:
      return state;
  }
};

export default standardReducer;
