import * as organizationTypes from './OrganizationTypes';

const initialState = {
  loading: false,
  organizationTemplateError: '',
  organizationTemplateUpdated: false,
  organizationTemplates: [],
  organizations: [],
  organizationsAll: [],
  error: '',
  created: null,
  deleted: null,
  invited: null,
  projectInvite: null,
  updated: null,
  types: null,
  userUpdated: null,
  userDeleted: null,
  discAdded: null,
  discUpdated: null,
  orgProjUpdated: null
};

const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case organizationTypes.ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        created: null,
        deleted: null,
        invited: null,
        userUpdated: null,
        userDeleted: null,
        projectInvite: null,
        discAdded: null,
        discDeleted: null
      };
    case organizationTypes.ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        organizations: action.payload,
        error: '',
        updated: action.updated
      };
    case organizationTypes.ORGANIZATION_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        organizationsAll: action.payload,
        error: ''
      };
    case organizationTypes.ORGANIZATION_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case organizationTypes.ORGANIZATION_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        created: {
          organization: action.payload,
          error: ''
        }
      };
    case organizationTypes.ORGANIZATION_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        created: {
          error: action.payload
        }
      };
    case organizationTypes.ORGANIZATION_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleted: {
          error: ''
        }
      };
    case organizationTypes.ORGANIZATION_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        deleted: {
          error: action.payload
        }
      };
    case organizationTypes.ORGANIZATION_TYPE_LOADING:
      return { ...state, types: { ...state.types, loading: true } };
    case organizationTypes.ORGANIZATION_TYPE_SUCCESS:
      return {
        ...state,
        types: {
          ...state.types,
          loading: false,
          data: action.payload,
          error: ''
        }
      };
    case organizationTypes.ORGANIZATION_TYPE_FAILURE:
      return {
        ...state,
        types: { ...state.types, loading: false, error: action.payload }
      };
    case organizationTypes.ORGANIZATION_INVITE_SUCCESS:
      return { ...state, loading: false, invited: { error: '' } };
    case organizationTypes.ORGANIZATION_INVITE_FAILURE:
      return { ...state, loading: false, invited: { error: action.payload } };
    case organizationTypes.ORGANIZATION_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        updated: {
          organization: action.payload,
          error: ''
        }
      };
    case organizationTypes.ORGANIZATION_USER_DELETE_SUCCESS:
      return { ...state, loading: false, userDeleted: { error: '' } };
    case organizationTypes.ORGANIZATION_USER_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        userDeleted: { error: action.payload }
      };
    case organizationTypes.ORGANIZATION_USER_UPDATE_SUCCESS:
      return { ...state, loading: false, userUpdated: { error: '' } };
    case organizationTypes.ORGANIZATION_USER_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        userUpdated: { error: action.payload }
      };
    case organizationTypes.ORGANIZATION_PROJECT_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        orgProjUpdated: { org: action.payload, error: '' }
      };
    case organizationTypes.ORGANIZATION_PROJECT_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        orgProjUpdated: { error: action.payload }
      };
    case organizationTypes.ORGANIZATION_PROJECT_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
        updated: true,
        projectInvite: { project: action.payload, error: '' }
      };
    case organizationTypes.ORGANIZATION_PROJECT_INVITE_FAILURE:
      return {
        ...state,
        loading: false,
        projectInvite: { error: action.payload }
      };
    case organizationTypes.ORGANIZATION_DISC_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        discAdded: { discipline: action.payload, error: '' }
      };
    case organizationTypes.ORGANIZATION_DISC_CREATE_FAILURE:
      return { ...state, loading: false, discAdded: { error: action.payload } };
    case organizationTypes.ORGANIZATION_DISC_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        discUpdated: { discipline: action.payload, error: '' }
      };
    case organizationTypes.ORGANIZATION_DISC_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        discUpdated: { error: action.payload }
      };
    case organizationTypes.ORGANIZATION_MEETING_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
        organizationTemplates: [],
        organizationTemplateUpdated: false
      };
    case organizationTypes.ORGANIZATION_MEETING_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        organizationTemplates: action.payload,
        organizationTemplateError: ''
      };
    case organizationTypes.ORGANIZATION_MEETING_TEMPLATE_SUBMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        organizationTemplateError: '',
        organizationTemplateUpdated: true
      };
    case organizationTypes.ORGANIZATION_MEETING_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        organizationTemplateError: action.payload,
        organizationTemplateUpdated: false
      };
    case organizationTypes.ORGANIZATION_INITIAL_STATE:
      return initialState;
    case organizationTypes.ORGANIZATION_CLEAR_MESSAGES:
      return { ...state, projectInvite: null };
    default:
      return state;
  }
};

export default organizationReducer;
