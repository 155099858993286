import Request from 'common/request';
import { LANGUAGE_NB, LS_LANGUAGE, PEGASUS_URL } from 'common/constants';

export default class PdfApi extends Request {
  constructor() {
    const baseUrl = process.env.REACT_APP_PEGASUS_URL || PEGASUS_URL;
    super(baseUrl);
    this.endpoint = '/pegasus';
  }

  generate(params) {
    params.language = localStorage.getItem(LS_LANGUAGE) || LANGUAGE_NB;
    return super.post(this.endpoint, params, true);
  }
}
