export const OBJECT_CONFLICT = 'OBJECT_CONFLICT';
export const OBJECT_REQUEST = 'OBJECT_REQUEST';
export const OBJECT_FAILURE = 'OBJECT_FAILURE';
export const OBJECT_FETCH_FAILURE = 'OBJECT_FETCH_FAILURE';
export const OBJECT_SUCCESS = 'OBJECT_SUCCESS';
export const OBJECT_CREATE_SUCCESS = 'OBJECT_CREATE_SUCCESS';
export const OBJECT_UPDATE_SUCCESS = 'OBJECT_UPDATE_SUCCESS';
export const OBJECT_UPDATE_CUSTOM_RESPONSE = 'OBJECT_UPDATE_CUSTOM_RESPONSE';
export const OBJECT_UPDATE_ROUTE_SUCCESS = 'OBJECT_UPDATE_ROUTE_SUCCESS';
export const OBJECT_MENTION_CREATE_SUCCESS = 'OBJECT_MENTION_CREATE_SUCCESS';
export const OBJECT_REVISION_FETCH_SUCCESS = 'OBJECT_REVISION_FETCH_SUCCESS';
export const OBJECT_REVISION_FETCH_FAILURE = 'OBJECT_REVISION_FETCH_FAILURE';
export const OBJECT_REVISION_REQUEST = 'OBJECT_REVISION_REQUEST';
export const OBJECT_REVISION_TYPES_FETCH_SUCCESS =
  'OBJECT_REVISION_TYPES_FETCH_SUCCESS';
export const OBJECT_TEMPLATE_FETCH_SUCCESS = 'OBJECT_TEMPLATE_FETCH_SUCCESS';
export const OBJECT_TEMPLATE_CREATE_SUCCESS = 'OBJECT_TEMPLATE_CREATE_SUCCESS';
export const OBJECT_TEMPLATE_UPDATE_SUCCESS = 'OBJECT_TEMPLATE_UPDATE_SUCCESS';
export const OBJECT_TEMPLATE_DELETE_SUCCESS = 'OBJECT_TEMPLATE_DELETE_SUCCESS';
export const OBJECT_BOQ_FETCH_SUCCESS = 'OBJECT_BOQ_FETCH_SUCCESS';
export const OBJECT_BOQ_RESET = 'OBJECT_BOQ_RESET';
export const OBJECT_TYPE_SUCCESS = 'OBJECT_TYPE_SUCCESS';
export const OBJECT_TYPE_FILTER_SUCCESS = 'OBJECT_TYPE_FILTER_SUCCESS';
export const OBJECT_FETCH_SUCCESS = 'OBJECT_FETCH_SUCCESS';
export const OBJECT_VIEW_SUCCESS = 'OBJECT_VIEW_SUCCESS';
export const OBJECT_VIEW_FAILURE = 'OBJECT_VIEW_FAILURE';
export const OBJECT_STATUS_SUCCESS = 'OBJECT_STATUS_SUCCESS';
export const OBJECT_STATUS_ALL_SUCCESS = 'OBJECT_STATUS_ALL_SUCCESS';
export const ACCESS_TYPE_SUCCESS = 'ACCESS_TYPE_SUCCESS';
export const ACCESS_TYPE_FAILURE = 'ACCESS_TYPE_FAILURE';
export const DISCIPLINE_TYPE_SUCCESS = 'DISCIPLINE_TYPE_SUCCESS';
export const DISCIPLINE_STATUS_SUCCESS = 'DISCIPLINE_STATUS_SUCCESS';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const DELETE_FAILURE = 'DELETE_FAILURE';
export const OBJECT_CLEAR_MESSAGES = 'OBJECT_CLEAR_MESSAGES';
export const OBJECT_CLEAR_UPDATED = 'OBJECT_CLEAR_UPDATED';
export const OBJECT_CLEAR_CUSTOM_NOTICE = 'OBJECT_CLEAR_CUSTOM_NOTICE';
export const UPDATE_OBJECT_SELECTED = 'UPDATE_OBJECT_SELECTED';
export const ATTACHMENT_DUPLICATES_SUCCESS = 'ATTACHMENT_DUPLICATES_SUCCESS';
export const ATTACHMENT_DUPLICATES_FAILURE = 'ATTACHMENT_DUPLICATES_FAILURE';
export const ATTACHMENT_DUPLICATES_RESET = 'ATTACHMENT_DUPLICATES_RESET';
export const MEETING_CHECK_SUCCESS = 'MEETING_CHECK_SUCCESS';
export const MEETING_CHECK_FAILURE = 'MEETING_CHECK_FAILURE';
export const MEETING_CHECK_CLEAR = 'MEETING_CHECK_CLEAR';
