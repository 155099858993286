import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Route } from 'react-router-dom';
import withWidth from '@material-ui/core/withWidth';
import {
  screenSetOrientation,
  screenSetWidth,
  setIsMobileDevice
} from 'redux/screen/ScreenAction';
import {
  getScreenOrientation,
  isMobileDevice,
  isMobileView
} from 'common/helper';

const RouteWithLayout = props => {
  const {
    component: Component,
    layout: Layout,
    width,
    ...rest
  } = props;
  const dispatch = useDispatch();
  const isMd = isMobileDevice();

  useEffect(() => {
    dispatch(screenSetWidth(width));
    dispatch(setIsMobileDevice(isMd && isMobileView(width)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, width]);

  useEffect(() => {
    if (isMd) {
      dispatch(screenSetOrientation(getScreenOrientation(window.orientation)));
      window.addEventListener('orientationchange', () => {
        dispatch(
          screenSetOrientation(getScreenOrientation(window.orientation))
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Route
      {...rest}
      render={matchProps => {
        return (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        );
      }}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired
};

export default withWidth()(RouteWithLayout);
