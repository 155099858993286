import React from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';

const HorizontalTextDivider = ({ children, ...props }) => {
  return (
    <Grid container alignItems="center" spacing={1} {...props}>
      <Grid item xs>
        <Divider />
      </Grid>
      <Grid item>
        <Typography variant="caption">{children}</Typography>
      </Grid>
      <Grid item xs>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default HorizontalTextDivider;
