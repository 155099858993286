import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { FormattedMessage } from 'react-intl';
import { Button, Divider, TextField, Typography } from '@material-ui/core';
import { NoButtonPopup } from 'components';
import { NewObject } from '../../components';
import { getDisplayName } from '../../components/helper';
import { makeStyles } from '@material-ui/core/styles';
import { objectsFetch } from 'redux/object/ObjectAction';
import { serializeParams } from 'common/helper';
import { useStyles } from '../../styles';
import {
  NEW,
  OBJECT_DOCUMENTATION,
  OBJECTS_ALL,
  SETTLEMENT
} from 'common/constants';

const acClasses = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 0)
  }
}));

const NewLinkedObject = props => {
  const {
    existing,
    isSettlement,
    objects,
    objectTypes,
    onPopupClose,
    onSubmit,
    projectId,
    recipientOrgId
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    object: {
      objectsAll: { content: objectsAll = [] },
      objectsSettlement: { content: objectsSettlement = [] }
    }
  } = useSelector(state => state);

  const [state, setState] = useState({
    linkedObjectList: [],
    popupState: { open: false, selectedObj: null },
    selectedObjs: objects
  });
  const { linkedObjectList, popupState, selectedObjs } = state;

  useEffect(() => {
    dispatch(
      objectsFetch(
        projectId,
        serializeParams({
          paginate: false,
          ...(isSettlement
            ? { recipient_organization_id: recipientOrgId, type: SETTLEMENT }
            : {})
        }),
        isSettlement ? 'objectsSettlement' : OBJECTS_ALL
      )
    );
  }, [dispatch, isSettlement, projectId, recipientOrgId]);

  useEffect(() => {
    const sourceList = isSettlement ? objectsSettlement : objectsAll;
    if (sourceList.length > 0) {
      const existingIds = new Set(existing.map(ex => ex.id));
      let filteredObjects = sourceList.reduce((acc, obj) => {
        if (!existingIds.has(obj.id))
          acc.push({ ...obj, name: getDisplayName(obj.name) });
        return acc;
      }, []);
      if (!isSettlement) {
        filteredObjects = filteredObjects.concat(
          objectTypes.map(type => ({
            name: NEW,
            title: `${type.name}`,
            object_type: type
          }))
        );
      }

      filteredObjects = filteredObjects.sort(
        (a, b) => -b.object_type.name.localeCompare(a.object_type.name)
      );
      setState(s => ({ ...s, linkedObjectList: filteredObjects }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    existing,
    objectsAll,
    objectsSettlement,
    objectTypes,
    projectId,
    dispatch
  ]);

  const handleCloseUp = () =>
    setState(s => ({
      ...s,
      popupState: { open: false, selectedObj: null }
    }));

  const handleListChange = (_event, value) => {
    const addedVal = value && value.length > 0 && value[value.length - 1];
    if (addedVal && addedVal.name === NEW)
      setState(s => ({
        ...s,
        popupState: { open: true, selectedObj: addedVal.object_type }
      }));
    else
      setState(s => ({
        ...s,
        selectedObjs: value
      }));
  };

  const handleSubmit = () => {
    onSubmit(selectedObjs);
    onPopupClose();
  };

  const handleNewObject = newObj => {
    setState(s => ({
      ...s,
      selectedObjs: [...s.selectedObjs, newObj]
    }));
    handleCloseUp();
  };

  return (
    <div className={classes.componentPopupRoot}>
      {isSettlement ? null : (
        <>
          <Typography variant="h3">
            <FormattedMessage
              defaultMessage="Linked Objects"
              id="common.LINKED OBJECTS"
            />
          </Typography>
          <Divider className={classes.dividerMargin} />
        </>
      )}
      <Autocomplete
        classes={acClasses()}
        disableCloseOnSelect
        filterSelectedOptions
        getOptionLabel={option => `${option.name} ${option.title}`}
        groupBy={option =>
          option.object_type.name.includes(OBJECT_DOCUMENTATION)
            ? OBJECT_DOCUMENTATION
            : option.object_type.name
        }
        id="objects"
        multiple
        onChange={handleListChange}
        options={linkedObjectList}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            label="Objects"
            size="small"
            variant="outlined"
          />
        )}
        size="small"
        value={selectedObjs}
      />
      <Divider className={classes.dividerMargin} />
      <div>
        <Button
          color="primary"
          disabled={selectedObjs.length < 1 && !isSettlement}
          onClick={handleSubmit}
          type="submit"
          variant="contained">
          {isSettlement ? (
            <FormattedMessage defaultMessage="Save" id="common.SAVE" />
          ) : (
            <FormattedMessage defaultMessage="Add Link" id="common.ADD_LINK" />
          )}
        </Button>
      </div>
      <NoButtonPopup handleParentClose={handleCloseUp} show={popupState.open}>
        <NewObject
          handleParentFormState={handleNewObject}
          objectTypes={objectTypes}
          projectId={projectId}
          selectedObjType={popupState.selectedObj}
        />
      </NoButtonPopup>
    </div>
  );
};

NewLinkedObject.propTypes = {
  existing: PropTypes.arrayOf(PropTypes.object),
  isSettlement: PropTypes.bool,
  objects: PropTypes.array,
  objectTypes: PropTypes.array,
  onPopupClose: PropTypes.func,
  onSubmit: PropTypes.func,
  projectId: PropTypes.string,
  recipientOrgId: PropTypes.string
};

NewLinkedObject.defaultProps = { isSettlement: false, objects: [] };

export default NewLinkedObject;
