import {
  ACTION_CURR_OBJ_DELETED,
  ACTION_DELETED,
  COMMUNICATION,
  DOCUMENTATION,
  INFO_REVISIONS,
  OBJECT,
  OBJECTS,
  OBJECTS_ALL,
  VIEW_REVISIONS
} from 'common/constants';
import {
  getUpdatedObjectFromRevisions,
  processCommData,
  processDocData
} from 'views/Object/components/helper';
import * as objTypes from './ObjectTypes';
const initialState = {
  loading: false,
  types: [],
  filterTypes: {
    doc_statuses: [],
    object_type: [],
    organization: []
  },
  accessTypes: [],
  disciplineTypes: [],
  error: '',
  fetchError: '',
  objects: {},
  objectsAll: {},
  objectsSettlement: {},
  updated: false,
  deleted: false,
  currObjDeleted: false,
  objectSelected: {},
  objectView: {},
  infoRevisions: [],
  viewRevisions: [],
  revLoading: false,
  revisionTypes: [],
  saveSuccess: false,
  status: [],
  statusAll: [],
  disciplineStatus: [],
  templates: {},
  updatedObjFromConflict: null,
  boqObject: {},
  duplicateLoading: false,
  duplicateFiles: null,
  duplicateError: '',
  customNotice: '',
  meetingChecked: false,
  meetingCheckError: '',
  meetingSeriesCount: 0
};

const objectReducer = (state = initialState, action) => {
  switch (action.type) {
    case objTypes.OBJECT_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        objectSelected: {},
        objectMentionCreated: {},
        deleted: false,
        currObjDeleted: false,
        isMention: false,
        templateId: '',
        meetingSeriesCount: 0
      };
    case objTypes.OBJECT_SUCCESS: {
      let payload = action.payload;
      if (action.field === OBJECTS) {
        let content = payload.content;
        // Process the data first before returning
        switch (action.objectType) {
          case COMMUNICATION:
          case OBJECT:
            payload.content = processCommData(content);
            break;
          case DOCUMENTATION:
            payload.content = processDocData(content);
            break;
          default:
            break;
        }
      }
      return {
        ...state,
        error: '',
        loading: false,
        [action.field]: payload,
        updatedObjFromConflict:
          action.field === OBJECTS_ALL ? state.updatedObjFromConflict : null
      };
    }
    case objTypes.OBJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        templateUpdated: false,
        updatedObjFromConflict: null
      };
    case objTypes.OBJECT_CONFLICT:
      return {
        ...state,
        loading: false,
        templateUpdated: false,
        updatedObjFromConflict: action.payload
      };
    case objTypes.OBJECT_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        fetchError: action.payload
      };
    case objTypes.OBJECT_VIEW_FAILURE:
      return {
        ...state,
        loading: false,
        fetchViewError: action.payload
      };
    case objTypes.OBJECT_TYPE_SUCCESS:
      return { ...state, loading: false, types: action.payload, error: '' };
    case objTypes.OBJECT_TYPE_FILTER_SUCCESS:
      return {
        ...state,
        // loading: false, // TODO: Temporary fix for flashing objects list. Need to refactor ObjectList to only dispatch one action when fetching so that setting of loading can be condensed
        filterTypes: action.payload,
        error: ''
      };
    case objTypes.DISCIPLINE_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        disciplineTypes: action.payload,
        error: ''
      };
    case objTypes.OBJECT_CREATE_SUCCESS:
    case objTypes.OBJECT_UPDATE_SUCCESS: {
      if (action.payload.status) {
        const currObjdIdx = (state.objects?.content || []).findIndex(
          o => o.id === action.payload.id
        );

        if (currObjdIdx > -1) {
          const currentObj = state.objects.content[currObjdIdx];

          if (
            currentObj.status &&
            currentObj.status.id !== action.payload.status.id
          )
            state.objects.content[currObjdIdx] = {
              ...currentObj,
              status: action.payload.status
            };
        }

        if (state.objectView?.id === action.payload.id)
          state.objectView = {
            ...state.objectView,
            id: action.payload.id,
            status: action.payload.status
          };
      }

      return {
        ...state,
        loading: false,
        error: '',
        updated: true,
        objectSelected: action.payload,
        saveSuccess: true,
        meetingChecked: false
      };
    }
    case objTypes.OBJECT_UPDATE_CUSTOM_RESPONSE: {
      return {
        ...state,
        customNotice: action.payload,
        loading: false,
        error: '',
        updated: true,
        saveSuccess: true
      };
    }
    case objTypes.OBJECT_UPDATE_ROUTE_SUCCESS:
      return { ...state, loading: false, error: '', updated: true };
    case objTypes.OBJECT_FETCH_SUCCESS: {
      let newState = {};
      if (action.payload.id === state.objectView.id)
        newState.objectView = action.payload;
      return {
        ...state,
        ...newState,
        loading: false,
        error: '',
        fetchError: '',
        updated: action.updated,
        objectSelected: action.payload,
        infoRevisions: [],
        viewRevisions: []
      };
    }
    case objTypes.OBJECT_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        fetchViewError: '',
        updated: action.updated,
        objectView: action.payload,
        updatedObjFromConflict: action.payload.metadata
          .duplicate_revision_object_id
          ? { id: action.payload.metadata.duplicate_revision_object_id }
          : null
      };
    case objTypes.OBJECT_MENTION_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        objectMentionCreated: action.payload
      };
    case objTypes.OBJECT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload,
        error: ''
      };
    case objTypes.OBJECT_STATUS_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        statusAll: action.payload,
        error: ''
      };
    case objTypes.DISCIPLINE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        disciplineStatus: action.payload,
        error: ''
      };
    case objTypes.DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        [action.route === OBJECTS && !action.isMention
          ? ACTION_CURR_OBJ_DELETED
          : ACTION_DELETED]: true,
        error: '',
        updatedObjFromConflict: null
      };
    case objTypes.DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        deleted: false,
        error: action.payload
      };
    case objTypes.ACCESS_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        accessTypes: action.payload,
        error: ''
      };
    case objTypes.ACCESS_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case objTypes.OBJECT_TEMPLATE_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        templates: action.payload,
        error: '',
        templateUpdated: false,
        templateDeleted: false
      };
    case objTypes.OBJECT_TEMPLATE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        templateUpdated: true,
        templateId: action.payload.id
      };
    case objTypes.OBJECT_TEMPLATE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        templateUpdated: true,
        objectSelected: action.payload
      };
    case objTypes.OBJECT_TEMPLATE_DELETE_SUCCESS:
      return { ...state, loading: false, error: '', templateDeleted: true };
    case objTypes.OBJECT_BOQ_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        boqObject: action.payload,
        error: '',
        templateUpdated: false,
        templateDeleted: false
      };
    case objTypes.OBJECT_BOQ_RESET:
      return {
        ...state,
        boqObject: {}
      };
    case objTypes.OBJECT_REVISION_REQUEST:
      return {
        ...state,
        revLoading: true
      };
    case objTypes.OBJECT_REVISION_FETCH_SUCCESS: {
      const revisionField = action.isView ? VIEW_REVISIONS : INFO_REVISIONS;
      return {
        ...state,
        revLoading: false,
        currObjDeleted: false,
        error: '',
        [revisionField]: action.payload.revisions,
        updatedObjFromConflict: getUpdatedObjectFromRevisions(
          action.payload.revisions
        )
      };
    }
    case objTypes.OBJECT_REVISION_TYPES_FETCH_SUCCESS: {
      return {
        ...state,
        error: '',
        revisionTypes: action.payload
      };
    }
    case objTypes.OBJECT_CLEAR_MESSAGES:
      return {
        ...state,
        error: '',
        saveSuccess: false,
        fetchError: '',
        fetchViewError: '',
        meetingCheckError: '',
        meetingChecked: false
      };
    case objTypes.OBJECT_CLEAR_UPDATED:
      return { ...state, updated: false };
    case objTypes.OBJECT_CLEAR_CUSTOM_NOTICE:
      return { ...state, customNotice: '' };
    case objTypes.UPDATE_OBJECT_SELECTED:
      return { ...state, objectSelected: action.payload };
    case objTypes.ATTACHMENT_DUPLICATES_SUCCESS:
      return {
        ...state,
        duplicateLoading: false,
        duplicateFiles: action.payload,
        duplicateError: ''
      };
    case objTypes.ATTACHMENT_DUPLICATES_FAILURE:
      return {
        ...state,
        duplicateLoading: false,
        duplicateFiles: null,
        duplicateError: action.payload
      };
    case objTypes.ATTACHMENT_DUPLICATES_RESET:
      return {
        ...state,
        duplicateLoading: !!action.payload,
        duplicateError: '',
        duplicateFiles: null
      };
    case objTypes.MEETING_CHECK_SUCCESS:
      return {
        ...state,
        meetingChecked: true,
        meetingCheckError: '',
        meetingSeriesCount: action.payload?.latest_meeting_no || 0
      };
    case objTypes.MEETING_CHECK_FAILURE:
      return {
        ...state,
        meetingChecked: true,
        meetingCheckError: action.payload
      };
    case objTypes.MEETING_CHECK_CLEAR:
      return { ...state, meetingChecked: false, meetingCheckError: '' };
    default:
      return state;
  }
};

export default objectReducer;
