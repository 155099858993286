import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core';
import {
  Delete as DeleteIcon,
  GetApp as DownloadIcon,
  Info as InfoIcon,
  Refresh as RefreshIcon
} from '@material-ui/icons';
import { SearchBox } from 'components';
import {
  downloadS3File,
  formatDate,
  formatExportRequestId
} from 'common/helper';
import { deleteExport, exportListFetch } from 'redux/export/ExportAction';
import { useStyles } from 'views/Admin/styles';
import {
  DEFAULT_DATE_FORMAT,
  STATUS_AVAILABLE_FOR_DOWNLOAD,
  STATUS_FAILED
} from 'common/constants';

const Exports = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { deleted, error, exports } = useSelector(state => state.export);

  const [state, setState] = useState({
    data: exports,
    searchText: ''
  });

  useEffect(() => {
    handleRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    setState(state => ({ ...state, data: exports }));
  }, [exports]);

  useEffect(() => {
    if (deleted) handleRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleted]);

  const handleRefresh = () => dispatch(exportListFetch());

  const deleteExportedFile = exportId => {
    const del = window.confirm(
      intl.formatMessage(
        { id: 'object.DELETE_ITEM_CONFIRMATION' },
        { name: '' }
      ) +
        '\n' +
        intl.formatMessage({ id: 'common.ACTION_CANNOT_UNDONE' })
    );
    if (del) dispatch(deleteExport(exportId));
  };

  const filterData = (event, filterText = '') => {
    if (event) event.preventDefault();
    setState(state => {
      const upCaseText = filterText.toUpperCase();
      const data = upCaseText
        ? exports.filter(
            d =>
              d.file.toUpperCase().includes(upCaseText) ||
              d.project?.name.toUpperCase().includes(upCaseText) ||
              formatExportRequestId(d.id).includes(upCaseText)
          )
        : exports;
      return { ...state, data };
    });
  };

  const setSearchText = text => {
    setState(state => ({ ...state, searchText: text }));
    filterData(null, text);
  };

  const resetState = () => {
    setState(state => ({ ...state, data: exports, searchText: '' }));
    filterData();
  };

  return (
    <div className={classes.userRoot}>
      <Grid container spacing={3}>
        {error ? (
          <Grid item xs={12}>
            <Alert severity="error" size="small">
              {error}
            </Alert>
          </Grid>
        ) : null}
        <Grid item md={7} xs={false} />
        <Grid item md={5} xs={12}>
          <SearchBox
            onChange={setSearchText}
            onClearButtonClick={resetState}
            onSearchButtonClick={filterData}
            onSubmit={filterData}
            searchText={state.searchText}
          />
        </Grid>
        <Grid item xs={12}>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table size="small" stickyHeader>
              <colgroup>
                <col width="25%" />
                <col width="50%" />
                <col width="25%" />
                <col />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage
                      defaultMessage="Request Date"
                      id="admin.REQUEST_DATE"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      defaultMessage="Project Name"
                      id="project.PROJECT_NAME"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      defaultMessage="Status"
                      id="common.STATUS"
                    />
                  </TableCell>
                  <TableCell style={{ paddingBottom: 0 }}>
                    <Tooltip
                      title={
                        <FormattedMessage
                          defaultMessage="Refresh page data"
                          id="admin.REFRESH_PAGE_DATA"
                        />
                      }>
                      <IconButton onClick={handleRefresh} size="small">
                        <RefreshIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.data.map(item => (
                  <TableRow key={item.id}>
                    <TableCell>
                      {formatDate(item.request_date, DEFAULT_DATE_FORMAT)}
                    </TableCell>
                    <TableCell>{item.project.name}</TableCell>
                    <TableCell>
                      <FormattedMessage
                        defaultMessage={item.status.name}
                        id={'export.' + item.status.name.toUpperCase()}
                      />
                    </TableCell>
                    <TableCell>
                      {item.status.name === STATUS_AVAILABLE_FOR_DOWNLOAD ? (
                        <Box display="flex" justifyContent="flex-end">
                          <Tooltip
                            title={
                              <FormattedMessage
                                defaultMessage="Download"
                                id="common.DOWNLOAD"
                              />
                            }>
                            <IconButton
                              onClick={() => downloadS3File(item.file)}
                              size="small">
                              <DownloadIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title={
                              <FormattedMessage
                                defaultMessage="Delete exported file"
                                id="admin.DELETE_EXPORTED_FILE"
                              />
                            }>
                            <IconButton
                              className={classes.deleteIcon}
                              onClick={() => deleteExportedFile(item.id)}
                              size="small">
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      ) : item.status.name === STATUS_FAILED ? (
                        <Tooltip
                          title={
                            <FormattedMessage
                              defaultMessage="Please contact Str8line Support."
                              id="export.FAILED_MESSAGE"
                            />
                          }>
                          <InfoIcon
                            className={classes.deleteIcon}
                            fontSize="small"
                          />
                        </Tooltip>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default Exports;
