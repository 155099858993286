import React, { Fragment, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Button,
  Popover
} from '@material-ui/core';
import {
  WatchLater as WatchLaterIcon,
  ExpandLess,
  ExpandMore
} from '@material-ui/icons';
import { formatDate, isObjectEmpty, valueInArray } from 'common/helper';
import { useStyles } from '../../styles';
import { getRevisionDisplay } from '../helper';
import {
  BUTTON,
  OBJECT_BHM,
  OBJECT_BOQ,
  OBJECT_DRAW,
  OBJECT_EO,
  OBJECT_KOE,
  OBJECT_MISC,
  OBJECT_TA,
  STATUS_CLAIM_SENT,
  STATUS_DRAFT,
  STATUS_EVALUATE,
  STATUS_IMPLEMENT,
  STATUS_NOTIFIED,
  STATUS_SENT,
  STATUS_SHARED,
  STATUS_UNSHARED
} from 'common/constants';
import classNames from 'classnames';

const History = props => {
  const classes = useStyles();
  const {
    component,
    currRevision,
    histories,
    recipientName,
    refName,
    revisionDisplay,
    revisions
  } = props;
  const objWithRevision = valueInArray(
    [
      OBJECT_BHM,
      OBJECT_BOQ,
      OBJECT_DRAW,
      OBJECT_EO,
      OBJECT_KOE,
      OBJECT_MISC,
      OBJECT_TA
    ],
    refName
  );

  const [state, setExpandState] = useState({
    open: false,
    anchorEl: null
  });

  const [list, setList] = useState([]);

  const filterHistory = (object, history) => {
    let statusList = [STATUS_DRAFT];
    if (
      object?.metadata.revised_by_org_id &&
      object?.organization?.id &&
      object.metadata.revised_by_org_id === object.organization.id
    ) {
      switch (object.object_type.ref_name) {
        // filter out "draft" status of recipient
        case OBJECT_BHM:
        case OBJECT_EO:
        case OBJECT_TA:
          statusList.push(STATUS_SENT);
          break;
        case OBJECT_KOE:
          statusList.push(STATUS_CLAIM_SENT, STATUS_NOTIFIED);
          break;
        default:
          break;
      }
    }
    return history.filter(h => !valueInArray(statusList, h.status?.name));
  };

  useEffect(() => {
    const allRevs = [...revisions];
    if (currRevision > 0 && revisions?.length > 0) {
      const newHistories = allRevs.reduce((acc, r, _i, remaining) => {
        if (r.metadata.revision === currRevision) remaining.splice(1);
        const revDisplay = r.metadata.revision_sequence_display || '';
        return {
          ...acc,
          [`${r.metadata.revision}_${revDisplay}`]: filterHistory(r, r.history)
        };
      }, {});
      setList(newHistories);
    } else if (!_.isEqual(histories, list)) {
      const currRevObj = allRevs?.find(
        r => r.metadata.revision === currRevision
      );
      setList({
        [`${currRevision}_${revisionDisplay}`]: filterHistory(
          currRevObj,
          histories
        )
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currRevision, histories, revisions]);

  const { open, anchorEl } = state;

  const handleExpand = e =>
    setExpandState(currState => ({
      ...currState,
      open: !open,
      anchorEl: e.currentTarget
    }));

  const handleClose = () =>
    setExpandState(currState => ({
      ...currState,
      open: !open,
      anchorEl: null
    }));

  const historyEntries = Object.entries(list).reverse();
  const historyOptsBtn = {
    button: () =>
      !currRevision && histories[0].status.name === STATUS_DRAFT ? (
        <Typography className={classes.spanButton}>
          <FormattedMessage
            defaultMessage={histories[0].status.name}
            id={'object.' + histories[0].status.name.toUpperCase()}
          />
        </Typography>
      ) : (
        <Button
          className={classes.disableHoverEffect}
          color="secondary"
          endIcon={open ? <ExpandLess /> : <ExpandMore />}
          onClick={handleExpand}>
          <FormattedMessage
            defaultMessage={histories[0].status.name}
            id={'object.' + histories[0].status.name.toUpperCase()}
            values={{ name: recipientName }}
          />
        </Button>
      ),
    span: () => (
      <Button
        component="span"
        endIcon={open ? <ExpandLess /> : <ExpandMore />}
        onClick={handleExpand}>
        <FormattedMessage defaultMessage="History" id="object.HISTORY" />
      </Button>
    )
  };

  return historyEntries.length ? (
    <div className={classes.centerAlign}>
      {historyOptsBtn[component]()}
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <Card className={classes.historyRoot}>
          <CardHeader
            avatar={<WatchLaterIcon fontSize="small" />}
            classes={{
              title: classes.title,
              avatar: classes.avatar
            }}
            className={classes.cardHeader}
            title={
              <FormattedMessage
                defaultMessage="Status history"
                id="object.STATUS_HISTORY"
              />
            }
          />
          <CardContent className={classes.historyContent}>
            {historyEntries.reduce((acc, [revisionNumber, historyList]) => {
              const [revision, revisionDisplay] = revisionNumber.split('_');
              if (historyList.length > 0) {
                return acc.concat(
                  historyList
                    .filter(
                      h =>
                        !valueInArray(
                          [STATUS_DRAFT, STATUS_EVALUATE, STATUS_IMPLEMENT],
                          h.status?.name
                        )
                    )
                    .map((history, idx) => {
                      const {
                        ctime,
                        discipline_type,
                        id,
                        organizations,
                        status,
                        updated_by
                      } = history;
                      return (
                        <div key={`${revisionNumber}_${id}`}>
                          {objWithRevision && revision <= currRevision ? (
                            <Typography display="block" variant="caption">
                              {getRevisionDisplay(
                                revision,
                                revisionDisplay,
                                true
                              )}
                            </Typography>
                          ) : null}
                          {discipline_type ? (
                            <div
                              className={classes.status}
                              key={`${discipline_type.id}_hist`}>
                              <Typography
                                className={classes.discipline}
                                display="inline"
                                variant="body2">
                                {discipline_type.name}
                              </Typography>
                              <Typography
                                className={classes.marginLeft1}
                                display="inline"
                                variant="body2">
                                <FormattedMessage
                                  defaultMessage={status.name}
                                  id={'object.' + status.name.toUpperCase()}
                                  values={{ name: recipientName }}
                                />{' '}
                                {formatDate(ctime)}
                              </Typography>
                              {component !== BUTTON ? (
                                <div
                                  className={classes.status}
                                  key={`${idx}_hist`}>
                                  <Typography
                                    className={classes.marginLeft1}
                                    display="inline"
                                    variant="body2">
                                    {updated_by}
                                  </Typography>
                                </div>
                              ) : null}
                            </div>
                          ) : (
                            <>
                              <Typography display="inline" variant="body1">
                                <strong>
                                  <FormattedMessage
                                    defaultMessage={status.name}
                                    id={'object.' + status.name.toUpperCase()}
                                    values={{ name: recipientName }}
                                  />{' '}
                                </strong>
                              </Typography>
                              {valueInArray(
                                [STATUS_SHARED, STATUS_UNSHARED],
                                status.name
                              ) && !isObjectEmpty(organizations)
                                ? organizations.map((org, idx) => (
                                    <Fragment key={idx}>
                                      <Typography
                                        className={classNames(
                                          classes.discipline,
                                          classes.formLabelMargin,
                                          classes.marginBottom1
                                        )}
                                        display="inline"
                                        variant="body2">
                                        {org.name}
                                      </Typography>
                                      {(idx + 1) % 2 === 1 ||
                                      idx === organizations.length - 1 ? (
                                        <br />
                                      ) : null}
                                    </Fragment>
                                  ))
                                : null}
                              <Typography display="inline" variant="body1">
                                {formatDate(ctime)}
                              </Typography>
                              {component !== BUTTON ? (
                                <div
                                  className={classes.status}
                                  key={`${idx}_hist`}>
                                  <Typography
                                    className={classes.marginLeft1}
                                    display="inline"
                                    variant="body2">
                                    {updated_by}
                                  </Typography>
                                </div>
                              ) : null}
                            </>
                          )}
                          <Divider className={classes.smallPopupDivider} />
                        </div>
                      );
                    })
                );
              } else return acc;
            }, [])}
          </CardContent>
        </Card>
      </Popover>
    </div>
  ) : null;
};

History.propTypes = {
  component: PropTypes.string,
  currRevision: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  histories: PropTypes.arrayOf(PropTypes.object),
  recipientName: PropTypes.string,
  refName: PropTypes.string,
  revisionDisplay: PropTypes.string,
  revisions: PropTypes.arrayOf(PropTypes.object)
};

export default History;
