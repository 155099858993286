import axios from 'axios';

export default class Request {
  constructor(url, config) {
    this.baseUrl = url;
    this.config = config;
  }

  get(endpoint, data, skipAuth = false, source) {
    if (!this.config && !skipAuth) throw new Error('No Auth Token Provided');
    if (skipAuth) this.config = {};
    if (data) {
      if (data.params) this.config.params = data.params;
      if (data.paramsSerializer)
        this.config.paramsSerializer = data.paramsSerializer;
    }
    if (source) {
      this.config = { ...this.config, cancelToken: source.token };
    }

    return axios.get(this.baseUrl + endpoint, this.config);
  }

  post(endpoint, data, skipAuth) {
    if (!skipAuth && !this.config) throw new Error('No Auth Token Provided');

    return axios.post(this.baseUrl + endpoint, data, this.config);
  }

  put(endpoint, data) {
    if (!this.config) throw new Error('No Auth Token Provided');

    return axios.put(this.baseUrl + endpoint, data, this.config);
  }

  delete(endpoint, data) {
    if (!this.config) throw new Error('No Auth Token Provided');
    let config = this.config;

    if (data) config.data = data;

    return axios.delete(this.baseUrl + endpoint, config);
  }
}
