import * as projectTypes from './ProjectTypes';
const initialState = {
  error: '',
  invite_error: '',
  billOfQuantities: [],
  boqSelected: {},
  invited: [],
  objects: [],
  projects: [],
  projectSelected: {},
  deleted: false,
  loading: false,
  updated: false
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case projectTypes.PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
        projectSelected: {},
        error: '',
        updated: false
      };
    case projectTypes.PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: action.payload,
        error: '',
        updated: false
      };
    case projectTypes.PROJECT_FAILURE:
      return { ...state, loading: false, error: action.payload };
    // for create and delete
    case projectTypes.PROJECT_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        projectSelected: action.payload,
        projectSelectedId: action.payload?.id,
        error: '',
        updated: true
      };
    case projectTypes.PROJECT_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        projectSelected: action.payload,
        projectSelectedId: action.payload?.id,
        objects: action.payload.objects,
        error: '',
        updated: false
      };
    case projectTypes.PROJECT_INVITE_REQUEST:
      return {
        ...state,
        loading: true,
        invited: [],
        invite_error: '',
        updated: false
      };
    case projectTypes.PROJECT_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        updated: true,
        invited: action.payload,
        invite_error: ''
      };
    case projectTypes.PROJECT_INVITE_FAILURE:
      return { ...state, loading: false, invite_error: action.payload };
    case projectTypes.PROJECT_INVITE_CLEAR:
      return { ...state, invited: [], invite_error: '' };
    case projectTypes.PROJECT_BOQ_REQUEST:
      return {
        ...state,
        boqSelected: {},
        deleted: false,
        loading: true,
        updated: false,
        error: ''
      };
    case projectTypes.PROJECT_BOQ_SUCCESS:
      let boqSelected = {},
        updated = true;
      if (action.payload && action.payload.constructor === Object) {
        updated = false;
        boqSelected = action.payload;
      }
      return {
        ...state,
        boqSelected: boqSelected,
        updated: updated,
        loading: false,
        error: ''
      };
    case projectTypes.PROJECT_BOQ_FETCH_SUCCESS:
      return {
        ...state,
        billOfQuantities: action.payload,
        deleted: false,
        loading: false,
        updated: false,
        error: ''
      };
    case projectTypes.PROJECT_BOQ_DELETE_SUCCESS:
      return { ...state, deleted: true, loading: false, error: '' };
    case projectTypes.PROJECT_BOQ_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case projectTypes.PROJECT_CLEAR_MESSAGES:
      return { ...state, error: '', invite_error: '' };
    case projectTypes.SET_SELECTED_PROJECT_ID:
      return { ...state, projectSelectedId: action.payload };
    case projectTypes.SET_SS_SELECTED_CATEGORY:
      return { ...state, selectedCategory: action.payload };
    default:
      return state;
  }
};

export default projectReducer;
