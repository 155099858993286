import * as pdfTypes from './PdfTypes';
import PdfApi from './PdfApi';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import pdfMake from 'pdfmake/build/pdfmake';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const pdfRequest = () => {
  return { type: pdfTypes.PDF_REQUEST };
};

const pdfFailure = error => {
  return { type: pdfTypes.PDF_FETCH_FAILURE, payload: error };
};

const pdfSuccess = types => {
  return { type: pdfTypes.PDF_FETCH_SUCCESS, payload: types };
};
export const generate = object => {
  return dispatch => {
    dispatch(pdfRequest());

    const api = new PdfApi();
    api
      .generate(object)
      .then(response => {
        const res = JSON.parse(response.data.body);
        res.footer = (currentPage, pageCount) => ({
          margin: [40, 10, 40, 10],
          columns: [
            {
              fontSize: 9,
              text: `${object.project_name}: ${object.name} ${object.title}`,
              alignment: 'left',
              color: '#DEDEDE'
            },
            {
              fontSize: 9,
              text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
              alignment: 'right'
            }
          ]
        });
        pdfMake.createPdf(res).download(`${object.name} ${object.title}.pdf`);
        dispatch(pdfSuccess());
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(pdfFailure(errorMsg));
      });
  };
};
