import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';
import {
  AddCircle as AddCircleIcon,
  Edit as EditIcon,
  RemoveCircle as RemoveCircleIcon
} from '@material-ui/icons';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  ALL,
  DATA_TYPE_NUMBER,
  DATA_TYPE_STRING,
  DISCIPLINE_TYPE,
  EDIT,
  EMAIL,
  LS_ORGANIZATION_ID,
  LS_ORGANIZATION_NAME,
  NAME,
  PROJECT_SETTINGS,
  ROLE,
  ROLE_PL
} from 'common/constants';
import {
  categorizeDiscTypes,
  getDefaultAccessValue,
  hasArrayError,
  hasError,
  isObjectEmpty
} from 'common/helper';
import { emailSchema } from 'common/schema';
import { getEmailExclusionSchema } from 'common/validators';
import { objectAccessTypeFetch } from 'redux/object/ObjectAction';
import {
  organizationFetch,
  organizationProjectInvite
} from 'redux/organization/OrganizationAction';
import { userRolesFetch } from 'redux/user/UserAction';
import { useStyles } from '../../styles';
import classNames from 'classnames';
import validate from 'validate.js';

const NewUser = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    disciplineTypes,
    onPopupClose,
    orgUsers,
    projectId,
    projectOrganizations,
    projectUsers
  } = props;
  const {
    object: { accessTypes, types: objectTypes },
    organization: { organizationsAll, projectInvite },
    user: { roles }
  } = useSelector(state => state);
  const emailSchemaWithExclusion = {
    email: (value, attributes) => {
      const exclusion = getEmailExclusionSchema(
        projectOrganizations,
        organizationsAll,
        value,
        {
          ...attributes,
          organization_name: localStorage.getItem(LS_ORGANIZATION_NAME)
        }
      );
      return {
        ...emailSchema.email,
        ...exclusion
      };
    }
  };
  const schema = {
    ...emailSchemaWithExclusion,
    project_settings: {
      array: {
        role: {
          presence: {
            allowEmpty: false,
            message: (
              <FormattedMessage
                defaultMessage="{name} is required"
                id="error.REQUIRED"
                values={{
                  name: (
                    <FormattedMessage defaultMessage="Role" id="common.ROLE" />
                  )
                }}
              />
            )
          }
        }
      }
    }
  };

  useEffect(() => {
    dispatch(organizationFetch(ALL.toLowerCase()));
  }, [dispatch]);

  useEffect(() => {
    if (accessTypes.length === 0) dispatch(objectAccessTypeFetch());
    if (roles.length === 0) dispatch(userRolesFetch());
  }, [accessTypes.length, roles.length, dispatch]);

  useEffect(() => {
    if (projectInvite && !projectInvite.error) onPopupClose();
  }, [onPopupClose, projectInvite]);

  const initSettings = {
    role: null,
    discipline_type: null,
    object_access: getDefaultAccessValue(accessTypes, objectTypes),
    edit: true
  };
  const newUser = {
    id: null,
    email: '',
    existing: false,
    project_settings: [initSettings],
    errors: validate({ project_settings: [initSettings] }, schema)
  };
  const [rows, setRows] = useState([{ ...newUser }]);

  const availableDisciplines = categorizeDiscTypes(disciplineTypes).notArchived;
  const projectUserIds =
    projectUsers && new Set(projectUsers.map(({ id }) => id));
  const rowUserIds = new Set(rows.map(({ id }) => id));
  const assignedUsers = new Set([...projectUserIds, ...rowUserIds]);
  const userList = orgUsers.filter(({ id }) => !assignedUsers.has(id));

  const updateRowValues = (event, value, rowIndex, sIndex, field) => {
    event.persist();
    const currRow = rows[rowIndex];
    switch (field) {
      case EMAIL:
        currRow.id = value?.id || null;
        currRow.name = value?.name || currRow.name || '';
        if (typeof value === DATA_TYPE_STRING) currRow.email = value;
        else currRow.email = value?.email || '';
        break;
      case NAME:
        currRow.name = event.target.value;
        break;
      default:
        currRow.project_settings[sIndex][field] = value;
    }
    currRow.errors = validate(currRow, schema);
    rows[rowIndex] = currRow;
    setRows([...rows]);
  };

  const handleAddRow = index => {
    if (typeof index === DATA_TYPE_NUMBER) {
      rows[index].project_settings.push(initSettings);
      rows[index].errors = validate(rows[index], schema);
    } else rows.push(newUser);
    setRows([...rows]);
  };

  const handleRemoveRow = (rowIndex, sIndex) => {
    const delUser = rows[rowIndex].project_settings;
    if (delUser.length > 1) {
      delUser.splice(sIndex, 1);
      rows[rowIndex].errors = validate(rows[rowIndex], schema);
    } else rows.splice(rowIndex, 1);
    setRows([...rows]);
  };

  const handleSave = () => {
    let invitees = [];
    rows.forEach(({ email, id: userId, name: userName, project_settings }) => {
      if (email)
        project_settings.forEach(setting => {
          const { discipline_type, role } = setting;
          if (role)
            invitees.push({
              user_id: userId,
              user: { email: email, name: userName },
              object_access: getDefaultAccessValue(
                accessTypes,
                objectTypes,
                role.name
              ),
              role_id: role.id,
              admin: role.name === ROLE_PL || false,
              discipline_type_id: discipline_type?.id
            });
        });
    });
    if (invitees.length > 0)
      dispatch(
        organizationProjectInvite(localStorage.getItem(LS_ORGANIZATION_ID), {
          organization_name: localStorage.getItem(LS_ORGANIZATION_NAME),
          project_id: projectId,
          invitees: invitees
        })
      );
  };

  return (
    <div
      className={classNames(classes.componentPopupRoot, classes.popupNewUser)}>
      {projectInvite?.error ? (
        <Alert className={classes.alert} severity="error">
          <FormattedMessage
            defaultMessage={projectInvite.error}
            id="error.UNEXPECTED_RESPONSE"
          />
        </Alert>
      ) : null}
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <Typography variant="h2">
          <FormattedMessage
            defaultMessage="Invite people to collaborate"
            id="organization.INVITE_COLLABORATE"
          />
        </Typography>
        <Button
          className={classes.disableHoverEffect}
          color="primary"
          onClick={handleAddRow}
          size="medium"
          startIcon={<AddCircleIcon className={classes.addItemIcon} />}>
          <FormattedMessage defaultMessage="Add More" id="common.ADD_MORE" />
        </Button>
      </Box>
      <TableContainer
        className={classes.popupTableBorder}
        component={Paper}
        style={{ boxShadow: 'none', maxHeight: 360, overflowY: 'auto' }}>
        <Table className={classes.tableNewUser} size="small" stickyHeader>
          <colgroup>
            <col width="25%" />
            <col width="25%" />
            <col width="20%" />
            <col width="20%" />
            <col width="10%" />
          </colgroup>
          <TableBody>
            {rows.map((row, rowIndex) => {
              const filteredPs = row.project_settings;
              const rowSpan = filteredPs.length;
              return filteredPs.map((setting, sIndex) => {
                const hasRoleError = hasArrayError(
                  row.errors,
                  PROJECT_SETTINGS,
                  ROLE,
                  sIndex
                );
                return (
                  <TableRow key={`${rowIndex}_${sIndex}`}>
                    {sIndex < 1 ? (
                      <>
                        <TableCell
                          align="left"
                          className={classNames(
                            classes.popupTableBorderUser,
                            classes.popupTableCell
                          )}
                          rowSpan={rowSpan}>
                          {row.email && row.existing ? (
                            row.email
                          ) : (
                            <Autocomplete
                              freeSolo
                              getOptionLabel={option => option.email}
                              onChange={(e, v) =>
                                updateRowValues(e, v, rowIndex, sIndex, EMAIL)
                              }
                              onInputChange={(e, v) =>
                                updateRowValues(e, v, rowIndex, sIndex, EMAIL)
                              }
                              options={userList}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  error={hasError(row.errors, EMAIL)}
                                  fullWidth
                                  helperText={
                                    hasError(row.errors, EMAIL)
                                      ? row.errors.email[0]
                                      : null
                                  }
                                  label={
                                    <FormattedMessage
                                      defaultMessage="E-mail"
                                      id="common.EMAIL"
                                    />
                                  }
                                  size="small"
                                  variant="outlined"
                                />
                              )}
                              size="small"
                            />
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classNames(
                            classes.popupTableBorderUser,
                            classes.popupTableCell
                          )}
                          rowSpan={rowSpan}>
                          <TextField
                            disabled={!!row.id}
                            fullWidth
                            label={
                              <FormattedMessage
                                defaultMessage="User"
                                id="admin.USER"
                              />
                            }
                            onChange={e =>
                              updateRowValues(e, null, rowIndex, sIndex, NAME)
                            }
                            size="small"
                            value={row.name || ''}
                            variant="outlined"
                          />
                        </TableCell>
                      </>
                    ) : null}
                    <TableCell className={classes.popupTableCell}>
                      {!setting.role || setting.edit ? (
                        <Autocomplete
                          getOptionLabel={option =>
                            option.name
                              ? intl.formatMessage({
                                  id: 'roles.' + option.name.toUpperCase()
                                })
                              : ''
                          }
                          onChange={(e, v) =>
                            updateRowValues(e, v, rowIndex, sIndex, ROLE)
                          }
                          options={roles}
                          renderInput={params => (
                            <TextField
                              {...params}
                              error={hasRoleError}
                              fullWidth
                              helperText={
                                hasRoleError
                                  ? row.errors.project_settings[0][sIndex]
                                      .role[0]
                                  : null
                              }
                              label={
                                <FormattedMessage
                                  defaultMessage="Role"
                                  id="admin.ROLES"
                                />
                              }
                              name="role"
                              size="small"
                              variant="outlined"
                            />
                          )}
                          size="small"
                          value={setting.role}
                        />
                      ) : (
                        <FormattedMessage
                          defaultMessage={setting.role.name}
                          id={'roles.' + setting.role.name.toUpperCase()}
                        />
                      )}
                    </TableCell>
                    <TableCell className={classes.popupTableCell}>
                      {setting.role?.name === ROLE_PL ? null : setting.edit ? (
                        <Autocomplete
                          getOptionLabel={option => option.name || ''}
                          onChange={(e, v) =>
                            updateRowValues(
                              e,
                              v,
                              rowIndex,
                              sIndex,
                              DISCIPLINE_TYPE
                            )
                          }
                          options={availableDisciplines}
                          renderInput={params => (
                            <TextField
                              {...params}
                              fullWidth
                              label={
                                <FormattedMessage
                                  defaultMessage="Discipline"
                                  id="common.DISCIPLINE"
                                />
                              }
                              size="small"
                              variant="outlined"
                            />
                          )}
                          size="small"
                          value={setting.discipline_type}
                        />
                      ) : (
                        (setting.discipline_type &&
                          setting.discipline_type.name) ||
                        ''
                      )}
                    </TableCell>
                    <TableCell align="right" className={classes.popupTableCell}>
                      <Box display="inline-flex">
                        {sIndex === rowSpan - 1 ? (
                          <Tooltip
                            arrow
                            title={
                              <FormattedMessage
                                defaultMessage="Add role to user"
                                id="help.ADD_USER_ROLE"
                              />
                            }>
                            <IconButton
                              className={classes.addItemIcon}
                              onClick={() => handleAddRow(rowIndex)}
                              size="small">
                              <AddCircleIcon />
                            </IconButton>
                          </Tooltip>
                        ) : null}
                        {row.existing && !setting.edit ? (
                          <Tooltip
                            arrow
                            title={
                              <FormattedMessage
                                defaultMessage="Edit"
                                id="common.EDIT"
                              />
                            }>
                            <IconButton
                              onClick={e =>
                                updateRowValues(e, true, rowIndex, sIndex, EDIT)
                              }
                              size="small">
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        ) : null}
                        <Tooltip
                          arrow
                          title={
                            <FormattedMessage
                              defaultMessage="Delete"
                              id="common.DELETE"
                            />
                          }>
                          <IconButton
                            className={classes.delItemIcon}
                            onClick={() => handleRemoveRow(rowIndex, sIndex)}
                            size="small">
                            <RemoveCircleIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              });
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        className={classNames(classes.floatRight, classes.marginTop2)}
        color="primary"
        disabled={
          rows.length < 1 || rows.some(row => !isObjectEmpty(row.errors))
        }
        onClick={handleSave}
        type="submit"
        variant="contained">
        <FormattedMessage
          defaultMessage="Save changes"
          id="common.SAVE_CHANGES"
        />
      </Button>
    </div>
  );
};

NewUser.propTypes = {
  disciplineTypes: PropTypes.array,
  onPopupClose: PropTypes.func,
  orgUsers: PropTypes.array,
  projectId: PropTypes.string,
  projectOrganizations: PropTypes.array,
  projectUsers: PropTypes.array
};

NewUser.defaultProps = {
  disciplineTypes: [],
  orgUsers: [],
  projectOrganizations: [],
  projectUsers: []
};

export default NewUser;
