import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  AddCircle as AddCircleIcon,
  RemoveCircle as RemoveCircleIcon
} from '@material-ui/icons';
import {
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { StyledCell } from 'components';
import { formatCurrency } from 'common/helper';
import { inputStyles, useStyles } from '../../styles';
import { useDebounce } from 'hooks';
import {
  DESCRIPTION,
  NUMBER_OF_UNITS,
  UNIT,
  UNIT_PRICE,
  VALUES,
  WBS
} from 'common/constants';
import Decimal from 'decimal.js';

const CostTable = props => {
  const classes = useStyles();
  const intl = useIntl();
  const base = {
    description: '',
    wbs: '',
    unit: '',
    unit_price: 0,
    number_of_units: 0,
    sum: 0
  };

  let {
    clearTimer,
    impact_price_description,
    orgName,
    setFormState,
    total_cost_nok,
    valuesField
  } = props;
  if (orgName && !impact_price_description)
    impact_price_description = { [orgName]: null };

  const getNewData = () => {
    return orgName
      ? impact_price_description[orgName]
      : impact_price_description;
  };

  const [state, setState] = useState({
    input: { newData: getNewData(), totalCost: total_cost_nok }
  });
  const inputValue = useDebounce(state.input, 1200);

  useEffect(() => {
    updateMetadata(inputValue.newData, inputValue.totalCost);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const getSum = (unitPrice, count) => {
    if (!unitPrice) return 0;

    const decUnitPrice = new Decimal(unitPrice);
    return count
      ? decUnitPrice.times(new Decimal(count)).toNumber()
      : decUnitPrice.toNumber();
  };

  const getTotalSum = data => {
    const totalSum = data.reduce((sum, item) => {
      return sum.plus(new Decimal(item.sum));
    }, new Decimal(0));
    return formatCurrency(totalSum.toNumber(), intl.locale);
  };

  const updateMetadata = (value, totalCost) => {
    setFormState(formState => {
      if (orgName) {
        value = {
          ...formState[valuesField].metadata.impact_price_description,
          [orgName]: value
        };
      }
      return {
        ...formState,
        [valuesField]: {
          ...formState[valuesField],
          metadata: {
            ...formState[valuesField].metadata,
            impact_price_description: value,
            total_cost_nok: totalCost
          }
        }
      };
    });
  };

  const handleChange = (event, index, name) => {
    event.persist();
    const { value } = event.target;

    let totalCost = total_cost_nok;
    let newData = getNewData();

    if (newData) newData[index][name] = value;
    else newData = [{ ...base, [name]: [value] }];

    if (name === UNIT_PRICE || name === NUMBER_OF_UNITS) {
      newData[index].sum = getSum(
        newData[index].unit_price,
        newData[index].number_of_units
      );
      totalCost = getTotalSum(newData);
      updateMetadata(newData, totalCost);
    } else setState(state => ({ ...state, input: { newData, totalCost } }));

    clearTimer();
  };

  const addItem = event => {
    event.persist();
    let newData = getNewData();
    newData = newData ? newData.concat(base) : [base];
    updateMetadata(newData, total_cost_nok);
  };

  const delItem = (event, index) => {
    event.persist();
    const newData = getNewData();
    newData.splice(index, 1);
    const totalCost = newData.length > 0 ? getTotalSum(newData) : 0;
    updateMetadata(newData, totalCost);
  };

  const data =
    impact_price_description && orgName
      ? impact_price_description[orgName] || []
      : impact_price_description || [];
  return (
    <div>
      <Typography className={classes.boldLabel}>
        <FormattedMessage
          id="object.COST_IMPACT"
          defaultMessage="Cost Impact"
        />
        :
      </Typography>
      <TableContainer>
        <Table size="small">
          <colgroup>
            <col />
            <col width="30%" />
            <col width="18%" />
            <col width="12%" />
            <col width="14%" />
            <col width="10%" />
            <col width="16%" />
          </colgroup>
          <TableHead>
            <TableRow>
              <StyledCell header>
                <IconButton onClick={addItem} size="small">
                  <AddCircleIcon className={classes.addItemIcon} />
                </IconButton>
              </StyledCell>
              <StyledCell header>
                <FormattedMessage
                  defaultMessage="Description"
                  id="common.DESCRIPTION"
                />
              </StyledCell>
              <StyledCell header>
                <FormattedMessage defaultMessage="WBS" id="object.WBS" />
              </StyledCell>
              <StyledCell header>
                <FormattedMessage defaultMessage="Unit" id="object.UNIT" />
              </StyledCell>
              <StyledCell header>
                <FormattedMessage
                  defaultMessage="Unit price"
                  id="object.UNIT_PRICE"
                />
              </StyledCell>
              <StyledCell header>
                <FormattedMessage
                  defaultMessage="Number of units"
                  id="object.NUMBER_OF_UNITS"
                />
              </StyledCell>
              <StyledCell header>
                <FormattedMessage defaultMessage="Sum" id="object.SUM" />
              </StyledCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={`cost_${index}`}>
                <StyledCell>
                  <IconButton onClick={e => delItem(e, index)} size="small">
                    <RemoveCircleIcon className={classes.delItemIcon} />
                  </IconButton>
                </StyledCell>
                <StyledCell>
                  <TextField
                    fullWidth
                    inputProps={{
                      style: item.description
                        ? inputStyles.multilineDesc
                        : inputStyles.label
                    }}
                    InputProps={{ disableUnderline: true }}
                    multiline
                    name={`${DESCRIPTION}_${index}`}
                    onChange={e => handleChange(e, index, DESCRIPTION)}
                    placeholder={intl.formatMessage({
                      defaultMessage: 'Description',
                      id: 'common.DESCRIPTION'
                    })}
                    required
                    size="small"
                    value={item.description || ''}
                  />
                </StyledCell>
                <StyledCell>
                  <TextField
                    fullWidth
                    inputProps={{
                      style: item.wbs
                        ? inputStyles.multiline
                        : inputStyles.label
                    }}
                    InputProps={{ disableUnderline: true }}
                    multiline
                    name={`${WBS}_${index}`}
                    onChange={e => handleChange(e, index, WBS)}
                    placeholder={intl.formatMessage({
                      defaultMessage: 'WBS',
                      id: 'object.WBS'
                    })}
                    size="small"
                    value={item.wbs || ''}
                  />
                </StyledCell>
                <StyledCell>
                  <TextField
                    fullWidth
                    inputProps={{
                      style: item.unit
                        ? inputStyles.multiline
                        : inputStyles.label
                    }}
                    InputProps={{ disableUnderline: true }}
                    multiline
                    name={`${UNIT}_${index}`}
                    onChange={e => handleChange(e, index, UNIT)}
                    placeholder={intl.formatMessage({
                      defaultMessage: 'Unit',
                      id: 'object.UNIT'
                    })}
                    size="small"
                    value={item.unit || ''}
                  />
                </StyledCell>
                <StyledCell>
                  <TextField
                    fullWidth
                    inputProps={{
                      style: item.unit_price
                        ? inputStyles.numeric
                        : inputStyles.label
                    }}
                    InputProps={{ disableUnderline: true }}
                    name={`${UNIT_PRICE}_${index}`}
                    onChange={e => handleChange(e, index, UNIT_PRICE)}
                    placeholder={intl.formatMessage({
                      defaultMessage: 'Unit price',
                      id: 'object.UNIT_PRICE'
                    })}
                    required
                    size="small"
                    type="number"
                    value={item.unit_price || ''}
                  />
                </StyledCell>
                <StyledCell>
                  <TextField
                    fullWidth
                    inputProps={{
                      style: item.number_of_units
                        ? inputStyles.numeric
                        : inputStyles.label
                    }}
                    InputProps={{ disableUnderline: true }}
                    name={`${NUMBER_OF_UNITS}_${index}`}
                    onChange={e => handleChange(e, index, NUMBER_OF_UNITS)}
                    placeholder={intl.formatMessage({
                      defaultMessage: 'Number of units',
                      id: 'object.NUMBER_OF_UNITS'
                    })}
                    required
                    size="small"
                    type="number"
                    value={item.number_of_units || ''}
                  />
                </StyledCell>
                <StyledCell align="right">
                  {formatCurrency(item.sum, intl.locale)}
                </StyledCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

CostTable.propTypes = {
  clearTimer: PropTypes.func.isRequired,
  impact_price_description: PropTypes.array,
  orgName: PropTypes.string,
  setFormState: PropTypes.func,
  total_cost_nok: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  valuesField: PropTypes.string
};

CostTable.defaultProps = { valuesField: VALUES };

export default CostTable;
