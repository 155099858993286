import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { Warning as WarningIcon } from '@material-ui/icons';
import { Button, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { useStyles } from '../styles';

const Delete = props => {
  const classes = useStyles();
  const {
    deleteFn,
    error,
    isDeleted,
    isRevisionDraft,
    name,
    objects,
    parentId,
    onPopupClose
  } = props;
  const dispatch = useDispatch();

  const handleDelete = () => {
    Object.keys(objects).forEach(id => {
      if (!parentId) dispatch(deleteFn(id));
      else dispatch(deleteFn(parentId, id));
    });
  };

  useEffect(() => {
    if (isDeleted) onPopupClose();
  }, [isDeleted, onPopupClose]);

  return (
    <div className={classNames(classes.popupRoot, classes.rootAlign)}>
      {error ? (
        <Alert className={classes.alert} severity="error">
          {error}
        </Alert>
      ) : null}
      <WarningIcon className={classes.icon} />
      <Typography className={classes.text} variant="h2">
        <FormattedMessage defaultMessage="Delete" id="common.DELETE" /> {name}?
      </Typography>
      <Typography className={classes.text} color="textSecondary" variant="h4">
        <FormattedMessage
          defaultMessage="You are about to delete {name}/s:"
          id="common.DELETE_CONFIRMATION"
          values={{ name }}
        />
      </Typography>
      {Object.entries(objects ? objects : {}).map(([id, name]) => (
        <Typography className={classes.highlight} key={id} variant="h4">
          {name}
        </Typography>
      ))}
      <Button
        color="secondary"
        onClick={() => props.onPopupClose()}
        variant="contained">
        <FormattedMessage defaultMessage="Cancel" id="common.CANCEL" />
      </Button>
      <Button
        className={classes.deleteButton}
        data-testid="delete-btn"
        onClick={handleDelete}
        variant="contained">
        {isRevisionDraft ? (
          <FormattedMessage
            defaultMessage="Delete Draft"
            id="object.DELETE_DRAFT"
          />
        ) : (
          <FormattedMessage defaultMessage="Delete" id="common.DELETE" />
        )}
      </Button>
    </div>
  );
};

Delete.propTypes = {
  deleteFn: PropTypes.func,
  error: PropTypes.string,
  isDeleted: PropTypes.bool,
  isRevisionDraft: PropTypes.bool,
  name: PropTypes.object,
  objects: PropTypes.object,
  onPopupClose: PropTypes.func,
  parentId: PropTypes.string
};

export default Delete;
