import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Alert } from '@material-ui/lab';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import {
  AddCircle as AddCircleIcon,
  RemoveCircle as RemoveCircleIcon
} from '@material-ui/icons';
import { getEmailExclusionSchema } from 'common/validators';
import { hasError, isObjectEmpty } from 'common/helper';
import { emailSchema } from 'common/schema';
import { organizationInvite } from '../../../../../redux';
import { useStyles } from '../../../styles';
import validate from 'validate.js';
import {
  ADMIN,
  ADMIN_USER_OPTIONS,
  EMAIL,
  NAME,
  WIDTH_XS
} from 'common/constants';

const UserInvite = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    screen: { screenWidth },
    user: { invite_error }
  } = useSelector(state => state);
  const { organization, inviteStatus, onPopupClose } = props;
  const {
    organization: { organizationsAll }
  } = useSelector(state => state);

  const schema = {
    email: (value, attributes) => {
      const exclusion = getEmailExclusionSchema(
        [organization],
        organizationsAll,
        value,
        attributes,
        <FormattedMessage
          defaultMessage="Email is already a member of the organization"
          id="error.INVALID_EMAIL_EXIST_ORGANIZATION"
        />
      );
      return {
        ...emailSchema.email,
        ...exclusion
      };
    }
  };

  const initValues = { email: '', admin: false };
  const initItem = { values: initValues, errors: validate(initValues, schema) };
  const [rows, setRows] = useState([initItem]);

  const handleAddRow = () => setRows([...rows, initItem]);
  const handleRemoveRow = index => {
    rows.splice(index, 1);
    setRows([...rows]);
  };

  const handleChange = (event, index) => {
    event.persist();
    const currRow = rows[index];
    const newRow = Object.assign(
      {},
      {
        values: {
          ...currRow.values,
          [event.target.name]: event.target.value
        }
      }
    );
    newRow.errors = validate(newRow.values, schema);
    rows[index] = newRow;
    setRows([...rows]);
  };

  const handleInvite = () => {
    const users = rows.map(row => ({
      email: row.values.email,
      name: row.values.name,
      admin: !!parseInt(row.values.admin)
    }));
    dispatch(organizationInvite(organization.id, { invitees: users }));
  };

  const allErrors = rows.reduce((acc, row) => {
    return isObjectEmpty(row.errors) ? acc : acc.concat(row.errors);
  }, []);

  useEffect(() => {
    if (inviteStatus && !inviteStatus.error) onPopupClose();
  }, [inviteStatus, onPopupClose]);

  return (
    <div className={classes.popupRoot}>
      {invite_error ? (
        <Alert className={classes.alert} severity="error">
          {/* TODO: identify errors and have them translated */}
          <FormattedMessage
            defaultMessage={invite_error}
            id="error.UNEXPECTED_RESPONSE"
          />
        </Alert>
      ) : null}
      {inviteStatus?.error ? (
        <Alert className={classes.alert} severity="error">
          {/* TODO: identify errors and have them translated */}
          <FormattedMessage
            defaultMessage={inviteStatus.error}
            id="error.UNEXPECTED_RESPONSE"
          />
        </Alert>
      ) : null}
      <Typography variant="h2">
        <FormattedMessage
          defaultMessage="Invite people to collaborate"
          id="organization.INVITE_COLLABORATE"
        />
      </Typography>
      <Divider className={classes.inviteDivider} />
      {rows.map((item, index) => {
        const rowErrors = item.errors;
        return (
          <Grid
            container
            key={index}
            spacing={screenWidth === WIDTH_XS ? 0 : 2}>
            <Grid item sm={5} xs={12}>
              <TextField
                className={classes.marginTop2}
                error={hasError(rowErrors, EMAIL)}
                fullWidth
                helperText={
                  hasError(rowErrors, EMAIL) ? rowErrors.email[0] : null
                }
                label={
                  <FormattedMessage
                    defaultMessage="Email Address"
                    id="common.EMAIL_ADDRESS"
                  />
                }
                name={EMAIL}
                onChange={event => handleChange(event, index)}
                size="small"
                type="text"
                value={item.values.email || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <TextField
                className={classes.marginTop2}
                error={hasError(rowErrors, NAME)}
                fullWidth
                helperText={
                  hasError(rowErrors, NAME) ? rowErrors.name[0] : null
                }
                label={
                  <FormattedMessage defaultMessage="Name" id="common.NAME" />
                }
                name={NAME}
                onChange={event => handleChange(event, index)}
                size="small"
                type="text"
                value={item.values.name || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item sm={3} xs={9}>
              <TextField
                className={classes.marginTop2}
                error={hasError(rowErrors, ADMIN)}
                fullWidth
                helperText={
                  hasError(rowErrors, ADMIN) ? rowErrors.admin[0] : null
                }
                label={
                  <FormattedMessage defaultMessage="Admin" id="admin.ADMIN" />
                }
                name={ADMIN}
                onChange={event => handleChange(event, index)}
                required
                select
                SelectProps={{ native: true }}
                size="small"
                value={item.values.admin}
                variant="outlined">
                <option disabled key="defaultValue" value="" />
                {ADMIN_USER_OPTIONS.map(option => (
                  <FormattedMessage
                    defaultMessage={option.name}
                    id={'admin.' + option.name.toUpperCase()}
                    key={option.id}>
                    {message => <option value={option.id}>{message}</option>}
                  </FormattedMessage>
                ))}
              </TextField>
            </Grid>
            <Grid item sm={1} xs={3}>
              <Box
                className={classes.fullHeight}
                display="flex"
                justifyContent="flex-end">
                <IconButton onClick={() => handleRemoveRow(index)} size="small">
                  <RemoveCircleIcon className={classes.deleteIcon} />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        );
      })}
      <Grid container>
        <Grid item md={12} sm={12}>
          <Button
            className={classes.inviteButton}
            color="primary"
            data-testid="button-add-more"
            onClick={handleAddRow}
            size="medium"
            startIcon={<AddCircleIcon className={classes.addInviteIcon} />}>
            <FormattedMessage defaultMessage="Add More" id="common.ADD_MORE" />
          </Button>
        </Grid>
      </Grid>
      <Divider className={classes.inviteDivider} />
      <Button
        color="primary"
        disabled={allErrors.length > 0}
        onClick={handleInvite}
        size="large"
        variant="contained">
        <FormattedMessage
          defaultMessage="Send Invite"
          id="organization.SEND_INVITE"
        />
      </Button>
    </div>
  );
};

UserInvite.propTypes = {
  inviteStatus: PropTypes.object,
  onPopupClose: PropTypes.func,
  organization: PropTypes.object
};

export default UserInvite;
