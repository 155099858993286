import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FormattedMessage, useIntl } from 'react-intl';
import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Tooltip
} from '@material-ui/core';
import {
  AddCircle as AddCircleIcon,
  Clear as ClearIcon,
  DoubleArrow as DoubleArrowIcon,
  Event as EventIcon,
  FileCopyOutlined as FileCopyOutlinedIcon
} from '@material-ui/icons';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  AGENDA,
  AGENDA_TITLE,
  CHAIRED_BY,
  DATA_TYPE_STRING,
  DEFAULT_DATE_FORMAT,
  DISTRIBUTION,
  DOT,
  ELLIPSIS,
  EMAIL,
  GUESTS,
  HYPHEN,
  INTERNAL,
  ITEM,
  LS_ORGANIZATION_ID,
  MAIN,
  MEETING_DATE,
  MENTIONED_OBJECTS,
  MENTIONED_USERS,
  MINUTES_BY,
  OBJECT,
  OBJECT_REF,
  PARTICIPANTS,
  REGEX_LAST_DOT,
  SUBITEM,
  UNDERSCORE,
  USER
} from 'common/constants';
import _ from 'lodash';
import classNames from 'classnames';
import validate from 'validate.js';
import { convertFromRaw } from 'draft-js';
import { doesExist, formatDate, hasError, valueInArray } from 'common/helper';
import { emailSchema } from 'common/schema';
import {
  convertAndGetMentionedItems,
  formatNumberDisplay,
  getEditorCurrentContent,
  getFromDeletedUsers,
  getHighestItemNo,
  getMeetingNo,
  getNewAgenda,
  getNewCommentItem,
  getNextNoInMeeting,
  getOrgUsers,
  getStatusById,
  isSameMeeting,
  parseItemNo
} from '../helper';
import { isCompletedStatus } from '../statusProcessor';
import { overrideProps, useStyles } from '../../styles';
import AgendaItem from './AgendaItem';

const Meeting = props => {
  const classes = useStyles();
  const {
    access_policy_distribution,
    clearTimer,
    disableEdit,
    displayObjectView,
    formState,
    handleMentionItems,
    hasNewMomNumbering,
    hasPopup,
    objectStatus,
    objectTypes,
    organizations,
    projectId,
    queryParams,
    setFormState,
    setHasPopup,
    suggestions
  } = props;
  const fsErrors = formState.errors;
  const {
    agenda: agendaList,
    discipline_type_id,
    distribution = { guests: [], internal: [] },
    participants = { guests: [], internal: [] },
    previous_participants = { guests: [], internal: [] },
    deleted_users,
    metadata,
    name,
    status_id
  } = formState.values;
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const filter = createFilterOptions();

  useEffect(() => {
    setFormState(formState => {
      const { agenda, metadata } = formState.values;
      const itemNo =
        hasNewMomNumbering && metadata.meeting_no
          ? formatNumberDisplay(metadata.meeting_no)
              .concat(DOT)
              .concat(formatNumberDisplay(getNextNoInMeeting(agenda)))
          : null;
      let newAgenda =
        !agenda || agenda.length === 0 ? [getNewAgenda(1, itemNo)] : agenda;
      const validator = newAgenda.reduce((acc, agenda, index) => {
        return { ...acc, [`${AGENDA_TITLE}_${index}`]: agenda.title };
      }, {});
      return {
        ...formState,
        values: {
          ...formState.values,
          agenda: _.cloneDeep(newAgenda),
          metadata
        },
        prevValues: {
          ...formState.prevValues,
          agenda: _.cloneDeep(newAgenda),
          metadata: {
            ...formState.prevValues.metadata
          }
        },
        refValidations: { ...formState.refValidations, ...validator }
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadata.template_id]);

  // onChange of chaired_by and minutes_by
  const handleUserChange = (event, value, name) => {
    event.persist();

    setFormState(formState => {
      let found = false;
      let object_users = formState.values.object_users || [];

      if (value) {
        object_users = object_users.map(user => {
          if (user.object_user_role === name) {
            found = true;
            user.user_id = value.id;
          }
          return user;
        });
        if (!found)
          object_users.push({ object_user_role: name, user_id: value.id });
      } else {
        const index = object_users.findIndex(
          user => user.object_user_role === name
        );
        if (index > -1) object_users.splice(index, 1);
      }

      return {
        ...formState,
        values: {
          ...formState.values,
          metadata: {
            ...metadata,
            [name]: value?.id || null
          },
          object_users: [...object_users]
        }
      };
    });
  };

  const updateStateValues = (key, value, validate, isMap = false) => {
    const validator = validate || {};

    setFormState(formState => {
      let { prevValues, refValidations, values } = formState;

      if (
        key === AGENDA &&
        !prevValues.agenda?.[0]?.comment?.[0]?.content && // prev agenda content is empty
        values.agenda?.[0]?.comment?.[0]?.content // curr agenda content contains draftjs data
      ) {
        // this happens when object is newly created and no agenda has been saved yet
        // make them equal; otherwise, the unsaved prompt will be triggered even without changes
        prevValues.agenda = _.cloneDeep(values.agenda);
      }

      return {
        ...formState,
        prevValues,
        values: {
          ...values,
          [key]: isMap ? { ...value } : [...value]
        },
        refValidations: { ...refValidations, ...validator }
      };
    });
  };

  const handleDateChange = date => {
    const meeting_date =
      !date || isNaN(date)
        ? date?.target?.value || ''
        : formatDate(date, DEFAULT_DATE_FORMAT);

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        metadata: {
          ...formState.values.metadata,
          meeting_date: meeting_date
        }
      }
    }));
  };

  const handleDateSelection = value => {
    setIsOpen(value);
    setHasPopup(value);
  };

  // onChange of Participants and Distribution lists
  const handleUserLists = (event, value, group, append = false) => {
    event.persist();

    setHasPopup(false);

    const { guests: vGuests, internal: vInternal } = value.reduce(
      (acc, curr) => {
        const isCurrString = typeof curr === DATA_TYPE_STRING;

        if (isCurrString && !!validate({ email: curr }, emailSchema)?.email) {
          return acc;
        }

        const user = isCurrString
          ? {
              guest: true,
              email: curr
            }
          : curr;
        const grp = user.guest ? GUESTS.toLowerCase() : INTERNAL;

        if (
          acc[INTERNAL].filter(val => val.email === user.email).length > 0 ||
          acc[GUESTS.toLowerCase()].filter(val => val.email === user.email)
            .length > 0
        ) {
          return acc;
        } else if (users.filter(val => val.email === user.email).length > 0) {
          acc[INTERNAL].push(users.find(val => val.email === user.email));
        } else {
          (acc[grp] = acc[grp] || []).push(user);
        }

        return acc;
      },
      { guests: [], internal: [] }
    );

    let guests = vGuests.map(guest => guest.email);
    guests = append ? participants.guests.concat(guests) : guests;
    const internal = append
      ? participants.internal.concat(vInternal)
      : vInternal;

    updateStateValues(
      group,
      {
        guests: _.uniq(guests),
        internal: _.uniqBy(internal, EMAIL)
      },
      { [group]: value },
      true
    );
  };

  const copyParticipantList = event => {
    const mergedGuests = [
      ...new Set([...distribution.guests, ...participants.guests])
    ].map(email => ({ guest: true, email }));
    const mergedInternal = [
      ...distribution.internal,
      ...participants.internal
    ].filter(
      (elem, idx, arr) =>
        arr.findIndex(elemIdx => elemIdx.email === elem.email) === idx
    );
    const mergedList = [...mergedGuests, ...mergedInternal];

    handleUserLists(event, mergedList, DISTRIBUTION);
  };

  // onChange of Agenda fields
  const handleAgendaChange = (event, index) => {
    event.persist();

    const title = event.target.value;

    setFormState(formState => {
      const { mentionedObjs, mentionedUsers } = formState;
      const { agenda, id } = formState.values;
      agenda[index].title = title;

      if (mentionedObjs.length > 0)
        mentionedObjs.forEach(mo => {
          if (mo.agenda_items)
            mo.agenda_items.forEach(ai => {
              if (
                id === ai.objectId &&
                agenda[index].rank === ai.agendaItemRank
              )
                ai.title = title;
            });
        });

      if (mentionedUsers.length > 0)
        mentionedUsers.forEach(mo => {
          if (mo.agenda_items)
            mo.agenda_items.forEach(ai => {
              if (
                id === ai.objectId &&
                agenda[index].rank === ai.agendaItemRank
              )
                ai.title = title;
            });
        });

      return {
        ...formState,
        mentionedObjs: mentionedObjs,
        mentionedUsers: mentionedUsers,
        values: {
          ...formState.values,
          agenda: agenda
        },
        refValidations: {
          ...formState.refValidations,
          [`${AGENDA_TITLE}_${index}`]: event.target.value
        }
      };
    });
  };

  // compare output to ^
  const handleEditorChange = (name, value) => {
    // name format: agenda_${indexMain}_comment_content_${indexComment}_sub_content_${indexSub}
    const split = name.split(UNDERSCORE);
    const index = split[1];
    const commentIdx = split[4];
    const subIdx = split[7];

    if (subIdx >= 0)
      agendaList[index].comment[commentIdx].sub_item[subIdx].content = value;
    else agendaList[index].comment[commentIdx].content = value;

    updateStateValues(AGENDA, agendaList);
  };

  // add new agenda item
  const handleAddAgenda = event => {
    event.persist();

    const len = agendaList?.length;
    const lastRank = len > 0 ? agendaList[len - 1].rank : 0;
    const newRank = parseInt(lastRank, 10) + 1;
    const itemNo = hasNewMomNumbering
      ? formatNumberDisplay(metadata.meeting_no)
          .concat(DOT)
          .concat(
            formatNumberDisplay(
              getNextNoInMeeting(
                agendaList,
                metadata.previous_meeting_item_count
              )
            )
          )
      : null;
    const agenda = [...agendaList, getNewAgenda(newRank, itemNo)];

    updateStateValues(AGENDA, agenda, {
      [`${AGENDA_TITLE}_${len}`]: ''
    });
  };

  const mentionedAgendasHelper = (
    deletedItems,
    mentionedItems,
    agendaRank,
    commentRank
  ) => {
    const ids = deletedItems.map(m => m.id);
    const mentions = mentionedItems.reduce((acc, mo) => {
      if (valueInArray(ids, mo.id)) {
        mo.agenda_items = mo.agenda_items.filter(ai =>
          commentRank
            ? !(ai.rank === commentRank && ai.agendaItemRank === agendaRank)
            : ai.agendaItemRank !== agendaRank
        );
      }

      return mo.agenda_items.length > 0 ? acc.concat(mo) : acc;
    }, []);

    return mentions;
  };

  const handleMentionsInDeletedAgenda = (deleted, agendaRank, commentRank) => {
    const { mentionedObjs, mentionedUsers } = formState;
    const { object, user } = deleted.reduce(
      (acc, curr) => {
        const content = convertFromRaw(JSON.parse(curr.content));
        const { object, user } = convertAndGetMentionedItems(
          getEditorCurrentContent(content)
        );

        acc[OBJECT] = [...acc[OBJECT], ...object];
        acc[USER] = [...acc[USER], ...user];

        return acc;
      },
      { [OBJECT]: [], [USER]: [] }
    );

    if (object.length > 0) {
      const mentions = mentionedAgendasHelper(
        object,
        mentionedObjs,
        agendaRank,
        commentRank
      );

      handleMentionItems(MENTIONED_OBJECTS, mentions);
    }

    if (user.length > 0) {
      const mentions = mentionedAgendasHelper(
        user,
        mentionedUsers,
        agendaRank,
        commentRank
      );

      handleMentionItems(MENTIONED_USERS, mentions);
    }
  };

  const updateAgendaNos = (agendaList, updatedNo, isDelete = false) => {
    if (hasNewMomNumbering) {
      updatedNo = updatedNo || (metadata.previous_meeting_item_count || 0) + 1;
      agendaList.forEach(agenda => {
        agenda.comment.forEach(comment => {
          updatedNo = updateItemNo(comment, updatedNo, isDelete);
        });
      });
    }
  };

  const updateItemNo = (comment, newItemNo, isDelete) => {
    if (comment.item_no) {
      let [itemPrefix, itemNo] = comment.item_no.split(REGEX_LAST_DOT);
      const intItemPrefix = parseInt(itemPrefix);
      const intItemNo = parseInt(itemNo);

      const modify =
        meetingNo === intItemPrefix &&
        (isDelete ? intItemNo > newItemNo : true);
      if (modify) {
        itemNo = formatNumberDisplay(isDelete ? intItemNo - 1 : newItemNo);
        comment.item_no = itemPrefix.concat(DOT).concat(itemNo);
        comment.rank = itemNo;

        if (comment.sub_item)
          comment.sub_item.forEach(si => {
            if (si.item_no) {
              const sub_item_no = si.item_no.split(REGEX_LAST_DOT).pop();
              si.item_no = comment.item_no.concat(DOT).concat(sub_item_no);
            }
          });

        return isDelete ? intItemNo - 1 : ++newItemNo;
      }
    }
    return newItemNo;
  };

  // delete agenda
  const handleDelAgenda = (event, index) => {
    event.persist();

    const del = window.confirm(
      intl.formatMessage(
        { id: 'object.DELETE_ITEM_CONFIRMATION' },
        { name: intl.formatMessage({ id: 'object.AGENDA' }).toLowerCase() }
      )
    );

    if (del) {
      const deleted = agendaList.splice(index, 1)[0];
      if (deleted.comment.length > 0) {
        handleMentionsInDeletedAgenda(deleted.comment, deleted.rank);
        updateAgendaNos(agendaList);
      }

      let validator = agendaList.reduce((acc, agendum, index) => {
        return { ...acc, [`${AGENDA_TITLE}_${index}`]: agendum.title };
      }, {});

      updateStateValues(AGENDA, agendaList, validator);
    }
  };

  // add new comment item to specified agenda
  const handleAddCommentItem = (event, index) => {
    event.persist();
    const { comment } = agendaList[index];
    const [lastItem] = comment.slice(-1);

    let itemNo;
    let lastRank = lastItem?.rank || 0;
    if (hasNewMomNumbering) {
      lastRank = isSameMeeting(lastItem?.item_no, agendaPrefix)
        ? getHighestItemNo(comment) + 1
        : getNextNoInMeeting(
            agendaList.slice(0, index),
            metadata.previous_meeting_item_count
          );
      itemNo = agendaPrefix.concat(DOT).concat(formatNumberDisplay(lastRank));
    }

    comment.push(getNewCommentItem(lastRank, itemNo, MAIN));
    updateAgendaNos(agendaList);
    updateStateValues(AGENDA, agendaList);
  };

  const getFirstCurrentCommentNo = comments => {
    const item = comments.find(c => isSameMeeting(c.item_no, agendaPrefix));
    return item?.item_no
      ? parseItemNo(item.item_no)
      : (metadata.previous_meeting_item_count || 0) + 1;
  };

  const handleAgendaMove = result => {
    if (result.destination) {
      const {
        destination: { droppableId, index: endIndex },
        draggableId,
        source: { index: startIndex }
      } = result;
      let validator = {};

      if (startIndex !== endIndex) {
        let { mentionedObjs } = formState;
        let { agenda } = formState.values;
        let [agendaIdx, itemIdx] = draggableId
          .split(UNDERSCORE)
          .map(v => parseInt(v));
        let hasUpdated = false;
        const agendaRank = agenda[agendaIdx].rank;

        switch (droppableId) {
          case AGENDA: {
            const [removed] = agenda.splice(startIndex, 1);
            agenda.splice(endIndex, 0, { ...removed });
            // update rank and validator for all agenda
            agenda.forEach((agendum, index) => {
              const new_rank = formatNumberDisplay(index + 1);
              validator[`${AGENDA_TITLE}_${index}`] = agendum.title;

              mentionedObjs.forEach(mo => {
                if (mo.agenda_items)
                  mo.agenda_items.forEach(ai => {
                    if (ai.agendaItemRank === agendum.rank && !ai.updated) {
                      ai.agendaItemRank = new_rank;
                      ai.updated = true;
                      hasUpdated = true;
                    }
                  });
              });

              agendum.rank = new_rank;
            });

            updateAgendaNos(agenda);
            break;
          }
          case ITEM: {
            const comments = agenda[agendaIdx].comment;
            let newItemNo = hasNewMomNumbering
              ? getFirstCurrentCommentNo(comments) // get this value first before updating comments
              : 0;

            const [removedItem] = comments.splice(startIndex, 1);
            comments.splice(endIndex, 0, { ...removedItem });
            // update rank for all affected agenda items
            // and any and all associated linked objects
            comments.forEach((item, index) => {
              const new_rank = formatNumberDisplay(index + 1);
              mentionedObjs.forEach(mo => {
                if (mo.agenda_items)
                  mo.agenda_items.forEach(ai => {
                    if (!ai.isSubItem) {
                      // processing for agenda main items
                      if (
                        !ai.updated &&
                        ai.agendaItemRank === agendaRank &&
                        ai.rank === item.rank
                      ) {
                        ai.rank = new_rank;
                        ai.updated = true;
                        hasUpdated = true;
                      }
                    } else {
                      // processing for sub items
                      // also update sub items of affected item
                      const [sub_itemRank, sub_rank] = ai.rank.split(HYPHEN);
                      if (
                        !ai.updated &&
                        ai.agendaItemRank === agendaRank &&
                        sub_itemRank === item.rank
                      ) {
                        ai.rank = `${new_rank}${HYPHEN}${sub_rank}`;
                        ai.updated = true;
                        hasUpdated = true;
                      }
                    }
                  });
              });

              item.rank = new_rank;
              if (hasNewMomNumbering) newItemNo = updateItemNo(item, newItemNo);
            });
            break;
          }
          case SUBITEM: {
            const item = agenda[agendaIdx].comment[itemIdx];
            const [removedSub] = item.sub_item.splice(startIndex, 1);
            item.sub_item.splice(endIndex, 0, { ...removedSub });

            let lastUpdatedSubNo = item.total_sub_item_count;
            // update rank for all affected subitems
            item.sub_item.forEach((sub, index) => {
              const new_rank = formatNumberDisplay(index + 1);

              mentionedObjs.forEach(mo => {
                if (mo.agenda_items)
                  mo.agenda_items.forEach(ai => {
                    if (
                      !ai.updated &&
                      ai.isSubItem &&
                      ai.agendaItemRank === agendaRank &&
                      ai.rank === item.rank.concat(HYPHEN).concat(sub.rank)
                    ) {
                      ai.rank = item.rank.concat(HYPHEN).concat(new_rank);
                      ai.updated = true;
                      hasUpdated = true;
                    }
                  });
              });

              sub.rank = new_rank;

              if (hasNewMomNumbering) {
                const newItemNo = isSameMeeting(sub.item_no, agendaPrefix)
                  ? new_rank
                  : parseItemNo(sub.item_no) > item.total_sub_item_count
                  ? formatNumberDisplay(++lastUpdatedSubNo)
                  : null;
                if (newItemNo) {
                  const [siPrefix] = sub.item_no.split(REGEX_LAST_DOT);
                  sub.item_no = siPrefix.concat(DOT).concat(newItemNo);
                }
              }
            });
            break;
          }

          default:
            break;
        }

        // if hasUpdated is true, remove `updated` key in agenda items
        // since agenda items are stored as jsonb objects in the db
        // and we don't want this key recorded
        if (hasUpdated)
          mentionedObjs.forEach(mo => {
            if (mo.agenda_items)
              mo.agenda_items = mo.agenda_items.map(
                //eslint-disable-next-line no-unused-vars
                ({ updated, ...base }) => base
              );
            return mo;
          });

        setFormState(formState => ({
          ...formState,
          mentionedObjs: mentionedObjs,
          values: {
            ...formState.values,
            agenda: agenda
          },
          refValidations: { ...formState.refValidations, ...validator }
        }));
      }
    }
    setHasPopup(false);
  };

  const {
    chaired_by,
    created_by_org_id,
    meeting_date,
    meeting_no,
    minutes_by
  } = metadata;
  const meetingNo = hasNewMomNumbering ? meeting_no : getMeetingNo(name);
  const agendaPrefix = formatNumberDisplay(meetingNo);
  const disableCopy = isCompletedStatus(
    getStatusById(objectStatus, status_id)?.name,
    OBJECT_REF
  );
  const senderId =
    created_by_org_id || localStorage.getItem(LS_ORGANIZATION_ID);
  const users = getOrgUsers(organizations, discipline_type_id, senderId);
  const chaired_by_defVal =
    users?.find(u => u.id === chaired_by) ||
    getFromDeletedUsers(deleted_users, chaired_by);
  const minutes_by_defVal =
    users?.find(u => u.id === minutes_by) ||
    getFromDeletedUsers(deleted_users, minutes_by);
  const allParticipants =
    participants?.internal.concat(participants.guests) || [];
  const allDistribution =
    distribution?.internal.concat(distribution.guests) || [];
  const getFilteredOptionLabelFn = filteredUsers =>
    filteredUsers.name || filteredUsers.email || '';
  const groupByFilteredFn = filteredUsers => filteredUsers.organization?.name;
  const getOptionLabelFn = user => {
    if (typeof user === DATA_TYPE_STRING) return user;
    if (user.guest) return user.email;

    return user.name || user.email || '';
  };
  const groupByFn = user => user.organization?.name;
  const filterOptions = (options, params) => {
    const filtered = filter(options, params);
    const errors = validate({ email: params.inputValue }, emailSchema);

    if (
      params.inputValue &&
      !doesExist(options, params.inputValue, EMAIL) &&
      !errors?.email
    )
      filtered.push({
        email: params.inputValue,
        guest: true,
        name: `Add "${params.inputValue}"`
      });

    return filtered;
  };

  return (
    <div>
      <Divider />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item md={2} xs={12}>
            <FormControl variant="outlined">
              <InputLabel
                className={classNames(
                  classes.dateLabel,
                  hasError(fsErrors, MEETING_DATE) ? classes.dateLabelError : ''
                )}
                disabled={disableEdit}
                htmlFor="outlined-adornment-date">
                <FormattedMessage defaultMessage="Date" id="common.DATE" />*
              </InputLabel>
              <OutlinedInput
                disabled={disableEdit}
                endAdornment={
                  <InputAdornment position="end">
                    <>
                      {isFocused && !disableEdit && meeting_date ? (
                        <IconButton
                          className={classes.grayIcon}
                          disabled={disableEdit}
                          edge="end"
                          onClick={() => handleDateChange()}
                          size="small">
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      ) : null}
                      {disableEdit ? null : (
                        <IconButton
                          className={classes.grayIcon}
                          edge="end"
                          onClick={() => handleDateSelection(true)}
                          size="small">
                          <EventIcon fontSize="small" />
                        </IconButton>
                      )}
                    </>
                  </InputAdornment>
                }
                error={hasError(fsErrors, MEETING_DATE)}
                id="outlined-adornment-date"
                inputProps={{ style: overrideProps.content }}
                labelWidth={30}
                onBlur={() => setIsFocused(false)}
                onChange={handleDateChange}
                onFocus={() => setIsFocused(true)}
                onMouseEnter={() => setIsFocused(true)}
                onMouseLeave={() => setIsFocused(false)}
                value={meeting_date || ''}
              />
              <FormHelperText error id="meetingDateHelperText">
                {hasError(fsErrors, MEETING_DATE)
                  ? fsErrors.meeting_date[0]
                  : null}
              </FormHelperText>
            </FormControl>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                clearable
                onAccept={() => handleDateSelection(false)}
                onChange={handleDateChange}
                onClose={() => handleDateSelection(false)}
                open={isOpen}
                TextFieldComponent={() => null}
                value={meeting_date}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item md={2} xs={6}>
            <Autocomplete
              disabled={disableEdit}
              filterSelectedOptions
              getOptionLabel={getFilteredOptionLabelFn}
              groupBy={groupByFilteredFn}
              id={CHAIRED_BY}
              name={CHAIRED_BY}
              onChange={(event, value) =>
                handleUserChange(event, value, CHAIRED_BY)
              }
              options={users}
              renderInput={params => (
                <TextField
                  {...params}
                  error={hasError(fsErrors, CHAIRED_BY)}
                  fullWidth
                  helperText={
                    hasError(fsErrors, CHAIRED_BY)
                      ? fsErrors.chaired_by[0]
                      : null
                  }
                  label={
                    <FormattedMessage
                      defaultMessage="Chaired by"
                      id="object.CHAIRED_BY"
                    />
                  }
                  required
                  size="small"
                  variant="outlined"
                />
              )}
              size="small"
              value={chaired_by_defVal || null}
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <Autocomplete
              disabled={disableEdit}
              filterSelectedOptions
              getOptionLabel={getFilteredOptionLabelFn}
              groupBy={groupByFilteredFn}
              id={MINUTES_BY}
              name={MINUTES_BY}
              onChange={(event, value) =>
                handleUserChange(event, value, MINUTES_BY)
              }
              options={users}
              renderInput={params => (
                <TextField
                  {...params}
                  error={hasError(fsErrors, MINUTES_BY)}
                  fullWidth
                  helperText={
                    hasError(fsErrors, MINUTES_BY)
                      ? fsErrors.minutes_by[0]
                      : null
                  }
                  label={
                    <FormattedMessage
                      defaultMessage="Minutes by"
                      id="object.MINUTES_BY"
                    />
                  }
                  required
                  size="small"
                  variant="outlined"
                />
              )}
              size="small"
              value={minutes_by_defVal || null}
            />
          </Grid>
          <Grid className={classes.inlineFlex} item md={6} xs={12}>
            {disableEdit ||
            (previous_participants.guests.length === 0 &&
              previous_participants.internal.length === 0) ? null : (
              <Tooltip
                placement="left"
                title={
                  <FormattedMessage
                    defaultMessage="Copy from previous meeting"
                    id="object.COPY_PREVIOUS_MEETING_PARTICIPANTS"
                  />
                }>
                <IconButton
                  className={classes.disableHoverEffect}
                  onClick={e =>
                    handleUserLists(
                      e,
                      previous_participants.internal.concat(
                        previous_participants.guests
                      ),
                      PARTICIPANTS,
                      true
                    )
                  }
                  size="small">
                  <FileCopyOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            <Autocomplete
              className={classes.halfWidth}
              disabled={disableEdit}
              filterOptions={filterOptions}
              filterSelectedOptions
              freeSolo
              getOptionLabel={getOptionLabelFn}
              groupBy={groupByFn}
              id={PARTICIPANTS}
              multiple
              name={PARTICIPANTS}
              onChange={(event, value) =>
                handleUserLists(event, value, PARTICIPANTS)
              }
              options={users.filter(
                user =>
                  !allParticipants.some(participant => {
                    if (typeof participant !== 'string') {
                      return participant.email === user.email;
                    }
                    return null;
                  })
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  label={
                    <FormattedMessage
                      defaultMessage="Participants"
                      id="object.PARTICIPANTS"
                    />
                  }
                  onKeyDown={() => {
                    if (!hasPopup) setHasPopup(true);
                  }}
                  size="small"
                  variant="outlined"
                />
              )}
              renderOption={option => option.name || option.email}
              size="small"
              value={allParticipants}
            />
            <Tooltip
              arrow
              className={classes.inlineFlex}
              title={
                <FormattedMessage defaultMessage="Copy" id="object.COPY" />
              }>
              <span>
                <IconButton
                  className={classNames(
                    classes.disableHoverEffect,
                    classes.noPadding
                  )}
                  disabled={allParticipants.length === 0 || disableCopy}
                  onClick={copyParticipantList}
                  size="small">
                  <DoubleArrowIcon fontSize="small" />
                </IconButton>
              </span>
            </Tooltip>
            <Autocomplete
              className={classes.halfWidth}
              disabled={disableEdit && !access_policy_distribution}
              filterOptions={filterOptions}
              filterSelectedOptions
              freeSolo
              getOptionLabel={getOptionLabelFn}
              groupBy={groupByFn}
              id={DISTRIBUTION}
              multiple
              name={DISTRIBUTION}
              onChange={(event, value) =>
                handleUserLists(event, value, DISTRIBUTION)
              }
              options={users.filter(
                user =>
                  !allDistribution.some(distribution => {
                    if (typeof distribution !== 'string') {
                      return distribution.email === user.email;
                    }
                    return null;
                  })
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  label={
                    <FormattedMessage
                      defaultMessage="Distribution"
                      id="object.DISTRIBUTION"
                    />
                  }
                  onKeyDown={() => {
                    if (!hasPopup) setHasPopup(true);
                  }}
                  size="small"
                  variant="outlined"
                />
              )}
              renderOption={option => option.name || option.email}
              size="small"
              value={allDistribution}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <DragDropContext
        onDragEnd={handleAgendaMove}
        onDragStart={() => setHasPopup(true)}>
        <Droppable droppableId={AGENDA}>
          {provided => (
            <>
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {agendaList?.length > 0
                  ? agendaList.map((agenda, index) => {
                      const titleNo = hasNewMomNumbering
                        ? meetingNo
                        : agendaPrefix;
                      const agendaNo = parseInt(titleNo)
                        ? formatNumberDisplay(titleNo, 5)
                        : ELLIPSIS;
                      return (
                        <Draggable
                          draggableId={index.toString()}
                          index={index}
                          isDragDisabled={disableEdit}
                          key={agenda.rank}>
                          {(provided, snapshot) => (
                            <div
                              {...provided.draggableProps}
                              ref={provided.innerRef}>
                              <Card
                                className={classNames(
                                  classes.marginTop2,
                                  snapshot.isDragging
                                    ? classes.dragAgendaHighlight
                                    : ''
                                )}>
                                <CardHeader
                                  {...provided.dragHandleProps}
                                  action={
                                    disableEdit ? null : (
                                      <IconButton
                                        className={classes.noPadding}
                                        onClick={e => handleDelAgenda(e, index)}
                                        size="small">
                                        <ClearIcon fontSize="small" />
                                      </IconButton>
                                    )
                                  }
                                  className={classNames(
                                    classes.grayBackground,
                                    classes.padding1
                                  )}
                                  subheader={
                                    <TextField
                                      disabled={disableEdit}
                                      error={hasError(
                                        fsErrors,
                                        `${AGENDA_TITLE}_${index}`
                                      )}
                                      fullWidth
                                      helperText={
                                        hasError(
                                          fsErrors,
                                          `${AGENDA_TITLE}_${index}`
                                        )
                                          ? fsErrors[
                                              `${AGENDA_TITLE}_${index}`
                                            ][0]
                                          : null
                                      }
                                      name={`${AGENDA_TITLE}_${index}`}
                                      onChange={event =>
                                        handleAgendaChange(event, index)
                                      }
                                      required
                                      size="small"
                                      type="text"
                                      value={agenda.title}
                                    />
                                  }
                                  subheaderTypographyProps={{
                                    style: overrideProps.subheader
                                  }}
                                  title={
                                    hasNewMomNumbering
                                      ? null
                                      : agendaNo
                                          .concat(HYPHEN)
                                          .concat(agenda.rank)
                                  }
                                  titleTypographyProps={{
                                    variant: 'h6',
                                    style: overrideProps.title
                                  }}
                                />
                                <CardContent
                                  className={classNames(
                                    classes.grayContent,
                                    classes.noPadding
                                  )}>
                                  <AgendaItem
                                    agendaPrefix={agendaPrefix}
                                    agendaRank={agenda.rank}
                                    clearTimer={clearTimer}
                                    comments={agenda.comment}
                                    disableEdit={disableEdit}
                                    displayObjectView={displayObjectView}
                                    formState={formState}
                                    getNewCommentItem={getNewCommentItem}
                                    handleAgendaMove={handleAgendaMove}
                                    handleEditorChange={handleEditorChange}
                                    handleMentionItems={handleMentionItems}
                                    handleMentionsInDeletedAgenda={
                                      handleMentionsInDeletedAgenda
                                    }
                                    hasNewMomNumbering={hasNewMomNumbering}
                                    indexMain={index}
                                    mentionedObjs={formState.mentionedObjs}
                                    mentionedUsers={formState.mentionedUsers}
                                    objectTypes={objectTypes}
                                    projectId={projectId}
                                    queryParams={queryParams}
                                    setHasPopup={setHasPopup}
                                    suggestions={suggestions}
                                    title={agenda.title}
                                    updateAgendaNos={updateAgendaNos}
                                    updateStateVals={updateStateValues}
                                    users={users}
                                  />
                                </CardContent>
                                {disableEdit ? null : (
                                  <CardActions
                                    className={classNames(
                                      classes.grayContent,
                                      classes.noTopPadding
                                    )}>
                                    <IconButton
                                      className={classNames(
                                        classes.addItemIcon,
                                        classes.alignRightButton
                                      )}
                                      onClick={event =>
                                        handleAddCommentItem(event, index)
                                      }
                                      size="small">
                                      <AddCircleIcon />
                                    </IconButton>
                                  </CardActions>
                                )}
                              </Card>
                            </div>
                          )}
                        </Draggable>
                      );
                    })
                  : null}
              </div>
              {provided.placeholder}
            </>
          )}
        </Droppable>
      </DragDropContext>
      {disableEdit ? null : (
        <CardActions>
          <Button
            className={classNames(classes.alignRightButton, classes.marginTop2)}
            color="primary"
            onClick={handleAddAgenda}
            variant="contained">
            <FormattedMessage
              defaultMessage="Add Agenda Item"
              id="object.ADD_AGENDA_ITEM"
            />
          </Button>
        </CardActions>
      )}
    </div>
  );
};

Meeting.propTypes = {
  access_policy_distribution: PropTypes.bool,
  clearTimer: PropTypes.func,
  disableEdit: PropTypes.bool,
  displayObjectView: PropTypes.func,
  formState: PropTypes.object,
  handleMentionItems: PropTypes.func,
  hasError: PropTypes.func,
  hasNewMomNumbering: PropTypes.bool,
  hasPopup: PropTypes.bool,
  objectStatus: PropTypes.array,
  objectTypes: PropTypes.array,
  organizations: PropTypes.array,
  projectId: PropTypes.string,
  queryParams: PropTypes.string,
  setFormState: PropTypes.any,
  setHasPopup: PropTypes.func,
  suggestions: PropTypes.array
};

Meeting.defaultProps = {
  access_policy_distribution: false
};

export default Meeting;
