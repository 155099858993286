import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from '@material-ui/core';
import { isCurrentOrg, valueInArray } from 'common/helper';
import { isCompletedStatus } from '../statusProcessor';
import {
  ERROR_409_STATUS_SYMBOL,
  OBJECT_BOQ,
  OBJECT_DOCUMENTATION,
  STATUS_BOQ_REQUESTED,
  STATUS_NOT_USED,
  STATUS_FINAL_QUANTITIES_ISSUED,
  STATUS_UPDATED_QUANTITIES_ISSUED
} from 'common/constants';
import { FormattedMessage } from 'react-intl';

const useArrayRef = () => {
  const refs = [];
  return [refs, el => el && refs.push(el)];
};

const OverrideAlert = ({
  conflictObject,
  currObjectStatus,
  handleReload,
  setHasPopup
}) => {
  const [buttons, ref] = useArrayRef();
  const [dialogForm, setDialogForm] = useState({
    isBoQ: false,
    isClosedObj: false,
    isEditorSameOrg: false,
    isOpen: false,
    isReloadOnly: false
  });

  useEffect(() => {
    if (dialogForm.isOpen) buttons.forEach(el => el.focus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogForm.isOpen]);

  useEffect(() => {
    const { error, metadata, object_type, status } = conflictObject || {};
    const ref_name = object_type?.ref_name;
    const revisedBySameOrg = isCurrentOrg(metadata?.revised_by_org_id);
    const isComplete = isCompletedStatus(status?.name, ref_name);
    const isBoQ = ref_name === OBJECT_BOQ;

    if (
      status?.name === STATUS_BOQ_REQUESTED &&
      valueInArray(
        [
          STATUS_FINAL_QUANTITIES_ISSUED,
          STATUS_UPDATED_QUANTITIES_ISSUED,
          STATUS_NOT_USED
        ],
        currObjectStatus
      )
    )
      // overwrite the data directly https://trello.com/c/5ES8SOdv/607-svv-new-status-for-boq#comment-624eb407b0fcc21b04efd152
      handleReload(false, isComplete, isBoQ);
    else {
      // need to catch this since object_cache from the API can't detect same org
      // revision when both org users are creating new ones
      setHasPopup(!!conflictObject);
      setDialogForm({
        isBoQ: isBoQ,
        isClosedObj: isComplete,
        isEditorSameOrg: revisedBySameOrg,
        isOpen: !!conflictObject,
        isReloadOnly:
          isBoQ ||
          isComplete ||
          object_type?.name?.includes(OBJECT_DOCUMENTATION) ||
          error === ERROR_409_STATUS_SYMBOL ||
          revisedBySameOrg
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conflictObject]);

  const closeDialog = () => {
    const isOpen = !dialogForm.isOpen;
    setHasPopup(isOpen);
    setDialogForm({ isOpen: isOpen });
  };
  const {
    isBoQ,
    isClosedObj,
    isEditorSameOrg,
    isOpen,
    isReloadOnly
  } = dialogForm;
  return (
    <Dialog keepMounted onClose={closeDialog} open={isOpen}>
      <DialogContent dividers>
        <Typography gutterBottom>
          <FormattedMessage
            defaultMessage="Your information is not updated. Please reload to update."
            id="common.RELOAD_INFO_MSG"
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        {isReloadOnly ? (
          <Button color="primary" onClick={() => closeDialog()} ref={ref}>
            <FormattedMessage defaultMessage="Cancel" id="common.CANCEL" />
          </Button>
        ) : (
          <Button color="primary" onClick={() => handleReload(true)} ref={ref}>
            <FormattedMessage
              defaultMessage="Reload and copy"
              id="common.RELOAD_AND_COPY"
            />
          </Button>
        )}
        <Button
          color="primary"
          onClick={() =>
            isReloadOnly && !(isEditorSameOrg || isClosedObj || isBoQ)
              ? handleReload(null)
              : handleReload(false, isClosedObj, isBoQ)
          }
          ref={ref}>
          <FormattedMessage defaultMessage="Reload" id="common.RELOAD" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
OverrideAlert.propTypes = {
  conflictObject: PropTypes.object,
  currObjectStatus: PropTypes.string,
  handleReload: PropTypes.func,
  setHasPopup: PropTypes.func.isRequired
};

export default OverrideAlert;
