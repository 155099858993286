import { useState, useMemo } from 'react';

import { DIVIDER } from 'common/constants';

const createCustomSuggestionsFilter = (searchValue, suggestions) => {
  const typeList = ['user', 'object'];
  // showing all for now, commenting some stuff below in case we want to limit to a specific size
  // const size = list =>
  //   list.constructor.name === 'List' ? list.size : list.length;

  const value = searchValue?.toLowerCase();
  const suggestionLists = suggestions.reduce(
    (suggestionTypeLists, suggestion) => {
      if (suggestion.name?.toLowerCase()?.indexOf(value) > -1) {
        // check if list of suggestion type have already been used. If not add it as a new list before pushing to it
        if (!suggestionTypeLists[suggestion.type]) {
          suggestionTypeLists[suggestion.type] = [];
        }
        suggestionTypeLists[suggestion.type].push(suggestion);
      }
      return suggestionTypeLists;
    },
    {}
  );

  return typeList.reduce((carried, type) => {
    const typeSuggestions = suggestionLists[type];
    if (typeSuggestions) {
      if (carried.length) {
        carried.push({ type: DIVIDER });
      }
      return carried.concat(typeSuggestions);
    }
    return carried;
  }, []);
};

export const useMentions = suggestions => {
  const [mentionSearch, setMentionSearch] = useState('');

  const changeMentionSearch = value => setMentionSearch(value);

  const mentionList = useMemo(() => {
    return createCustomSuggestionsFilter(mentionSearch, suggestions);
    // eslint-disable-next-line
  }, [mentionSearch, suggestions.length]);
  // We could have checked suggestions with _.isEqual against the previous value
  // instead but suggestions.length is much less computational expensive

  return { mentionList, changeMentionSearch };
};
