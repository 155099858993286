import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Checkbox,
  Collapse,
  Grid,
  IconButton,
  //InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  //TextField,
  Tooltip
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  AddCircle as AddCircleIcon,
  Delete as DeleteIcon,
  //Search as SearchIcon,
  SupervisorAccount as SupervisorAccountIcon
} from '@material-ui/icons';
import { Popup } from 'components';
import UserInvite from '../UserInvite';
import {
  organizationUserDelete,
  organizationUserUpdate,
  organizationFetch
} from 'redux/organization/OrganizationAction';
import { valueInArray } from 'common/helper';
import { useStyles } from '../../../styles';

const Users = ({ history, organization }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    organization: orgData,
    user: {
      info: { id: userId }
    }
  } = useSelector(state => state);
  const users = organization.users;
  const [state, setState] = useState({
    search: '',
    selected: {},
    selfDelete: false,
    users: []
  });
  //const [search, setSearch] = useState('');

  useEffect(() => {
    const org = orgData.organizations.find(org => org.id === organization.id);
    const users = org?.users || [];
    const admins = users.filter(user => user.admin);
    setState(state => ({
      ...state,
      orgData,
      users: users,
      adminCount: admins.length
    }));
  }, [orgData, organization]);

  useEffect(() => {
    if (
      (orgData.invited && !orgData.invited.error) ||
      (orgData.userUpdated && !orgData.userUpdated.error) ||
      (orgData.userDeleted && !orgData.userDeleted.error)
    ) {
      dispatch(organizationFetch(null, true));
      setState(state => ({ ...state, selected: {} }));
      if (state.selfDelete) history.push('/organizations');
    }
    // eslint-disable-next-line
  }, [
    orgData.invited,
    orgData.userUpdated,
    orgData.userDeleted,
    state.selfDelete,
    dispatch
  ]);

  /* const handleSearch = event => {
    event.persist();
    setSearch(event.target.value);
  }; */

  const handleSelect = (event, user) => {
    event.stopPropagation();
    if (event.target.checked) {
      setState(state => ({
        ...state,
        selected: {
          ...state.selected,
          [user.id]: user.email
        }
      }));
    } else {
      setState(state => {
        delete state.selected[user.id];
        return {
          ...state,
          selected: {
            ...state.selected
          }
        };
      });
    }
  };

  const handleSelectAll = event => {
    if (event.target.checked) {
      let allUsers = {};
      state.users.map(
        user => (allUsers = { ...allUsers, [user.id]: user.email })
      );
      setState(state => ({ ...state, selected: allUsers }));
    } else setState(state => ({ ...state, selected: {} }));
  };

  const hasAdmin = userIds =>
    state.users.find(u => !valueInArray(userIds, u.id) && u.admin);
  const showRequiredError = () =>
    window.alert(
      intl.formatMessage({ id: 'error.REQUIRED_ORGANIZATION_ADMIN' })
    );

  const handleAdmin = user => {
    if (hasAdmin([user.id]))
      dispatch(
        organizationUserUpdate(organization.id, user.id, { admin: !user.admin })
      );
    else showRequiredError();
  };

  const handleDelete = user => {
    const delUsers = user || state.selected;
    const delIds = Object.keys(delUsers);
    if (!hasAdmin(delIds)) showRequiredError();
    else {
      const message =
        delIds.length > 1
          ? 'common.USER_DELETE_CONFIRMATION_3'
          : 'common.USER_DELETE_CONFIRMATION_2';
      const del = window.confirm(
        intl.formatMessage({ id: message }) +
          intl.formatMessage({ id: 'common.ACTION_CANNOT_UNDONE' })
      );
      if (del) {
        dispatch(organizationUserDelete(organization.id, { user_ids: delIds }));
        if (valueInArray(delIds, userId))
          setState(s => ({ ...s, selfDelete: true }));
      }
    }
  };

  const selectedUserIds = users ? Object.keys(state.selected) : [];
  const isAllSelected = users ? selectedUserIds.length === users.length : false;
  const isSelected = id => selectedUserIds.includes(id);
  const deleteError = orgData.userDeleted?.error || '';
  const updateError = organization.user?.updated?.error || '';

  return (
    <>
      {deleteError ? (
        <Collapse in timeout="auto">
          <Alert className={classes.alert} severity={'error'}>
            <FormattedMessage
              defaultMessage={deleteError}
              id="error.UNEXPECTED_RESPONSE"
            />
          </Alert>
        </Collapse>
      ) : null}
      {updateError ? (
        <Collapse in timeout="auto">
          <Alert className={classes.alert} severity={'error'}>
            <FormattedMessage
              defaultMessage={updateError}
              id="error.UNEXPECTED_RESPONSE"
            />
          </Alert>
        </Collapse>
      ) : null}
      <div className={classes.userRoot}>
        <Grid container spacing={3}>
          {/* TODO: Organization search user */}
          {/* <Grid
            item
            xs={12}
          >
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              onChange={handleSearch}
              size="small"
              type="search"
              value={search || ''}
              variant="outlined"
            />
          </Grid> */}
          <Grid item xs={12}>
            <TableContainer
              className={classes.tableContainer}
              component={Paper}>
              <Table data-testid="table" size="small" stickyHeader>
                <colgroup>
                  <col />
                  <col width="35%" />
                  <col width="40%" />
                  <col width="25%" />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      onClick={handleSelectAll}
                      padding="checkbox">
                      <Checkbox
                        data-testid="button-all"
                        checked={isAllSelected}
                      />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage
                        defaultMessage="Email"
                        id="common.EMAIL_ADDRESS"
                      />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage
                        defaultMessage="Name"
                        id="common.NAME"
                      />
                    </TableCell>
                    <TableCell>
                      <Box display="flex" justifyContent="flex-end">
                        <Popup
                          button={
                            <IconButton data-testid="button-add" size="small">
                              <AddCircleIcon className={classes.addIcon} />
                            </IconButton>
                          }
                          dataTestId="popup-add-user">
                          <UserInvite
                            inviteStatus={orgData.invited}
                            organization={organization}
                          />
                        </Popup>
                        <Tooltip
                          arrow
                          title={
                            <FormattedMessage
                              defaultMessage="Delete"
                              id="common.DELETE"
                            />
                          }>
                          <span>
                            <IconButton
                              className={classes.deleteIcon}
                              data-testid="button-delete"
                              disabled={!selectedUserIds.length}
                              onClick={() => handleDelete()}
                              size="small">
                              <DeleteIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.users
                    ? state.users.map(user => {
                        const isItemSelected = isSelected(user.id);
                        return (
                          <TableRow
                            hover
                            key={user.id}
                            role="checkbox"
                            selected={isItemSelected}
                            tabIndex={-1}>
                            <TableCell
                              align="center"
                              onClick={event => handleSelect(event, user)}
                              padding="checkbox">
                              <Checkbox checked={isItemSelected} />
                            </TableCell>
                            <TableCell align="left" component="th" scope="row">
                              {user.email}
                            </TableCell>
                            <TableCell align="left">{user.name}</TableCell>
                            <TableCell>
                              <Box display="flex" justifyContent="flex-end">
                                <Tooltip
                                  title={
                                    user.admin ? (
                                      <FormattedMessage
                                        defaultMessage="Admin"
                                        id="admin.ADMIN"
                                      />
                                    ) : (
                                      <FormattedMessage
                                        defaultMessage="Not Admin"
                                        id="admin.NOT ADMIN"
                                      />
                                    )
                                  }>
                                  <IconButton
                                    data-testid="button-admin"
                                    onClick={() => handleAdmin(user)}
                                    size="small">
                                    <SupervisorAccountIcon
                                      className={
                                        user.admin ? '' : classes.disabledIcon
                                      }
                                      color="secondary"
                                    />
                                  </IconButton>
                                </Tooltip>
                                {user.admin && state.adminCount === 1 ? null : (
                                  <Tooltip
                                    arrow
                                    title={
                                      <FormattedMessage
                                        defaultMessage="Delete"
                                        id="common.DELETE"
                                      />
                                    }>
                                    <IconButton
                                      className={classes.deleteIcon}
                                      onClick={() =>
                                        handleDelete({ [user.id]: user.email })
                                      }
                                      size="small">
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

Users.propTypes = {
  history: PropTypes.object,
  organization: PropTypes.object
};

export default Users;
