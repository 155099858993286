import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core';
import {
  AddCircle as AddCircleIcon,
  Archive as ArchiveIcon,
  Unarchive as UnarchiveIcon
} from '@material-ui/icons';
import AddDisciplineType from '../AddDisciplineType';
import { Popup } from 'components';
import { ARCHIVED, MULTIPLE, NOT_ARCHIVED } from 'common/constants';
import { organizationDiscUpdate } from 'redux/organization/OrganizationAction';
import { valueInArray } from 'common/helper';
import { useStyles } from 'views/Admin/styles';

const DtTable = ({ discAdded, field, orgData, orgId, state, setState }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isArchived = ARCHIVED === field;

  const deselect = ids => {
    setState(selected => ({
      ...state,
      selected: {
        ...state.selected,
        [field]: state.selected[field].filter(s => !valueInArray(ids, s.id))
      }
    }));
  };

  const handleSelectAll = event => {
    let allTypes = [];
    if (event.target.checked)
      allTypes = state.types[field].map(type => ({
        id: type.id,
        archived: type.archived,
        organization_id: orgId
      }));
    setState(state => ({
      ...state,
      selected: { ...state.selected, [field]: allTypes }
    }));
  };

  const handleSelect = (event, type) => {
    event.stopPropagation();

    if (event.target.checked) {
      setState(state => ({
        ...state,
        selected: {
          ...state.selected,
          [field]: [
            ...state.selected[field],
            { id: type.id, archived: type.archived, organization_id: orgId }
          ]
        }
      }));
    } else {
      setState(selected => ({
        ...state,
        selected: {
          ...state.selected,
          [field]: state.selected[field].filter(s => s.id !== type.id)
        }
      }));
    }
  };

  const handleAllClick = event => {
    event.persist();
    const params = {
      organization_id: orgId,
      discipline_types: state.selected[field].map(s => ({
        ...s,
        archived: !s.archived
      }))
    };
    dispatch(organizationDiscUpdate(params, MULTIPLE));
    deselect(params.discipline_types.map(dt => dt.id));
  };

  const handleClick = (event, type) => {
    const params = {
      archived: !type.archived,
      organization_id: orgId
    };
    dispatch(organizationDiscUpdate(params, type.id));
    deselect([type.id]);
  };

  const existingDisciplines =
    field === ARCHIVED
      ? []
      : state.types[ARCHIVED].concat(state.types[NOT_ARCHIVED]);
  return (
    <Grid item xs={12}>
      <TableContainer className={classes.tableContainerDisc} component={Paper}>
        <Table data-testid="table" size="small" stickyHeader>
          <colgroup>
            <col />
            <col width="30%" />
            <col width="20%" />
            <col width="35%" />
            <col width="15%" />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                onClick={e => handleSelectAll(e)}
                padding="checkbox">
                <Checkbox
                  data-testid={
                    isArchived ? 'button-all-unarchived' : 'button-all-archived'
                  }
                  checked={
                    state.selected[field].length === state.types[field].length
                  }
                />
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="Name" id="common.NAME" />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Abbreviation"
                  id="admin.ABBREVIATION"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Description"
                  id="common.DESCRIPTION"
                />
              </TableCell>
              <TableCell>
                <Box display="flex" justifyContent="flex-end">
                  {isArchived ? null : (
                    <Popup
                      button={
                        <IconButton data-testid="button-add" size="small">
                          <AddCircleIcon className={classes.addIcon} />
                        </IconButton>
                      }
                      dataTestId="popup-add-discipline">
                      <AddDisciplineType
                        existingDisciplines={existingDisciplines}
                        organizationId={orgId}
                        orgData={orgData}
                        status={discAdded}
                      />
                    </Popup>
                  )}
                  <IconButton
                    className={
                      isArchived ? classes.addIcon : classes.deleteIcon
                    }
                    data-testid={
                      isArchived ? 'button-restore' : 'button-delete'
                    }
                    disabled={state.selected[field].length < 1}
                    onClick={handleAllClick}
                    size="small"
                    variant="contained">
                    {isArchived ? <UnarchiveIcon /> : <ArchiveIcon />}
                  </IconButton>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.types[field].map(type => {
              const isItemSelected = state.selected[field].some(
                s => s.id === type.id
              );
              return (
                <TableRow
                  hover
                  key={type.id}
                  role="checkbox"
                  selected={isItemSelected}
                  tabIndex={-1}>
                  <TableCell
                    align="center"
                    onClick={e => handleSelect(e, type)}
                    padding="checkbox">
                    <Checkbox checked={isItemSelected} />
                  </TableCell>
                  <TableCell align="left" component="th" scope="row">
                    {type.name}
                  </TableCell>
                  <TableCell align="left">{type.code}</TableCell>
                  <TableCell align="left">{type.description}</TableCell>
                  <TableCell align="right">
                    <Tooltip title={isArchived ? 'Unarchive' : 'Archive'}>
                      <IconButton
                        className={
                          isArchived ? classes.addIcon : classes.deleteIcon
                        }
                        data-testid="button-archive"
                        onClick={e => handleClick(e, type)}
                        size="small"
                        variant="contained">
                        {isArchived ? <UnarchiveIcon /> : <ArchiveIcon />}
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

DtTable.propTypes = {
  discAdded: PropTypes.object,
  field: PropTypes.string,
  orgData: PropTypes.object,
  orgId: PropTypes.string,
  setState: PropTypes.func,
  state: PropTypes.object
};

export default DtTable;
