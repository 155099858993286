import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent
} from '@material-ui/core';

const Confirm = props => {
  let {
    abortLabel,
    confirmLabel,
    message,
    onClose,
    onConfirm,
    setHasPopup,
    style = {}
  } = props;
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (setHasPopup) setHasPopup(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    if (onClose) onClose();
    if (setHasPopup) setHasPopup(false);
    setOpen(false);
  };

  const handleOk = () => {
    if (onConfirm) onConfirm();
    handleClose();
  };

  if (!abortLabel)
    abortLabel = (
      <FormattedMessage defaultMessage="Cancel" id="common.CANCEL" />
    );

  if (!confirmLabel)
    confirmLabel = <FormattedMessage defaultMessage="Ok" id="common.OK" />;

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogContent
        dividers
        style={{ ...style, textAlign: 'justify', textJustify: 'auto' }}>
        {message}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          data-testid="close-btn"
          onClick={() => handleClose()}>
          {abortLabel}
        </Button>
        <Button
          color="primary"
          data-testid="confirm-btn"
          onClick={() => handleOk()}>
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Confirm.propTypes = {
  abortLabel: PropTypes.object,
  confirmLabel: PropTypes.object,
  message: PropTypes.object,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  setHasPopup: PropTypes.func,
  style: PropTypes.object
};

export default Confirm;
