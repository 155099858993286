import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Divider, IconButton, InputBase, Paper } from '@material-ui/core';
import { Clear as ClearIcon, Search as SearchIcon } from '@material-ui/icons';
import { useStyles } from '../styles';

const SearchBox = props => {
  const {
    onChange,
    onClearButtonClick,
    onSearchButtonClick,
    onSubmit,
    searchText
  } = props;
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Paper
      className={classes.searchBoxContainer}
      component="form"
      onSubmit={e => onSubmit(e, searchText)}>
      <InputBase
        className={classes.searchBox}
        onChange={e => onChange(e.target.value)}
        placeholder={intl.formatMessage({
          defaultMessage: 'Search',
          id: 'common.SEARCH'
        })}
        value={searchText}
      />
      {searchText ? (
        <>
          <IconButton
            className={classes.disableHoverEffect}
            onClick={onClearButtonClick}
            size="small">
            <ClearIcon fontSize="small" />
          </IconButton>
          <Divider className={classes.searchDivider} orientation="vertical" />
        </>
      ) : null}
      <IconButton
        className={classes.disableHoverEffect}
        disabled={!searchText}
        onClick={e => onSearchButtonClick(e, searchText)}
        size="small">
        <SearchIcon fontSize="small" />
      </IconButton>
    </Paper>
  );
};

SearchBox.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClearButtonClick: PropTypes.func.isRequired,
  onSearchButtonClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  searchText: PropTypes.string
};

SearchBox.defaultProps = {
  searchText: ''
};

export default SearchBox;
