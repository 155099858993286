import React from 'react';
import { TableCell } from '@material-ui/core';
import { useStyles } from '../styles';
import classNames from 'classnames';

const StyledCell = props => {
  const classes = useStyles();
  const { align, className, header, ...rest } = props;
  return (
    <TableCell
      {...rest}
      align={align || 'center'}
      className={classNames(
        className || classes.tableCellBorder,
        header ? classes.grayBackground : classes.whiteBackground
      )}
    />
  );
};

export default StyledCell;
