import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { AddCircle as AddCircleIcon } from '@material-ui/icons';
import MeetingAgendaItem from './MeetingAgendaItem';
import { useStyles } from '../../../styles';
import { AGENDA } from 'common/constants';

const MeetingTemplate = ({
  admin,
  agendas,
  className,
  index,
  prevAgendas,
  updateTemplateValues
}) => {
  const classes = useStyles();
  const defaultAgenda = {
    title: AGENDA,
    rank: '',
    errors: {},
    edit: true,
    global: false,
    touched: true
  };

  const handleAddAgenda = event => {
    const agendasLength = agendas.length;
    const lastRank = agendasLength > 0 ? agendas[agendasLength - 1].rank : 0;
    const newRank = parseInt(lastRank, 10) + 1;

    const updatedAgendas = [
      ...agendas,
      {
        ...defaultAgenda,
        title: defaultAgenda.title.concat(` ${newRank}`),
        rank: newRank.toString().padStart(2, '0')
      }
    ];
    updateTemplateValues(event, index, AGENDA, updatedAgendas, {
      updatePrev: true
    });
  };

  const handleAgendaMove = result => {
    if (result.destination) {
      const {
        destination: { index: endIndex },
        source: { index: startIndex }
      } = result;

      if (startIndex !== endIndex) {
        const [removed] = agendas.splice(startIndex, 1);
        agendas.splice(endIndex, 0, { ...removed });
        // update rank and validator for all agenda
        agendas.forEach((agendum, index) => {
          const new_rank = (index + 1).toString().padStart(2, '0');
          agendum.rank = new_rank;
          agendum.touched = true;
        });
        updateTemplateValues(null, index, AGENDA, agendas, {
          updatePrev: true
        });
      }
    }
  };

  return (
    <div className={className || classes.projectContainer}>
      <DragDropContext onDragEnd={handleAgendaMove}>
        <TableContainer component={Paper}>
          <Table className={classes.fixedTableLayout} size="small" stickyHeader>
            <colgroup>
              <col width="10%" />
              <col width="70%" />
              <col />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage defaultMessage="Number" id="admin.NUMBER" />
                </TableCell>
                <TableCell>
                  <FormattedMessage defaultMessage="Title" id="common.TITLE" />
                </TableCell>
                <TableCell align="right">
                  {admin ? (
                    <IconButton
                      className={classes.addIcon}
                      onClick={handleAddAgenda}
                      size="small">
                      <AddCircleIcon />
                    </IconButton>
                  ) : null}
                </TableCell>
              </TableRow>
            </TableHead>
            <Droppable droppableId="MeetingTemplateAgendaDroppable">
              {provided => (
                <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                  {agendas.map((agenda, agendaIndex) => (
                    <Draggable
                      draggableId={agendaIndex.toString()}
                      index={agendaIndex}
                      isDragDisabled={!admin}
                      key={agenda.rank}>
                      {(provided, snapshot) => (
                        <MeetingAgendaItem
                          draggableProvided={provided}
                          admin={admin}
                          agendas={agendas}
                          agenda={agenda}
                          defaultAgenda={defaultAgenda}
                          indexes={[agendaIndex]}
                          key={agendaIndex}
                          prevAgenda={prevAgendas[agendaIndex]}
                          templateIndex={index}
                          updateTemplateValues={updateTemplateValues}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </Table>
        </TableContainer>
      </DragDropContext>
    </div>
  );
};

MeetingTemplate.propTypes = {
  admin: PropTypes.bool,
  agendas: PropTypes.array,
  className: PropTypes.string,
  index: PropTypes.number,
  prevAgendas: PropTypes.array,
  updateTemplateValues: PropTypes.func
};

MeetingTemplate.defaultProps = { admin: false };

export default MeetingTemplate;
