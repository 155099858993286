import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Popover } from '@material-ui/core';
import { Help as HelpIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  message: {
    padding: theme.spacing(1)
  },
  popover: {
    pointerEvents: 'none'
  }
}));

const HelpPopover = ({ message }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'help-popover' : undefined;
  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <IconButton
        aria-label="tag"
        aria-owns={id}
        onMouseEnter={handleClick}
        onMouseLeave={handleClose}
        size="small">
        <HelpIcon fontSize="small" />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        className={classes.popover}
        id="help-popover"
        open={open}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}>
        <div className={classes.message}>{message}</div>
      </Popover>
    </>
  );
};

HelpPopover.propTypes = {
  message: PropTypes.object
};

export default HelpPopover;
