import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import { WysWyg } from 'views/Object/components';
import { useStyles } from '../../../styles';

const Item = ({ displayObjectView, isSubItem, item, itemNo, label }) => {
  const classes = useStyles();

  return (
    <Grid
      className={isSubItem ? classes.agendaView : classes.padding1}
      container>
      <Grid item md={2} xs={2}>
        <Typography variant="h6">{itemNo}</Typography>
      </Grid>
      <Grid item md={3} xs={3}>
        <Typography className={classes.agendaButton}>
          <FormattedMessage defaultMessage="Status" id="common.STATUS" />:{' '}
          <FormattedMessage
            defaultMessage={item.status}
            id={'object.STATUS_' + item.status.toUpperCase()}
          />
        </Typography>
      </Grid>
      <Grid item md={4} xs={4}>
        <Typography>
          <FormattedMessage
            defaultMessage="Action Owner"
            id="object.ACTION_OWNER"
          />
          : {item.action_owner}
        </Typography>
      </Grid>
      <Grid item md={3} xs={3}>
        <Typography>
          <FormattedMessage defaultMessage="Due Date" id="object.DUE_DATE" />:{' '}
          {item.due_date}
        </Typography>
      </Grid>
      <Grid item md={12} xs={12}>
        <WysWyg
          defaultValue={item.content}
          displayObjectView={displayObjectView}
          key={`${label}_content`}
          name={`${label}_content`}
          readOnly
        />
      </Grid>
    </Grid>
  );
};

Item.propTypes = {
  displayObjectView: PropTypes.func,
  isSubItem: PropTypes.bool,
  item: PropTypes.object,
  itemNo: PropTypes.string,
  label: PropTypes.string
};

export default Item;
