import React from 'react';
import { FormattedMessage } from 'react-intl';
import validate from 'validate.js';

export const processCategories = (categories, parent_index) => {
  const categoryNames = categories.map(c => c.name);
  return categories.reduce((acc, category, index) => {
    const errors = validate(
      category,
      categoryTemplateSchema(categoryNames.filter((_, ci) => ci !== index))
    );
    if (errors) {
      const key = (parent_index ? `${parent_index}_` : '').concat(`${index}`);
      acc = { ...acc, [key]: errors };
    }

    if (category.children.length > 0) {
      const childErrors = processCategories(category.children, index);
      if (childErrors) acc = { ...acc, ...childErrors };
    }

    return acc;
  }, {});
};

export const categoryTemplateSchema = categories => ({
  name: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="Name is required"
          id="error.REQUIRED_NAME"
        />
      )
    },
    exclusion: {
      within: categories,
      message: (
        <FormattedMessage
          defaultMessage="already exists"
          id="error.ALREADY_EXISTS"
        />
      )
    }
  }
});

export const categoryNameSchema = {
  name: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="Name is required"
          id="error.REQUIRED_NAME"
        />
      )
    }
  }
};

export const meetingTemplateSchema = {
  title: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="Title is required"
          id="error.REQUIRED_TITLE"
        />
      )
    }
  }
};

export const agendaTemplateSchema = {
  title: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="Title is required"
          id="error.REQUIRED_TITLE"
        />
      )
    }
  }
};
