import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';
import Slide from './Slide';
import '../styles/carousel.css';
import { isOnFullScreenMode } from 'common/helper';
import { useEvent } from 'hooks';

const Carousel = props => {
  const { currObject, selectedItem, setCurrObject, setDownload } = props;
  const {
    attachments,
    carouselSlides,
    height,
    isFullscreen,
    loadedSlides,
    selectedSlide
  } = currObject;
  const { isMobileDevice } = useSelector(state => state.screen);

  useEffect(() => {
    // initially, only the selected file is loaded
    let slides = [];
    for (let index = 0; index < attachments.length; index++) {
      if (index === selectedItem) slides.push(getSlide(index));
      else slides.push(<div key={`slide_${index}`} />);
    }
    setCurrObject(co => ({
      ...co,
      carouselSlides: slides,
      loadedSlides: co.loadedSlides.concat(selectedItem)
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments]);

  const getSlide = index => {
    return (
      <Slide
        file={attachments[index]}
        index={index}
        isFullscreen={isFullscreen}
        key={`slide_${index}`}
        setDownload={setDownload}
      />
    );
  };

  const fullscreenChange = () => {
    const carousel = document.getElementsByClassName('carousel')[0];
    const slider = document.getElementsByClassName('slider')[0];
    const sliderWrapper = document.getElementsByClassName('slider-wrapper')[0];
    const isFullScreen = !!isOnFullScreenMode();

    if (!isFullScreen) {
      carousel.removeAttribute('id');
    } else {
      carousel.setAttribute('id', 'full');
      slider.style.height = `${height}px`;
      sliderWrapper.style.height = `${height}px`;
    }

    setCurrObject(co => {
      const moreAttrs = isFullScreen
        ? {}
        : { loadedSlides: co.loadedSlides.splice(co.selectedSlide, 1) };
      const slides = carouselSlides.map((d, index) => {
        if (index === co.selectedSlide || co.loadedSlides.includes(index))
          d = (
            <d.type
              {...d.props}
              isFullscreen={isFullScreen}
              key={`slide_${index}`}
            />
          );

        return d;
      });

      return {
        ...co,
        ...moreAttrs,
        carouselSlides: [...slides],
        height: sliderWrapper.offsetHeight,
        isFullscreen: isFullScreen
      };
    });
  };

  const updateCurrentSlide = index => {
    let cObj = { ...currObject };
    if (selectedSlide !== index) {
      cObj = {
        ...cObj,
        selectedSlide: index,
        ...(!cObj.isFullscreen && {
          height: null
        })
      };
    }

    let slides = cObj.carouselSlides;
    slides[index] = getSlide(index);

    setCurrObject(co => ({
      ...co,
      ...cObj,
      carouselSlides: [...slides],
      loadedSlides: [...co.loadedSlides, index]
    }));
  };

  const customRenderItem = (item, props) => {
    const { slideno } = item.props;
    const hasBeenLoaded = !slideno || loadedSlides.includes(slideno);

    if (!hasBeenLoaded && props.isSelected) {
      setCurrObject(co => ({
        ...co,
        loadedSlides: [...co.loadedSlides, slideno]
      }));
    }

    return props.isSelected || hasBeenLoaded ? (
      <item.type {...item.props} />
    ) : null;
  };

  // detect enter or exit fullscreen mode
  useEvent('webkitfullscreenchange', fullscreenChange);
  useEvent('mozfullscreenchange', fullscreenChange);
  useEvent('fullscreenchange', fullscreenChange);
  useEvent('MSFullscreenChange', fullscreenChange);

  return (
    <ResponsiveCarousel
      dynamicHeight={isFullscreen ? false : true}
      onChange={updateCurrentSlide}
      renderItem={customRenderItem}
      selectedItem={selectedItem}
      showStatus={!isMobileDevice}
      showThumbs={false}
      style={{
        marginTop: 100,
        height: isFullscreen ? '100%' : 'inherit'
      }}>
      {carouselSlides}
    </ResponsiveCarousel>
  );
};

Carousel.propTypes = {
  currObject: PropTypes.object,
  selectedItem: PropTypes.number,
  setCurrObject: PropTypes.func,
  setDownload: PropTypes.func
};

Carousel.defaultProps = { selectedItem: 0 };

export default Carousel;
