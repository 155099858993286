import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Box, Typography } from '@material-ui/core';
import { WysWyg } from '../../../components';
import {
  formatForEditor,
  getWyswygViewData
} from 'views/Object/components/helper';
import { isObjectEmpty } from 'common/helper';
import { useStyles } from '../../../styles';
import { OBJECT_BHM } from 'common/constants';
import { useSelector } from 'react-redux';

const Ta = props => {
  const {
    createdBy,
    disciplineName,
    displayObjectView,
    isSenderOrg,
    objectInfo: { metadata, name, object_type, title }
  } = props;
  const classes = useStyles();

  const {
    project: { projectSelected }
  } = useSelector(state => state);

  const showReference = useMemo(
    () => !!projectSelected?.settings?.bhm_ta_reference_field,
    [projectSelected]
  );

  let inquiry = [''],
    reference = '',
    response = [''],
    solutions = [''];

  if (!isObjectEmpty(metadata)) {
    if (metadata.inquiry) inquiry = metadata.inquiry;
    if (metadata.reference && showReference)
      reference = formatForEditor(metadata.reference);
    if (metadata.response) response = metadata.response;
    if (metadata.solutions) solutions = metadata.solutions;
    if (isSenderOrg) {
      if (metadata.new_inquiry_text)
        inquiry = getWyswygViewData(inquiry, metadata.new_inquiry);
      if (metadata.new_solutions_text)
        solutions = getWyswygViewData(solutions, metadata.new_solutions);
    } else {
      if (metadata.new_response_text)
        response = getWyswygViewData(response, metadata.new_response);
    }
  }

  return (
    <div>
      <Typography component="div">
        <Box
          fontSize="h4.fontSize"
          fontWeight="fontWeightBold"
          m={1}
          textAlign="left">
          {name} {title}
        </Box>
        <Box
          alignItems="center"
          className={classes.noMarginBottom}
          display="flex"
          justifyContent="space-between"
          m={1}>
          <div>
            <FormattedMessage
              defaultMessage="Created by"
              id="common.CREATED_BY"
            />
            : {createdBy}
          </div>
          <div>
            <FormattedMessage
              defaultMessage="Action Owner"
              id="object.ACTION_OWNER"
            />
            : {metadata?.action_owner?.email || ''}
          </div>
        </Box>
        <Box
          alignItems="center"
          className={classes.noMarginBottom}
          display="flex"
          justifyContent="space-between"
          m={1}>
          <div>
            <FormattedMessage
              defaultMessage="Discipline"
              id="common.DISCIPLINE"
            />
            : {disciplineName}
          </div>
          <div>
            <FormattedMessage
              defaultMessage="Answer Deadline"
              id="object.ANSWER_DEADLINE"
            />
            : {metadata.due_date}
          </div>
        </Box>
      </Typography>
      {showReference ? (
        <>
          <Typography component="div">
            <Box fontWeight="fontWeightBold" m={1} textAlign="left">
              <FormattedMessage
                defaultMessage="Reference"
                id="object.REFERENCE3"
              />
              :
            </Box>
          </Typography>
          <WysWyg
            defaultValue={reference}
            displayObjectView={displayObjectView}
            key="reference"
            name="reference"
            readOnly
          />
        </>
      ) : null}
      <Typography component="div">
        <Box fontWeight="fontWeightBold" m={1} textAlign="left">
          <FormattedMessage defaultMessage="Query" id="object.QUERY" />:
        </Box>
      </Typography>
      {inquiry.map((query, index) => (
        <div
          className={
            index > 0 ? classes.revisionEditorView : classes.paddingBottom
          }
          key={`div_inquiry_${index}`}>
          <WysWyg
            defaultValue={query}
            displayObjectView={displayObjectView}
            key={`inquiry_${index}`}
            name={`inquiry_${index}`}
            readOnly
          />
        </div>
      ))}
      {object_type && object_type.ref_name === OBJECT_BHM ? null : (
        <>
          <Typography component="div">
            <Box fontWeight="fontWeightBold" m={1} textAlign="left">
              <FormattedMessage
                defaultMessage="Solutions"
                id="object.SOLUTIONS"
              />
              :
            </Box>
          </Typography>
          {solutions.map((solution, index) => (
            <div
              className={
                index > 0 ? classes.revisionEditorView : classes.paddingBottom
              }
              key={`div_solutions_${index}`}>
              <WysWyg
                defaultValue={solution}
                displayObjectView={displayObjectView}
                key={`solutions_${index}`}
                name={`solutions_${index}`}
                readOnly
              />
            </div>
          ))}
        </>
      )}
      <Typography component="div">
        <Box fontWeight="fontWeightBold" m={1} textAlign="left">
          <FormattedMessage defaultMessage="Response" id="object.RESPONSE" />:
        </Box>
      </Typography>
      {response.map((resp, index) => (
        <div
          className={
            index > 0 ? classes.revisionEditorView : classes.paddingBottom
          }
          key={`div_response_${index}`}>
          <WysWyg
            defaultValue={resp}
            displayObjectView={displayObjectView}
            key={`response_${index}`}
            name={`response_${index}`}
            readOnly
          />
        </div>
      ))}
    </div>
  );
};

Ta.propTypes = {
  createdBy: PropTypes.string,
  disciplineName: PropTypes.string,
  displayObjectView: PropTypes.func,
  isSenderOrg: PropTypes.bool,
  objectInfo: PropTypes.object
};

export default Ta;
