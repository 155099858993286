export default {
  root: {
    width: '100%'
  },
  toolbar: {
    borderWidth: '0px 0px 1px 0px', 
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.26)',
    '&:hover': {
      borderColor: 'grey' 
    },
    backgroundColor: '#ddd'
  },
  container: {
    fontSize: 'inherit',
    border: '1px solid rgba(0, 0, 0, 0.26)', 
    '&:hover': {
      border: '1px solid grey' 
    }
  },
  editor: {
    padding: 8
  },
  editorContainer: {
    padding: 8
  }
};
  