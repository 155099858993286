import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Alert } from '@material-ui/lab';
import {
  Box,
  Button,
  Collapse,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { BrregField, Popup, PreviewPane, Uploader } from 'components';
import { generateCompanyCode, getCompanyId, hasError } from 'common/helper';
import { organizationUpdate } from '../../../../../redux';
import { useStyles } from '../../../styles';
import validate from 'validate.js';
import {
  BUCKET_LOGOS,
  COMPANY_CODE,
  DIR_ORGANIZATIONS,
  ERROR,
  FILE_PATH,
  REGEX_ONE_LETTER,
  SUCCESS
} from 'common/constants';

const Info = ({ data, isAdmin, organization: { updated: orgUpdated } }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [formState, setState] = useState({
    values: data,
    errors: {}
  });
  const { values } = formState;

  const schema = {
    'org_meta.company_code': {
      presence: {
        allowEmpty: false,
        message: (
          <FormattedMessage
            defaultMessage="Company Code is required"
            id="error.REQUIRED_COMPANY_CODE"
          />
        )
      },
      length: {
        minimum: 2,
        maximum: 4
      },
      format: {
        pattern: REGEX_ONE_LETTER,
        message: (
          <FormattedMessage
            defaultMessage="Must contain at least one letter"
            id="error.CONTAIN_A_LETTER"
          />
        )
      }
    },
    'org_meta.company_id': {
      presence: {
        allowEmpty: false,
        message: (
          <FormattedMessage
            defaultMessage="Company Id is required"
            id="error.REQUIRED_COMPANY_ID"
          />
        )
      },
      length: {
        maximum: 20
      }
    }
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values]);

  useEffect(() => {
    setState(formState => ({
      ...formState,
      values: {
        ...data,
        org_meta: data.org_meta
          ? {
              ...data.org_meta,
              company_id:
                data.org_meta?.company_id || getCompanyId(data.org_tag)
            }
          : {}
      },
      errors: {}
    }));
  }, [data]);

  const handleChange = event => {
    event.persist();
    let { name, value = '' } = event.target;
    if (name === COMPANY_CODE) value = value.toUpperCase();

    setState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        org_meta: {
          ...formState.values.org_meta,
          [name]: value
        }
      }
    }));
  };

  const handleSelectChange = (event, value, errors) => {
    event.persist();
    setState(formState => {
      let { name = '', org_meta = {}, org_tag = '' } = value || {};
      org_meta = {
        ...formState.values.org_meta,
        ...org_meta,
        company_code:
          org_meta.company_code?.toUpperCase() ||
          generateCompanyCode({ name, org_tag }),
        company_id: org_meta.company_id || getCompanyId(org_tag)
      };

      const values = { ...formState.values, name, org_meta, org_tag };
      return {
        ...formState,
        values,
        errors: { ...validate(values, schema), ...errors }
      };
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    dispatch(organizationUpdate(data.id, values));
  };

  const addAttachment = attachment => {
    const [{ file }] = Object.values(attachment.files).map(file => ({
      file: file.file
    }));
    const params = {
      ...values,
      org_meta: {
        ...values.org_meta,
        logo_url: file
      }
    };
    setState(prevState => ({
      ...prevState,
      values: {
        ...params
      }
    }));
    dispatch(organizationUpdate(data.id, params));
  };

  return (
    <>
      {orgUpdated?.organization || orgUpdated?.error ? (
        <Collapse in timeout="auto">
          <Alert
            className={classes.alert}
            severity={orgUpdated.error ? ERROR : SUCCESS}>
            {orgUpdated.error ? (
              /* TODO: identify errors and have them translated */
              <FormattedMessage
                defaultMessage={orgUpdated.error}
                id="error.UNEXPECTED_RESPONSE"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Your changes have been saved!"
                id="common.SAVED_CHANGES"
              />
            )}
          </Alert>
        </Collapse>
      ) : null}
      <Box
        alignItems="center"
        className={classes.orgName}
        display="flex"
        justifyContent="space-between">
        <Typography align="left" data-testid="header-org-name" variant="h2">
          {data.name}
        </Typography>
        <Popup
          button={
            values.org_meta.logo_url ? (
              <Button className={classes.imageButton}>
                <PreviewPane
                  bucket={BUCKET_LOGOS}
                  filePath={values.org_meta.logo_url}
                  srcFormat={FILE_PATH}>
                  <img alt="Organization" className={classes.imageLogo} />
                </PreviewPane>
              </Button>
            ) : (
              <Button color="primary" variant="contained">
                <FormattedMessage
                  defaultMessage="Add Logo"
                  id="common.ADD_LOGO"
                />
              </Button>
            )
          }>
          <Uploader
            bucket={BUCKET_LOGOS}
            dropZoneProps={{
              accept: 'image/*',
              maxFiles: 1,
              multiple: false
            }}
            message={
              <FormattedMessage
                defaultMessage="Upload Logo"
                id="common.UPLOAD_LOGO"
              />
            }
            onSubmit={addAttachment}
            uploadPath={`${DIR_ORGANIZATIONS}${values.id}/`}
          />
        </Popup>
      </Box>
      <div className={classes.orgRoot}>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <BrregField
              label={
                <FormattedMessage defaultMessage="Name" id="common.NAME" />
              }
              modified={data.name !== formState.values.name}
              onChange={handleSelectChange}
              value={data.name}
            />
          </Grid>
          <Grid item md={3} xs={6}>
            <TextField
              disabled
              error={hasError(formState.errors, 'org_meta.company_id')}
              fullWidth
              helperText={
                hasError(formState.errors, 'org_meta.company_id')
                  ? formState.errors['org_meta.company_id'][0]
                  : null
              }
              InputLabelProps={{
                shrink: true
              }}
              label={
                <FormattedMessage
                  defaultMessage="Company ID"
                  id="organization.COMPANY_ID"
                />
              }
              name={'company_id'}
              size="small"
              type="number"
              value={getCompanyId(formState.values.org_tag)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={6}>
            <TextField
              disabled={!isAdmin}
              error={hasError(formState.errors, 'org_meta.company_code')}
              fullWidth
              helperText={
                hasError(formState.errors, 'org_meta.company_code')
                  ? formState.errors['org_meta.company_code'][0]
                  : null
              }
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{ style: { textTransform: 'uppercase' } }}
              label={
                <FormattedMessage
                  defaultMessage="Company Code"
                  id="admin.COMPANY_CODE"
                />
              }
              name={COMPANY_CODE}
              onChange={handleChange}
              size="small"
              value={values.org_meta.company_code || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={!isAdmin}
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              label={
                <FormattedMessage
                  defaultMessage="Description"
                  id="common.DESCRIPTION"
                />
              }
              multiline
              name="description"
              onChange={handleChange}
              minRows="3"
              size="small"
              value={values.org_meta.description || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={!isAdmin}
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              label={
                <FormattedMessage defaultMessage="Address" id="admin.ADDRESS" />
              }
              multiline
              name="address"
              onChange={handleChange}
              minRows="4"
              size="small"
              value={values.org_meta.address || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={!isAdmin}
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              label={
                <FormattedMessage
                  defaultMessage="Phone Number"
                  id="admin.PHONE_NUMBER"
                />
              }
              name="phone_number"
              onChange={handleChange}
              size="small"
              value={values.org_meta.phone_number || ''}
              variant="outlined"
            />
          </Grid>
        </Grid>
        {isAdmin ? (
          <Grid
            alignItems="flex-end"
            className={classes.marginTop2}
            container
            justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              data-testid="save"
              disabled={!formState.isValid}
              onClick={handleSubmit}>
              <FormattedMessage defaultMessage="Save" id="common.SAVE" />
            </Button>
          </Grid>
        ) : null}
      </div>
    </>
  );
};

Info.propTypes = {
  data: PropTypes.object,
  isAdmin: PropTypes.bool,
  organization: PropTypes.object
};

Info.defaultProps = {
  isAdmin: false
};

export default Info;
