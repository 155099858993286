import {
  ERROR_CODE_401,
  ERROR_PASSWORD_UPDATE,
  LS_STR8LINE_TOKEN
} from 'common/constants';
import * as userTypes from './UserTypes';
import Str8line from '../auth/AuthApi';
import { getUserFromToken } from '../../common/helper';

export const userRequest = () => {
  return { type: userTypes.USER_REQUEST };
};

export const userSuccess = user => {
  return { type: userTypes.USER_SUCCESS, payload: user };
};

export const userFailure = error => {
  return { type: userTypes.USER_FAILURE, payload: error };
};

export const userOtpSuccess = () => {
  return { type: userTypes.USER_OTP_SUCCESS };
};

export const userCreate = params => {
  return dispatch => {
    dispatch(userRequest());
    const api = new Str8line();
    api
      .signup(params.email, params.userType, params.name)
      .then(response => {
        dispatch(userUpdateSuccess(response.data.data));
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(userFailure(errorMsg));
      });
  };
};

export const userUpdateSuccess = data => {
  return { type: userTypes.USER_UPDATE_SUCCESS, payload: data };
};
export const userUpdate = (params, rest = {}) => {
  const { id, isUpdatePassword = false, str8line_token = null } = rest;

  return dispatch => {
    dispatch(userRequest());
    const api = new Str8line(
      str8line_token || localStorage.getItem(LS_STR8LINE_TOKEN)
    );
    api.user
      .update(id || getUserFromToken(), params)
      .then(() => {
        dispatch(userUpdateSuccess());
      })
      .catch(error => {
        let errorMsg = error.message;
        if (isUpdatePassword && error.response.status === ERROR_CODE_401)
          errorMsg = [ERROR_PASSWORD_UPDATE];
        dispatch(userFailure(errorMsg));
      });
  };
};

export const userInfo = () => {
  return dispatch => {
    dispatch(userRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.user
      .info(getUserFromToken())
      .then(response => {
        dispatch(userSuccess(response.data.data));
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(userFailure(errorMsg));
      });
  };
};

export const userRefresh = () => {
  return dispatch => {
    dispatch({ type: userTypes.USER_REFRESH });
  };
};

const userInviteSuccess = data => {
  return { type: userTypes.USER_INVITE_SUCCESS, payload: data };
};
const userInviteFailure = error => {
  return { type: userTypes.USER_INVITE_FAILURE, payload: error };
};
export const userInvite = params => {
  return dispatch => {
    dispatch(userRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.user
      .invite(params)
      .then(response => {
        dispatch(userInviteSuccess(response.data.data));
      })
      .catch(error => {
        const errorMsg = error.message
          ? error.message
          : error.split('\n', 1)[0];
        dispatch(userInviteFailure(errorMsg));
      });
  };
};

const userRoleSuccess = data => {
  return { type: userTypes.USER_ROLE_SUCCESS, payload: data };
};
export const userRolesFetch = () => {
  return dispatch => {
    dispatch(userRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.user
      .getRoles()
      .then(response => {
        dispatch(userRoleSuccess(response.data.data));
      })
      .catch(error => {
        dispatch(userFailure(error.message));
      });
  };
};

const userDeleteSuccess = () => {
  return { type: userTypes.USER_DELETE_SUCCESS };
};
export const userDelete = (id, str8line_token) => {
  return dispatch => {
    dispatch(userRequest());
    const token = str8line_token ?? localStorage.getItem(LS_STR8LINE_TOKEN);
    const api = new Str8line(token);
    api.user
      .delete(id)
      .then(() => {
        dispatch(userDeleteSuccess());
      })
      .catch(error => {
        let errorMsg = error.message;
        if (error.response.status === ERROR_CODE_401)
          errorMsg = [error.response.status];
        else if (error.response.data) errorMsg = error.response.data.errors;
        dispatch(userFailure(errorMsg));
      });
  };
};

const configurationCreateSuccess = data => {
  return { type: userTypes.USER_CONFIGURATION_CREATE_SUCCESS, payload: data };
};
export const configurationCreate = params => {
  return dispatch => {
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.user
      .configurationCreate(params)
      .then(response =>
        dispatch(configurationCreateSuccess(response.data.data))
      )
      .catch(error => dispatch(userFailure(error.message)));
  };
};

const configurationFetchSuccess = data => {
  return { type: userTypes.USER_CONFIGURATION_FETCH_SUCCESS, payload: data };
};
export const configurationFetch = id => {
  return dispatch => {
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.user
      .configurationFetch(id)
      .then(response => dispatch(configurationFetchSuccess(response.data.data)))
      .catch(error => dispatch(userFailure(error.message)));
  };
};

const configurationUpdateSuccess = data => {
  return { type: userTypes.USER_CONFIGURATION_UPDATE_SUCCESS, payload: data };
};
export const configurationUpdate = params => {
  return dispatch => {
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.user
      .configurationUpdate(params)
      .then(response =>
        dispatch(configurationUpdateSuccess(response.data.data))
      )
      .catch(error => dispatch(userFailure(error.message)));
  };
};

const settingFetchSuccess = data => {
  return { type: userTypes.USER_SETTING_FETCH_SUCCESS, payload: data };
};
export const settingFetch = (id, str8line_token) => {
  return dispatch => {
    const token = str8line_token ?? localStorage.getItem(LS_STR8LINE_TOKEN);
    const api = new Str8line(token);
    api.user
      .settingFetch(id)
      .then(response => dispatch(settingFetchSuccess(response.data.data)))
      .catch(error => dispatch(userFailure(error.message)));
  };
};

const settingUpdateSuccess = data => {
  return { type: userTypes.USER_SETTING_UPDATE_SUCCESS, payload: data };
};
export const settingUpdate = (params, str8line_token) => {
  return dispatch => {
    dispatch(userRefresh());
    const token = str8line_token ?? localStorage.getItem(LS_STR8LINE_TOKEN);
    const api = new Str8line(token);
    api.user
      .settingUpdate(params)
      .then(response => dispatch(settingUpdateSuccess(response.data.data)))
      .catch(error => dispatch(userFailure(error.message)));
  };
};
