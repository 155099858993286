import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Box, Typography } from '@material-ui/core';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants';
import { formatDate } from 'common/helper';
import {
  formatForEditor,
  showBoQApprovalStatus
} from '../../../components/helper';
import { WysWyg } from '../../../components';
import { useStyles } from '../../../styles';
import BoqTableView from './BoqTable';

const Boq = props => {
  const {
    displayObjectView,
    isSenderOrg,
    objectInfo: { comments, metadata, name, status, title }
  } = props;
  const classes = useStyles();
  const formattedText = formatForEditor(metadata.text);
  const showApprovalStatus = showBoQApprovalStatus(isSenderOrg, status?.name);

  return (
    <div>
      <Typography component="div">
        <Box
          fontSize="h4.fontSize"
          fontWeight="fontWeightBold"
          m={1}
          textAlign="left">
          {name} {title}
        </Box>
        <Box
          alignItems="center"
          className={classes.noMarginBottom}
          display="flex"
          justifyContent="space-between"
          m={1}>
          <div>
            <FormattedMessage
              defaultMessage="Measured quantity"
              id="object.MEASURED_QUANTITY"
            />
            : {metadata.measuredQuantity}
          </div>
          <div>
            <FormattedMessage defaultMessage="Unit" id="object.UNIT" />:{' '}
            {metadata.unit}
          </div>
        </Box>
        <Box
          alignItems="center"
          className={classes.noMarginBottom}
          display="flex"
          justifyContent="space-between"
          m={1}>
          <div>
            <FormattedMessage
              defaultMessage="Approved quantity"
              id="object.APPROVED_QUANTITY"
            />
            : {metadata.approvedQuantity}
          </div>
          <div>
            <FormattedMessage
              defaultMessage="Per cent of Contract total"
              id="object.CONTRACT_TOTAL"
            />
            : {metadata.contractPercentage}
          </div>
        </Box>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          m={1}>
          <div>
            <FormattedMessage
              defaultMessage="Contract quantity"
              id="object.CONTRACT_QUANTITY"
            />
            : {metadata.contractQuantity}
          </div>
        </Box>
      </Typography>
      <div className={classes.paddingBottom}>
        <BoqTableView
          data={metadata.delmalebrev}
          showApprovalStatus={showApprovalStatus}
        />
      </div>
      <div className={classes.paddingBottom}>
        <WysWyg
          defaultValue={formattedText}
          displayObjectView={displayObjectView}
          key="boq_text"
          name="boq_text"
          readOnly
        />
      </div>
      <Typography components="div" className={classes.boldLabel}>
        <FormattedMessage defaultMessage="Comments" id="object.COMMENTS" />:
      </Typography>
      {comments.map((comment, index) => (
        <Fragment key={index}>
          <Box
            className={classes.paddingBottom}
            display="flex"
            justifyContent="space-between">
            <Typography>
              {comment.user?.name
                ? `${comment.user.name}:`
                : comment.user?.email
                ? `${comment.user.email}:`
                : ''}
            </Typography>
            <Typography>
              {formatDate(comment.date, DEFAULT_DATE_TIME_FORMAT)}
            </Typography>
          </Box>
          <div className={classes.paddingBottom} key={`div_comment_${index}`}>
            <WysWyg
              defaultValue={comment.content}
              displayObjectView={displayObjectView}
              key={`comment_${index}`}
              name={`comment_${index}`}
              readOnly
            />
          </div>
        </Fragment>
      ))}
    </div>
  );
};

Boq.propTypes = {
  displayObjectView: PropTypes.func,
  objectInfo: PropTypes.object
};

export default Boq;
