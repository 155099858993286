import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { AddCircle as AddCircleIcon } from '@material-ui/icons';
import CategoryInfo from './CategoryInfo';
import { useStyles } from '../../../styles';
import {
  DEFAULT_REVISION_TYPE,
  DOCUMENT_CATEGORIES,
  MAIN_CATEGORY
} from 'common/constants';
import { useDispatch, useSelector } from 'react-redux';
import { revisionTypesFetch } from 'redux/object/ObjectAction';

const CategoryTemplate = ({
  admin,
  categories,
  className,
  errors,
  index,
  prevCategories,
  updateTemplateValues
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    object: { revisionTypes = [] }
  } = useSelector(state => state);

  const initCategory = {
    name: MAIN_CATEGORY,
    revision_type: null,
    children: [],
    errors: {},
    edit: true,
    global: false,
    touched: true
  };
  const [defaultCategory, setDefaultCategory] = useState(initCategory);

  useEffect(() => {
    if (revisionTypes.length === 0) dispatch(revisionTypesFetch());
    else if (!defaultCategory.revision_type) {
      const revision_type = revisionTypes.find(
        type => type.action_name === DEFAULT_REVISION_TYPE
      );
      setDefaultCategory(category => ({
        ...category,
        revision_type,
        revision_type_id: revision_type?.id
      }));
    }
  }, [dispatch, revisionTypes, defaultCategory.revision_type]);

  const handleAddCategory = event => {
    const updatedCategories = [
      ...categories,
      {
        ...defaultCategory,
        name: defaultCategory.name.concat(` ${categories.length + 1}`)
      }
    ];
    updateTemplateValues(event, index, DOCUMENT_CATEGORIES, updatedCategories, {
      updatePrev: true
    });
  };

  return (
    <div className={className || classes.projectContainer}>
      <TableContainer component={Paper}>
        <Table className={classes.fixedTableLayout} size="small" stickyHeader>
          <colgroup>
            <col width="50%" />
            <col width="30%" />
            <col />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage defaultMessage="Name" id="common.NAME" />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Revision/Version logic"
                  id="object.REVISION_TAG"
                />
              </TableCell>
              <TableCell align="right">
                {admin ? (
                  <IconButton
                    className={classes.addIcon}
                    onClick={handleAddCategory}
                    size="small">
                    <AddCircleIcon />
                  </IconButton>
                ) : null}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((category, cIndex) => (
              <CategoryInfo
                admin={admin}
                categories={categories}
                category={category}
                defaultCategory={defaultCategory}
                errors={errors}
                indexes={[cIndex]}
                key={cIndex}
                prevCategory={prevCategories[cIndex]}
                templateIndex={index}
                updateTemplateValues={updateTemplateValues}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

CategoryTemplate.propTypes = {
  admin: PropTypes.bool,
  categories: PropTypes.array,
  className: PropTypes.string,
  errors: PropTypes.object,
  index: PropTypes.number,
  prevCategories: PropTypes.array,
  updateTemplateValues: PropTypes.func
};

CategoryTemplate.defaultProps = { admin: false };

export default CategoryTemplate;
