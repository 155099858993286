import * as documentTypes from './DocumentTypes';
const initialState = {
  error: '',
  categoryError: '',
  categoryTemplateError: '',
  categoryDeleted: false,
  categoryLoading: false,
  categoryTemplateUpdated: false,
  deleted: false,
  isSaving: false,
  loading: false,
  updated: false,
  category: null,
  savingSuccess: null,
  selectedCategoryTemplate: {},
  categories: null,
  categoryTemplates: [],
  phases: [],
  types: []
};

const documentReducer = (state = initialState, action) => {
  switch (action.type) {
    case documentTypes.DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        category: null
      };
    case documentTypes.DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        [action.route]: action.payload
      };
    case documentTypes.DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case documentTypes.CATEGORY_REQUEST:
      return {
        ...state,
        categoryDeleted: false,
        categoryLoading: true,
        categoryError: ''
      };
    case documentTypes.CATEGORY_SUCCESS:
      return {
        ...state,
        categoryLoading: false,
        categoryError: '',
        [action.key]: action.payload
      };
    case documentTypes.CATEGORY_FAILURE:
      return {
        ...state,
        categoryLoading: false,
        categoryError: action.payload
      };
    case documentTypes.CATEGORY_DELETE_SUCCESS: {
      return {
        ...state,
        categoryDeleted: true,
        categoryLoading: false,
        categoryError: ''
      };
    }
    case documentTypes.CLEAR_ADDED_FIELDS:
      return {
        ...state,
        category: null
      };
    case documentTypes.CATEGORY_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
        categoryTemplates: [],
        categoryTemplateUpdated: false
      };
    case documentTypes.CATEGORY_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        [action.field]: action.payload,
        categoryTemplateError: ''
      };
    case documentTypes.CATEGORY_TEMPLATE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        categoryTemplateError: '',
        categoryTemplateUpdated: true
      };
    case documentTypes.CATEGORY_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        categoryTemplateError: action.payload,
        categoryTemplateUpdated: false
      };
    case documentTypes.DOCUMENT_CLEAR_MESSAGES:
      return {
        ...state,
        error: '',
        categoryError: '',
        categoryTemplateError: ''
      };
    default:
      return state;
  }
};

export default documentReducer;
