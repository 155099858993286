import Request from '../../common/request';

export default class StandardApi extends Request {
  constructor(url) {
    super(url);

    this.endpoint = '/standards';
  }

  fetch() {
    return super.get(this.endpoint, {}, true);
  }
}
