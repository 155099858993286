import React, { Suspense, lazy, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Alert, Autocomplete } from '@material-ui/lab';
import {
  ALL,
  ARCHIVE_AND_EXPORT,
  BILL_OF_QUANTITIES,
  CHART,
  MEMBERS,
  TABLE,
  USERS
} from 'common/constants';
import {
  isCurrentOrg,
  isObjectEmpty,
  isSmallView,
  valueInArray
} from 'common/helper';
import {
  clearExport,
  organizationFetch,
  singleProjectFetch
} from '../../../../../redux';
import { useStyles } from '../../../styles';
import { ExportConfirm } from 'components';
import {
  BillOfQuantities,
  MemberList,
  ProjectInvite,
  UserList
} from '../../components';

const Project = ({ onClickArchive, organization }) => {
  const OrgChart = lazy(() => import('components/OrgChart'));
  const classes = useStyles();
  const dispatch = useDispatch();
  const { discipline_types, id, projects, users: orgUsers } = organization;
  const {
    export: { created: exportCreated, error: exportError },
    organization: { orgProjUpdated, projectInvite: orgProjectInvite },
    project: {
      invited: projectInvited,
      invite_error: projectInviteError,
      projectSelected,
      updated
    },
    screen: { screenWidth },
    user: {
      info: { id: userId }
    }
  } = useSelector(state => state);
  const { archived: projectArchived, organizations, organization_hierarchy } =
    projectSelected || {};
  const projOrgs = organizations || [];
  const myOrg = projOrgs.find(org => org.id === id);
  const [selectedProject, setSelected] = useState({});
  const [states, setStates] = useState({
    [ARCHIVE_AND_EXPORT]: false,
    [BILL_OF_QUANTITIES]: false,
    [MEMBERS]: false,
    [USERS]: false,
    orgView: TABLE,
    showArchive: false,
    confirmExport: false
  });
  const handleArchive = value => {
    const isArchived = onClickArchive(value);

    if (isArchived)
      setSelected({
        ...selectedProject,
        archived: !value.archived
      });
  };
  const handleSelectChange = (event, value) => setSelected(value);
  const handleClick = type =>
    setStates(prevStates => ({
      ...prevStates,
      ...(isSmallView(screenWidth) && {
        [ARCHIVE_AND_EXPORT]: false,
        [BILL_OF_QUANTITIES]: false,
        [MEMBERS]: false,
        [USERS]: false
      }),
      [type]: !prevStates[type]
    }));
  const handleSwitch = (evt, value) =>
    setStates(prevStates => ({
      ...prevStates,
      orgView: value ? CHART : TABLE
    }));

  useEffect(() => {
    if (!states.members)
      setStates(prevStates => ({
        ...prevStates,
        orgView: TABLE
      }));
  }, [states.members]);

  useEffect(() => {
    if (!isObjectEmpty(selectedProject) && selectedProject.id)
      dispatch(singleProjectFetch(selectedProject.id));
  }, [selectedProject, dispatch]);

  useEffect(() => {
    let showArchive = false;
    if (isCurrentOrg(projectSelected?.creator_org_id)) {
      const orgAdminUsers = orgUsers.reduce(
        (acc, u) => (u.admin ? acc.concat(u.id) : acc),
        []
      );
      if (valueInArray(orgAdminUsers, userId)) showArchive = true;
    }
    setStates(prevStates => ({ ...prevStates, showArchive: showArchive }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectSelected]);

  useEffect(() => {
    if (
      selectedProject.id &&
      ((orgProjectInvite?.project?.length > 0 && !orgProjectInvite.error) ||
        (orgProjUpdated && !orgProjUpdated.error))
    ) {
      dispatch(organizationFetch(ALL.toLowerCase()));
      dispatch(singleProjectFetch(selectedProject.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgProjUpdated, orgProjectInvite, dispatch]);

  useEffect(() => {
    if (projectInvited.length > 0 && !projectInviteError)
      dispatch(organizationFetch(ALL.toLowerCase()));
  }, [projectInvited, projectInviteError, dispatch]);

  useEffect(() => {
    if (id) setSelected({});
  }, [id, dispatch]);

  useEffect(() => {
    if (selectedProject.id && updated)
      dispatch(singleProjectFetch(selectedProject.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated, dispatch]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        ClickAwayListenerProps={{ onClickAway: () => null }}
        onClose={() => dispatch(clearExport())}
        open={exportCreated || !!exportError}>
        <Alert
          onClose={() => dispatch(clearExport())}
          severity={exportError ? 'error' : 'success'}>
          {exportError ? (
            exportError
          ) : (
            <FormattedMessage
              defaultMessage={
                'Export request has been sent. ' +
                'You can check the status of the request in My Account > Exports.'
              }
              id="common.EXPORT_CREATED_MESSAGE"
            />
          )}
        </Alert>
      </Snackbar>
      <Autocomplete
        className={classes.padding2}
        data-testid="autocomplete"
        getOptionLabel={option => option.name || ''}
        onChange={handleSelectChange}
        options={projects.filter(project => !project.archived)}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            label={
              <FormattedMessage
                defaultMessage="Select Project"
                id="admin.SELECT_PROJECT"
              />
            }
            size="small"
            variant="outlined"
          />
        )}
        size="small"
        value={selectedProject}
      />
      {!isObjectEmpty(selectedProject) && selectedProject.id ? (
        <>
          <List>
            <ListItem
              button
              data-testid={MEMBERS}
              divider
              onClick={() => handleClick(MEMBERS)}>
              {states[MEMBERS] ? <ExpandLess /> : <ExpandMore />}
              <ListItemText>
                <FormattedMessage
                  defaultMessage="Member Organizations"
                  id="admin.MEMBER_ORGANIZATIONS"
                />
              </ListItemText>
            </ListItem>
            <Collapse in={states[MEMBERS]} timeout="auto" unmountOnExit>
              <Box
                alignItems="center"
                className={classes.projectContainer}
                display="flex"
                justifyContent="flex-end">
                <Typography display="inline">
                  <FormattedMessage defaultMessage="Table" id="common.TABLE" />
                </Typography>
                <Switch
                  color="primary"
                  name="OrgView"
                  onChange={handleSwitch}
                />
                <Typography display="inline">
                  <FormattedMessage defaultMessage="Chart" id="common.CHART" />
                </Typography>
              </Box>
              {states.orgView === TABLE ? (
                <MemberList
                  data={projOrgs}
                  projectArchived={projectArchived}
                  projId={selectedProject.id}
                />
              ) : (
                <Suspense fallback={null}>
                  <OrgChart
                    isAdmin
                    organizations={organization_hierarchy}
                    projOrgs={projOrgs}
                    projectId={selectedProject.id}
                  />
                </Suspense>
              )}
              {projectArchived ? null : (
                <Box display="flex" justifyContent="flex-end">
                  <ProjectInvite
                    organizations={projOrgs}
                    projectId={selectedProject.id}
                  />
                </Box>
              )}
            </Collapse>
            <ListItem
              button
              data-testid={USERS}
              divider
              onClick={() => handleClick(USERS)}>
              {states[USERS] ? <ExpandLess /> : <ExpandMore />}
              <ListItemText>
                <FormattedMessage
                  defaultMessage="My Organization Users"
                  id="admin.MY_ORG_USERS"
                />
              </ListItemText>
            </ListItem>
            <Collapse in={states[USERS]} timeout="auto" unmountOnExit>
              <UserList
                currentOrg={organization}
                disciplineTypes={discipline_types}
                myOrg={myOrg}
                projectArchived={projectArchived}
                projId={selectedProject.id}
                projOrgs={projOrgs}
              />
            </Collapse>
            <ListItem
              button
              data-testid={BILL_OF_QUANTITIES}
              divider
              onClick={() => handleClick(BILL_OF_QUANTITIES)}>
              {states[BILL_OF_QUANTITIES] ? <ExpandLess /> : <ExpandMore />}
              <ListItemText>
                <FormattedMessage
                  defaultMessage="Bill of Quantities"
                  id="admin.BILL_OF_QUANTITIES"
                />
              </ListItemText>
            </ListItem>
            <Collapse
              in={states[BILL_OF_QUANTITIES]}
              timeout="auto"
              unmountOnExit>
              <BillOfQuantities
                myOrg={myOrg}
                organizations={organizations}
                projectArchived={projectArchived}
                projectId={selectedProject.id}
              />
            </Collapse>
            <ListItem
              button
              data-testid={ARCHIVE_AND_EXPORT}
              divider
              onClick={() => handleClick(ARCHIVE_AND_EXPORT)}>
              {states[ARCHIVE_AND_EXPORT] ? <ExpandLess /> : <ExpandMore />}
              <ListItemText>
                {states.showArchive ? (
                  <>
                    <FormattedMessage
                      defaultMessage="Archive"
                      id="admin.ARCHIVE"
                    />{' '}
                    <FormattedMessage defaultMessage="and" id="common.AND" />{' '}
                  </>
                ) : null}
                <FormattedMessage defaultMessage="Export" id="admin.EXPORT" />
              </ListItemText>
            </ListItem>
            <Collapse
              in={states[ARCHIVE_AND_EXPORT]}
              timeout="auto"
              unmountOnExit>
              {states.showArchive ? (
                <Box
                  alignItems="center"
                  className={classes.projectContainer}
                  display="flex"
                  justifyContent="flex-start">
                  <Button
                    className={
                      selectedProject.archived
                        ? classes.buttonUnarchive
                        : classes.buttonArchive
                    }
                    onClick={() => handleArchive(selectedProject)}>
                    <FormattedMessage
                      defaultMessage={
                        selectedProject.archived ? 'Unarchive' : 'Archive'
                      }
                      id={
                        selectedProject.archived
                          ? 'admin.UNARCHIVE'
                          : 'admin.ARCHIVE'
                      }
                    />
                  </Button>{' '}
                  <Typography className={classes.marginLeft2}>
                    {selectedProject.archived ? (
                      <FormattedMessage
                        defaultMessage="Press to archive your project"
                        id="admin.UNARCHIVE_INFO"
                      />
                    ) : (
                      <FormattedMessage
                        defaultMessage="Press to unarchive your project"
                        id="admin.ARCHIVE_INFO"
                      />
                    )}
                  </Typography>
                </Box>
              ) : null}
              <Box
                alignItems="center"
                className={classes.projectContainer}
                display="flex"
                justifyContent="flex-start">
                <Button
                  className={classes.buttonExport}
                  color="secondary"
                  onClick={() =>
                    setStates(prevStates => ({
                      ...prevStates,
                      confirmExport: true
                    }))
                  }
                  variant="contained">
                  <FormattedMessage
                    defaultMessage="Export"
                    id="common.EXPORT"
                  />
                </Button>
                {states.confirmExport ? (
                  <ExportConfirm
                    onClose={() =>
                      setStates(prevStates => ({
                        ...prevStates,
                        confirmExport: false
                      }))
                    }
                    projectId={selectedProject.id}
                    projectName={selectedProject.name}
                  />
                ) : null}
                <Typography className={classes.marginLeft2}>
                  <FormattedMessage
                    defaultMessage="Press to export all project contents to zip"
                    id="admin.EXPORT_INFO"
                  />
                </Typography>
              </Box>
            </Collapse>
          </List>
        </>
      ) : null}
    </>
  );
};

Project.propTypes = {
  onClickArchive: PropTypes.func,
  organization: PropTypes.object
};

export default Project;
