import Request from '../../common/request';

export default class ObjectApi extends Request {
  constructor(token, url) {
    super(url, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    this.endpoint = '/objects';
  }

  all(id, params, source) {
    return super.get(`/projects/${id}${this.endpoint}`, params, false, source);
  }

  info(id) {
    return super.get(`${this.endpoint}/${id}`);
  }

  create(params) {
    return super.post(this.endpoint, params);
  }

  update(id, params) {
    return super.put(`${this.endpoint}/${id}`, params);
  }

  delete(id) {
    return super.delete(`${this.endpoint}/${id}`);
  }

  types() {
    return super.get(`${this.endpoint}/types`);
  }

  filter(params) {
    return super.get(`${this.endpoint}/types/filter`, params);
  }

  accessTypes() {
    return super.get(`${this.endpoint}/access/types`);
  }

  disciplineTypes(id) {
    const url = '/disciplines'.concat(id ? `?project_id=${id}` : '');
    return super.get(url);
  }

  getStatus(params) {
    return super.get(`/status?${params}`);
  }

  deleteWithRoute(route, id, params) {
    const url = `/${route}/${id}`.concat(params ? params : '');
    return super.delete(url);
  }

  updateWithRoute(route, id, params) {
    return super.put(`/${route}/${id}`, params);
  }

  revisions(id, params) {
    return super.get(`${this.endpoint}/${id}/revisions?${params}`);
  }

  revisionTypes() {
    return super.get('/lookup/revisions/types');
  }

  search(params) {
    return super.get(`${this.endpoint}/search`, params);
  }

  checkDuplicates(params) {
    return super.post('/attachments/check/duplicates', params);
  }

  checkMeetingCreationAccess(params) {
    return super.get(`${this.endpoint}/check/meeting`, params);
  }
}
