import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Alert, Autocomplete } from '@material-ui/lab';
import {
  AddCircle as AddCircleIcon,
  ContactMail as ContactMailIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon
} from '@material-ui/icons';
import {
  Box,
  // Button,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from '@material-ui/core';
// import { Popup } from 'components';
// import { ObjectAccess } from '../../components';
import {
  clearOrganizationMessages,
  organizationProjectInvite,
  projectUpdate,
  projectDelete
} from '../../../../../redux';
import {
  DATA_TYPE_NUMBER,
  DATA_TYPE_STRING,
  DISCIPLINE_TYPE,
  EDIT,
  EMAIL,
  LS_ORGANIZATION_ID,
  NAME,
  PRIMARY_CONTACT,
  PROJECT_SETTINGS,
  ROLE,
  ROLE_OBSERVER,
  ROLE_PL,
  USER
} from 'common/constants';
import {
  categorizeDiscTypes,
  getDefaultAccessValue,
  hasArrayError,
  hasError,
  isObjectEmpty
} from 'common/helper';
import { emailSchema } from 'common/schema';
import { getEmailExclusionSchema } from 'common/validators';
import { useStyles } from '../../../styles';
import classNames from 'classnames';
import validate from 'validate.js';

const UserList = ({
  currentOrg,
  disciplineTypes,
  myOrg,
  projectArchived,
  orgChartView,
  projId,
  projOrgs
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const {
    object: { accessTypes, types: objectTypes },
    organization: { organizationsAll, projectInvite },
    user: { roles }
  } = useSelector(state => state);
  const { users = [] } = myOrg || {};
  const availableDisciplines = categorizeDiscTypes(disciplineTypes).notArchived;
  const emailSchemaWithExclusion = {
    email: (value, attributes) => {
      const exclusion = getEmailExclusionSchema(
        projOrgs,
        organizationsAll,
        value,
        { ...attributes, organization_name: myOrg?.name }
      );
      return {
        ...emailSchema.email,
        ...exclusion
      };
    }
  };
  const schema = {
    ...emailSchema,
    project_settings: {
      array: {
        role: {
          presence: {
            allowEmpty: false,
            message: (
              <FormattedMessage
                defaultMessage="{name} is required"
                id="error.REQUIRED"
                values={{
                  name: (
                    <FormattedMessage defaultMessage="Role" id="common.ROLE" />
                  )
                }}
              />
            )
          }
        }
      }
    }
  };
  const schemaWithExclusion = { ...schema, ...emailSchemaWithExclusion };

  useEffect(() => {
    if (users && users.length > 0) {
      let primary_user = {};
      const existingData = users.map(user => {
        user.existing = true;
        user.project_settings = user.project_settings.map(up => {
          if (isObjectEmpty(primary_user) && up.primary_contact)
            primary_user = user;
          return {
            ...up,
            object_access: up.object_access
              ? up.object_access
              : getDefaultAccessValue(
                  accessTypes,
                  objectTypes,
                  up.role?.name || ROLE_PL
                ),
            touched: false,
            edit: false
          };
        });
        return user;
      });
      setPrimary(primary_user);
      setRows([...existingData]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const [rows, setRows] = useState([]);
  const [primary, setPrimary] = useState({});

  const initSettings = {
    role: null,
    discipline_type: null,
    object_access: getDefaultAccessValue(accessTypes, objectTypes),
    touched: false,
    edit: true
  };
  const newUser = {
    id: null,
    email: '',
    existing: false,
    project_settings: [initSettings],
    errors: validate({ project_settings: [initSettings] }, schemaWithExclusion)
  };

  const getRequiredCounts = (arr, userId) => {
    return arr.reduce(
      (acc, row) => {
        if (row.email)
          row.project_settings.forEach(ps => {
            if (ps.role && (!userId || row.id !== userId)) {
              if (ps.role.name === ROLE_PL) acc = { ...acc, pl: ++acc.pl };
              if (ps.primary_contact) acc = { ...acc, pc: ++acc.pc };
            }
          });
        return acc;
      },
      { pl: 0, pc: 0 }
    );
  };

  const showError = isPc => {
    window.alert(
      intl.formatMessage(
        { id: 'error.REQUIRED_PROJECT_USER' },
        {
          value: isPc
            ? intl.formatMessage({ id: 'common.PRIMARY_CONTACT' })
            : intl.formatMessage({ id: 'roles.APPROVER 1 - PROJECT LEAD' })
        }
      )
    );
  };

  const handleAddRow = index => {
    if (typeof index === DATA_TYPE_NUMBER) {
      rows[index].project_settings.push(initSettings);
      rows[index].errors = validate(rows[index], schema);
    } else rows.push(newUser);
    setRows([...rows]);
  };

  const handleRemoveRow = (rowIndex, sIndex) => {
    const count = getRequiredCounts(rows, rows[rowIndex].id);
    const noPc = !count.pc;
    if (noPc || !count.pl) showError(noPc);
    else {
      const delUser = rows[rowIndex].project_settings;
      const name = intl
        .formatMessage({
          id: 'common.' + (delUser.length > 1 ? ROLE : USER).toUpperCase()
        })
        .toLowerCase();
      const del = window.confirm(
        intl.formatMessage(
          { id: 'object.DELETE_ITEM_CONFIRMATION' },
          { name: name }
        ) +
          '\n' +
          intl.formatMessage({ id: 'common.ACTION_CANNOT_UNDONE' })
      );
      if (del) {
        if (delUser[sIndex].id) {
          dispatch(
            projectDelete(
              projId,
              `project_setting_ids[]=${
                delUser[sIndex].id
              }&organization_id=${localStorage.getItem(LS_ORGANIZATION_ID)}`
            )
          );
        }
        if (delUser.length > 1) {
          delUser.splice(sIndex, 1);
          rows[rowIndex].errors = validate(rows[rowIndex], schema);
        } else rows.splice(rowIndex, 1);
        setRows([...rows]);
      }
    }
  };

  const updateRowValues = (event, value, rowIndex, sIndex, field) => {
    event.persist();

    const currRow = rows[rowIndex];
    switch (field) {
      case EMAIL:
        currRow.id = value?.id || null;
        currRow.name = value?.name || currRow.name || '';
        if (typeof value === DATA_TYPE_STRING) currRow.email = value;
        else currRow.email = value?.email || '';
        break;
      case NAME:
        currRow.name = event.target.value;
        break;
      case PRIMARY_CONTACT: {
        const params = [];
        rows.forEach(row => {
          if (primary?.id === row.id)
            row.project_settings.forEach(ps => {
              ps.primary_contact = false;
              ps.touched = true;
              params.push(createData(row, ps));
            });
          else if (row.id === currRow.id)
            row.project_settings.forEach(ps => {
              ps.primary_contact = true;
              ps.touched = true;
              params.push(createData(row, ps));
            });

          return row;
        });
        dispatch(projectUpdate(projId, { project_settings: params }));
        return;
      }
      default:
        currRow.project_settings[sIndex][field] = value;
    }
    currRow.project_settings[sIndex].touched = true;

    currRow.errors = validate(
      currRow,
      currRow.existing ? schema : schemaWithExclusion
    );
    rows[rowIndex] = currRow;
    setRows([...rows]);
  };

  const createData = ({ email, id: userId, name: userName }, setting) => {
    const { discipline_type, id, role } = setting;
    return {
      id: id,
      user_id: userId,
      user: { email: email, name: userName },
      object_access: getDefaultAccessValue(
        accessTypes,
        objectTypes,
        role?.name || ROLE_OBSERVER
      ),
      role_id: role?.id,
      admin: role?.name === ROLE_PL || false,
      discipline_type_id: discipline_type?.id,
      primary_contact: setting.primary_contact || false
    };
  };

  const handleSave = () => {
    const count = getRequiredCounts(rows);
    const noPc = !count.pc;
    if (noPc || !count.pl) showError(noPc);
    else {
      let existingToUpdate = [],
        newUsersToInvite = [];
      rows.forEach(row => {
        if (row.email)
          row.project_settings.forEach(setting => {
            // process only those that have been modified
            if (setting.touched && setting.role) {
              const data = createData(row, setting);
              if (row.existing) existingToUpdate.push(data);
              else newUsersToInvite.push(data);
            }
          });
      });

      if (newUsersToInvite.length > 0) {
        const org = myOrg || currentOrg;
        const params = {
          organization_name: org.name,
          project_id: projId,
          invitees: newUsersToInvite
        };
        dispatch(organizationProjectInvite(org.id, params));
      }

      if (existingToUpdate.length > 0)
        dispatch(projectUpdate(projId, { project_settings: existingToUpdate }));
    }
  };

  const myUserIds = users && new Set(users.map(({ id }) => id));
  const orgUsers = currentOrg?.users || [];
  const nonMembers = orgUsers?.filter(({ id }) => !myUserIds.has(id));

  const clearProjectInviteError = () => {
    dispatch(clearOrganizationMessages());
  };
  return (
    <div className={classes.padding3}>
      {projectInvite?.error ? (
        <Alert
          className={classes.marginBottom2}
          onClose={clearProjectInviteError}
          severity="error">
          <FormattedMessage
            defaultMessage={projectInvite.error}
            id="error.UNEXPECTED_RESPONSE"
          />
        </Alert>
      ) : null}
      <TableContainer component={Paper}>
        <Table size="small" stickyHeader>
          {orgChartView ? (
            <colgroup>
              <col width="30%" />
              <col width="30%" />
              <col width="20%" />
              <col width="20%" />
            </colgroup>
          ) : (
            <colgroup>
              <col />
              <col width="30%" />
              <col width="20%" />
              <col width="20%" />
              <col width="20%" />
              <col width="10%" />
            </colgroup>
          )}
          <TableHead>
            <TableRow>
              {orgChartView ? null : <TableCell />}
              <TableCell>
                <FormattedMessage defaultMessage="E-mail" id="common.EMAIL" />
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="User" id="admin.USER" />
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="Role" id="admin.ROLES" />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Discipline"
                  id="common.DISCIPLINE"
                />
              </TableCell>
              {
                // Comment this out for now
                // <TableCell align="center">
                //   <FormattedMessage
                //     defaultMessage="Object Access"
                //     id="admin.OBJECT_ACCESS"
                //   />
                // </TableCell>
              }
              {orgChartView ? null : projectArchived ? (
                <TableCell />
              ) : (
                <TableCell>
                  <Box display="flex" justifyContent="flex-end">
                    <Tooltip
                      arrow
                      title={
                        <FormattedMessage
                          defaultMessage="Add user to the project"
                          id="help.ADD_USER_PROJECT"
                        />
                      }>
                      <IconButton
                        className={classes.addIcon}
                        onClick={handleAddRow}
                        size="small">
                        <AddCircleIcon size="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      arrow
                      title={
                        <FormattedMessage
                          defaultMessage="Save changes"
                          id="common.SAVE_CHANGES"
                        />
                      }>
                      <span>
                        <IconButton
                          className={classes.saveIcon}
                          disabled={rows.some(
                            row => !isObjectEmpty(row.errors)
                          )}
                          onClick={handleSave}
                          size="small">
                          <SaveIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Box>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => {
              const rowSpan = row.project_settings.length;
              const hasPLRole = row.project_settings.some(
                ps => ps.role?.name === ROLE_PL
              );
              return row.project_settings.map((setting, sIndex) => {
                const hasEmailError = hasError(row.errors, EMAIL);
                const hasRoleError = hasArrayError(
                  row.errors,
                  PROJECT_SETTINGS,
                  ROLE,
                  sIndex
                );
                const errorStyle =
                  hasEmailError || hasRoleError ? classes.vAlignTop : null;

                return (
                  <TableRow key={`${rowIndex}_${sIndex}`}>
                    {sIndex < 1 ? (
                      <>
                        {orgChartView ? null : (
                          <TableCell
                            align="center"
                            className={classes.borderContact}
                            rowSpan={rowSpan}>
                            {hasPLRole && row.existing ? (
                              <Tooltip
                                arrow
                                title={
                                  <FormattedMessage
                                    defaultMessage="Primary Contact"
                                    id="common.PRIMARY_CONTACT"
                                  />
                                }>
                                <FormControlLabel
                                  checked={primary?.id === row.id}
                                  className={classes.noMargin}
                                  disabled={projectArchived}
                                  control={
                                    primary?.id !== row.id &&
                                    projectArchived ? (
                                      <div />
                                    ) : (
                                      <Radio
                                        checkedIcon={<ContactMailIcon />}
                                        size="small"
                                      />
                                    )
                                  }
                                  onChange={e =>
                                    updateRowValues(
                                      e,
                                      e.target.value,
                                      rowIndex,
                                      sIndex,
                                      PRIMARY_CONTACT
                                    )
                                  }
                                />
                              </Tooltip>
                            ) : null}
                          </TableCell>
                        )}
                        <TableCell
                          align="left"
                          className={classNames(classes.borderUser, errorStyle)}
                          rowSpan={rowSpan}>
                          {row.email && row.existing ? (
                            row.email
                          ) : (
                            <Autocomplete
                              freeSolo
                              getOptionLabel={option => option.email}
                              onChange={(e, v) =>
                                updateRowValues(e, v, rowIndex, sIndex, EMAIL)
                              }
                              onInputChange={(e, v) =>
                                updateRowValues(e, v, rowIndex, sIndex, EMAIL)
                              }
                              options={nonMembers}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  error={hasEmailError}
                                  fullWidth
                                  helperText={
                                    hasEmailError ? row.errors.email[0] : null
                                  }
                                  label={
                                    <FormattedMessage
                                      defaultMessage="Select user"
                                      id="admin.SELECT_USER"
                                    />
                                  }
                                  size="small"
                                  variant="outlined"
                                />
                              )}
                              size="small"
                            />
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classNames(classes.borderUser, errorStyle)}
                          rowSpan={rowSpan}>
                          {row.id && !setting.edit ? (
                            row.name || ''
                          ) : (
                            <TextField
                              disabled={!!row.id}
                              fullWidth
                              onChange={e =>
                                updateRowValues(e, null, rowIndex, sIndex, NAME)
                              }
                              size="small"
                              value={row.name || ''}
                              variant="outlined"
                            />
                          )}
                        </TableCell>
                      </>
                    ) : null}
                    <TableCell className={errorStyle}>
                      {!setting.role || setting.edit ? (
                        <Autocomplete
                          getOptionLabel={option =>
                            option.name
                              ? intl.formatMessage({
                                  id: 'roles.' + option.name.toUpperCase()
                                })
                              : ''
                          }
                          onChange={(e, v) =>
                            updateRowValues(e, v, rowIndex, sIndex, ROLE)
                          }
                          options={roles}
                          renderInput={params => (
                            <TextField
                              {...params}
                              error={hasRoleError}
                              fullWidth
                              helperText={
                                hasRoleError
                                  ? row.errors.project_settings[0][sIndex]
                                      .role[0]
                                  : null
                              }
                              name="role"
                              size="small"
                              variant="outlined"
                            />
                          )}
                          size="small"
                          value={setting.role}
                        />
                      ) : (
                        <FormattedMessage
                          defaultMessage={setting.role.name}
                          id={'roles.' + setting.role.name.toUpperCase()}
                        />
                      )}
                    </TableCell>
                    <TableCell className={errorStyle}>
                      {setting.role?.name === ROLE_PL ? null : setting.edit ? (
                        <Autocomplete
                          getOptionLabel={option => option.name || ''}
                          onChange={(e, v) =>
                            updateRowValues(
                              e,
                              v,
                              rowIndex,
                              sIndex,
                              DISCIPLINE_TYPE
                            )
                          }
                          options={availableDisciplines}
                          renderInput={params => (
                            <TextField
                              {...params}
                              fullWidth
                              size="small"
                              variant="outlined"
                            />
                          )}
                          size="small"
                          value={setting.discipline_type}
                        />
                      ) : (
                        setting.discipline_type?.name || ''
                      )}
                    </TableCell>
                    {
                      // Comment this out for now
                      // <TableCell align="center">
                      //   <Popup
                      //     button={
                      //       <Button
                      //         color="primary"
                      //         disabled={!setting.role}
                      //         variant="contained">
                      //         <FormattedMessage
                      //           defaultMessage="Edit"
                      //           id="common.EDIT"
                      //         />
                      //       </Button>
                      //     }>
                      //     <ObjectAccess
                      //       accessTypes={accessTypes}
                      //       data={setting.object_access}
                      //       handleChange={updateRowValues}
                      //       rowIndex={rowIndex}
                      //       sIndex={sIndex}
                      //       types={types}
                      //     />
                      //   </Popup>
                      // </TableCell>
                    }
                    {orgChartView ? null : projectArchived ? (
                      <TableCell />
                    ) : (
                      <TableCell>
                        <Box display="flex" justifyContent="flex-end">
                          {sIndex === rowSpan - 1 ? (
                            <Tooltip
                              arrow
                              title={
                                <FormattedMessage
                                  defaultMessage="Add role to user"
                                  id="help.ADD_USER_ROLE"
                                />
                              }>
                              <IconButton
                                className={classes.addIcon}
                                onClick={e => handleAddRow(rowIndex)}
                                size="small">
                                <AddCircleIcon />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                          {row.existing && !setting.edit ? (
                            <Tooltip
                              arrow
                              title={
                                <FormattedMessage
                                  defaultMessage="Edit"
                                  id="common.EDIT"
                                />
                              }>
                              <IconButton
                                onClick={e =>
                                  updateRowValues(
                                    e,
                                    true,
                                    rowIndex,
                                    sIndex,
                                    EDIT
                                  )
                                }
                                size="small">
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                          <Tooltip
                            arrow
                            title={
                              <FormattedMessage
                                defaultMessage="Delete"
                                id="common.DELETE"
                              />
                            }>
                            <IconButton
                              className={classes.deleteIcon}
                              onClick={() => handleRemoveRow(rowIndex, sIndex)}
                              size="small">
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                );
              });
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

UserList.propTypes = {
  currentOrg: PropTypes.object,
  disciplineTypes: PropTypes.array,
  myOrg: PropTypes.object,
  orgChartView: PropTypes.bool,
  projectArchived: PropTypes.bool,
  projId: PropTypes.string,
  projOrgs: PropTypes.array
};

UserList.defaultProps = {
  disciplineTypes: [],
  orgChartView: false,
  projectArchived: false,
  projOrgs: []
};

export default UserList;
