import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import { NavigateNext as NavigateNextIcon } from '@material-ui/icons';

const BreadCrumbs = props => {
  const {
    goBack,
    history,
    objectName,
    orgId,
    orgName,
    projectId,
    projectName
  } = props;

  const handleCrumbs = (event, name) => {
    event.preventDefault();
    let page = '',
      data = {};
    switch (name) {
      case 'home': // when we support multiple orgs, home will be the org list
      case 'organization':
        page = '/projects';
        data = { organizationId: orgId };
        break;
      case 'project':
        page = '/objects';
        data = { projectId: projectId };
        break;
      default:
        break;
    }
    if (page) {
      if (goBack) goBack(page, data);
      else history.push(page, data);
    }
  };
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextIcon fontSize="small" />}>
      <Link color="secondary" href="/" onClick={e => handleCrumbs(e, 'home')}>
        <Typography color="secondary">HOME</Typography>
      </Link>
      {orgId && orgName ? (
        projectName ? (
          <Link
            color="secondary"
            href="/"
            onClick={e => handleCrumbs(e, 'organization')}>
            <Typography color="secondary">{orgName}</Typography>
          </Link>
        ) : (
          <Typography>{orgName}</Typography>
        )
      ) : null}
      {projectId && projectName ? (
        objectName ? (
          <Link
            color="secondary"
            href="/"
            onClick={e => handleCrumbs(e, 'project')}>
            <Typography color="secondary">{projectName}</Typography>
          </Link>
        ) : (
          <Typography>{projectName}</Typography>
        )
      ) : null}
      {objectName ? <Typography>{objectName}</Typography> : null}
    </Breadcrumbs>
  );
};

BreadCrumbs.propTypes = {
  goBack: PropTypes.func,
  history: PropTypes.object,
  objectName: PropTypes.node,
  orgId: PropTypes.string,
  orgName: PropTypes.string,
  projectId: PropTypes.string,
  projectName: PropTypes.string
};

export default BreadCrumbs;
