import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FileIcon, defaultStyles as fileIconStyles } from 'react-file-icon';
import PropTypes from 'prop-types';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import {
  Avatar,
  Box,
  Checkbox,
  IconButton,
  MenuItem,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography
} from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import { CategorySelector, Distribution } from '../../components';
import { ObjectsPagination, Popup } from 'components';
import {
  downloadFile,
  formatDate,
  generateCompanyInitials,
  getFileExt,
  valueInArray
} from 'common/helper';
import { useStyles } from '../../../styles';
import {
  ACTION_POST,
  AWS_URL,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
  DOCUMENT,
  OBJECT_COLUMN_ID_ACTION_OWNER,
  OBJECT_COLUMN_ID_DESCRIPTION,
  OBJECT_COLUMN_ID_FILE_EXT,
  OBJECT_COLUMN_ID_LAST_UPDATED,
  OBJECT_COLUMN_ID_NAME,
  OBJECT_COLUMN_ID_REVISION,
  OBJECT_COLUMN_ID_SELECTED_CATEGORY,
  OBJECT_COLUMN_ID_STATUS,
  OBJECT_COLUMN_ID_TITLE,
  OBJECT_LIST_LIMIT,
  ORG_AVATAR_BACKGROUND,
  ORG_AVATAR_BG_LIST,
  PDF,
  SORT_ORDER_ASC,
  THREE_D,
  MAXIMUM_AVATARS_IN_DISTRIBUTION_COLUMN
} from 'common/constants';
import classNames from 'classnames';
import _ from 'lodash';

const DWG_FILES = process.env.REACT_APP_SUPPORTED_CAD_FILES;
const THREE_D_FILES = process.env.REACT_APP_SUPPORTED_3D_FILES;

const DocumentationTable = props => {
  const {
    displayObjectView,
    goToObjectInfo,
    handleChangePage,
    isMobileDevice,
    isProjectArchived,
    object: { loading: objectLoading, objects },
    objectId,
    order,
    orderBy,
    projectLoading,
    projectName,
    projectOrganizations,
    setTableSort: setSort
  } = props;
  const classes = useStyles();
  const [states, setStates] = useState({
    anchor: null,
    bgColors: {},
    content: [],
    page: 0,
    selected: {},
    total: 0
  });
  const { anchor, bgColors, content, page, selected, total } = states;

  useEffect(() => {
    const { content = [], page = 0, total = 0 } = objects;
    setStates(states => ({
      ...states,
      content,
      page,
      total
    }));
  }, [objects]);

  useEffect(() => {
    if (projectOrganizations.length > 0) {
      const bgColors = projectOrganizations.reduce((acc, org, idx) => {
        return { ...acc, [org.id]: ORG_AVATAR_BG_LIST[idx % 7] };
      }, {});
      setStates(states => ({ ...states, bgColors }));
    }
  }, [projectOrganizations]);

  const selectedKeys = Object.keys(selected);
  const dubheUrl = process.env.REACT_APP_DUBHE_URL;

  const downloadZip = () => {
    const zipName = `${projectName}_${formatDate(
      new Date(),
      DEFAULT_DATE_TIME_FORMAT
    )}.zip`;
    document.querySelector('#filesHiddenInput').value = JSON.stringify(
      Object.values(selected).reduce(
        (res, curr) =>
          curr.attachments?.length > 0
            ? res.concat(
                curr.attachments.map(attachment =>
                  encodeURIComponent(attachment.file.replace(`${AWS_URL}/`, ''))
                )
              )
            : res,
        []
      )
    );
    document.querySelector('#nameHiddenInput').value = zipName;
    downloadFile(dubheUrl, zipName, ACTION_POST);
    handlePopover(null);
  };

  const handlePopover = value =>
    setStates(states => ({ ...states, anchor: value }));

  const handleSelectAll = event => {
    event.stopPropagation();
    setStates(states => {
      const selected = event.target.checked
        ? content.reduce(
            (acc, obj) =>
              obj.access_policy_unshared ? acc : { ...acc, [obj.id]: obj },
            {}
          )
        : {};
      return { ...states, selected: selected };
    });
  };

  const handleSelect = (event, obj) => {
    event.stopPropagation();
    setStates(states => {
      if (event.target.checked)
        states.selected = { ...states.selected, [obj.id]: obj };
      else delete states.selected[obj.id];

      return { ...states, selected: { ...states.selected } };
    });
  };

  const access_policy_distribution =
    content && content.find(obj => obj.access_policy_distribution);
  return (
    <>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table
          className={classes.table}
          id="documentation-object-table"
          name="documentation-object-table"
          size="small"
          stickyHeader>
          <colgroup>
            {isMobileDevice ? null : <col />}
            <col />
            {/* filename */}
            <col width="20%" />
            {/* document# */}
            <col width="5%" />
            {/* category */}
            <col width="15%" />
            {/* description */}
            <col width="15%" />
            {/* status */}
            <col width="10%" />
            {/* revision */}
            <col width="5%" />
            {/* last updated */}
            <col width="5%" />
            {/* action owner */}
            <col width="10%" />
            {/* organizations */}
            {isMobileDevice ? null : <col width="15%" />}
          </colgroup>
          <TableHead>
            <TableRow>
              {isMobileDevice ? null : (
                <TableCell align="left" className={classes.tableCellCheckbox}>
                  <Box display="flex">
                    <Checkbox
                      checked={
                        selectedKeys.length > 0 &&
                        selectedKeys.length === content.length
                      }
                      className={classes.noRightPadding}
                      onClick={handleSelectAll}
                    />
                    <IconButton
                      className={classNames(
                        classes.disableHoverEffect,
                        classes.noLeftPadding
                      )}
                      disabled={!selectedKeys.length}
                      onClick={e => handlePopover(e.currentTarget)}
                      onClose={() => handlePopover(null)}
                      size="small">
                      <MoreVertIcon />
                    </IconButton>
                  </Box>
                  <Popover
                    anchorEl={anchor}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'left'
                    }}
                    onClose={() => handlePopover(null)}
                    open={Boolean(anchor)}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'left'
                    }}>
                    {access_policy_distribution && !isProjectArchived ? (
                      <Popup
                        button={
                          <MenuItem button key="addDistro">
                            <FormattedMessage
                              defaultMessage="Share with"
                              id="object.SHARE_WITH"
                            />
                          </MenuItem>
                        }>
                        <Distribution
                          objects={Object.values(selected)}
                          organizations={projectOrganizations}
                        />
                      </Popup>
                    ) : null}
                    <Popup
                      button={
                        <MenuItem button key="changeCategory">
                          <FormattedMessage
                            defaultMessage="Change category"
                            id="object.CHANGE_CATEGORY"
                          />
                        </MenuItem>
                      }>
                      <CategorySelector
                        classes={classes}
                        objects={Object.values(selected)}
                      />
                    </Popup>
                    <MenuItem button onClick={downloadZip} key="multiDownload">
                      <FormattedMessage
                        defaultMessage="Download"
                        id="common.DOWNLOAD"
                      />
                    </MenuItem>
                  </Popover>
                </TableCell>
              )}
              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === OBJECT_COLUMN_ID_FILE_EXT}
                  direction={
                    orderBy === OBJECT_COLUMN_ID_FILE_EXT
                      ? order
                      : SORT_ORDER_ASC
                  }
                  onClick={() => setSort(OBJECT_COLUMN_ID_FILE_EXT)}>
                  <FormattedMessage
                    defaultMessage="File type"
                    id="object.FILE_TYPE"
                  />
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === OBJECT_COLUMN_ID_TITLE}
                  direction={
                    orderBy === OBJECT_COLUMN_ID_TITLE ? order : SORT_ORDER_ASC
                  }
                  onClick={() => setSort(OBJECT_COLUMN_ID_TITLE)}>
                  <FormattedMessage
                    defaultMessage="Filename"
                    id="common.FILENAME"
                  />
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === OBJECT_COLUMN_ID_NAME}
                  direction={
                    orderBy === OBJECT_COLUMN_ID_NAME ? order : SORT_ORDER_ASC
                  }
                  onClick={() => setSort(OBJECT_COLUMN_ID_NAME)}>
                  <FormattedMessage
                    defaultMessage="Document#"
                    id="object.DOCUMENT_NO"
                  />
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === OBJECT_COLUMN_ID_SELECTED_CATEGORY}
                  direction={
                    orderBy === OBJECT_COLUMN_ID_SELECTED_CATEGORY
                      ? order
                      : SORT_ORDER_ASC
                  }
                  onClick={() => setSort(OBJECT_COLUMN_ID_SELECTED_CATEGORY)}>
                  <FormattedMessage
                    defaultMessage="Category"
                    id="common.CATEGORY"
                  />
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === OBJECT_COLUMN_ID_DESCRIPTION}
                  direction={
                    orderBy === OBJECT_COLUMN_ID_DESCRIPTION
                      ? order
                      : SORT_ORDER_ASC
                  }
                  onClick={() => setSort(OBJECT_COLUMN_ID_DESCRIPTION)}>
                  <FormattedMessage
                    defaultMessage="Description"
                    id="common.DESCRIPTION"
                  />
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === OBJECT_COLUMN_ID_STATUS}
                  direction={
                    orderBy === OBJECT_COLUMN_ID_STATUS ? order : SORT_ORDER_ASC
                  }
                  onClick={() => setSort(OBJECT_COLUMN_ID_STATUS)}>
                  <FormattedMessage
                    defaultMessage="Status"
                    id="common.STATUS"
                  />
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <TableSortLabel
                  active={orderBy === OBJECT_COLUMN_ID_REVISION}
                  direction={
                    orderBy === OBJECT_COLUMN_ID_REVISION
                      ? order
                      : SORT_ORDER_ASC
                  }
                  onClick={() => setSort(OBJECT_COLUMN_ID_REVISION)}>
                  <FormattedMessage
                    defaultMessage="Revision"
                    id="common.REVISION"
                  />
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === OBJECT_COLUMN_ID_LAST_UPDATED}
                  direction={
                    orderBy === OBJECT_COLUMN_ID_LAST_UPDATED
                      ? order
                      : SORT_ORDER_ASC
                  }
                  onClick={() => setSort(OBJECT_COLUMN_ID_LAST_UPDATED)}>
                  <FormattedMessage
                    defaultMessage="Last updated"
                    id="object.LAST_UPDATED"
                  />
                </TableSortLabel>
              </TableCell>
              <TableCell align="left" size="small">
                <TableSortLabel
                  active={orderBy === OBJECT_COLUMN_ID_ACTION_OWNER}
                  direction={
                    orderBy === OBJECT_COLUMN_ID_ACTION_OWNER
                      ? order
                      : SORT_ORDER_ASC
                  }
                  onClick={() => setSort(OBJECT_COLUMN_ID_ACTION_OWNER)}>
                  <FormattedMessage
                    defaultMessage="Action Owner"
                    id="object.ACTION_OWNER"
                  />
                </TableSortLabel>
              </TableCell>
              {isMobileDevice ? null : (
                <TableCell
                  align="left"
                  className={classes.historyPopper}
                  size="small">
                  <FormattedMessage
                    defaultMessage="Shared with"
                    id="object.SHARED_WITH"
                  />
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {content && !objectLoading && !projectLoading
              ? content.map((obj, index) => {
                  let sharedWith = _.chain(obj.distribution)
                    .uniqBy('organization.id')
                    .sortBy(({ organization }) =>
                      organization.name.toLocaleUpperCase()
                    )
                    .value();

                  const ext =
                    obj.attachments && obj.attachments[0]?.file
                      ? getFileExt(obj.attachments[0].file)
                      : '';
                  let iconStyles = fileIconStyles[ext]
                    ? fileIconStyles[ext]
                    : { type: DOCUMENT };
                  if (ext === PDF) iconStyles.color = '#d93831';
                  else if (
                    ext &&
                    (THREE_D_FILES.includes(ext) || DWG_FILES.includes(ext))
                  )
                    iconStyles.type = THREE_D;
                  return (
                    <TableRow
                      hover
                      id={`doc-row-${index}`}
                      key={obj.id}
                      onClick={() =>
                        displayObjectView(obj.id, obj.object_type.name)
                      }
                      selected={obj.id === objectId}>
                      {isMobileDevice ? null : (
                        <TableCell
                          align="left"
                          onClick={e => handleSelect(e, obj)}
                          padding="checkbox">
                          <Checkbox
                            checked={valueInArray(selectedKeys, obj.id)}
                            disabled={obj.access_policy_unshared}
                          />
                        </TableCell>
                      )}
                      <TableCell className={classes.fileIconCell}>
                        {obj.attachments && obj.attachments[0]?.file ? (
                          <div className={classes.fileIcon}>
                            <FileIcon
                              extension={ext}
                              labelUppercase={true}
                              {...iconStyles}
                            />
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell align="left">
                        {isMobileDevice || isProjectArchived ? (
                          obj.title
                        ) : (
                          <Tooltip
                            arrow
                            title={
                              <FormattedMessage
                                defaultMessage="Open"
                                id="object.OPEN"
                              />
                            }>
                            <span
                              className={classes.inlineFlex}
                              id={`doc-title-${index}`}
                              onClick={() => goToObjectInfo(obj.id, 'doc')}>
                              <Typography
                                className={classes.link}
                                color="secondary"
                                variant="body1">
                                {obj.title}
                              </Typography>
                            </span>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell align="left">{obj.display_name}</TableCell>
                      <TableCell align="left">
                        {obj.metadata?.selected_category?.name}
                      </TableCell>
                      <TableCell align="left">
                        {obj.metadata?.description || ''}
                      </TableCell>
                      <TableCell align="left">{obj.display_status}</TableCell>
                      <TableCell align="left">{obj.revision}</TableCell>
                      <TableCell>
                        {formatDate(obj.last_updated, DEFAULT_DATE_FORMAT)}
                      </TableCell>
                      <TableCell align="left" size="small">
                        {obj.action_owner}
                      </TableCell>
                      {isMobileDevice ? null : (
                        <TableCell align="left" size="small">
                          {sharedWith.length ? (
                            <Tooltip
                              arrow
                              classes={{
                                tooltipPlacementBottom:
                                  classes.orgTooltipContainer
                              }}
                              placement="bottom-start"
                              title={sharedWith.map(dist => (
                                <div
                                  className={classes.buttonLabel}
                                  key={dist.organization.id}>
                                  {dist.organization.name}
                                </div>
                              ))}>
                              <AvatarGroup className={classes.orgAvatar}>
                                {sharedWith
                                  .slice(
                                    0,
                                    MAXIMUM_AVATARS_IN_DISTRIBUTION_COLUMN
                                  )
                                  .map(dist => (
                                    <Avatar
                                      alt={dist.organization.name}
                                      className={
                                        classes[
                                          ORG_AVATAR_BACKGROUND +
                                            bgColors[dist.organization.id]
                                        ]
                                      }
                                      key={dist.organization.id}>
                                      <Typography variant="caption">
                                        {generateCompanyInitials(
                                          dist.organization.name
                                        )}
                                      </Typography>
                                    </Avatar>
                                  ))}
                                {sharedWith.length >
                                MAXIMUM_AVATARS_IN_DISTRIBUTION_COLUMN ? (
                                  <Avatar
                                    className={classes.orgAvatarBackgroundPink}>
                                    <Typography variant="caption">
                                      +
                                      {sharedWith.length -
                                        MAXIMUM_AVATARS_IN_DISTRIBUTION_COLUMN}
                                    </Typography>
                                  </Avatar>
                                ) : null}
                              </AvatarGroup>
                            </Tooltip>
                          ) : null}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <form
        action={dubheUrl}
        id="downloadForm"
        method="post"
        name="downloadForm">
        <input id="filesHiddenInput" name="files" type="hidden" />
        <input id="nameHiddenInput" name="name" type="hidden" />
      </form>
      <ObjectsPagination
        handleChangePage={handleChangePage}
        limit={OBJECT_LIST_LIMIT}
        page={page}
        total={total}
      />
    </>
  );
};

DocumentationTable.propTypes = {
  displayObjectView: PropTypes.func.isRequired,
  goToObjectInfo: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  isMobileDevice: PropTypes.bool,
  isProjectArchived: PropTypes.bool,
  object: PropTypes.object,
  objectId: PropTypes.string,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  projectLoading: PropTypes.bool,
  projectName: PropTypes.string,
  projectOrganizations: PropTypes.array,
  setTableSort: PropTypes.func
};

export default DocumentationTable;
