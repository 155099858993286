import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';
import {
  Clear as ClearIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Fullscreen as FullscreenIcon,
  OpenInNew as OpenInNewIcon,
  SaveAlt as SaveIcon
} from '@material-ui/icons';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Hidden,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { Carousel, Confirm, Popup, Slide } from 'components';
import { NotAuthorized } from 'views';
import { DocUploader, Status } from '../components';
import {
  getStatusIdByName,
  getUploadPath,
  processDownload
} from '../components/helper';
import {
  downloadS3File,
  isCurrentOrg,
  isObjectEmpty,
  requestFullScreen
} from 'common/helper';
import {
  objectStatusFetch,
  singleObjectFetch
} from 'redux/object/ObjectAction';
import { useStyles } from '../styles';
import {
  OBJECT,
  OBJECT_DOC,
  STATUS_ISSUED_FOR_APPROVAL,
  STATUS_VOID
} from 'common/constants';

const ObjectPreview = props => {
  const classes = useStyles();
  const {
    className,
    goToObjectInfo,
    hideOpen,
    isBoQ,
    isDocFlow,
    isProjectArchived,
    listView,
    objectId: objId,
    projectId,
    revision,
    selectedAttachment,
    toggleDrawer,
    topAccessLevel
  } = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    object: {
      fetchViewError: objFetchViewError,
      objectView: objView,
      status: objStatus,
      updated: objUpdated
    },
    screen: { isMobileDevice },
    user: {
      info: { id: userId, name: userName }
    }
  } = useSelector(state => state);
  const objectView = isObjectEmpty(revision) ? objView : revision;
  const objectId = objId.split('?')[0];
  const [currObject, setCurrObject] = useState({
    hasError: false,
    height: null,
    isFullscreen: false,
    carouselSlides: [],
    loadedSlides: [],
    selectedSlide: 0,
    values: {}
  });
  const [download, setDownload] = useState({ file: '', message: '' });
  const [anchorEl, setAnchorEl] = useState(null);
  const isAnchorOpen = Boolean(anchorEl);

  useEffect(() => {
    // reset slides
    setCurrObject(co => ({ ...co, carouselSlides: [], loadedSlides: [] }));

    if (isObjectEmpty(revision) || objUpdated)
      dispatch(
        singleObjectFetch(objectId, listView ? false : objUpdated, true)
      );
  }, [listView, objectId, objUpdated, revision, dispatch]);

  useEffect(() => {
    if (objectView.object_type?.id) {
      if (isMobileDevice) {
        setCurrObject(s => ({
          ...s,
          file: objectView?.attachments && objectView.attachments[0]
        }));
      } else {
        if (!isDocFlow) {
          const status = objectView.status?.id
            ? `&status_id=${objectView.status.id}`
            : '';
          const params = `type_id=${objectView.object_type.id}${status}`;
          dispatch(objectStatusFetch({ type: OBJECT, params: params }));
        }

        setCurrObject(s => ({
          ...s,
          ...objectView,
          access_policy_change_status: objectView.access_policy_change_status,
          access_policy_revise: objectView.access_policy_revise,
          values: {
            id: objectView.id,
            name: objectView.name,
            project_id: objectView.projectId || projectId
          },
          loadedSlides: []
        }));
      }
    }
  }, [dispatch, isDocFlow, isMobileDevice, objectView, projectId]);

  const handleActionClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleActionClose = () => {
    setAnchorEl(null);
  };

  const isVoided =
    currObject.values.status_id === getStatusIdByName(objStatus, STATUS_VOID);

  const unsharedMessage = currObject.access_policy_unshared
    ? intl.formatMessage({ id: 'error.UNAUTHORIZED_UNSHARED' }, { br: <br /> })
    : null;

  const closeErrorButton =
    listView && toggleDrawer ? (
      <Button
        className={classes.floatRight}
        onClick={toggleDrawer}
        size="small"
        style={{ maxWidth: 64 }}
        variant="contained">
        <FormattedMessage defaultMessage="Close" id="common.CLOSE" />
      </Button>
    ) : null;

  return objFetchViewError || unsharedMessage ? (
    <NotAuthorized button={closeErrorButton} message={unsharedMessage} />
  ) : isMobileDevice ? (
    currObject.file ? (
      <div>
        <Slide
          file={currObject.file}
          setDownload={setDownload}
          toggleDrawer={toggleDrawer}
        />
      </div>
    ) : null
  ) : (
    <div className={className || classes.objViewRoot}>
      {download.file ? (
        <Confirm
          message={download.message}
          onClose={() => setDownload({ file: '', message: '' })}
          onConfirm={() => downloadS3File(download.file)}
          style={{ padding: 0 }}
        />
      ) : null}
      <Card>
        <CardHeader
          action={
            <div style={{ backgroundColor: 'white' }}>
              {currObject &&
              currObject.status?.name &&
              !isDocFlow &&
              !isVoided ? (
                <>
                  {// do not allow for existing data that have more than one attachments per doc
                  currObject.attachments?.length === 1 &&
                  currObject.access_policy_revise &&
                  !isProjectArchived ? (
                    <Popup
                      button={
                        <Button
                          className={classes.viewBtnMarginLeft}
                          color="secondary"
                          size="small"
                          variant="contained">
                          <FormattedMessage
                            defaultMessage="Revise"
                            id="attachment.REVISE"
                          />
                        </Button>
                      }>
                      <DocUploader
                        dropZoneProps={{
                          multiple: false,
                          maxFiles: 1
                        }}
                        isRevision
                        object={currObject}
                        projectId={projectId}
                        uploadPath={getUploadPath(projectId)}
                      />
                    </Popup>
                  ) : null}
                  <Status
                    btnStyle={{
                      background: '#66adc7',
                      marginLeft: 12,
                      padding: '4px 10px'
                    }}
                    disabled={
                      !currObject.access_policy_change_status ||
                      isProjectArchived
                    }
                    formState={currObject}
                    isBoQ={isBoQ}
                    isCreatorOrg={isCurrentOrg(
                      currObject.metadata?.created_by_org_id
                    )}
                    isCreatorUser={
                      currObject.history &&
                      currObject.history[0]?.status?.name ===
                        STATUS_ISSUED_FOR_APPROVAL
                        ? userName === currObject.history[0].updated_by &&
                          isCurrentOrg(currObject.metadata?.created_by_org_id)
                        : userId === currObject.metadata?.created_by_id
                    }
                    isPreview
                    name={currObject.object_type.ref_name}
                    recipientId={currObject.organization?.id}
                    setFormState={setCurrObject}
                    showVoid
                    status={currObject.status.name}
                    topAccessLevel={topAccessLevel}
                  />
                </>
              ) : null}
              <Hidden mdUp>
                {currObject?.attachments?.length === 1 || !hideOpen ? (
                  <>
                    <Button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      className={classes.viewBtnMarginLeft}
                      color="primary"
                      endIcon={
                        isAnchorOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />
                      }
                      onClick={handleActionClick}
                      size="small"
                      variant="contained">
                      <FormattedMessage
                        defaultMessage="Actions"
                        id="common.ACTIONS"
                      />
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      classes={{ list: classes.actionsMenu }}
                      keepMounted
                      open={isAnchorOpen}
                      onClose={handleActionClose}>
                      {isDocFlow ? (
                        <MenuItem
                          onClick={() => {
                            requestFullScreen();
                            handleActionClose();
                          }}>
                          <ListItemIcon>
                            <FullscreenIcon />
                          </ListItemIcon>
                          <Typography>
                            <FormattedMessage
                              defaultMessage="Full Screen"
                              id="common.FULL_SCREEN"
                            />
                          </Typography>
                        </MenuItem>
                      ) : null}
                      {currObject?.attachments?.length === 1 ? (
                        <MenuItem
                          onClick={() => {
                            processDownload(
                              currObject.attachments[0],
                              setDownload
                            );
                            handleActionClose();
                          }}>
                          <ListItemIcon>
                            <SaveIcon />
                          </ListItemIcon>
                          <Typography>
                            <FormattedMessage
                              defaultMessage="Download"
                              id="common.DOWNLOAD"
                            />
                          </Typography>
                        </MenuItem>
                      ) : null}
                      {hideOpen || isProjectArchived ? null : (
                        <MenuItem
                          onClick={() =>
                            goToObjectInfo(
                              currObject.id,
                              OBJECT_DOC.toLowerCase()
                            )
                          }>
                          <ListItemIcon>
                            <OpenInNewIcon />
                          </ListItemIcon>
                          <Typography>
                            <FormattedMessage
                              defaultMessage="Open"
                              id="object.OPEN"
                            />
                          </Typography>
                        </MenuItem>
                      )}
                    </Menu>
                  </>
                ) : null}
              </Hidden>
              <Hidden smDown>
                {isDocFlow ? (
                  <Button
                    color="primary"
                    onClick={() => requestFullScreen()}
                    size="small"
                    variant="contained">
                    <FormattedMessage
                      defaultMessage="Full Screen"
                      id="common.FULL_SCREEN"
                    />
                  </Button>
                ) : null}
                {currObject?.attachments?.length === 1 ? (
                  <Button
                    className={classes.viewBtnMarginLeft}
                    color="primary"
                    onClick={() =>
                      processDownload(currObject.attachments[0], setDownload)
                    }
                    size="small"
                    startIcon={<SaveIcon />}
                    variant="contained">
                    <FormattedMessage
                      defaultMessage="Download"
                      id="common.DOWNLOAD"
                    />
                  </Button>
                ) : null}
                {hideOpen || isProjectArchived ? null : (
                  <Button
                    className={classes.viewBtnMarginLeft}
                    color="primary"
                    onClick={() =>
                      goToObjectInfo(currObject.id, OBJECT_DOC.toLowerCase())
                    }
                    size="small"
                    variant="contained">
                    <FormattedMessage defaultMessage="Open" id="object.OPEN" />
                  </Button>
                )}
              </Hidden>
              {toggleDrawer ? (
                <>
                  <Hidden lgUp>
                    <IconButton onClick={toggleDrawer} size="small">
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </Hidden>
                  <Hidden mdDown>
                    <Button
                      className={classes.viewBtnMarginLeft}
                      onClick={toggleDrawer}
                      size="small"
                      variant="contained">
                      <FormattedMessage
                        defaultMessage="Close"
                        id="common.CLOSE"
                      />
                    </Button>
                  </Hidden>
                </>
              ) : null}
            </div>
          }
          style={{ backgroundColor: 'white' }}
        />
        {currObject?.hasError ? (
          <Alert className={classes.marginBottom2} severity="error">
            <FormattedMessage
              defaultMessage={
                'Unable to change status due to missing required fields in object. ' +
                'Click open object to update.'
              }
              id="error.PREVIEW_STATUS_UPDATE"
            />
          </Alert>
        ) : null}
        {currObject &&
        currObject.id === objectId &&
        currObject.attachments?.length > 0 ? (
          <CardContent>
            <Carousel
              currObject={currObject}
              selectedItem={selectedAttachment}
              setCurrObject={setCurrObject}
              setDownload={setDownload}
            />
          </CardContent>
        ) : null}
      </Card>
    </div>
  );
};

ObjectPreview.propTypes = {
  className: PropTypes.string,
  goToObjectInfo: PropTypes.func.isRequired,
  hideOpen: PropTypes.bool,
  isBoQ: PropTypes.bool,
  isDocFlow: PropTypes.bool,
  isProjectArchived: PropTypes.bool,
  listView: PropTypes.bool,
  objectId: PropTypes.string,
  projectId: PropTypes.string,
  revision: PropTypes.object,
  selectedAttachment: PropTypes.number,
  toggleDrawer: PropTypes.func,
  topAccessLevel: PropTypes.string
};

export default ObjectPreview;
