import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import Alert from '@material-ui/lab/Alert';
import {
  Button,
  TextField,
  Link,
  Typography,
  Paper,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { userCreate } from '../../../redux';
import { emailSchema } from 'common/schema';

const schema = {
  ...emailSchema,
  name: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="Name is required"
          id="error.REQUIRED_NAME"
        />
      )
    },
    length: {
      maximum: 64
    }
  }
};

const useStyles = makeStyles(theme => ({
  contentPaper: {
    height: 'max-content',
    padding: 64
  },
  alert: {
    marginBottom: theme.spacing(2)
  },
  consent: {
    textAlign: 'justify',
    margin: theme.spacing(2, 0)
  },
  form: {
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signUpButton: {
    margin: theme.spacing(2, 0)
  }
}));

const SignUpInput = props => {
  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });
  const [isChecked, setCheckboxState] = useState(false);
  const dispatch = useDispatch();
  const user = props.user;

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleSignUp = event => {
    event.preventDefault();
    dispatch(userCreate(formState.values));
  };

  const userError = useMemo(() => {
    if (user.error) {
      return <FormattedMessage id={user.error} />;
    }
    return null;
  }, [user.error]);

  return (
    <form className={classes.form} onSubmit={handleSignUp}>
      <Paper className={classes.contentPaper} elevation={4}>
        {userError ? (
          <Alert className={classes.alert} severity="error">
            {userError}
          </Alert>
        ) : null}
        <Typography data-testid="header-message" variant="h2">
          <FormattedMessage
            defaultMessage="First enter your email"
            id="main.ENTER_EMAIL"
          />
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          <FormattedMessage
            defaultMessage="We'll send you a quick confirmation message."
            id="main.SEND_CONFIRMATION"
          />
        </Typography>
        <TextField
          className={classes.textField}
          error={hasError('email')}
          fullWidth
          helperText={hasError('email') ? formState.errors.email[0] : null}
          label={
            <FormattedMessage
              defaultMessage="Email address"
              id="common.EMAIL_ADDRESS"
            />
          }
          name="email"
          onChange={handleChange}
          type="text"
          value={formState.values.email || ''}
          variant="outlined"
        />
        <TextField
          className={classes.textField}
          error={hasError('name')}
          fullWidth
          helperText={hasError('name') ? formState.errors.name[0] : null}
          label={<FormattedMessage defaultMessage="Name" id="common.NAME" />}
          name="name"
          onChange={handleChange}
          type="text"
          value={formState.values.name || ''}
          variant="outlined"
        />
        <FormControlLabel
          className={classes.consent}
          control={
            <Checkbox
              checked={isChecked}
              color="primary"
              onChange={() => setCheckboxState(!isChecked)}
            />
          }
          label={
            <FormattedMessage
              defaultMessage="By checking this box, I consent to Str8line processing my personal data for the purpose of sending communications such as password reset emails and other system related messages or alerts. Additionally, Str8line can use the information to improve my platform experience. Lastly, I understand that Str8line will strive to keep all of my information secure and protected. I understand that I can revoke my consent at any time."
              id="main.CONSENT_AGREE"
            />
          }
        />
        <Button
          className={classes.signUpButton}
          color="primary"
          disabled={!formState.isValid || user.loading || !isChecked}
          fullWidth
          size="large"
          type="submit"
          variant="contained">
          <FormattedMessage defaultMessage="Sign up" id="main.SIGN_UP" />
        </Button>
        <Typography color="textSecondary" variant="body1">
          <FormattedMessage
            defaultMessage="Have an account?"
            id="main.HAVE_ACCOUNT"
          />{' '}
          <Link component={RouterLink} to="/sign-in" variant="h6">
            <FormattedMessage defaultMessage="Sign in" id="main.SIGN_IN" />
          </Link>
        </Typography>
      </Paper>
    </form>
  );
};

SignUpInput.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object
};

export default withRouter(SignUpInput);
