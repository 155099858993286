import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import NewLinkedObject from './NewLinkedObject';
import { Popup } from 'components';
import { Delete as DeleteIcon } from '@material-ui/icons';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import classNames from 'classnames';
import { deleteWithRoute } from 'redux/object/ObjectAction';
import { getDisplayName } from '../../components/helper';
import { withStyles } from '@material-ui/styles';
import { useStyles } from '../../styles';
import {
  LINKED_OBJECTS,
  OBJECT,
  OBJECT_REF,
  OBJECT_TMPL,
  REVISION_VALUES,
  STATUS,
  STATUS_DRAFT,
  TITLE,
  VALUES
} from 'common/constants';

const GrayTableCell = withStyles(() => ({
  root: {
    backgroundColor: '#eee',
    paddingBottom: 0
  }
}))(TableCell);

const LinkedObject = ({
  displayObjectView,
  formState,
  isEditable,
  objectTypes,
  organizations,
  projectId,
  setFormState,
  setHasPopup
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const fsValuesField = formState.isRevision ? REVISION_VALUES : VALUES;
  const { id: objectId, linked_objects = [] } = formState[fsValuesField];

  // newData is an array of Str8line Objects
  const addLinkedObjects = newData => {
    setFormState(formState => ({
      ...formState,
      [fsValuesField]: {
        ...formState[fsValuesField],
        linked_objects: (formState[fsValuesField].linked_objects || []).concat(
          newData
        )
      }
    }));
  };

  const getLinkedObjects = () => {
    return linked_objects.reduce((res, obj) => {
      let lo = { ...obj };
      if (lo.object_type === OBJECT_REF && lo.agenda_items) {
        lo = lo.agenda_items.map(o => {
          const newObj = { ...obj };
          // some existing data do not have object id even after db update
          // see: https://github.com/Vardoge/scripts/pull/10#issue-974530379
          if (!o.objectId || o.objectId === lo.id) {
            newObj.name = `${newObj.name}-${o.title}-${o.rank}`;
            newObj.id = `${newObj.id}?${o.agendaItemRank}-${o.rank}`;
          }
          return newObj;
        });
      }
      return res.concat(lo);
    }, []);
  };

  const goToLinkedObject = (event, id, object_type) => {
    event.preventDefault();
    if (displayObjectView) displayObjectView(id, object_type);
  };

  const handleDelete = (event, index) => {
    event.persist();

    const { linked_object_id: delId, name: delName } = linked_objects[index];
    const msg =
      intl.formatMessage(
        { id: 'object.UNLINK_CONFIRMATION' },
        { name: delName }
      ) +
      '\n' +
      intl.formatMessage({ id: 'common.ACTION_CANNOT_UNDONE' });

    const del = window.confirm(msg);
    if (del) {
      linked_objects.splice(index, 1);
      setFormState(formState => ({
        ...formState,
        [fsValuesField]: {
          ...formState[fsValuesField],
          linked_objects: linked_objects
        }
      }));
      if (delId) dispatch(deleteWithRoute(LINKED_OBJECTS, delId));
    }
  };

  const data = getLinkedObjects();
  const exclusionList = objectId ? data.concat({ id: objectId }) : data;
  const row_headers = [OBJECT, TITLE, STATUS];
  return (
    <Card className={classNames(classes.grayContent, classes.objectCards)}>
      <CardHeader
        className={classNames(classes.grayBackground, classes.padding1)}
        title={
          <FormattedMessage
            defaultMessage="Linked Objects"
            id={'common.LINKED OBJECTS'}
          />
        }
        titleTypographyProps={{ variant: 'h6' }}
      />
      <CardContent className={classes.sectionTable}>
        <TableContainer className={classes.sectionContainer}>
          <Table size="small" stickyHeader>
            <colgroup>
              <col width="30%" />
              <col width="35%" />
              <col width="30%" />
              <col width="5%" />
            </colgroup>
            <TableHead>
              <TableRow>
                {row_headers.map(name => (
                  <GrayTableCell align="left" key={name}>
                    <Typography variant="h6">
                      <FormattedMessage
                        defaultMessage="Object"
                        id={'common.' + name.toUpperCase()}
                      />
                    </Typography>
                  </GrayTableCell>
                ))}
                <TableCell className={classes.grayContent} />
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => {
                const status = row.status
                  ? row.status.constructor === Object
                    ? row.status.name
                    : row.status
                  : STATUS_DRAFT;
                const org =
                  row.organization_id &&
                  organizations.length &&
                  organizations.find(o => o.id === row.organization_id);
                return row.object_type === OBJECT_TMPL ? null : (
                  <TableRow key={index}>
                    <GrayTableCell>
                      <span
                        className={classes.link}
                        onClick={e =>
                          goToLinkedObject(e, row.id, row.object_type)
                        }>
                        <Typography color="secondary" variant="body1">
                          {getDisplayName(row.name)}
                        </Typography>
                      </span>
                    </GrayTableCell>
                    <GrayTableCell>
                      <Typography variant="body1">{row.title}</Typography>
                    </GrayTableCell>
                    <GrayTableCell>
                      <Typography variant="body1">
                        <FormattedMessage
                          defaultMessage={status}
                          id={'object.' + status.toUpperCase()}
                          values={{ name: org?.name || '' }}
                        />
                      </Typography>
                    </GrayTableCell>
                    <GrayTableCell
                      align="right"
                      className={classes.paddingRight}>
                      {isEditable ? (
                        <IconButton
                          className={classes.floatRight}
                          onClick={e => handleDelete(e, index)}
                          size="small">
                          <DeleteIcon
                            className={classes.delItemIcon}
                            fontSize="small"
                          />
                        </IconButton>
                      ) : null}
                    </GrayTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      {isEditable ? (
        <CardActions className={classes.sectionLoButton}>
          <Popup
            button={
              <Button color="primary" variant="contained">
                <FormattedMessage
                  defaultMessage="Add Link"
                  id="common.ADD_LINK"
                />
              </Button>
            }
            className={classes.floatRight}
            setHasPopup={setHasPopup}>
            <NewLinkedObject
              existing={exclusionList}
              objectTypes={objectTypes}
              onSubmit={addLinkedObjects}
              projectId={projectId}
              setHasPopup={setHasPopup}
            />
          </Popup>
        </CardActions>
      ) : null}
    </Card>
  );
};

LinkedObject.propTypes = {
  displayObjectView: PropTypes.func,
  formState: PropTypes.object,
  isEditable: PropTypes.bool,
  objectTypes: PropTypes.array,
  organizations: PropTypes.array,
  projectId: PropTypes.string,
  setFormState: PropTypes.func,
  setHasPopup: PropTypes.func
};

export default LinkedObject;
