import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography
} from '@material-ui/core';
import {
  ExitToApp as ExitToAppIcon,
  Face as FaceIcon,
  HelpOutline as HelpIcon
} from '@material-ui/icons';
import { Confirm } from 'components';
import { logout } from '../../../../redux';
import {
  DOC_WITH_ID_URL,
  OBJECT_WITH_ID_URL,
  STR8LINE_SUPPORT_DOC_URL
} from 'common/constants';
import { useMsal } from '@azure/msal-react';

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer'
  },
  list: {
    width: 200
  },
  selfCenter: {
    alignSelf: 'center'
  }
}));

const Navigation = ({ history, userInfo }) => {
  const { instance } = useMsal();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [confirm, setConfirm] = useState(null);

  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleSignout = () => {
    instance.setActiveAccount(null);
    dispatch(logout());
  };

  const goToAdminPage = event => {
    if (event) event.preventDefault();
    handleClose();
    history.push('/admin');
  };

  const openInNewTab = url => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const handleItemSelection = fn => {
    if (
      history.location.pathname.includes(OBJECT_WITH_ID_URL) ||
      history.location.pathname.includes(DOC_WITH_ID_URL)
    ) {
      setConfirm({
        onConfirm: () => fn(),
        onClose: () => {
          setConfirm(null);
          handleClose();
        }
      });
    } else fn();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const userName = userInfo?.name || '';
  let userEmail = userInfo?.email || '';
  userEmail =
    userEmail.length > 30 ? `(${userEmail.slice(0, 25)}...)` : `(${userEmail})`;

  return (
    <div className={classes.selfCenter}>
      {confirm ? (
        <Confirm
          {...confirm}
          abortLabel={
            <FormattedMessage
              default="Stay on this page"
              id="object.STAY_ON_THIS_PAGE"
            />
          }
          confirmLabel={
            <FormattedMessage
              default="Leave this page"
              id="object.LEAVE_THIS_PAGE"
            />
          }
          message={
            <Typography>
              <FormattedMessage
                default="You will lose any unsaved changes if you navigate away from the page."
                id="object.NAVIGATE_UNSAVED_CHANGES"
              />
            </Typography>
          }
        />
      ) : null}
      <Avatar
        className={classes.avatar}
        data-testid="button-avatar"
        onClick={handleClick}>
        {userName ? userName.charAt(0).toUpperCase() : null}
      </Avatar>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        id={id}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <div className={classes.list}>
          <List component="nav">
            <ListItem>
              <ListItemText
                align="center"
                primary={userName}
                secondary={userEmail}
              />
            </ListItem>
            <Divider />
            <ListItem
              button
              data-testid="button-admin"
              onClick={() => handleItemSelection(goToAdminPage)}>
              <ListItemIcon>
                <FaceIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    defaultMessage="My Account"
                    id="main.MY_ACCOUNT"
                  />
                }
              />
            </ListItem>
            <Divider />
            <ListItem
              button
              data-testid="button-help"
              onClick={() => openInNewTab(STR8LINE_SUPPORT_DOC_URL)}>
              <ListItemIcon>
                <HelpIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    defaultMessage="Support"
                    id="common.SUPPORT"
                  />
                }
              />
            </ListItem>
            <Divider />
            <ListItem
              button
              data-testid="button-signout"
              onClick={() => handleItemSelection(handleSignout)}>
              <ListItemIcon>
                <ExitToAppIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage defaultMessage="Log Out" id="main.LOGOUT" />
                }
              />
            </ListItem>
          </List>
        </div>
      </Popover>
    </div>
  );
};

Navigation.propTypes = {
  history: PropTypes.object,
  userInfo: PropTypes.object
};

export default Navigation;
