import * as projectTypes from './ProjectTypes';
import Str8line from '../auth/AuthApi';
import {
  ACTION_DELETE,
  ACTION_GET,
  ACTION_POST,
  ACTION_PUT,
  LS_STR8LINE_TOKEN,
  SS_STORED_PROJECT_ID
} from 'common/constants';

const projectRequest = () => {
  return { type: projectTypes.PROJECT_REQUEST };
};

const projectSuccess = project => {
  return { type: projectTypes.PROJECT_SUCCESS, payload: project };
};

const projectUpdateSuccess = project => {
  return { type: projectTypes.PROJECT_UPDATE_SUCCESS, payload: project };
};

const projectFailure = error => {
  return { type: projectTypes.PROJECT_FAILURE, payload: error };
};

export const projectFetch = id => {
  return dispatch => {
    dispatch(projectRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.project
      .all(id)
      .then(response => {
        dispatch(projectSuccess(response.data.data));
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(projectFailure(errorMsg));
      });
  };
};

const projectFetchSuccess = project => {
  return { type: projectTypes.PROJECT_FETCH_SUCCESS, payload: project };
};

export const singleProjectFetch = (id, params) => {
  return dispatch => {
    dispatch(projectRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.project
      .info(id, params)
      .then(response => {
        dispatch(projectFetchSuccess(response.data.data));
      })
      .catch(error => {
        dispatch(projectFailure(error.message));
      });
  };
};

export const projectCreate = params => {
  return dispatch => {
    dispatch(projectRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.project
      .create(params)
      .then(response => {
        dispatch(projectUpdateSuccess(response.data.data));
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(projectFailure(errorMsg));
      });
  };
};

export const projectUpdate = (id, params) => {
  return dispatch => {
    dispatch(projectRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.project
      .update(id, params)
      .then(response => {
        dispatch(projectUpdateSuccess(response.data.data));
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(projectFailure(errorMsg));
      });
  };
};

export const projectDelete = (id, params) => {
  return dispatch => {
    dispatch(projectRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.project
      .delete(id, params)
      .then(() => {
        dispatch(projectUpdateSuccess());
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(projectFailure(errorMsg));
      });
  };
};

const projectInviteRequest = () => {
  return { type: projectTypes.PROJECT_INVITE_REQUEST };
};
const projectInviteSuccess = data => {
  return { type: projectTypes.PROJECT_INVITE_SUCCESS, payload: data };
};
const projectInviteFailure = error => {
  return { type: projectTypes.PROJECT_INVITE_FAILURE, payload: error };
};
export const projectInvite = (id, params) => {
  return dispatch => {
    dispatch(projectInviteRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.project
      .invite(id, params)
      .then(response => {
        dispatch(projectInviteSuccess(response.data.data));
      })
      .catch(error => {
        dispatch(projectInviteFailure(error.message));
      });
  };
};

const billOfQuantityRequest = () => {
  return { type: projectTypes.PROJECT_BOQ_REQUEST };
};
const billOfQuantitySuccess = (method, data) => {
  const type = {
    [ACTION_DELETE]: projectTypes.PROJECT_BOQ_DELETE_SUCCESS,
    [ACTION_GET]: projectTypes.PROJECT_BOQ_FETCH_SUCCESS,
    [ACTION_POST]: projectTypes.PROJECT_BOQ_SUCCESS,
    [ACTION_PUT]: projectTypes.PROJECT_BOQ_SUCCESS
  };

  return {
    method: method,
    payload: data,
    type: type[method]
  };
};
const billOfQuantityFailure = error => {
  return { type: projectTypes.PROJECT_BOQ_FAILURE, payload: error };
};
export const billOfQuantity = (method, id, params, boq_id) => {
  return dispatch => {
    dispatch(billOfQuantityRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.project
      .billOfQuantity(method, id, params, boq_id)
      .then(response => {
        dispatch(billOfQuantitySuccess(method, response.data.data));
      })
      .catch(error => {
        dispatch(billOfQuantityFailure(error.message));
      });
  };
};

export const clearInviteError = () => {
  return { type: projectTypes.PROJECT_INVITE_CLEAR };
};

export const clearProjectMessages = () => {
  return { type: projectTypes.PROJECT_CLEAR_MESSAGES };
};

export const setSelectedProjectId = id => {
  sessionStorage.setItem(SS_STORED_PROJECT_ID, id);
  return { type: projectTypes.SET_SELECTED_PROJECT_ID, payload: id };
};
