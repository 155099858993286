import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { SignUpInput } from './components';
import { EmailSent } from '../index';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentBody: {
    paddingTop: theme.spacing(7),
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center'
  }
}));

const SignUp = () => {
  const classes = useStyles();
  const user = useSelector(state => state.user);
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.contentBody}>
          {user.updated ? (
            <EmailSent
              email={user.info.email}
              message="Click the link to confirm your address and get started."
            />
          ) : (
            <SignUpInput user={user} />
          )}
        </div>
      </div>
    </div>
  );
};

SignUp.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignUp);
