import React from 'react';
import {
  IconButton,
  MenuList,
  MenuItem,
  Popover,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LanguageIcon from '@material-ui/icons/Language';
import { makeStyles } from '@material-ui/core/styles';
import { useLanguage, languageList } from 'context/LanguageContext';

const useStyles = makeStyles(() => ({
  buttonText: {
    color: '#66adc7',
    textTransform: 'uppercase',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  flexGrow: {
    flexGrow: 1
  },
  languageIcon: {
    fontSize: 15,
    paddingRight: 5
  },
  menu: {
    display: 'flex',
    width: '100%'
  }
}));

const LanguageSelect = () => {
  const [ locale, updateLocale ] = useLanguage();

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'lang-select-popover' : undefined;

  const openLanguageList = event => {
    setAnchorEl(event.currentTarget);
  };

  const closeLanguageList = () => {
    setAnchorEl(null);
  };

  const selectLanguage = language => {
    updateLocale(language);
    closeLanguageList();
  };

  return (
    <>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        aria-owns={id}
        className={classes.buttonText}
        disableFocusRipple={true}
        disableRipple={true}
        onClick={openLanguageList}
        variant="text">
        <LanguageIcon className={classes.languageIcon} />
        <Typography className={classes.buttonText}>
          {locale.toUpperCase()}
        </Typography>
        <ExpandMoreIcon size="small" />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        onClose={closeLanguageList}
        id="lang-select-popover"
        open={Boolean(anchorEl)}>
        <MenuList id="simple-menu" open={Boolean(anchorEl)}>
          {Object.keys(languageList).map((language, key) => {
            return (
              <MenuItem key={key} onClick={() => selectLanguage(language)}>
                <Typography className={classes.buttonText}>
                  {languageList[language].name}
                </Typography>
              </MenuItem>
            );
          })}
        </MenuList>
      </Popover>
    </>
  );
};

export default LanguageSelect;
