import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  image: {
    display: 'inline-block',
    maxWidth: '100%',
    height: 400,
    width: 400
  }
}));

const NotAuthorized = props => {
  const classes = useStyles();
  const intl = useIntl();
  const message =
    props.location?.message ||
    props.message ||
    intl.formatMessage({ id: 'common.UNAUTHORIZED' }, { br: <br /> });

  return (
    <div className={classes.root}>
      {props.button}
      <Grid alignItems="center" container justifyContent="center" spacing={4}>
        <Grid item>
          <img
            alt="Unauthorized"
            className={classes.image}
            src="/images/unauthorized.svg"
          />
        </Grid>
        <Grid item>
          <Typography align="center" variant="h3">
            {message}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

NotAuthorized.propTypes = {
  button: PropTypes.node,
  location: PropTypes.object,
  message: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string
  ])
};

export default NotAuthorized;
