import * as screenTypes from './ScreenTypes';

export const screenSetWidth = screenWidth => {
  return { type: screenTypes.SCREEN_SET_WIDTH, screenWidth };
};

export const screenSetOrientation = orientation => {
  return { type: screenTypes.SCREEN_SET_ORIENTATION, orientation };
};

export const setIsMobileDevice = isMobileDevice => {
  return { type: screenTypes.SET_IS_MOBILE_DEVICE, isMobileDevice };
};
