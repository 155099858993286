import Request from 'common/request';
import { ARA_URL } from 'common/constants';

export default class BrregApi extends Request {
  constructor() {
    super(process.env.REACT_APP_ARA_URL || ARA_URL);
  }

  fetch(params) {
    return super.get('/ara', params, true);
  }
}
