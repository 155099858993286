import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Divider } from '@material-ui/core';
import { useStyles } from '../../styles';
import { DIVIDER } from 'common/constants';

const MentionEntry = props => {
  const {
    className,
    isFocused, //eslint-disable-line no-unused-vars
    mention: { name, type },
    searchValue, //eslint-disable-line no-unused-vars
    selectMention, //eslint-disable-line no-unused-vars
    theme,
    ...parentProps
  } = props;

  const classes = useStyles();

  if (type === DIVIDER) {
    return (
      <div
        {...parentProps}
        className={classNames(className, classes.listDividerContainer)}>
        <Divider className={classes.listDivider} />
      </div>
    );
  }

  return (
    <div {...parentProps} className={className}>
      <span className={theme?.mentionSuggestionsEntryText}>{name}</span>
    </div>
  );
};

MentionEntry.propTypes = {
  className: PropTypes.string,
  isFocused: PropTypes.bool,
  mention: PropTypes.object,
  searchValue: PropTypes.string,
  selectMention: PropTypes.func,
  theme: PropTypes.object
};

export default MentionEntry;
