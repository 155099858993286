import { withStyles } from '@material-ui/styles';
import { CardHeader } from '@material-ui/core';

const NoBgCardAction = withStyles(() => ({
  action: {
    backgroundColor: '#53a3c0'
  }
}))(CardHeader);

export default NoBgCardAction;
