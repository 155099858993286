import * as exportTypes from './ExportTypes';
const initialState = {
  error: '',
  created: false,
  deleted: false,
  loading: false,
  export: null,
  exports: [],
  status: []
};

const exportReducer = (state = initialState, action) => {
  switch (action.type) {
    case exportTypes.EXPORT_REQUEST:
      return { ...initialState, loading: true };
    case exportTypes.EXPORT_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case exportTypes.EXPORT_CREATE_SUCCESS:
      return { ...state, created: true, export: action.payload, error: '' };
    case exportTypes.EXPORT_DELETE_SUCCESS:
      return { ...state, deleted: true, error: '' };
    case exportTypes.EXPORT_LIST_SUCCESS:
      return { ...state, exports: action.payload, error: '', loading: false };
    case exportTypes.EXPORT_STATUS_SUCCESS:
      return { ...state, status: action.payload, error: '', loading: false };
    case exportTypes.EXPORT_CLEAR:
      return { ...initialState, loading: false };
    default:
      return state;
  }
};

export default exportReducer;
