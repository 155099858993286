import { PAGE_INFO } from 'common/constants';
import * as adminTypes from './AdminTypes';

const initialState = {
  isOrgAdmin: false,
  isProjLead: false,
  selectedOrg: null,
  subPage: PAGE_INFO
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminTypes.ADMIN_INITIAL_STATE:
      return initialState;
    case adminTypes.ADMIN_SET_ORG_MENU:
      return {
        ...state,
        subPage: action.subPage
      };
    case adminTypes.ADMIN_SET_ORG_SETTINGS:
      return {
        ...state,
        ...action.params
      };
    default:
      return state;
  }
};

export default adminReducer;
