import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { WysWyg } from '../../components';
import {
  CardContent,
  Divider,
  FormHelperText,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { getOrgInfo } from '../../components/helper';
import { isCurrentOrg } from 'common/helper';
import { objectCreateOrUpdate } from '../../../../redux';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from '../../styles';
import { STATUS_READ, STATUS_SENT } from 'common/constants';

const Letter = props => {
  const {
    clearTimer,
    createdBy,
    disableEdit,
    displayObjectView,
    formState,
    handleChange: handleValueChange,
    handleEditorChange,
    handleMentionItems,
    hasError,
    objectTypes,
    organizations,
    projectId,
    senderOrgInfo,
    setHasPopup,
    suggestions
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const { object, user } = useSelector(state => state);
  const { status: statusList } = object;
  const currUserId = user?.info?.id;
  useEffect(() => {
    const { discipline_type_id, history, metadata } = formState.values;
    if (
      statusList.length > 0 &&
      isCurrentOrg(discipline_type_id) &&
      currUserId !== metadata.created_by_id &&
      history?.length > 0 &&
      history[0].status.name === STATUS_SENT
    ) {
      const read = statusList.find(s => s.name === STATUS_READ);
      if (read) {
        const params = {
          ...formState.values,
          organization_id: discipline_type_id,
          status_id: read.id
        };
        delete params.discipline_type_id;
        dispatch(objectCreateOrUpdate(params));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusList]);

  const sender = (createdBy || '').concat(`\n${senderOrgInfo}`);
  const recipient = getOrgInfo(
    organizations,
    formState.values.discipline_type_id
  );

  const overrideProps = {
    inputMultiline: {
      whiteSpace: 'pre-line'
    }
  };
  return (
    <div>
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={6} xs={6}>
            <TextField
              disabled
              fullWidth
              inputProps={{ style: overrideProps.inputMultiline }}
              InputLabelProps={{ shrink: true }}
              label={
                <FormattedMessage defaultMessage="Sender" id="object.SENDER" />
              }
              multiline
              name="sender"
              minRows="4"
              type="text"
              value={sender || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <TextField
              defaultValue={recipient}
              disabled
              fullWidth
              inputProps={{ style: overrideProps.inputMultiline }}
              InputLabelProps={{ shrink: true }}
              label={
                <FormattedMessage
                  defaultMessage="Recipient"
                  id="common.RECIPIENT"
                />
              }
              multiline
              name="recipient"
              minRows="4"
              type="text"
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <TextField
              disabled
              fullWidth
              label={
                <FormattedMessage
                  defaultMessage="Our Reference"
                  id="object.OUR_REFERENCE"
                />
              }
              name="s_reference"
              size="small"
              type="text"
              value={
                formState.values.name ||
                intl.formatMessage({ id: 'object.AUTO_GENERATE' })
              }
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <TextField
              disabled={disableEdit}
              fullWidth
              label={
                <FormattedMessage
                  defaultMessage="Recipient Reference"
                  id="object.RECIPIENT_REFERENCE"
                />
              }
              name="reference"
              onChange={handleValueChange}
              size="small"
              type="text"
              value={formState.values.metadata.reference || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <>
              <Typography className={classes.boldLabel}>
                <FormattedMessage
                  defaultMessage="Message"
                  id="object.MESSAGE"
                />
                : *
              </Typography>
              <WysWyg
                clearTimer={clearTimer}
                defaultValue={
                  formState.values.metadata.content
                    ? formState.values.metadata.content
                    : ''
                }
                displayObjectView={displayObjectView}
                error={hasError('content_text')}
                handleMentionItems={handleMentionItems}
                handleParentEditorChange={handleEditorChange}
                mentionedObjs={formState.mentionedObjs}
                mentionedUsers={formState.mentionedUsers}
                name="content"
                objectTypes={objectTypes}
                projectId={projectId}
                readOnly={disableEdit}
                setHasPopup={setHasPopup}
                suggestions={suggestions}
              />
              <FormHelperText
                className={classes.errorText}
                error
                id="contentHelperText">
                {hasError('content_text')
                  ? formState.errors.content_text[0]
                  : null}
              </FormHelperText>
            </>
          </Grid>
        </Grid>
      </CardContent>
    </div>
  );
};

Letter.propTypes = {
  clearTimer: PropTypes.func,
  createdBy: PropTypes.string,
  disableEdit: PropTypes.bool,
  displayObjectView: PropTypes.func,
  formState: PropTypes.object,
  handleChange: PropTypes.func,
  handleEditorChange: PropTypes.func,
  handleMentionItems: PropTypes.func,
  hasError: PropTypes.func,
  objectTypes: PropTypes.array,
  organizations: PropTypes.array,
  projectId: PropTypes.string,
  senderOrgInfo: PropTypes.string,
  setHasPopup: PropTypes.func,
  suggestions: PropTypes.array
};

export default Letter;
