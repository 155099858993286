export default {
  root: {
    '&$selected': {
      backgroundColor: '#53a3c0',
      '&:hover': {
        backgroundColor: '#d6e9f0'
      }
    }
  },
  button: {
    '&:hover': {
      backgroundColor: '#d6e9f0'
    }
  }
};
        