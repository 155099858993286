import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextField } from '@material-ui/core';
import { Category, Meeting } from '../';
import { useStyles } from '../../../styles';
import {
  ADMIN_TEMPLATES_OPTIONS_LIST,
  CATEGORY_OPTION,
  MEETING_OPTION
} from 'common/constants';

const Templates = ({ organizationId }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [view, setView] = useState(null);

  const handleSelection = (event, value) => {
    let selected;
    switch (value) {
      case CATEGORY_OPTION:
        selected = <Category organizationId={organizationId} />;
        break;
      case MEETING_OPTION:
        selected = <Meeting organizationId={organizationId} />;
        break;
      default:
        break;
    }
    setView(selected);
  };

  return (
    <>
      <Autocomplete
        className={classes.padding2}
        getOptionLabel={option =>
          intl.formatMessage({
            defaultMessage: option,
            id: 'common.' + option.toUpperCase()
          })
        }
        onChange={handleSelection}
        options={ADMIN_TEMPLATES_OPTIONS_LIST}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            inputProps={{
              ...params.inputProps,
              'data-testid': 'autocomplete-template'
            }}
            label={
              <FormattedMessage
                defaultMessage="Select Template Type"
                id="admin.SELECT_TEMPLATE_TYPE"
              />
            }
            size="small"
            variant="outlined"
          />
        )}
        size="small"
      />
      {view}
    </>
  );
};

Templates.propTypes = {
  organizationId: PropTypes.string
};

export default Templates;
