import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import validate from 'validate.js';
import _ from 'lodash';
import { Alert } from '@material-ui/lab';
import {
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography
} from '@material-ui/core';
import {
  Clear as ClearIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  ExpandLess,
  ExpandMore,
  Save as SaveIcon
} from '@material-ui/icons';
import MeetingTemplate from './MeetingTemplate';
import { hasError } from 'common/helper';
import {
  orgMeetingTemplateDelete,
  orgMeetingTemplatesFetch,
  orgMeetingTemplateSubmit
} from 'redux/organization/OrganizationAction';
import { meetingTemplateSchema } from '../../../validations/schema';
import {
  AGENDA,
  DEFAULT_TEMPLATE_NAME,
  TITLE,
  WIDTH_XS
} from 'common/constants';
import { useStyles } from '../../../styles';

const Meeting = ({ organizationId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    organization: {
      organizationTemplates,
      organizationTemplateError,
      organizationTemplateUpdated
    },
    screen: { screenWidth }
  } = useSelector(state => state);
  const [state, setState] = useState({
    selected: {},
    templates: [],
    prevTemplates: []
  });
  const { prevTemplates, selected, templates } = state;

  useEffect(() => {
    if (organizationId) dispatch(orgMeetingTemplatesFetch(organizationId));
  }, [organizationId, dispatch]);

  useEffect(() => {
    if (organizationId && organizationTemplateUpdated)
      dispatch(orgMeetingTemplatesFetch(organizationId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationTemplateUpdated, dispatch]);

  useEffect(() => {
    setState(state => ({
      ...state,
      templates: _.cloneDeep(organizationTemplates),
      prevTemplates: _.cloneDeep(organizationTemplates)
    }));
  }, [organizationTemplates]);

  const hasAgendaError = agenda => agenda.some(a => !!a.errors?.name);

  const hasTouched = agenda => agenda.some(a => a.touched);

  const addTemplate = event => {
    event.persist();

    setState(state => {
      const templates = state.templates.concat({
        title: DEFAULT_TEMPLATE_NAME,
        agenda: [],
        errors: {},
        edit: true,
        touched: true
      });
      state.selected[templates.length - 1] = true;
      return {
        selected: selected,
        templates: templates,
        prevTemplates: templates
      };
    });
  };

  const updateTemplateValues = (event, index, field, value, extra = {}) => {
    if (event) event.persist();
    setState(state => {
      state.templates[index] = {
        ...state.templates[index],
        [field]: value,
        touched:
          field === TITLE ||
          (field === AGENDA && hasTouched(value)) ||
          !!extra.hasDeleted
      };
      state.templates[index].errors =
        validate(state.templates[index], meetingTemplateSchema) || {};
      const updatedTemplates = [...state.templates];
      const prevTemplates = extra.updatePrev
        ? updatedTemplates
        : state.prevTemplates;
      return {
        ...state,
        templates: updatedTemplates,
        prevTemplates: prevTemplates
      };
    });
  };

  const handleDisplay = index => {
    setState(state => {
      state.selected[index] = !state.selected[index];
      return { ...state };
    });
  };

  const handleDelete = (event, index) => {
    event.persist();
    const del = window.confirm(
      intl.formatMessage({ id: 'object.DELETE_TEMPLATE_CONFIRMATION' }) +
        '\n' +
        intl.formatMessage({ id: 'common.ACTION_CANNOT_UNDONE' })
    );
    if (del) {
      const delTmpl = templates[index];
      if (delTmpl.id) {
        dispatch(orgMeetingTemplateDelete(delTmpl.id));
      } else {
        templates.splice(index, 1);
        setState(state => ({ ...state, templates: [...templates] }));
      }
    }
  };

  const handleEdit = (event, index) => {
    setState(state => {
      const prevTitle = state.prevTemplates[index].title;
      let currTemplate = state.templates[index];
      currTemplate = {
        ...currTemplate,
        edit: !currTemplate.edit,
        title: prevTitle,
        touched:
          currTemplate.title !== prevTitle || hasTouched(currTemplate.agenda)
      };
      currTemplate.errors = validate(currTemplate, meetingTemplateSchema) || {};
      state.templates[index] = currTemplate;
      return { ...state, templates: [...state.templates] };
    });
  };

  const handleSave = (event, index) => {
    event.persist();
    const { agenda, id, title } = templates[index];
    const params = {
      organization_id: organizationId,
      title: title,
      agenda: agenda
    };
    dispatch(orgMeetingTemplateSubmit(params, id));
  };

  return (
    <Grid container>
      <Grid item md={12} xs={12}>
        {organizationTemplateError ? (
          <Alert className={classes.alert} severity="error">
            {organizationTemplateError}
          </Alert>
        ) : null}
        <Button
          className={classNames(classes.floatRight, classes.marginRight2)}
          color="primary"
          onClick={addTemplate}
          variant="contained">
          <Typography className={classes.buttonLabel} variant="body1">
            <FormattedMessage
              defaultMessage="Add Template"
              id="admin.ADD_TEMPLATE"
            />
          </Typography>
        </Button>
      </Grid>

      <Grid item md={12} xs={12}>
        <List>
          {templates.map(({ title, agenda, errors, edit, touched }, index) => {
            return (
              <Fragment key={index}>
                <Divider className={classes.marginTop2} />
                <ListItem button onClick={() => handleDisplay(index)}>
                  {selected[index] ? <ExpandLess /> : <ExpandMore />}
                  <ListItemText>
                    {edit ? (
                      <TextField
                        error={hasError(errors, TITLE)}
                        helperText={
                          hasError(errors, TITLE) ? errors.title[0] : null
                        }
                        onChange={e =>
                          updateTemplateValues(e, index, TITLE, e.target.value)
                        }
                        onClick={e => e.stopPropagation()}
                        size="small"
                        style={{
                          width:
                            screenWidth === WIDTH_XS
                              ? '75%'
                              : touched
                              ? '94%'
                              : '97%'
                        }}
                        value={title || ''}
                      />
                    ) : (
                      title
                    )}
                  </ListItemText>
                  <ListItemSecondaryAction edge="end">
                    <IconButton
                      onClick={e => handleEdit(e, index)}
                      size="small">
                      {edit ? <ClearIcon /> : <EditIcon />}
                    </IconButton>
                    <IconButton
                      className={classes.deleteIcon}
                      onClick={e => handleDelete(e, index)}
                      size="small">
                      <DeleteIcon />
                    </IconButton>
                    {touched ? (
                      <IconButton
                        className={classes.saveIcon}
                        disabled={
                          hasError(errors, TITLE) || hasAgendaError(agenda)
                        }
                        onClick={e => handleSave(e, index)}
                        size="small">
                        <SaveIcon />
                      </IconButton>
                    ) : null}
                  </ListItemSecondaryAction>
                </ListItem>
                <Collapse in={selected[index]} timeout="auto" unmountOnExit>
                  <MeetingTemplate
                    admin
                    agendas={agenda}
                    index={index}
                    prevAgendas={prevTemplates[index].agenda}
                    updateTemplateValues={updateTemplateValues}
                  />
                </Collapse>
              </Fragment>
            );
          })}
        </List>
      </Grid>
    </Grid>
  );
};

Meeting.propTypes = {
  organizationId: PropTypes.string
};

export default Meeting;
