import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Topbar } from './components';
import { Backdrop, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: 'calc(100vh - 90px)'
  },
  content: {
    height: '100%'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const Minimal = ({ children }) => {
  const classes = useStyles();
  const isLoading = useSelector(state => state.user.loading);

  return (
    <div className={classes.root}>
      <Topbar
      />
      <main className={classes.content}>{children}</main>
      {isLoading ? (
        <Backdrop className={classes.backdrop} open>
          <div>
            <CircularProgress color="primary" />
          </div>
        </Backdrop>
      ) : null}
    </div>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Minimal;
