import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone-uploader';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import { Box, Button, Snackbar, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useStyles } from '../styles';
import {
  ACTION_DELETE,
  ACTION_PUT,
  MAX_FILE_COUNT,
  MAX_FILE_SIZE,
  STATUS_ABORTED,
  STATUS_DONE,
  STATUS_ERROR_FILE_SIZE,
  STATUS_ERROR_UPLOAD,
  STATUS_ERROR_UPLOAD_PARAMS,
  STATUS_ERROR_VALIDATION,
  STATUS_EXCEPTION_UPLOAD,
  STATUS_REJECTED_MAX_FILES,
  STATUS_REMOVED,
  STATUS_RESTARTED,
  STATUS_UPLOADING
} from 'common/constants';
import { actionOnS3File, getPresignedUrl } from 'common/ursa';
import { convertAndFormatBoQ } from 'common/boqParser';
import { formatBytes, getFilenameWithoutID } from 'common/helper';
import Layout from './Layout';
import './dropZoneStyle.css';

const Uploader = props => {
  const {
    bucket,
    checkDuplicateUpload,
    className,
    disableBtns,
    dropZoneProps,
    hideDefaultButton = false,
    inputContent,
    message,
    nextRank,
    onPopupClose,
    onSubmit,
    uploadPath,
    validateBoQ
  } = props;
  const classes = useStyles();
  const intl = useIntl();
  const [attachment, setAttachment] = useState({
    content: null, // used for BoQ
    files: {},
    hasError: false,
    isUploading: false,
    rank: nextRank || 0,
    xmlFile: null
  });
  const [omittedFileNames, setOmittedFileNames] = useState([]);
  const maxFileSize = dropZoneProps?.maxFileSize || MAX_FILE_SIZE;
  const displayFileSize = formatBytes(maxFileSize);

  // dropzone: called every time a file's `status` changes
  const handleChangeStatus = ({ cancel, file, meta }, status) => {
    let thisFile = { file: meta.fileUrl, error: '', status: status };
    switch (status) {
      case STATUS_ABORTED:
        thisFile.error = intl.formatMessage(
          { id: 'attachment.UPLOAD_CANCELLED' },
          { name: meta.name }
        );
        break;
      case STATUS_DONE:
        thisFile.done = true;
        break;
      case STATUS_ERROR_UPLOAD:
      case STATUS_ERROR_UPLOAD_PARAMS:
      case STATUS_EXCEPTION_UPLOAD:
        thisFile.error = intl.formatMessage(
          { id: 'attachment.UPLOAD_FAILED' },
          { name: meta.name }
        );
        break;
      case STATUS_ERROR_VALIDATION:
        thisFile.error = meta.validationError;
        break;
      case STATUS_ERROR_FILE_SIZE:
        thisFile.error = intl.formatMessage(
          {
            id: meta.size
              ? 'error.MAX_FILE_SIZE_EXCEEDED'
              : 'error.MIN_FILE_SIZE_ERROR'
          },
          {
            br: <br />,
            max_file_size: displayFileSize
          }
        );
        break;
      case STATUS_REMOVED: // file is deleted before submitting
        if (meta.fileUrl) {
          const url = new URL(meta.fileUrl);
          actionOnS3File(ACTION_DELETE, url.pathname, null, bucket);
        }
        thisFile = null;
        break;
      case STATUS_RESTARTED:
        break;
      case STATUS_UPLOADING:
        if (validateBoQ) handleBoQValidation(file, cancel);
        break;
      case STATUS_REJECTED_MAX_FILES:
        setOmittedFileNames(oldList => [...oldList, file.name]);
        return;
      default:
        return;
    }

    setAttachment(attachment => {
      let newFiles = attachment.files;

      if (thisFile) newFiles = { ...newFiles, [meta.id]: thisFile };
      else delete newFiles[meta.id];

      const updatedData = {
        ...attachment,
        files: newFiles,
        hasError: Object.values(newFiles).some(f => f.error),
        isUploading: Object.values(newFiles).some(
          f => f.status === STATUS_UPLOADING
        )
      };

      if (hideDefaultButton) onSubmit(updatedData);
      return updatedData;
    });
  };

  const setBoQFileError = () => {
    setAttachment(a => {
      for (const key of Object.keys(a.files)) {
        a.files[key].error = intl.formatMessage({ id: 'object.XML_ERROR' });
      }
      return { ...a, hasError: true };
    });
  };
  const handleBoQValidation = (file, cancel) => {
    const fileReader = new FileReader();
    fileReader.onload = async () => {
      try {
        const data = await convertAndFormatBoQ(fileReader.result);
        setAttachment(a => ({ ...a, content: data }));
      } catch (error) {
        cancel();
        setBoQFileError();
      }
    };
    fileReader.onerror = () => {
      cancel();
      setBoQFileError();
    };
    fileReader.readAsText(file);
  };

  const handleValidation = ({ meta: { name: metaName } }) => {
    let error = '';
    if (
      Object.values(attachment.files).some(
        file =>
          file.file &&
          metaName.toLowerCase() ===
            getFilenameWithoutID(file.file).toLowerCase()
      )
    )
      error = intl.formatMessage({ id: 'attachment.DUPLICATE_FILE_UPLOADED' });
    return error;
  };

  // dropzone: specify upload params and url for your files
  const getUploadParams = async ({ file, meta: { name } }) => {
    const encodedName = encodeURIComponent(decodeURIComponent(name));
    const filename = `${uploadPath}${uuidv4()}_${encodedName}`;
    const { signedUrl, fileUrl } = await getPresignedUrl(
      ACTION_PUT,
      filename,
      bucket,
      true
    );

    return {
      method: ACTION_PUT,
      body: file,
      meta: { fileUrl },
      url: signedUrl
    };
  };
  const handleSubmit = () => {
    onSubmit(attachment);
    onPopupClose();
  };

  const handleCloseWarning = () => setOmittedFileNames([]);

  const disable =
    Object.keys(attachment.files).length === 0 ||
    attachment.hasError ||
    attachment.isUploading ||
    (validateBoQ && !attachment.content);

  const defaultMessageObj =
    dropZoneProps?.maxFiles === 1
      ? {
          defaultMessage:
            'Drag File/s or Click to Browse{br}(max {max_file_size})',
          id: 'attachment.UPLOADER_LABEL_SINGLE'
        }
      : {
          defaultMessage:
            'Drag File/s or Click to Browse{br}(max {max_file_size} each)',
          id: 'attachment.UPLOADER_LABEL'
        };

  return (
    <div className={className || classes.componentPopupRoot}>
      <Dropzone
        {...dropZoneProps}
        canCancel
        getUploadParams={getUploadParams}
        inputContent={
          inputContent
            ? inputContent
            : intl.formatMessage(defaultMessageObj, {
                br: <br />,
                count: dropZoneProps?.maxFiles || MAX_FILE_COUNT,
                max_file_size: displayFileSize
              })
        }
        LayoutComponent={props =>
          Layout({
            ...props,
            disableBtns,
            hide: attachment.isUploading,
            uploadedFiles: attachment.files
          })
        }
        maxSizeBytes={maxFileSize}
        minSizeBytes={1}
        onChangeStatus={handleChangeStatus}
        validate={checkDuplicateUpload ? handleValidation : null}
      />
      {hideDefaultButton ? null : (
        <Button
          className={classes.floatRight}
          color="primary"
          disabled={disable}
          onClick={handleSubmit}
          type="submit"
          variant="contained">
          {message ? (
            message
          ) : (
            <FormattedMessage
              defaultMessage="Add Attachment"
              id="common.ADD_ATTACHMENT"
            />
          )}
        </Button>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        ClickAwayListenerProps={{ onClickAway: () => null }}
        onClick={handleCloseWarning}
        open={!!omittedFileNames.length}>
        <Box paddingTop={3}>
          <Alert
            action={
              <IconButton color="inherit" onClick={handleCloseWarning}>
                <CloseIcon />
              </IconButton>
            }
            severity="warning">
            <AlertTitle>
              <FormattedMessage
                defaultMessage="Some files were omitted because the number of files exceeded the allowed
              maximum for simultaneous uploads:"
                id="attachment.MAX_FILES_NUMBER_EXCEEDED_WARNING"
              />
            </AlertTitle>
            {omittedFileNames.join(' // ')}
          </Alert>
        </Box>
      </Snackbar>
    </div>
  );
};

Uploader.propTypes = {
  bucket: PropTypes.string,
  checkDuplicateUpload: PropTypes.bool,
  className: PropTypes.string,
  disableBtns: PropTypes.bool,
  dropZoneProps: PropTypes.object,
  handleXML: PropTypes.func,
  hideDefaultButton: PropTypes.bool,
  inputContent: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  message: PropTypes.object,
  nextRank: PropTypes.number,
  onPopupClose: PropTypes.func,
  onSubmit: PropTypes.func,
  uploadPath: PropTypes.string,
  validateBoQ: PropTypes.bool
};

export default Uploader;
