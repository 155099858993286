import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { ObjectsPagination } from 'components';
import { COMMA, HYPHEN, OBJECT_LIST_LIMIT } from 'common/constants';
import { formatCurrency, isCurrentOrg } from 'common/helper';
import { useDebounce } from 'hooks';
import { useStyles } from '../styles';

const BoQPreview = props => {
  const {
    boq,
    boqId,
    boqPage,
    boqTotal,
    filterDispatch,
    goToObjectInfo,
    isModal,
    isProjectArchived,
    onPopupClose,
    selectedBoQ,
    sums
  } = props;
  const intl = useIntl();
  const classes = useStyles();
  const [states, setStates] = useState({
    approvedSum: 0,
    boq: [],
    boqItem: '',
    contractSum: 0,
    measuredSum: 0,
    page: 0
  });
  const boqHeaders = [
    intl.formatMessage({ id: 'object.CHAPTER' }),
    intl.formatMessage({ id: 'object.PROCESS' }),
    intl.formatMessage({ id: 'object.DESCRIPTION' }),
    intl.formatMessage({ id: 'object.STATUS' }),
    intl.formatMessage({ id: 'object.UNIT' }),
    intl.formatMessage({ id: 'object.CONTRACT_QUANTITY' }),
    intl.formatMessage({ id: 'object.MEASURED_QUANTITY' }),
    intl.formatMessage({ id: 'object.APPROVED_QUANTITY' }),
    intl.formatMessage({ id: 'object.UNIT_PRICE' }),
    intl.formatMessage({ id: 'object.CONTRACT_SUM' }),
    intl.formatMessage({ id: 'object.MEASURED_SUM' }),
    intl.formatMessage({ id: 'object.APPROVED_SUM' })
  ];
  const searchedBoQ = useDebounce(states.boqItem, 2000);

  const handleChangePage = (evt, newPage) => {
    if (isModal)
      setStates(prevStates => ({
        ...prevStates,
        page: newPage
      }));
    else filterDispatch({ ...selectedBoQ, page: newPage });
  };

  const handleChangeSearch = evt => {
    evt.persist();

    setStates(prevStates => ({
      ...prevStates,
      boqItem: evt.target.value.toLowerCase()
    }));
  };

  useEffect(() => {
    let approvedSum = 0,
      contractSum = 0,
      measuredSum = 0;
    if (isModal)
      contractSum = boq.reduce((acc, curr) => acc + curr.contractSum, 0);
    else {
      const isContractor = isCurrentOrg(selectedBoQ.contractor_organization_id);
      contractSum = sums.contract_sum;
      approvedSum = isContractor
        ? sums.approved_sum_final
        : sums.approved_sum_temp;
      measuredSum = isContractor
        ? sums.measured_sum_temp
        : sums.measured_sum_final;
    }

    setStates(prevStates => ({
      ...prevStates,
      approvedSum,
      contractSum,
      measuredSum
    }));

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boq, isModal, sums]);

  useEffect(() => {
    const filteredBoQ =
      searchedBoQ.length > 0
        ? boq.filter(item => {
            const process = item.process.toString().toLowerCase();
            let postnrDashed = item.post_nr_path.path.split(COMMA);
            postnrDashed.pop();
            postnrDashed = (postnrDashed.length > 0
              ? postnrDashed.join(HYPHEN).concat(HYPHEN)
              : ''
            )
              .concat(process)
              .toLowerCase();

            return (
              item.description.toLowerCase().includes(searchedBoQ) ||
              item.post_nr.toLowerCase().includes(searchedBoQ) ||
              postnrDashed.includes(searchedBoQ) ||
              process.includes(searchedBoQ)
            );
          })
        : boq;

    setStates(prevStates => ({
      ...prevStates,
      boq: filteredBoQ,
      page: 0
    }));
  }, [boq, searchedBoQ]);

  return (
    <div className={isModal ? classes.boqRoot : ''}>
      {isModal ? (
        <>
          <Box
            alignItems="center"
            className={classes.marginBottom2}
            display="flex"
            justifyContent="space-between">
            <Box flexGrow={0.115}>
              <TextField
                fullWidth
                label={
                  <FormattedMessage
                    defaultMessage="Search"
                    id="common.SEARCH"
                  />
                }
                onChange={event => handleChangeSearch(event)}
                size="small"
                variant="outlined"
              />
            </Box>
            <IconButton onClick={() => onPopupClose()} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className={classes.marginBottom2} display="flex">
            <Box flexGrow={states.contractSum ? 0.1 : 0.16}>
              <Card className={classes.boqSum}>
                <CardContent>
                  <Typography variant="h6">
                    <FormattedMessage
                      defaultMessage="Contract Sum"
                      id="object.CONTRACT_SUM"
                    />
                  </Typography>
                  <Typography align="right" color="primary" variant="h3">
                    {formatCurrency(states.contractSum, intl.locale)}
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </>
      ) : (
        <Box
          className={classes.marginBottom2}
          display="flex"
          justifyContent="space-between">
          <Box flexGrow={0.3}>
            <Card className={classes.boqSum}>
              <CardContent>
                <Typography variant="h6">
                  <FormattedMessage
                    defaultMessage="Contract Sum"
                    id="object.CONTRACT_SUM"
                  />
                </Typography>
                <Typography align="right" color="primary" variant="h3">
                  {formatCurrency(states.contractSum, intl.locale)}
                </Typography>
              </CardContent>
            </Card>
          </Box>
          <Box flexGrow={0.3}>
            <Card className={classes.boqSum}>
              <CardContent>
                <Typography variant="h6">
                  <FormattedMessage
                    defaultMessage="Approved Sum"
                    id="object.APPROVED_SUM"
                  />
                </Typography>
                <Typography align="right" color="primary" variant="h3">
                  {formatCurrency(states.approvedSum, intl.locale)}
                </Typography>
              </CardContent>
            </Card>
          </Box>
          <Box flexGrow={0.3}>
            <Card className={classes.boqSum}>
              <CardContent>
                <Typography variant="h6">
                  <FormattedMessage
                    defaultMessage="Measured Sum"
                    id="object.MEASURED_SUM"
                  />
                </Typography>
                <Typography align="right" color="primary" variant="h3">
                  {formatCurrency(states.measuredSum, intl.locale)}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Box>
      )}
      <TableContainer
        className={isModal ? classes.boqModalTableRoot : classes.boqTableRoot}
        component={Paper}>
        <Table
          id="boq-object-table"
          name="boq-object-table"
          size="small"
          stickyHeader>
          <TableHead>
            <TableRow className={classes.boqTableHeaderRow}>
              {boqHeaders.map((item, index) => (
                <TableCell key={index}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {states.boq
              .slice(
                states.page * OBJECT_LIST_LIMIT,
                states.page * OBJECT_LIST_LIMIT + OBJECT_LIST_LIMIT
              )
              .map((item, index) => (
                <TableRow id={`boq-row-${index}`} key={index}>
                  <TableCell style={{ minWidth: '30px' }}>
                    {isModal || isProjectArchived ? (
                      item.chapter
                    ) : (
                      <Tooltip
                        arrow
                        title={
                          <FormattedMessage
                            defaultMessage="Open"
                            id="object.OPEN"
                          />
                        }>
                        <span
                          className={classes.inlineFlex}
                          onClick={() => goToObjectInfo(boqId, item)}>
                          <Typography
                            className={classes.link}
                            color="secondary"
                            variant="body1">
                            {item.chapter}
                          </Typography>
                        </span>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>
                    {isModal || isProjectArchived ? (
                      item.process
                    ) : (
                      <Tooltip
                        arrow
                        title={
                          <FormattedMessage
                            defaultMessage="Open"
                            id="object.OPEN"
                          />
                        }>
                        <span
                          className={classes.inlineFlex}
                          onClick={() => goToObjectInfo(boqId, item)}>
                          <Typography
                            className={classes.link}
                            color="secondary"
                            variant="body1">
                            {item.process}
                          </Typography>
                        </span>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>
                    {isModal || isProjectArchived ? (
                      item.description
                    ) : (
                      <Tooltip
                        arrow
                        title={
                          <FormattedMessage
                            defaultMessage="Open"
                            id="object.OPEN"
                          />
                        }>
                        <span
                          className={classes.inlineFlex}
                          id={`boq-title-${index}`}
                          onClick={() => goToObjectInfo(boqId, item)}>
                          <Typography
                            className={classes.link}
                            color="secondary"
                            variant="body1">
                            {item.description}
                          </Typography>
                        </span>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>
                    {item.object?.status?.name
                      ? intl.formatMessage({
                          defaultMessage: item.object.status.name,
                          id: 'object.' + item.object.status.name.toUpperCase()
                        })
                      : ''}
                  </TableCell>
                  <TableCell>{item.unit}</TableCell>
                  <TableCell>{item.contractQuantity}</TableCell>
                  <TableCell>
                    {item.object?.metadata?.measuredQuantity ||
                      item.measuredQuantity}
                  </TableCell>
                  <TableCell>
                    {item.object?.metadata?.approvedQuantity ||
                      item.approvedQuantity}
                  </TableCell>
                  <TableCell>
                    {formatCurrency(item.unitPrice, intl.locale)}
                  </TableCell>
                  <TableCell>
                    {formatCurrency(item.contractSum, intl.locale)}
                  </TableCell>
                  <TableCell>
                    {item.object?.metadata?.measuredSum
                      ? formatCurrency(
                          item.object.metadata.measuredSum,
                          intl.locale
                        )
                      : item.measuredSum}
                  </TableCell>
                  <TableCell>
                    {item.object?.metadata?.measuredSum
                      ? formatCurrency(
                          item.object.metadata.approvedSum,
                          intl.locale
                        )
                      : item.approvedSum}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ObjectsPagination
        handleChangePage={handleChangePage}
        limit={OBJECT_LIST_LIMIT}
        page={boqPage || states.page}
        total={boqTotal || states.boq.length}
      />
    </div>
  );
};

BoQPreview.propTypes = {
  boq: PropTypes.array,
  boqId: PropTypes.string,
  boqPage: PropTypes.number,
  boqTotal: PropTypes.number,
  filterDispatch: PropTypes.func,
  goToObjectInfo: PropTypes.func,
  isModal: PropTypes.bool,
  isProjectArchived: PropTypes.bool,
  onPopupClose: PropTypes.func,
  selectedBoQ: PropTypes.object,
  sums: PropTypes.object
};

BoQPreview.defaultProps = {
  boqPage: 0,
  boqTotal: 0,
  isModal: false,
  isProjectArchived: false
};

export default BoQPreview;
