import * as brregTypes from './BrregTypes';

const initialState = {
  error: null,
  loading: false,
  organizations: [],
  page: {}
};

const brregReducer = (state = initialState, action) => {
  switch (action.type) {
    case brregTypes.BRREG_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case brregTypes.BRREG_REQUEST:
      return {
        ...state,
        error: null,
        loading: true
      };
    case brregTypes.BRREG_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        organizations: action.payload.organizations,
        page: action.payload.page
      };
    case brregTypes.BRREG_UNREQUEST:
      return {
        ...state,
        error: null,
        loading: false
      };
    default:
      return state;
  }
};

export default brregReducer;
