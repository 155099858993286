import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { RouteWithLayout } from './components';
import { Main as PrivateLayout, Minimal as PublicLayout } from './layouts';

import {
  AdminMain as AdminView,
  ForgotPassword as ForgotPasswordView,
  NotAuthorized as NotAuthorizedView,
  NotFound as NotFoundView,
  ObjectInfo as ObjectInfoView,
  ObjectList as ObjectListView,
  Organization as OrganizationView,
  Password as PasswordView,
  Project as ProjectView,
  SignIn as SignInView,
  Welcome as WelcomeView
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/sign-in" />
      <RouteWithLayout
        component={WelcomeView}
        exact
        layout={PrivateLayout}
        // only navigate to welcome after successfully activating password
        path="/welcome"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={PublicLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={ForgotPasswordView}
        exact
        layout={PublicLayout}
        path="/forgot"
      />
      <RouteWithLayout
        component={PasswordView}
        exact
        layout={PrivateLayout}
        path="/set-password"
      />
      <RouteWithLayout
        component={AdminView}
        exact
        layout={PrivateLayout}
        path="/admin"
      />
      <RouteWithLayout
        component={OrganizationView}
        exact
        layout={PrivateLayout}
        path="/organizations"
      />
      <RouteWithLayout
        component={ProjectView}
        exact
        layout={PrivateLayout}
        path="/projects"
      />
      <RouteWithLayout
        component={ObjectListView}
        exact
        layout={PrivateLayout}
        path="/objects"
      />
      <RouteWithLayout
        component={ObjectInfoView}
        exact
        layout={PrivateLayout}
        path="/object/:id"
      />
      <RouteWithLayout
        component={ObjectInfoView}
        exact
        layout={PrivateLayout}
        path="/doc/:id"
      />
      <RouteWithLayout
        component={NotAuthorizedView}
        exact
        layout={PublicLayout}
        path="/not-authorized"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={PublicLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
