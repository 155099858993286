import _ from 'lodash';
import { OBJECT_COLUMN_ID_LAST_UPDATED, SORT_ORDER_DESC } from './constants';

// simple less or greater compare for now
export const descendingComparator = (a, b, orderBy, intl) => {
  let A = _.get(a, orderBy, 0);
  let B = _.get(b, orderBy, 0);

  if (intl) {
    A = intl.formatMessage({
      defaultMessage: A,
      id: 'object.' + A.toUpperCase()
    });
    B = intl.formatMessage({
      defaultMessage: B,
      id: 'object.' + B.toUpperCase()
    });
  } else if (orderBy === OBJECT_COLUMN_ID_LAST_UPDATED) {
    return new Date(B) - new Date(A);
  }

  A = A.toString().toLowerCase();
  B = B.toString().toLowerCase();

  if (B < A) {
    return -1;
  }
  if (B > A) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy, intl) => {
  return order === SORT_ORDER_DESC
    ? (a, b) => descendingComparator(a, b, orderBy, intl)
    : (a, b) => -descendingComparator(a, b, orderBy, intl);
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

export const sortDisciplinesByOrganization = (
  disciplines,
  organizationHierarchy
) => {
  const orderedOrganizations = sortOrganizationHierarchy(organizationHierarchy);
  const disciplinesKeyValues = Object.entries(disciplines);
  let sortedDisciplines = [];

  orderedOrganizations.forEach(org => {
    sortedDisciplines = sortedDisciplines.concat(
      disciplinesKeyValues.filter(dsc => dsc[1][0].org_id === org.id)
    );
  });

  return sortedDisciplines;
};

// Breadth first search on organizations to form an ordered array of organizations
export const sortOrganizationHierarchy = organizationHierarchy => {
  let searchQueue = [];
  const sortedOrgs = [];
  searchQueue = searchQueue.concat(organizationHierarchy);

  while (searchQueue.length > 0) {
    const currentOrg = searchQueue.shift();
    sortedOrgs.push(currentOrg);
    if (!!currentOrg.children)
      searchQueue = searchQueue.concat(currentOrg.children);
  }

  return sortedOrgs;
};
