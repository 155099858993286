import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { StyledCell } from 'components';
import { formatCurrency } from 'common/helper';
import { useStyles } from '../../../styles';

const CostTable = ({ data }) => {
  const classes = useStyles();
  const intl = useIntl();
  return data && data.length > 0 ? (
    <div className={classes.paddingTop1}>
      <Typography className={classes.boldLabel}>
        <FormattedMessage
          id="object.COST_IMPACT"
          defaultMessage="Cost Impact"
        />
        :
      </Typography>
      <TableContainer>
        <Table size="small">
          <colgroup>
            <col width="30%" />
            <col width="18%" />
            <col width="12%" />
            <col width="14%" />
            <col width="10%" />
            <col width="16%" />
          </colgroup>
          <TableHead>
            <TableRow>
              <StyledCell header>
                <FormattedMessage
                  id="common.DESCRIPTION"
                  defaultMessage="Description"
                />
              </StyledCell>
              <StyledCell header>
                <FormattedMessage id="object.WBS" defaultMessage="WBS" />
              </StyledCell>
              <StyledCell header>
                <FormattedMessage id="object.UNIT" defaultMessage="Unit" />
              </StyledCell>
              <StyledCell header>
                <FormattedMessage
                  id="object.UNIT_PRICE"
                  defaultMessage="Unit price"
                />
              </StyledCell>
              <StyledCell header>
                <FormattedMessage
                  id="object.NUMBER_OF_UNITS"
                  defaultMessage="Number of units"
                />
              </StyledCell>
              <StyledCell header>
                <FormattedMessage id="object.SUM" defaultMessage="Sum" />
              </StyledCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={index}>
                <StyledCell>{item.description}</StyledCell>
                <StyledCell>{item.wbs}</StyledCell>
                <StyledCell>{item.unit}</StyledCell>
                <StyledCell align="right">
                  {formatCurrency(item.unit_price, intl.locale)}
                </StyledCell>
                <StyledCell align="right">{item.number_of_units}</StyledCell>
                <StyledCell align="right">
                  {formatCurrency(item.sum, intl.locale)}
                </StyledCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  ) : null;
};

CostTable.propTypes = {
  data: PropTypes.array
};

export default CostTable;
