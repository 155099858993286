import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { convertFromRaw, EditorState } from 'draft-js';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { IconButton, MenuItem, Popover } from '@material-ui/core';
import { useStyles } from '../../styles';
import { FORWARD_INDICATOR, OBJECT_TA, STATUS_DRAFT } from 'common/constants';
import PropTypes from 'prop-types';

const MorePopover = props => {
  const classes = useStyles();
  const { formState, setFormState, status, history } = props;
  const [anchorElMore, setAnchorEl] = useState(null);
  const open = Boolean(anchorElMore);
  const id = open ? 'more-popover' : undefined;

  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const getContentText = value => {
    const text = convertFromRaw(JSON.parse(value));
    const editor = EditorState.createWithContent(text);
    return editor.getCurrentContent().getPlainText();
  };

  const handleClickMenu = event => {
    event.preventDefault();
    handleClose();
    const draftStatus = status.find(status => status.name === STATUS_DRAFT);
    const { object_type, projectId } = formState.values;
    history.replace('/object/new', {
      objectType: object_type,
      disciplineTypeId: '',
      projectId: projectId
    });

    setFormState(formState => {
      const {
        id,
        linked_objects,
        metadata: { inquiry, reference, solutions },
        name,
        status,
        title
      } = formState.values;
      const previousObject = { id, name, status, title };
      let metadata = {
        revision: 0,
        reference: reference,
        new_inquiry: inquiry[0],
        new_inquiry_text: getContentText(inquiry[0])
      };
      if (object_type.ref_name === OBJECT_TA)
        metadata = {
          ...metadata,
          new_solutions: solutions[0],
          new_solutions_text: getContentText(solutions[0])
        };
      delete formState.values.id;
      delete formState.values.history;
      return {
        ...formState,
        isForward: true,
        values: {
          ...formState.values,
          status: draftStatus,
          status_id: draftStatus.id,
          discipline_type_id: null,
          name,
          organization: null,
          title: `${FORWARD_INDICATOR}: ${title}`,
          linked_objects: [...linked_objects, previousObject],
          history: [],
          metadata: metadata
        }
      };
    });
  };

  return (
    <div className={classes.centerAlign}>
      <IconButton
        aria-owns={id}
        className={classes.moreIconButton}
        component="span"
        onClick={handleClick}
        onClose={handleClose}
        size="small">
        <MoreVertIcon />
      </IconButton>
      <Popover
        anchorEl={anchorElMore}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <MenuItem button key={'forwardBtn'} onClick={handleClickMenu}>
          <FormattedMessage defaultMessage={'Forward'} id={'common.FORWARD'} />
        </MenuItem>
      </Popover>
    </div>
  );
};

MorePopover.propTypes = {
  formState: PropTypes.object,
  history: PropTypes.object,
  setFormState: PropTypes.func,
  status: PropTypes.array
};

export default MorePopover;
