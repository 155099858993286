import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  AddCircle as AddCircleIcon,
  Clear as ClearIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  ExpandLess,
  ExpandMore,
  Help as HelpIcon,
  RemoveCircle as RemoveCircleIcon
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { getIndentCount, hasError } from 'common/helper';
import { templateCategoryDelete } from 'redux/document/DocumentAction';
import { useStyles } from '../../../styles';
import {
  DOCUMENT_CATEGORIES,
  EXPAND,
  MAX_NESTING_DEPTH,
  NAME,
  REGEX_SPACE_ALL,
  REVISION,
  SMALL,
  SUBCATEGORY_NAME,
  UNDERSCORE,
  WIDTH_XS
} from 'common/constants';
import { Autocomplete } from '@material-ui/lab';

const CategoryInfo = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const {
    object: { revisionTypes = [] },
    screen: { isMobileDevice, screenWidth }
  } = useSelector(state => state);

  const {
    admin,
    categories,
    category,
    defaultCategory,
    errors,
    indexes,
    prevCategory,
    templateIndex,
    updateTemplateValues
  } = props;

  const handleAddCategory = event => {
    category.children = [
      ...category.children,
      {
        ...defaultCategory,
        name: `${category.name} ${SUBCATEGORY_NAME} ${category.children.length +
          1}`
      }
    ];
    category.expand = true;
    updateTemplateValues(
      event,
      templateIndex,
      DOCUMENT_CATEGORIES,
      categories,
      { updatePrev: true }
    );
  };

  const handleEditCategory = (event, value) => {
    category.edit = value;
    if (!value) {
      category.name = prevCategory.name;
      category.revision_type = prevCategory.revision_type;
      category.revision_type_id = prevCategory.revision_type_id;
      category.touched = false;
    }
    updateTemplateValues(event, templateIndex, DOCUMENT_CATEGORIES, categories);
  };

  const handleDelete = event => {
    const del = admin
      ? window.confirm(
          intl.formatMessage(
            { id: 'object.DELETE_ITEM_CONFIRMATION' },
            {
              name: intl.formatMessage({ id: 'common.CATEGORY' }).toLowerCase()
            }
          ) +
            '\n' +
            intl.formatMessage({ id: 'common.ACTION_CANNOT_UNDONE' })
        )
      : true;
    if (del) {
      if (admin && category.id) dispatch(templateCategoryDelete(category.id));
      else {
        const delIndex = indexes.pop();
        if (indexes.length > 0) {
          let currCategory;
          indexes.forEach((index, i) => {
            if (i === 0) currCategory = categories[index];
            else currCategory = currCategory.children[index];
          });
          currCategory.children.splice(delIndex, 1);
        } else categories.splice(delIndex, 1);
        updateTemplateValues(
          event,
          templateIndex,
          DOCUMENT_CATEGORIES,
          categories,
          { hasDeleted: true, updatePrev: true }
        );
      }
    }
  };

  const updateCategoryValues = (event, field) => {
    event.persist();
    category.touched = true;
    switch (field) {
      case NAME:
        category.name = event.target.value;
        break;
      case EXPAND:
        category.expand = !category.expand;
        break;
      default:
        break;
    }
    updateTemplateValues(event, templateIndex, DOCUMENT_CATEGORIES, categories);
  };

  const updateRevisionValue = (event, value) => {
    event.persist();
    if (value) {
      category.touched = true;
      category.revision_type = value;
      category.revision_type_id = value?.id;
      updateTemplateValues(
        event,
        templateIndex,
        DOCUMENT_CATEGORIES,
        categories
      );
    }
  };

  const { children, edit, expand, name, revision_type } = category;
  const adminEdit = admin && edit;
  const iconFontSize =
    screenWidth === WIDTH_XS || isMobileDevice ? SMALL : 'medium';
  let name_style = isMobileDevice ? { minWidth: '100px' } : {},
    revision_style = {};
  if (edit) {
    if (admin) {
      name_style = {
        minWidth: isMobileDevice ? name_style.minWidth : '180px',
        paddingLeft:
          (indexes.length > MAX_NESTING_DEPTH
            ? MAX_NESTING_DEPTH
            : indexes.length - 1) * 16
      };
    }
    revision_style = { minWidth: '100px' };
    if (isMobileDevice) revision_style = { ...revision_style, padding: 0 };
  }
  const indentCount = getIndentCount(
    indexes.length - 1,
    screenWidth === WIDTH_XS ? 2 : 4
  );
  const thisError = errors[indexes.join(UNDERSCORE)];

  const NoWrapTooltip = withStyles({
    tooltip: {
      maxWidth: 'none',
      whiteSpace: 'nowrap'
    }
  })(Tooltip);

  return (
    <>
      <TableRow className={classes.categoryRowHover} hover>
        <TableCell
          className={children.length > 0 ? classes.categoryCellCollapsible : ''}
          onClick={e => updateCategoryValues(e, EXPAND)}
          style={name_style}>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between">
            {adminEdit ? (
              <TextField
                error={hasError(thisError, NAME)}
                fullWidth
                helperText={
                  hasError(thisError, NAME) ? thisError.name[0] : null
                }
                multiline
                onChange={e => updateCategoryValues(e, NAME)}
                onClick={e => e.stopPropagation()}
                size="small"
                value={name || ''}
                variant="outlined"
              />
            ) : (
              <NoWrapTooltip title={name}>
                <Typography noWrap style={{ marginLeft: indentCount }}>
                  {name}
                </Typography>
              </NoWrapTooltip>
            )}
            <IconButton
              className={classNames(classes.imageButton, classes.floatRight)}
              size="small"
              style={{
                visibility: children.length > 0 ? 'visible' : 'hidden'
              }}>
              {expand ? (
                <ExpandLess fontSize="small" />
              ) : (
                <ExpandMore fontSize="small" />
              )}
            </IconButton>
          </Box>
        </TableCell>
        <TableCell style={revision_style}>
          {edit ? (
            <Autocomplete
              classes={{ option: classes.autocompleteOption }}
              getOptionLabel={option =>
                intl.formatMessage({
                  defaultMessage: option.action_name,
                  id:
                    'object.REVISION_' +
                    option.action_name
                      .replace(REGEX_SPACE_ALL, UNDERSCORE)
                      .toUpperCase()
                })
              }
              id={REVISION}
              name={REVISION}
              onChange={updateRevisionValue}
              options={revisionTypes}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  required
                  size="small"
                  variant="outlined"
                />
              )}
              renderOption={option => {
                const id =
                  'object.REVISION_' +
                  option.action_name
                    .replace(REGEX_SPACE_ALL, UNDERSCORE)
                    .toUpperCase();
                return (
                  <>
                    <Typography>
                      {option.action_name
                        ? intl.formatMessage({
                            defaultMessage: option.action_name,
                            id: id
                          })
                        : ''}
                    </Typography>
                    <NoWrapTooltip
                      title={intl.formatMessage({
                        defaultMessage: '',
                        id: id + '_HELP'
                      })}>
                      <IconButton className={classes.hidden} size="small">
                        <HelpIcon fontSize="small" />
                      </IconButton>
                    </NoWrapTooltip>
                  </>
                );
              }}
              value={revision_type}
            />
          ) : (
            <FormattedMessage
              defaultMessage={revision_type?.action_name || ''}
              id={
                'object.REVISION_' +
                revision_type?.action_name
                  ?.replace(REGEX_SPACE_ALL, UNDERSCORE)
                  .toUpperCase()
              }
            />
          )}
        </TableCell>
        <TableCell
          align="right"
          style={screenWidth === WIDTH_XS ? { minWidth: '80px' } : null}>
          {admin ? (
            <IconButton
              className={classNames(classes.addIcon, {
                [classes.hidden]: !isMobileDevice
              })}
              onClick={handleAddCategory}
              size="small">
              <AddCircleIcon fontSize={iconFontSize} />
            </IconButton>
          ) : null}
          <IconButton onClick={e => handleEditCategory(e, !edit)} size="small">
            {edit ? (
              <ClearIcon fontSize={iconFontSize} />
            ) : (
              <EditIcon fontSize={iconFontSize} />
            )}
          </IconButton>
          <IconButton onClick={handleDelete} size="small">
            {admin ? (
              <DeleteIcon
                className={classes.deleteIcon}
                fontSize={iconFontSize}
              />
            ) : (
              <RemoveCircleIcon
                className={classes.deleteIcon}
                fontSize={iconFontSize}
              />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      {expand
        ? children.map((child, childIndex) => {
            const indices = indexes.concat(childIndex);
            return (
              <CategoryInfo
                admin={admin}
                categories={categories}
                category={child}
                defaultCategory={defaultCategory}
                errors={errors}
                indexes={indices}
                key={`${indices.join(UNDERSCORE)}`}
                prevCategory={prevCategory.children[childIndex]}
                templateIndex={templateIndex}
                updateTemplateValues={updateTemplateValues}
              />
            );
          })
        : null}
    </>
  );
};

CategoryInfo.propTypes = {
  admin: PropTypes.bool,
  categories: PropTypes.array,
  category: PropTypes.object,
  defaultCategory: PropTypes.object.isRequired,
  errors: PropTypes.object,
  indexes: PropTypes.array,
  prevCategory: PropTypes.object,
  templateIndex: PropTypes.number,
  updateTemplateValues: PropTypes.func
};

CategoryInfo.defaultProps = { admin: false, errors: {} };

export default CategoryInfo;
