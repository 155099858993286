import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  theme => ({
    root: {
      padding: theme.spacing(4),
      display: 'flex'
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    menuIcon: {
      padding: 8
    },
    menuIconContainer: {
      position: 'fixed',
      top: 91,
      zIndex: 100
    },
    hide: {
      display: 'none'
    },
    drawerPaper: {
      height: 'calc(100% - 90px)!important',
      top: '90px!important',
      width: 200,
      [theme.breakpoints.down('md')]: {
        width: 250
      }
    },
    mainContent: {
      marginTop: 30,
      [theme.breakpoints.down('md')]: {
        marginLeft: 10,
        marginRight: 10
      }
    },
    main: {
      marginLeft: 230,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      width: 'calc(100% - 260px)',
      [theme.breakpoints.down('md')]: {
        marginLeft: 10,
        width: 'initial'
      }
    },
    cardHeader: {
      backgroundColor: theme.palette.black
    },
    tab: {
      backgroundColor: theme.palette.background.default,
      borderRight: `1px solid ${theme.palette.divider}`
    },
    tabBlack: {
      backgroundColor: theme.palette.black,
      color: theme.palette.white
    },
    tableContainer: {
      maxHeight: 'calc(100vh - 200px) !important',
      width: '100%'
    },
    tableContainerDisc: {
      maxHeight: 'calc(100vh - 240px) !important',
      width: '100%'
    },
    tableContainerNotif: {
      maxHeight: 'calc(100vh - 225px) !important',
      width: '100%'
    },
    adminIcon: {
      color: theme.palette.primary.main
    },
    addIcon: {
      color: '#77d353'
    },
    deleteContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    deleteIcon: {
      color: '#e86d6f'
    },
    disabledIcon: {
      color: '#ddd'
    },
    saveIcon: {
      color: '#263238'
    },
    noMargin: {
      margin: '0 !important'
    },
    margin2: {
      margin: theme.spacing(2, 0)
    },
    marginBottom2: {
      marginBottom: theme.spacing(2)
    },
    marginRight2: {
      marginRight: theme.spacing(2)
    },
    marginTop2: {
      marginTop: theme.spacing(2)
    },
    marginLeft2: {
      marginLeft: theme.spacing(2)
    },
    noPadding: {
      margin: 0,
      padding: 0
    },
    padding: {
      padding: theme.spacing(2, 0)
    },
    padding2: {
      padding: theme.spacing(2)
    },
    padding2X: {
      padding: theme.spacing(0, 2)
    },
    padding3: {
      padding: theme.spacing(3)
    },
    projectContainer: {
      padding: theme.spacing(1, 2)
    },
    fullHeight: {
      height: '100%'
    },
    fullWidth: {
      width: '100%'
    },
    halfWidth: {
      width: '50%'
    },
    inline: {
      display: 'inline'
    },
    inlineFlex: {
      display: 'inline-flex'
    },
    listItem: {
      marginTop: theme.spacing(1)
    },
    inviteDivider: {
      margin: '16px 0px !important'
    },
    inviteButton: {
      marginTop: theme.spacing(1)
    },
    popupRoot: {
      height: '100%',
      overflowY: 'auto',
      padding: theme.spacing(2),
      width: 550,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        width: 500
      },
      [theme.breakpoints.only('xs')]: {
        '@media (orientation: landscape)': {
          width: 480
        },
        '@media (orientation: portrait)': {
          width: 230
        }
      }
    },
    alert: {
      margin: theme.spacing(0, 3, 2)
    },
    alertMultiple: {
      margin: theme.spacing(2, 0),
      padding: theme.spacing(0, 2)
    },
    borderUser: {
      borderRight: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(1, 2)
    },
    borderContact: {
      borderRight: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(1)
    },
    tableNoBorder: {
      borderBottom: 'none !important'
    },
    userRoot: {
      flexGrow: 1,
      padding: theme.spacing(2)
    },
    content: {
      paddingTop: 60,
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        paddingTop: 0
      }
    },
    image: {
      marginTop: 50,
      display: 'inline-block',
      maxWidth: '100%',
      width: 560
    },
    text: {
      color: theme.palette.text.primary,
      fontWeight: 800,
      fontSize: '60px',
      letterSpacing: '-0.24px',
      lineHeight: '65px'
    },
    orgName: {
      padding: theme.spacing(0, 3, 3),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0, 2, 2)
      }
    },
    orgRoot: {
      padding: theme.spacing(0, 3, 3),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0, 2, 2)
      }
    },
    // todo : for multi-organizations, use this for grid container in OrgSettings
    gridContainer: {
      paddingTop: 40
    },
    settingsView: {
      paddingTop: theme.spacing(3),
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(2)
      }
    },
    card: {
      height: 140,
      marginBottom: 30,
      '&:last-child': {
        marginBottom: 0
      }
    },
    paper: {
      padding: theme.spacing(2)
    },
    cardRowContent: {
      padding: theme.spacing(1),
      textAlign: 'center'
    },
    addInviteIcon: {
      color: '#77d353'
    },
    imageLogo: {
      width: 160
    },
    imageButton: {
      '&:hover': {
        background: 'transparent'
      }
    },
    buttonLabel: {
      color: theme.palette.white
    },
    floatRight: {
      float: 'right'
    },
    vAlignTop: {
      verticalAlign: 'top'
    },
    hidden: {
      visibility: 'hidden'
    },
    categoryRowHover: {
      '&:hover > td > button': {
        visibility: 'inherit'
      }
    },
    categoryCellCollapsible: {
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: '#d6e9f0 !important'
      }
    },
    fixedTableLayout: {
      tableLayout: 'fixed'
    },
    viewBtnMarginLeft: {
      marginLeft: 12
    },
    notificationAllRow: {
      background: '#d6e9f0',

      '& > td': {
        borderBottom: '3px solid #d9dee1'
      }
    },
    notificationItem: {
      // Change to 0.3 when Communication is supported
      flex: 0.5
    },
    archive: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%'
    },
    buttonArchive: {
      backgroundColor: '#e86d6f',
      boxShadow:
        '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
      '&:hover': {
        backgroundColor: '#f95f62',
        boxShadow:
          '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
      },
      '&:active': {
        backgroundColor: '#e86d6f'
      },
      maxWidth: 120,
      minWidth: 120
    },
    buttonArchiveText: {
      color: '#fff'
    },
    buttonUnarchive: {
      backgroundColor: '#dd882e',
      boxShadow:
        '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
      '&:hover': {
        backgroundColor: '#fa8505',
        boxShadow:
          '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
      },
      '&:active': {
        backgroundColor: '#dd882e'
      },
      maxWidth: 120,
      minWidth: 120
    },
    buttonExport: {
      maxWidth: 120,
      minWidth: 120
    },
    link: {
      cursor: 'pointer'
    },
    autocompleteOption: {
      justifyContent: 'space-between',
      '&[data-focus="true"] > button': {
        visibility: 'inherit'
      },
      '&[data-focus="false"] > button': {
        visibility: 'hidden'
      }
    }
  }),
  { index: 1 }
);
