export const PROJECT_REQUEST = 'PROJECT_REQUEST';
export const PROJECT_FAILURE = 'PROJECT_FAILURE';
export const PROJECT_SUCCESS = 'PROJECT_SUCCESS';
export const PROJECT_FETCH_SUCCESS = 'PROJECT_FETCH_SUCCESS';
export const PROJECT_UPDATE_SUCCESS = 'PROJECT_UPDATE_SUCCESS';
export const PROJECT_DELETE_SUCCESS = 'PROJECT_UPDATE_SUCCESS';
export const PROJECT_INVITE_REQUEST = 'PROJECT_INVITE_REQUEST';
export const PROJECT_INVITE_SUCCESS = 'PROJECT_INVITE_SUCCESS';
export const PROJECT_INVITE_FAILURE = 'PROJECT_INVITE_FAILURE';
export const PROJECT_INVITE_CLEAR = 'PROJECT_INVITE_CLEAR';
export const PROJECT_BOQ_REQUEST = 'PROJECT_BOQ_REQUEST';
export const PROJECT_BOQ_SUCCESS = 'PROJECT_BOQ_SUCCESS';
export const PROJECT_BOQ_DELETE_SUCCESS = 'PROJECT_BOQ_DELETE_SUCCESS';
export const PROJECT_BOQ_FETCH_SUCCESS = 'PROJECT_BOQ_FETCH_SUCCESS';
export const PROJECT_BOQ_FAILURE = 'PROJECT_BOQ_FAILURE';
export const PROJECT_CLEAR_MESSAGES = 'OBJECT_CLEAR_MESSAGES';
export const SET_SS_SELECTED_CATEGORY = 'SET_SS_SELECTED_CATEGORY';
export const SET_SELECTED_PROJECT_ID = 'SET_SELECTED_PROJECT_ID';
