import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ACTION_GET, FILE_PATH } from 'common/constants';
import { getPresignedUrl } from 'common/ursa';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { useStyles } from '../styles';

const PreviewPane = ({
  bucket,
  children,
  encodeUrl,
  filePath,
  srcFormat,
  style
}) => {
  const classes = useStyles();
  const [mySignedUrl, setMySignedUrl] = useState({
    signedUrl: '',
    path: ''
  });

  useEffect(() => {
    let isMounted = true;
    const { signedUrl, path } = mySignedUrl;
    if (!signedUrl || filePath !== path) {
      const url = new URL(filePath);
      getPresignedUrl(ACTION_GET, url.pathname, bucket).then(data => {
        if (isMounted)
          setMySignedUrl({ signedUrl: data.signedUrl, path: filePath });
      });
    }
    return () => (isMounted = false);
  }, [bucket, filePath, mySignedUrl]);

  return mySignedUrl.signedUrl ? (
    <div className={classes.previewPlaceholder} style={style}>
      {React.cloneElement(children, {
        src: srcFormat.replace(
          FILE_PATH,
          encodeUrl
            ? encodeURIComponent(mySignedUrl.signedUrl)
            : mySignedUrl.signedUrl
        )
      })}
    </div>
  ) : null;
};

PreviewPane.propTypes = {
  bucket: PropTypes.string,
  children: PropTypes.object.isRequired,
  encodeUrl: PropTypes.bool,
  filePath: PropTypes.string.isRequired,
  srcFormat: PropTypes.string.isRequired,
  style: PropTypes.object
};

export default PreviewPane;
