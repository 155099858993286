import React, { Fragment, useEffect } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
  FormHelperText
} from '@material-ui/core';
import { CostTable, DisciplineHeader, WysWyg } from '../../components';
import { CostTable as CostTableView } from '../../ObjectView/components';
import { getDisciplineStatus } from '../statusProcessor';
import { isObjectEmpty, valueInArray } from 'common/helper';
import { sortDisciplinesByOrganization } from 'common/sorting';
import { objectStatusFetch } from '../../../../redux';
import { useStyles } from '../../styles';
import {
  BACKGROUND,
  DESCRIPTION,
  DISCIPLINE,
  DISC_BG_LIST,
  LS_ORGANIZATION_NAME,
  OBJECT_ALL,
  OBJECT_DISP,
  REGEX_UNDERSCORE,
  ROLE_DL,
  ROLE_PL,
  STATUS_DRAFT,
  STATUS_EVALUATE,
  STATUS_EVALUATION_COMPLETE,
  STATUS_EVALUATION_ON_GOING
} from 'common/constants';

const Ico = props => {
  const {
    clearTimer,
    disableEdit,
    displayObjectView,
    formState,
    handleChange,
    handleEditorChange,
    handleMentionItems,
    hasError,
    isCreatorOrg,
    objectTypes,
    organizationHierarchy,
    organizations,
    projectId,
    setDiscAccess,
    setFormState,
    setHasPopup,
    suggestions,
    topAccessLevel
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    object: { disciplineStatus, disciplineTypes, types: objTypes }
  } = useSelector(state => state);
  const {
    access_policy_disciplines,
    metadata: {
      description,
      disciplines = {},
      hasCostEffects: mdCostEffects,
      impact_price_description,
      total_cost_nok,
      total_schedule_impact_days
    },
    status
  } = formState.values;

  const currOrgName = localStorage.getItem(LS_ORGANIZATION_NAME);
  const disciplineId =
    objTypes?.find(v => v.ref_name === OBJECT_DISP)?.id || '';
  const mainStatus = status?.name || STATUS_DRAFT;
  const accessDisciplines =
    mainStatus === STATUS_DRAFT
      ? [OBJECT_ALL.toUpperCase()]
      : access_policy_disciplines;
  const hasCostEffects = mdCostEffects ? mdCostEffects[currOrgName] : false;

  const availableDisciplines = Object.entries(disciplineTypes).reduce(
    (res, [orgName, values]) => {
      let orgDiscs = disciplines[orgName];
      values = values.filter(v =>
        orgDiscs ? !orgDiscs.find(d => d.id === v.id) : true
      );

      if (values.length > 0)
        res[orgName] = res[orgName] ? res[orgName].concat(values) : values;

      return res;
    },
    {}
  );

  const sortedAvailableDisciplines = sortDisciplinesByOrganization(
    availableDisciplines,
    organizationHierarchy
  );
  const sortedDisciplines = sortDisciplinesByOrganization(
    disciplines,
    organizationHierarchy
  );

  useEffect(() => {
    if (disciplineStatus.length === 0 && disciplineId)
      dispatch(
        objectStatusFetch({
          type: DISCIPLINE,
          params: `type_id=${disciplineId}`
        })
      );
  }, [disciplineStatus.length, disciplineId, dispatch]);

  const handleDisciplines = (event, item, orgName) => {
    event.persist();

    setFormState(formState => {
      let disciplines = formState.values.metadata.disciplines || {};
      let orgDisciplines = disciplines[orgName];

      if (event.target.checked) {
        const orgUsers =
          organizations.find(org => org.name === orgName)?.users || [];
        // fallback to pl if dl is not available
        const { dl, pl } = orgUsers.slice(0).reduce(
          (acc, ou, _index, arr) => {
            if (acc.dl) arr.splice(1);
            else {
              const { dl, pl } = ou.project_settings.slice(0).reduce(
                (acc2, ps, _index, arr2) => {
                  if (acc2.dl) arr2.splice(1);
                  else if (!acc2.pl && ps.role?.name === ROLE_PL) acc2.pl = ou;
                  else if (
                    ps.role?.name === ROLE_DL &&
                    (!ps.discipline_type || // DL to all
                      ps.discipline_type?.name === item.name)
                  )
                    acc2.dl = ou;
                  return acc2;
                },
                { dl: null, pl: null }
              );
              acc = { dl: dl || acc.dl, pl: pl || acc.pl };
            }
            return acc;
          },
          { dl: null, pl: null }
        );

        const newDisc = {
          ...item,
          description: '',
          action_owner: (dl || pl)?.id || '',
          status: getDisciplineStatus(mainStatus)
        };

        orgDisciplines = orgDisciplines
          ? orgDisciplines.concat(newDisc)
          : [newDisc];
      } else {
        orgDisciplines = orgDisciplines.filter(
          disc => !(disc.name === item.name && disc.org_id === item.org_id)
        );
      }

      if (!orgDisciplines.length) delete disciplines[orgName];
      else disciplines[orgName] = orgDisciplines;

      return {
        ...formState,
        values: {
          ...formState.values,
          metadata: {
            ...formState.values.metadata,
            disciplines: disciplines
          }
        }
      };
    });
  };

  const handleDisciplineChange = (name, value, field) => {
    const [orgName, discName] = name.split(REGEX_UNDERSCORE);
    const discField = field || DESCRIPTION;

    const newDisciplines = disciplines[orgName].map(disc => {
      if (disc.name === discName) return { ...disc, [discField]: value };
      return disc;
    });

    setFormState(formState => {
      return {
        ...formState,
        values: {
          ...formState.values,
          metadata: {
            ...formState.values.metadata,
            disciplines: {
              ...formState.values.metadata.disciplines,
              [orgName]: newDisciplines
            }
          }
        }
      };
    });
  };

  const handleCostEffects = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        metadata: {
          ...formState.values.metadata,
          hasCostEffects: {
            ...formState.values.metadata.hasCostEffects,
            [currOrgName]: event.target.checked
          }
        }
      }
    }));
  };

  return (
    <>
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={3} xs={6}>
            <TextField
              disabled
              fullWidth
              label={
                <FormattedMessage
                  defaultMessage="Total cost (NOK)"
                  id="common.TOTAL_COST"
                />
              }
              name="total_cost_nok"
              size="small"
              type="float"
              value={total_cost_nok || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={6}>
            <TextField
              disabled={disableEdit || !isCreatorOrg}
              error={hasError('total_schedule_impact_days')}
              fullWidth
              helperText={
                hasError('total_schedule_impact_days')
                  ? formState.errors.total_schedule_impact_days[0]
                  : null
              }
              label={
                <FormattedMessage
                  defaultMessage="Total schedule impact (days)"
                  id="common.TOTAL_SCHEDULE_IMPACT"
                />
              }
              name="total_schedule_impact_days"
              onChange={handleChange}
              size="small"
              type="number"
              value={total_schedule_impact_days || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography>
              <FormattedMessage
                defaultMessage="Description"
                id="common.DESCRIPTION"
              />
              : *
            </Typography>
            <WysWyg
              clearTimer={clearTimer}
              defaultValue={description || ''}
              displayObjectView={displayObjectView}
              error={hasError('description_text')}
              handleMentionItems={handleMentionItems}
              handleParentEditorChange={handleEditorChange}
              mentionedObjs={formState.mentionedObjs}
              mentionedUsers={formState.mentionedUsers}
              name="description"
              objectTypes={objectTypes}
              projectId={projectId}
              readOnly={disableEdit || !isCreatorOrg}
              setHasPopup={setHasPopup}
              suggestions={suggestions}
            />
            <FormHelperText
              className={classes.errorText}
              error
              id="descriptionHelperText">
              {hasError('description_text')
                ? formState.errors.description_text[0]
                : null}
            </FormHelperText>
          </Grid>
          {sortedDisciplines.map(([orgName, discs], index) => {
            const bgColor = DISC_BG_LIST[index % 4];
            return (
              <Fragment key={`fr_${orgName}`}>
                <Grid item md={12} xs={12}>
                  <div className={classes.discOrgLabelContainer}>
                    <Typography
                      className={classNames(
                        classes.discOrgLabelContent,
                        classes[BACKGROUND + bgColor]
                      )}>
                      {orgName}
                    </Typography>
                  </div>
                </Grid>
                {discs.map(disc => {
                  const isDiscOwner = orgName === currOrgName;
                  const canSetEvaluate =
                    valueInArray(
                      [STATUS_EVALUATION_COMPLETE, STATUS_EVALUATION_ON_GOING],
                      mainStatus
                    ) &&
                    disc.status !== STATUS_EVALUATE &&
                    ((isCreatorOrg && topAccessLevel === ROLE_PL) ||
                      (isDiscOwner &&
                        valueInArray([ROLE_DL, ROLE_PL], topAccessLevel)));

                  const disableActionOwner =
                    disableEdit ||
                    !isDiscOwner ||
                    !accessDisciplines ||
                    (accessDisciplines[0] !== OBJECT_ALL.toUpperCase() &&
                      !accessDisciplines.some(pd => pd.name === disc.name));

                  const disableStatus =
                    disableEdit || (disableActionOwner && !canSetEvaluate);

                  if (!disableActionOwner || !disableStatus)
                    setDiscAccess(true); // TODO: fix bad setState call
                  return (
                    <Grid
                      item
                      key={`grid_${orgName}_${disc.code}`}
                      md={4}
                      xs={4}>
                      <Card>
                        <DisciplineHeader
                          actionOwner={disc.action_owner}
                          bgColor={bgColor}
                          canSetEvaluate={canSetEvaluate}
                          disableActionOwner={disableActionOwner}
                          disableStatus={disableStatus}
                          disciplineType={disc}
                          handleDisciplineChange={handleDisciplineChange}
                          handleDisciplines={handleDisciplines}
                          isDiscOwner={isDiscOwner}
                          mainStatus={mainStatus}
                          organizations={organizations}
                          orgName={orgName}
                          setFormState={setFormState}
                          setHasPopup={setHasPopup}
                          status={disc.status}
                        />
                        <CardContent
                          className={classNames(
                            classes.noPaddingCardContent,
                            classes.wyswyg
                          )}>
                          <WysWyg
                            clearTimer={clearTimer}
                            defaultValue={disc.description || ''}
                            discId={disc.id}
                            displayObjectView={displayObjectView}
                            handleMentionItems={handleMentionItems}
                            handleParentEditorChange={handleDisciplineChange}
                            key={`field_${orgName}_${disc.code}`}
                            mentionedObjs={formState.mentionedObjs}
                            mentionedUsers={formState.mentionedUsers}
                            name={orgName + '_' + disc.name}
                            objectTypes={objectTypes}
                            orgName={orgName}
                            projectId={projectId}
                            readOnly={disableActionOwner}
                            setHasPopup={setHasPopup}
                            suggestions={suggestions}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Fragment>
            );
          })}
          {disableEdit || isObjectEmpty(sortedAvailableDisciplines)
            ? null
            : sortedAvailableDisciplines.map(([orgName, discs], idx) =>
                discs.length ? (
                  <Grid item key={`${orgName}_${idx}`} md={12} xs={12}>
                    <FormGroup className={classes.disciplineBox} row>
                      <Typography className={classes.organizationCard}>
                        {discs[0].org_code}
                      </Typography>
                      {discs.map(disc => (
                        <FormControlLabel
                          className={classes.organizationDiscipline}
                          control={
                            <Checkbox
                              color="primary"
                              key={`chk_${disc.id}`}
                              name={disc.name}
                              onChange={e =>
                                handleDisciplines(e, disc, orgName)
                              }
                            />
                          }
                          key={disc.id}
                          label={disc.code}
                          labelPlacement="end"
                        />
                      ))}
                    </FormGroup>
                  </Grid>
                ) : null
              )}
          <Grid item md={12} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={hasCostEffects || false}
                  color="primary"
                  disabled={disableEdit}
                  name="hasCostEffects"
                  onChange={handleCostEffects}
                />
              }
              label={
                <FormattedMessage
                  defaultMessage="Add cost effects"
                  id="object.ADD_COST_EFFECTS"
                />
              }
              labelPlacement="end"
            />
            {hasCostEffects ? (
              disableEdit ? (
                <CostTableView data={impact_price_description} />
              ) : (
                <CostTable
                  clearTimer={clearTimer}
                  impact_price_description={impact_price_description}
                  orgName={currOrgName}
                  setFormState={setFormState}
                  total_cost_nok={total_cost_nok}
                />
              )
            ) : null}
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};

Ico.propTypes = {
  clearTimer: PropTypes.func,
  disableEdit: PropTypes.bool,
  displayObjectView: PropTypes.func,
  formState: PropTypes.object,
  handleChange: PropTypes.func,
  handleEditorChange: PropTypes.func,
  handleMentionItems: PropTypes.func,
  hasError: PropTypes.func,
  isCreatorOrg: PropTypes.bool,
  objectTypes: PropTypes.array,
  organizationHierarchy: PropTypes.array,
  organizations: PropTypes.array,
  projectId: PropTypes.string,
  setDiscAccess: PropTypes.func,
  setFormState: PropTypes.any,
  setHasPopup: PropTypes.func,
  suggestions: PropTypes.array,
  topAccessLevel: PropTypes.string
};

export default Ico;
