import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from 'redux/forge/ForgeAction';
import ReactForgeViewer from 'react-forge-viewer-eu';

const ForgeViewer = ({ isFullscreen, urn }) => {
  const dispatch = useDispatch();
  const forgeState = useSelector(state => state.forge);
  const { credentials } = forgeState;
  const [state, setState] = useState({
    view: null,
    credentials: credentials || null
  });

  useEffect(() => {
    if (credentials) {
      setState(s => ({
        ...s,
        credentials: credentials
      }));
    } else dispatch(getToken());
  }, [dispatch, credentials]);

  const handleViewerError = _error => {
    // console.log('Error loading viewer.', error);
    // TODO: handle error
  };
  /* after the viewer loads a document, we need to select which viewable to
  display in our component */
  const handleDocumentLoaded = (_doc, viewables) => {
    if (viewables.length === 0) {
      // console.log('Document contains no viewables.');
      // TODO: Handle no viewables
    } else {
      //Select the first viewable in the list to use in our viewer component
      setState(s => ({
        ...s,
        view: viewables[0]
      }));
    }
  };

  const handleDocumentError = (_viewer, _error) => {
    // console.log('Error loading a document', error);
  };

  const handleModelLoaded = (_viewer, _model) => {};

  const handleModelError = (_viewer, _error) => {
    // console.log('Error loading the model.', error);
    // TODO: handle error
  };

  /* Once the viewer has initialized, it will ask us for a forge token so it can
  access the specified document. */
  const handleTokenRequested = onAccessToken => {
    const { credentials } = state;
    if (onAccessToken)
      onAccessToken(credentials.access_token, credentials.expires_in);
  };
  return (
    <div style={{ height: isFullscreen ? 'calc(100vh - 50px)' : 600 }}>
      {state.credentials ? (
        <ReactForgeViewer
          headless={false}
          onDocumentError={handleDocumentError}
          onDocumentLoad={handleDocumentLoaded}
          onModelError={handleModelError}
          onModelLoad={handleModelLoaded}
          onTokenRequest={handleTokenRequested}
          onViewerError={handleViewerError}
          region="eu"
          urn={urn}
          version="7.74" // https://forge.autodesk.com/en/docs/viewer/v7/change_history/changelog_v7/
          view={state.view}
        />
      ) : null}
    </div>
  );
};

export default ForgeViewer;
