import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button
} from '@material-ui/core';
import {
  AddCircleOutlined as AddCircleOutlinedIcon,
  People as PeopleIcon
} from '@material-ui/icons';
import { NewOrganization } from './components';
import { Popup } from 'components';
import { clearOrgInStorage, formatDate, setupOrg } from 'common/helper';
import { organizationFetch } from '../../redux';
import { useFetch } from 'hooks';
import { useProjectStyles } from '../Project/styles';
import { LS_ORGANIZATION_CODE, LS_ORGANIZATION_ID, LS_ORGANIZATION_NAME } from 'common/constants';

const Organization = props => {
  const classes = useProjectStyles();
  const organization = useSelector(s => s.organization);
  const dispatch = useDispatch();

  clearOrgInStorage();
  useFetch(organizationFetch);

  useEffect(() => {
    if (
      (organization.created && organization.created.error === '') ||
      (organization.deleted && organization.deleted.error === '') ||
      (organization.invited && organization.invited.error === '')
    ) {
      if (organization.created) {
        setupOrg(organization.created.organization);
        props.history.push('/welcome');
      }
      dispatch(organizationFetch());
    }
  }, [
    dispatch,
    organization.created,
    organization.deleted,
    organization.invited,
    props.history
  ]);

  const goToProjects = (event, code, id, name) => {
    event.preventDefault();
    localStorage.setItem(LS_ORGANIZATION_ID, id);
    localStorage.setItem(LS_ORGANIZATION_CODE, code);
    localStorage.setItem(LS_ORGANIZATION_NAME, name);
    props.history.push('/projects');
  };

  return (
    <div className={classes.root}>
      {organization.organizations.length === 0 ? (
        <div className={classes.emptyResultContainer}>
          <Popup
            button={
              <Button className={classes.emptyResultButton}>
                <AddCircleOutlinedIcon
                  className={classes.addIcon}
                  color="primary"
                  fontSize="large"
                />
                <Typography component="div">
                  <Box className={classes.textLarge} textAlign="center">
                    <FormattedMessage
                      id="organization.CREATE_ORGANIZATION"
                      defaultMessage="Create Organization"
                    />
                  </Box>
                </Typography>
              </Button>
            }>
            <NewOrganization />
          </Popup>
        </div>
      ) : (
        <div>
          <div className={classes.tableContainer}>
            <span className={classes.textUpperCase}>
              <Typography display="inline" variant="h3">
                <FormattedMessage
                  id="organization.ORGANIZATIONS"
                  defaultMessage="Organizations"
                />
              </Typography>
            </span>
            {/*
              // Comment out for now. There should only be one organization.
              <span className={classes.floatRight}>
              <Popup
                button={
                  <Button
                    arial-label="Add Organization"
                    color="primary"
                    startIcon={<AddCircleOutlinedIcon fontSize="small" />}
                    variant="contained">
                    <Typography className={classes.buttonLabel} variant="body2">
                      <FormattedMessage
                        id="organization.CREATE_ORGANIZATION"
                        defaultMessage="Create Organization"
                      />
                    </Typography>
                  </Button>
                }>
                <NewOrganization />
              </Popup>
            </span>*/}
          </div>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table className={classes.table} size="small">
              <colgroup>
                <col width="25%" />
                <col width="20%" />
                <col width="15%" />
                <col width="20%" />
                <col width="20%" />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <FormattedMessage id="common.NAME" defaultMessage="Name" />
                  </TableCell>
                  <TableCell align="left">
                    <FormattedMessage
                      id="organization.ID"
                      defaultMessage="Id"
                    />
                  </TableCell>
                  <TableCell align="left">
                    <FormattedMessage id="common.CODE" defaultMessage="Code" />
                  </TableCell>
                  <TableCell align="left">
                    <FormattedMessage
                      id="organization.CREATED"
                      defaultMessage="Created"
                    />
                  </TableCell>
                  <TableCell align="left">
                    <FormattedMessage
                      id="organization.MEMBERS"
                      defaultMessage="Members"
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {organization.organizations.map(row => {
                  const orgCode =
                    row.org_meta && row.org_meta.company_code
                      ? row.org_meta.company_code
                      : '';
                  return (
                    <TableRow
                      hover
                      key={row.id}
                      onClick={e => goToProjects(e, orgCode, row.id, row.name)}
                      role="checkbox"
                      tabIndex={-1}>
                      <TableCell align="left" component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.org_tag}</TableCell>
                      <TableCell align="left">
                        {row.org_meta && row.org_meta.company_code}
                      </TableCell>
                      <TableCell align="left">
                        {formatDate(row.created_at)}
                      </TableCell>
                      <TableCell align="left">
                        <Typography
                          className={classes.membersIconWithLabel}
                          color="primary"
                          display="inline"
                          variant="h4">
                          {row.users ? row.users.length : 0}
                          <PeopleIcon className={classes.peopleIcon} />
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};

Organization.propTypes = {
  history: PropTypes.object
};

export default withRouter(Organization);
