export const AWS_URL =
  'https://str8line-attachments.s3.eu-central-1.amazonaws.com';
export const EXPORT_URL =
  'https://str8line-exports.s3.eu-central-1.amazonaws.com';
export const POLARIS_URL = 'https://str8line-polaris-staging.herokuapp.com/v1';
export const ARA_URL =
  'https://c58ytda1gd.execute-api.eu-central-1.amazonaws.com/staging';
export const DUBHE_URL =
  'https://str8line-dubhe-staging.herokuapp.com/download';
export const PEGASUS_URL =
  'https://c58ytda1gd.execute-api.eu-central-1.amazonaws.com/staging/pegasus';
export const VIRGO_URL =
  'https://fs846g56c7.execute-api.eu-central-1.amazonaws.com/prod';

export const STR8LINE_SUPPORT_DOC_URL =
  process.env.REACT_APP_SUPPORT_DOC_URL || 'https://str8line.no/support/';
export const LANGUAGE_EN_GB = 'en';
export const LANGUAGE_NAME_EN_GB = 'English';
export const LANGUAGE_NB = 'nb';
export const LANGUAGE_NAME_NORSK_BOKMAL = 'Norsk Bokmål';

export const ACCESS_CHANGE_STATUS = 'change status';
export const ACCESS_WRITE = 'write';
export const ACCESS_READ = 'read';
export const ACCESS_NONE = 'no access';

export const ROLE_PL = 'Approver 1 - Project Lead';
export const ROLE_DL = 'Approver 2 - Discipline Lead';
export const ROLE_PARTICIPANT = 'Participant';
export const ROLE_OBSERVER = 'Observer';
export const DEFAULT_ACCESS_BY_ROLE = {
  [ROLE_PL]: ACCESS_CHANGE_STATUS,
  [ROLE_DL]: ACCESS_CHANGE_STATUS,
  [ROLE_PARTICIPANT]: ACCESS_WRITE,
  [ROLE_OBSERVER]: ACCESS_READ,
  [undefined]: ACCESS_NONE
};

export const OBJECT_ALL = 'All';
export const OBJECT_BOQ = 'BOQ';
export const OBJECT_BHM = 'BHM';
export const OBJECT_DISCIPLINE = 'Discipline';
export const OBJECT_DISP = 'DISP';
export const OBJECT_DOC = 'DOC';
export const OBJECT_DOCUMENTATION = 'Documentation';
export const OBJECT_DRAW = 'DRAW';
export const OBJECT_EO = 'EO';
export const OBJECT_ICO = 'ICO';
export const OBJECT_KOE = 'KOE';
export const OBJECT_LE = 'LE';
export const OBJECT_LETTER = 'Letter';
export const OBJECT_MEETING = 'Meeting';
export const OBJECT_MISC = 'MISC';
export const OBJECT_REF = 'REF';
export const OBJECT_TA = 'TA';
export const OBJECT_TMPL = 'TMPL';

export const OBJECT_COLUMN_ID_ACTION_OWNER = 'action_owner';
export const OBJECT_COLUMN_ID_CREATED_DATE = 'created_at';
export const OBJECT_COLUMN_ID_DESCRIPTION = 'description';
export const OBJECT_COLUMN_ID_FILE_EXT = 'extension';
export const OBJECT_COLUMN_ID_FIRST_PUBLISHED = 'first_published';
export const OBJECT_COLUMN_ID_LAST_UPDATED = 'last_updated';
export const OBJECT_COLUMN_ID_NAME = 'name';
export const OBJECT_COLUMN_ID_REVISION = 'revision';
export const OBJECT_COLUMN_ID_STATUS = 'status';
export const OBJECT_COLUMN_ID_TITLE = 'title';
export const OBJECT_COLUMN_ID_SELECTED_CATEGORY = 'category';

export const COMMUNICATION_TYPES = [
  OBJECT_BHM,
  OBJECT_BOQ,
  OBJECT_DISP,
  OBJECT_EO,
  OBJECT_ICO,
  OBJECT_KOE,
  OBJECT_LETTER,
  OBJECT_MEETING,
  OBJECT_TA,
  OBJECT_TMPL
];

export const ORG_TYPE_CLIENT = 'Client';
export const ORG_TYPE_CONSULTANT = 'Consultant';
export const ORG_TYPE_CONTRACTOR = 'Contractor';
export const ORG_TYPE_SUB_CONSULTANT = 'Sub-Consultant';
export const ORG_TYPE_SUB_CONTRACTOR = 'Sub-Contractor';

export const SORT_ORDER_ASC = 'asc';
export const SORT_ORDER_DESC = 'desc';

export const ACCESS_CONTROL_LEVEL = 'access_control_level';
export const ACCESS_CONTROL_ORG = 'access_control_org';
export const ACTION_OWNER = 'action_owner';
export const ACTION_OWNER_LABEL = 'Action Owner';
export const ADD = 'add';
export const ADMIN = 'admin';
export const AGENDA = 'agenda';
export const AGENDA_TITLE = 'agendaTitle';
export const ALL = 'ALL';
export const ANCESTRY = 'ancestry';
export const ARCHIVE_AND_EXPORT = 'archive_and_export';
export const ARCHIVED = 'archived';
export const AREA = 'area';
export const ATTACHMENTS = 'attachments';
export const BACK = 'back';
export const BILL_OF_QUANTITIES = 'bill_of_quantities';
export const BRREG = 'Brreg';
export const BUTTON = 'button';
export const CATEGORIES = 'categories';
export const CATEGORY = 'category';
export const CATEGORY_ID = 'category_id';
export const CATEGORY_OPTION = 'category';
export const CATEGORY_TEMPLATES = 'categoryTemplates';
export const CHAIRED_BY = 'chaired_by';
export const CHART = 'chart';
export const CHECKBOX = 'checkbox';
export const CLEAR = 'clear';
export const CLOSE = 'close';
export const CODE = 'code';
export const COMPANY_CODE = 'company_code';
export const CONTRACT = 'contract';
export const COUNTRY_CODE = 'countryCode';
export const CURRENT_PASSWORD = 'current_password';
export const DAILY = 'daily';
export const DAILY_REPORT = 'daily_summary_report';
export const DEFAULT = 'default';
export const DEFAULT_REVISION_TYPE = 'Letter';
export const DEFAULT_TEMPLATE_NAME = 'New Template';
export const DELETE = 'delete';
export const DELMALEBREV = 'Delmålebrev';
export const DESCRIPTION = 'description';
export const DISCIPLINE = 'discipline';
export const DISCIPLINE_TYPE = 'discipline_type';
export const DISCIPLINE_TYPE_ID = 'discipline_type_id';
export const DISTRIBUTION = 'distribution';
export const DIVIDER = 'divider';
export const DOCUMENT = 'document';
export const DOCUMENTATION_TYPE = 'documentation_type';
export const DOCUMENTATION_TYPE_MULTIPLE = 'Multiple';
export const DOCUMENTATION_TYPE_SERIES = 'Series';
export const DOCUMENTATION_TYPE_SINGLE = 'Single';
export const DOCUMENT_CATEGORIES = 'document_categories';
export const DRAWER_LEFT_OPEN = 'drawerLeftOpen';
export const DRAWER_RIGHT_OPEN = 'drawerRightOpen';
export const DRAWER_VARIANT_PERSISTENT = 'persistent';
export const DRAWER_VARIANT_TEMPORARY = 'temporary';
export const DUE_DATE = 'due_date';
export const DUE_DATE_MIN = 'due_date_min';
export const EDIT = 'edit';
export const EMAIL = 'email';
export const ERROR = 'error';
export const EXPAND = 'expand';
export const FILE_PATH = '<FILE_PATH>';
export const FILES = 'FILES';
export const GUESTS = 'Guests';
export const HISTORY = 'history';
export const ID = 'id';
export const INFO = 'info';
export const INFO_REVISIONS = 'infoRevisions';
export const INTERNAL = 'internal';
export const ITEM = 'item';
export const LANDSCAPE = 'landscape';
export const LINKED_OBJECTS = 'linked_objects';
export const MAIN = 'main';
export const MAIN_CATEGORY = 'Main Category';
export const MEETING_OPTION = 'meeting';
export const MEETING_DATE = 'meeting_date';
export const MEETING_TEMPLATE = 'meetingTemplate';
export const MEMBERS = 'members';
export const MENTION = 'mention';
export const MENTIONED_OBJECTS = 'mentionedObjs';
export const MENTIONED_USERS = 'mentionedUsers';
export const MINUTES_BY = 'minutes_by';
export const MULTIPLE = 'multiple';
export const NAME = 'name';
export const NEW = 'NEW';
export const NEW_COMMENT = 'new_comment';
export const NEW_COMMENT_TEXT = 'new_comment_text';
export const NEW_PASSWORD = 'new_password';
export const NEW_PASSWORD_CONFIRMATION = 'new_password_confirmation';
export const NOT_ARCHIVED = 'notArchived';
export const NUMBER_OF_UNITS = 'number_of_units';
export const OBJECT = 'object';
export const OBJECTS = 'objects';
export const OBJECTS_ALL = 'objectsAll';
export const OBJECT_TYPE_ID = 'object_type_id';
export const ORGANIZATION = 'organization';
export const ORGANIZATION_CONTRACT = 'organization_contract';
export const ORGANIZATION_EMAIL = 'organization_email';
export const ORGANIZATION_IDS = 'organization_ids';
export const ORGANIZATION_ID = 'organization_id';
export const ORGANIZATION_MEETING_TEMPLATE = 'organizationMeetingTemplate';
export const ORGANIZATION_NAME = 'organization_name';
export const ORGANIZATION_TYPE = 'organization_type';
export const ORGANIZATION_TYPE_ID = 'organization_type_id';
export const ORG_TAG = 'org_tag';
export const PARTICIPANTS = 'participants';
export const PASSWORD = 'password';
export const PASSWORD_CONFIRMATION = 'password_confirmation';
export const PORTRAIT = 'portrait';
export const PRIMARY_CONTACT = 'primary_contact';
export const PROJECT = 'project';
export const PROJECT_SETTINGS = 'project_settings';
export const RANK = 'rank';
export const REAL_TIME_REPORT = 'real_time';
export const REVISION = 'revision';
export const REVISION_LABEL = 'Rev ';
export const REVISION_VALUES = 'revisionValues';
export const ROLE = 'role';
export const SAVE = 'save';
export const SELECTED_CATEGORY = 'selected_category';
export const SELECTED_CATEGORY_TEMPLATE = 'selectedCategoryTemplate';
export const SMALL = 'small';
export const STANDARD = 'standard';
export const STANDARD_ID = 'standard_id';
export const STATUS = 'status';
export const STATUS_ID = 'status_id';
export const SUBCATEGORY_ID = 'subcategory_id';
export const SUBCATEGORY_NAME = 'Subcategory';
export const SUBITEM = 'subitem';
export const SUCCESS = 'success';
export const TABLE = 'table';
export const TEMPLATE = 'template';
export const THREE_D = '3d';
export const TITLE = 'title';
export const TYPES = 'types';
export const UNIT = 'unit';
export const UNIT_PRICE = 'unit_price';
export const UNTITLED = 'Untitled';
export const UP = 'up';
export const USER = 'user';
export const USERS = 'users';
export const VALUES = 'values';
export const VIEW_REVISIONS = 'viewRevisions';
export const WARNING = 'warning';
export const WBS = 'wbs';

export const STATUS_VOID = 'Void';
export const STATUS_DRAFT = 'Draft';
export const STATUS_ISSUED_FOR_APPROVAL = 'Issued for approval';
export const STATUS_APPROVED_BY_DL = 'Approved by DL';
export const STATUS_APPROVED_BY_PL = 'Approved by PL';
export const STATUS_EVALUATION_ON_GOING = 'Evaluation On-going';
export const STATUS_EVALUATION_COMPLETE = 'Evaluation Complete';
export const STATUS_IMPLEMENTATION_ON_GOING = 'Implementation On-going';
export const STATUS_IMPLEMENTATION_COMPLETE = 'Implementation Complete';
export const STATUS_EVALUATE = 'Evaluate';
export const STATUS_FEEDBACK_GIVEN = 'Feedback Given';
export const STATUS_NOT_AFFECTED = 'Not affected';
export const STATUS_IMPLEMENT = 'Implement';
export const STATUS_COMPLETED = 'Completed';
export const STATUS_READY_FOR_ISSUE = 'Ready for issue';
export const STATUS_READY_FOR_APPROVAL = 'Ready for approval';
export const STATUS_READY_TO_SEND = 'Ready to Send';
export const STATUS_READ = 'Read';
export const STATUS_SEND = 'Send';
export const STATUS_SENT = 'Sent';
export const STATUS_SENT_FOR_FILTER = 'SENT_FOR_FILTER';
export const STATUS_ISSUED_AND_APPROVED = 'Issued & Approved';
export const STATUS_ISSUED_WITH_COMMENTS = 'Issued with comments';
export const STATUS_ISSUED_FOR_REVIEW = 'Issued for review';
export const STATUS_RETURNED_WITH_COMMENTS = 'Returned with comments';
export const STATUS_ISSUED = 'Issued';
export const STATUS_REVISED = 'Revised';
export const STATUS_ESTIMATE_SUBMITTED = 'Estimate Submitted';
export const STATUS_INFO_REQUESTED = 'Information Requested';
export const STATUS_WITHDRAWN = 'Withdrawn';
export const STATUS_REJECTED = 'Rejected';
export const STATUS_REJECTED_MAX_FILES = 'rejected_max_files';
export const STATUS_APPROVED = 'Approved';
export const STATUS_APPROVED_AND_ACCEPTED = 'Approved & Accepted';
export const STATUS_APPROVED_ESTIMATE_DISPUTED = 'Approved - Estimate disputed';
export const STATUS_SIGNED_AND_CLOSED = 'Signed & Closed';
export const STATUS_RESPONSE_ISSUED = 'Response issued';
export const STATUS_ANSWERED_AND_CLOSED = 'Answered & Closed';
export const STATUS_PUBLISH = 'Publish';
export const STATUS_PUBLISHED = 'Published';
export const STATUS_APPROVED_AND_PUBLISHED = 'Approved & Published';
export const STATUS_HOLD = 'Hold';
export const STATUS_BOQ_REQUESTED = 'BoQ requested';
export const STATUS_UPDATED_QUANTITIES_ISSUED = 'Updated quantities issued';
export const STATUS_FINAL_QUANTITIES_ISSUED = 'Final quantities issued';
export const STATUS_PRELIM_BOQ_APPROVED = 'Prelim BoQ approved';
export const STATUS_APPROVED_AND_CLOSED = 'Approved & Closed';
export const STATUS_NOT_USED = 'Not used';
export const STATUS_NOTIFIED = 'Notified';
export const STATUS_CLAIM_SENT = 'Claim sent';

export const STATUS_SHARED = 'Shared';
export const STATUS_UNSHARED = 'Unshared';

export const STATUS_CLOSED = 'closed';
export const STATUS_INFORMATION = 'information';
export const STATUS_OPEN = 'open';

export const STATUS_AVAILABLE_FOR_DOWNLOAD = 'Available for Download';
export const STATUS_IN_PROGRESS = 'In progress';
export const STATUS_FAILED = 'Failed';

export const STATUS_ABORTED = 'aborted';
export const STATUS_DONE = 'done';
export const STATUS_ERROR_FILE_SIZE = 'error_file_size';
export const STATUS_ERROR_UPLOAD = 'error_upload';
export const STATUS_ERROR_UPLOAD_PARAMS = 'error_upload_params';
export const STATUS_ERROR_VALIDATION = 'error_validation';
export const STATUS_EXCEPTION_UPLOAD = 'exception_upload';
export const STATUS_GETTING_UPLOAD_PARAMS = 'getting_upload_params';
export const STATUS_REMOVED = 'removed';
export const STATUS_RESTARTED = 'restarted';
export const STATUS_UPLOADING = 'uploading';

export const ACTION_CANCEL = 'cancel';
export const ACTION_CURR_OBJ_DELETED = 'currObjDeleted';
export const ACTION_DELETE = 'delete';
export const ACTION_DELETED = 'deleted';
export const ACTION_GET = 'get';
export const ACTION_POST = 'post';
export const ACTION_PUT = 'put';
export const ACTION_UPDATE = 'update';

export const ATTACHMENT_DATE_FORMAT = 'DD.MM.YYYY';
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DISPLAY_DATE_FORMAT = 'MMM DD, YYYY';

export const FORGE_URN = 'urn:adsk.objects:os.object:';

export const PDF = 'pdf';
export const SHARECAD_VIEWER_URL_FORMAT = '//sharecad.org/cadframe/load?url=';
export const MS_VIEWER_URL_FORMAT =
  'https://docs.google.com/viewer?embedded=true&url=';

export const DIR_ORGANIZATIONS = 'organizations/';
export const DIR_PROJECTS = 'projects/';
export const DIR_TEMP = 'temp/';

export const MATCH_URL_OBJECTS = '/objects';
export const OBJECT_WITH_ID_URL = '/object/';
export const DOC_WITH_ID_URL = '/doc/';

export const IMPACT = 'impact';
export const IMPACT_HSE = 'HSE';
export const IMPACT_OTHER = 'Other';
export const IMPACT_PRICE = 'Price';
export const IMPACT_QUALITY = 'Quality';
export const IMPACT_SCHEDULE = 'Schedule';
export const SETTLEMENT = 'settlement';

export const POPOVER_ACTION_OWNER = 'popover-ao';
export const POPOVER_SELECT = 'popover-select';
export const POPOVER_STATUS = 'popover-status';

export const ERROR_CODE_401 = 401;
export const ERROR_401 = 'Request failed with status code 401';
export const ERROR_409_STATUS_SYMBOL = 'Conflict';
export const ERROR_PASSWORD_UPDATE = 'Error in password update';
export const FORWARD_INDICATOR = 'FWD';

export const BOQ = 'Malebrev';
export const COMMUNICATION = 'Communication';
export const DOCUMENTATION = 'Documentation';

export const CATEGORY_DRAWING = 'Drawing';
export const CATEGORY_MODEL = 'Model';

export const BACKGROUND = 'background';
export const BORDER_GRADIENT = 'borderGradient';
export const DEFAULT_BG_COLOR = 'Blue';
export const DISC_BG_LIST = ['Blue', 'Green', 'Magenta', 'Orange'];
export const ORG_AVATAR_BACKGROUND = 'orgAvatarBackground';
export const ORG_AVATAR_BG_LIST = [
  'Violet',
  'Purple',
  'Blue',
  'Green',
  'Orange',
  'Yellow',
  'Red'
];

// eslint-disable-next-line
export const REGEX_LAST_DOT = /\.(?=[^\.]+$)/;
export const REGEX_UNDERSCORE = /_(.+)/;
export const REGEX_ONE_LETTER = '^(?=.*[a-zA-Z]).{1,4}$'; // has to be a string
export const REGEX_GI_MODIFIER = 'gi';
export const REGEX_EMAIL_VALIDATION = /\S+@\S+\.\S+/; // https://stackoverflow.com/a/9204568
export const REGEX_DASH_ALL = /-/g;
export const REGEX_SPACE_ALL = / /g;
export const REGEX_ZERO = /^0+/;

export const BUCKET_ATTACHMENTS = 'str8line-attachments';
export const BUCKET_CONTRACTS = 'str8line-contracts';
export const BUCKET_EXPORTS = 'str8line-exports';
export const BUCKET_LOGOS = 'str8line-logos';
export const BUCKET_MENGDEBESKRIVELSE = 'str8line-mengdebeskrivelse';

export const FILE_TYPE_ZIP = '.zip';

export const AT_SIGN = '@';
export const BULLET_POINT = '●'; // draftjs will not render it if it's in hex or unicode
export const COMMA = ',';
export const DOT = '.';
export const ELLIPSIS = '...';
export const FORWARD_SLASH = '/';
export const HYPHEN = '-';
export const SEMICOLON = ';';
export const UNDERSCORE = '_';

export const COMPANY_CODE_LENGTH = 4;
export const DEFAULT_INDENT_MULTIPLIER = 4;
export const DEFAULT_MEETING_NO = 1;
export const MAXIMUM_AVATARS_IN_DISTRIBUTION_COLUMN = 3;
export const MAX_NESTING_DEPTH = 6;
export const REGIONAL_INDICATOR = 127397;
export const HEX_CODE_CHECK_MARK = 0x2713;
export const UNICODE_SPACE = '\u00A0';
export const SOFT_HYPHEN = '\u200B';
export const COUNTRY_NO = 'NO';
export const COUNTRY_NO_CODE = '47';
export const COUNTRIES = [{ code: COUNTRY_NO, label: 'Norway', phone: '47' }];
export const FIELDS_OBJECT_BHM = ['inquiry', 'response', 'reference'];
export const FIELDS_OBJECT_KOE_EO = [
  'comment',
  'description',
  'impact',
  'impact_description',
  'impact_price_description',
  'impact_total_schedule_days',
  'reason',
  'settlement',
  'total_cost_nok'
];
export const FIELDS_OBJECT_TA = [
  'inquiry',
  'reference',
  'response',
  'solutions'
];
export const ADMIN_USER_OPTIONS = [
  { id: '0', name: 'Not Admin' },
  { id: '1', name: 'Admin' }
];

export const ADMIN_TEMPLATES_OPTIONS_LIST = [CATEGORY_OPTION, MEETING_OPTION];

export const DATA_TYPE_BOOLEAN = 'boolean';
export const DATA_TYPE_NUMBER = 'number';
export const DATA_TYPE_STRING = 'string';

export const PAGE_ARCHIVED_PROJECTS = 'archived_projects';
export const PAGE_DASHBOARD = 'dashboard';
export const PAGE_DISCIPLINES = 'disciplines';
export const PAGE_EXPORTS = 'exports';
export const PAGE_INFO = 'info';
export const PAGE_NOTIFICATIONS = 'notifications';
export const PAGE_ORGANIZATIONS = 'organizations';
export const PAGE_PROJECTS = 'projects';
export const PAGE_SETTINGS = 'settings';
export const PAGE_TEMPLATES = 'templates';
export const PAGE_USER_DETAILS = 'user_details';
export const PAGE_USERS = 'users';

export const LATEST_MOM_NUMBER_VERSION = 2;
export const NOTIFICATIONS_THRESHOLD = 2;

// sessionStorage
export const SS_FILTER_MY_ACTIONS = 'SS_FILTER_MY_ACTIONS';
export const SS_FILTER_PARAMS = 'SS_FILTER_PARAMS';
export const SS_FILTER_SEARCH = 'SS_FILTER_SEARCH';
export const SS_FILTER_UNSHARED = 'SS_FILTER_UNSHARED';
export const SS_FILTERS = 'SS_FILTERS';
export const SS_PAGE_NUMBER = 'SS_PAGE_NUMBER';
export const SS_SELECTED_BOQ = 'SS_SELECTED_BOQ';
export const SS_SELECTED_CATEGORY = 'SS_SELECTED_CATEGORY';
export const SS_SORT_ORDER = 'SS_SORT_ORDER';
export const SS_SORT_ORDER_BY = 'SS_SORT_ORDER_BY';
export const SS_STORED_PROJECT_ID = 'SS_STORED_PROJECT_ID';

// localStorage
export const LS_LANGUAGE = 'LS_LANGUAGE';
export const LS_ORGANIZATION_CODE = 'LS_ORGANIZATION_CODE';
export const LS_ORGANIZATION_ID = 'LS_ORGANIZATION_ID';
export const LS_ORGANIZATION_NAME = 'LS_ORGANIZATION_NAME';
export const LS_STR8LINE_TOKEN = 'LS_STR8LINE_TOKEN';

export const WIDTH_LG = 'lg';
export const WIDTH_MD = 'md';
export const WIDTH_SM = 'sm';
export const WIDTH_XL = 'xl';
export const WIDTH_XS = 'xs';

export const OBJECT_LIST_LIMIT = 50;
export const MAX_FILE_SIZE = process.env.REACT_APP_MAX_FILE_SIZE || 1073741824;
export const MAX_BOQ_FILE_SIZE =
  process.env.REACT_APP_MAX_BOQ_FILE_SIZE || 15728640;
export const MAX_FILE_COUNT = process.env.REACT_APP_MAX_FILE_COUNT || 50;

// Don't forget to update Pegasus (export/invoice) if this is updated.
export const EXPORT_FEE = '1995 NOK / 195 EUR';

export const SET_PASSWORD = 'set-password';
export const ENABLED_2FA = 'enabled_2fa';

export const VIRUS_SCAN_CLEAN = 'CLEAN';
export const VIRUS_SCAN_INFECTED = 'INFECTED';

export const HISTORY_POP = 'POP';
export const HISTORY_REPLACE = 'REPLACE';

// BoQ Constants
export const ANDREKRAV = 'AndreKrav';
export const ANDREKRAVJA = 'AndreKravJa';
export const ANDREKRAVNEI = 'AndreKravNei';
export const ATTRIB_GRUPPE = '@_Gruppe';
export const ATTRIB_OVERSKRIFT = '@_Overskrift';
export const ATTRIB_STIKKORD = '@_Stikkord';
export const ATTRIB_TEKST = '@_Tekst';
export const AVREGNING = 'Avregning';
export const NS3459 = 'NS3459';
export const PRISFORESPORSEL = 'Prisforesporsel';
export const PRISTILBUD = 'Pristilbud';
export const PROSJEKTDATA = 'Prosjektdata';
export const PROSJEKTNS = 'ProsjektNS';
export const PROSJEKTPROSESS = 'ProsjektProsess';

export const BOQ_CODE_TEXT_KEYS_ORDER = Object.freeze([
  'Overskrift',
  'Matrisevalg',
  'Stikkordvalg',
  ANDREKRAVNEI,
  ANDREKRAVJA
]);

export const API_RESPONSE_CATEGORY_IS_IN_USE = 'Category is in use';
export const API_RESPONSE_NO_ACCESS_CHANGE_CATEGORY =
  'No access to change category';
export const API_RESPONSE_ACTIVE_MEETING_TEMPLATE = 'Active meeting template';
export const API_RESPONSE_NO_ACCESS_MEETING_TEMPLATE =
  'No access to meeting template';

export const SSO_NAME_ENTRA_ID = 'Microsoft - Entra ID';
