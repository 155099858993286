import { FormattedMessage, useIntl } from 'react-intl';
import { useMsal, useAccount } from '@azure/msal-react';
import { Button, Divider, Typography, Box } from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';

import { loginRequest, silentRequest } from 'context/MsalContext/authConfig';
import { loginWithSsoToken } from '../../redux';
import { useDispatch } from 'react-redux';
import { SSO_NAME_ENTRA_ID } from 'common/constants';

const LoginAlternatives = () => {
  const intl = useIntl();
  const { instance } = useMsal();
  const activeAccount = useAccount();
  const dispatch = useDispatch();

  const generateErrorTextAndLogout = useCallback(
    error => {
      let errorMsg = `${intl.formatMessage(
        {
          id: 'error.FAILED_TO_LOGIN_USING_ENTRA'
        },
        { errorMessage: error.message || ' ' }
      )}`;
      if (error.response) {
        if (
          error.response.status === 401 &&
          error.response.data?.error === 'Unauthorized. User not found'
        ) {
          errorMsg = intl.formatMessage(
            {
              id: 'error.INVALID_SSO_CREDETIALS'
            },
            { email: activeAccount?.username }
          );
        }
        if (error.response?.status === 403) {
          errorMsg = intl.formatMessage(
            {
              id: 'error.INVALID_SSO_TENANT'
            },
            { tenantId: activeAccount?.tenantId }
          );
        }
      }
      instance.setActiveAccount(null);
      return errorMsg;
    },
    [intl, activeAccount, instance]
  );

  useEffect(() => {
    if (activeAccount) {
      instance
        .acquireTokenSilent({
          account: activeAccount,
          ...silentRequest
        })
        .then(({ idToken }) =>
          dispatch(
            loginWithSsoToken(
              idToken,
              generateErrorTextAndLogout,
              SSO_NAME_ENTRA_ID
            )
          )
        );
    }
  }, [activeAccount, dispatch, instance, intl, generateErrorTextAndLogout]);

  const handleLoginWithAzure = useCallback(() => {
    instance
      .loginPopup({
        ...loginRequest,
        prompt: 'select_account'
      })
      .then(({ account }) => instance.setActiveAccount(account))
      .catch(e => {
        if (e.message.startsWith('user_cancelled')) {
          return;
        }
      });
  }, [instance]);

  return (
    <>
      <Box marginY={4}>
        <Divider sx={{ marginBottom: 2 }} />
        <Typography align="center" color="textSecondary" variant="body2">
          <FormattedMessage
            defaultMessage="Login Alternatives"
            id="main.LOGIN_ALTERNATIVES"
          />
        </Typography>
      </Box>
      <Button
        color="secondary"
        fullWidth
        onClick={handleLoginWithAzure}
        size="small"
        variant="outlined">
        <FormattedMessage
          defaultMessage="Sign in using Microsoft Entra ID"
          id="main.SIGN_IN_USING_MICROSOFT_ENTRA_ID"
        />
      </Button>
    </>
  );
};

export default LoginAlternatives;
