import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import {
  Button,
  IconButton,
  TextField,
  Typography,
  Paper
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { forgotPassword } from '../../redux';
import { EmailSent } from 'views';
import { setupOrg } from 'common/helper';
import { emailSchema } from 'common/schema';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  alert: {
    marginBottom: theme.spacing(2)
  },
  form: {
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  },
  contentPaper: {
    height: 'max-content',
    padding: 64
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  passwordButton: {
    margin: theme.spacing(2, 0)
  }
}));

const Password = props => {
  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const errors = validate(formState.values, emailSchema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));

    // commenting this for now
    // if (user.info.id) return <Redirect to="/welcome" />;

    const { id, organizations } = user.info;
    if (id) {
      setupOrg(organizations && organizations[0]);
      return <Redirect to="/projects" />;
    }
  }, [formState.values, user.info]);

  const { history } = props;
  const handleBack = () => {
    history.goBack();
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSetPassword = event => {
    event.preventDefault();
    dispatch(forgotPassword(formState.values.email));
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const userError = useMemo(() => {
    if (user.error) {
      if (user.error === 'error.SSO_RESET_PASSWORD') {
        return (
          <FormattedMessage
            defaultMessage="It is not possible to reset the password for this email since."
            id="error.SSO_RESET_PASSWORD"
          />
        );
      }
      if (user.error === 'error.EMAIL_DOES_NOT_EXIST') {
        return (
          <FormattedMessage
            defaultMessage="It is not possible to reset the password for this email since."
            id="error.EMAIL_DOES_NOT_EXIST"
          />
        );
      }
      return user.error;
    }
    return null;
  }, [user.error]);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.contentHeader}>
          <IconButton
            onClick={handleBack}
            style={{ display: user.updated ? 'none' : 'block' }}>
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div className={classes.contentBody}>
          {user.updated ? (
            <EmailSent
              email={formState.values.email}
              message={
                <FormattedMessage
                  defaultMessage="Follow the instructions to reset your password."
                  id="main.FOLLOW_RESET_PASSWORD"
                />
              }
            />
          ) : (
            <form className={classes.form} onSubmit={handleSetPassword}>
              <Paper className={classes.contentPaper} elevation={4}>
                {user.error ? (
                  <Alert className={classes.alert} severity="error">
                    {userError}
                  </Alert>
                ) : null}
                <Typography data-testid="header-recovery" variant="h2">
                  <FormattedMessage
                    defaultMessage="Recover your account"
                    id="main.RECOVER_ACCOUNT"
                  />
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  label={
                    <FormattedMessage
                      defaultMessage="Email Address"
                      id="common.EMAIL_ADDRESS"
                    />
                  }
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ''}
                  variant="outlined"
                />
                <Button
                  className={classes.passwordButton}
                  color="primary"
                  disabled={!formState.isValid || user.loading}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained">
                  <FormattedMessage
                    defaultMessage="Submit"
                    id="common.SUBMIT"
                  />
                </Button>
              </Paper>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

Password.propTypes = {
  history: PropTypes.object
};

export default withRouter(Password);
