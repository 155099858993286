import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent
} from '@material-ui/core';

const InfoPopup = ({ fnClose, message, open }) => {
  return (
    <Dialog onClose={fnClose} open={open}>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={fnClose}
          size="small"
          variant="contained">
          <FormattedMessage defaultMessage="Ok" id="common.OK" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

InfoPopup.propTypes = {
  fnClose: PropTypes.func,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  open: PropTypes.bool
};

export default InfoPopup;
