import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  Reply as ExportIcon,
  Unarchive as UnarchiveIcon
} from '@material-ui/icons';
import { ExportConfirm } from 'components';
import { clearExport } from 'redux/export/ExportAction';
import { isCurrentOrg, valueInArray } from 'common/helper';
import { useStyles } from '../../../styles';

const ArchivedProjects = ({ onClickArchive, organization }) => {
  const { projects, users: orgUsers } = organization;
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    export: { created: exportCreated, error: exportError },
    user: {
      info: { id: userId }
    }
  } = useSelector(state => state);
  const [showArchive, setShowArchive] = useState(false);
  const [toExport, setExport] = useState(null);

  useEffect(() => {
    const orgAdminUsers = orgUsers.reduce(
      (acc, u) => (u.admin ? acc.concat(u.id) : acc),
      []
    );
    if (valueInArray(orgAdminUsers, userId)) setShowArchive(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUsers]);

  return (
    <div className={classes.userRoot}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        ClickAwayListenerProps={{ onClickAway: () => null }}
        onClose={() => dispatch(clearExport())}
        open={exportCreated || !!exportError}>
        <Alert
          onClose={() => dispatch(clearExport())}
          severity={exportError ? 'error' : 'success'}>
          {exportError ? (
            exportError
          ) : (
            <FormattedMessage
              defaultMessage={
                'Export request has been sent. ' +
                'You can check the status of the request in My Account > Exports.'
              }
              id="common.EXPORT_CREATED_MESSAGE"
            />
          )}
        </Alert>
      </Snackbar>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table size="small" stickyHeader>
              <colgroup>
                <col width="90%" />
                <col width="10%" />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage defaultMessage="Name" id="common.NAME" />
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {projects
                  .filter(project => project.archived)
                  .map(project => (
                    <TableRow hover key={project.id} tabIndex={-1}>
                      <TableCell align="left" component="th" scope="row">
                        {project.name}
                      </TableCell>
                      <TableCell align="right">
                        <>
                          {showArchive &&
                          isCurrentOrg(project.creator_org_id) ? (
                            <Tooltip title="Unarchive">
                              <IconButton
                                className={classes.addIcon}
                                onClick={() => onClickArchive(project)}
                                size="small"
                                variant="contained">
                                <UnarchiveIcon />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                          <Tooltip
                            title={
                              <FormattedMessage
                                defaultMessage="Export"
                                id="common.EXPORT"
                              />
                            }>
                            <IconButton
                              onClick={() => setExport(project)}
                              size="small">
                              <ExportIcon
                                size="small"
                                style={{ transform: 'rotateY(180deg)' }}
                              />
                            </IconButton>
                          </Tooltip>
                          {toExport ? (
                            <ExportConfirm
                              onClose={() => setExport(null)}
                              projectId={toExport.id}
                              projectName={toExport.name}
                            />
                          ) : null}
                        </>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

ArchivedProjects.propTypes = {
  onClickArchive: PropTypes.func,
  organization: PropTypes.object
};

export default ArchivedProjects;
