import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button, Typography } from '@material-ui/core';
import { LS_ORGANIZATION_ID } from 'common/constants';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  content: {
    height: '100%',
    display: 'flex'
  },
  contentBody: {
    flexGrow: 1,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    padding: 82,
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  projectButton: {
    margin: theme.spacing(2, 0)
  },
  imageContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 500
  }
}));

const SignIn = props => {
  const { history } = props;

  const classes = useStyles();

  const goToNext = event => {
    event.preventDefault();
    history.push('/projects');
  };

  const organizationId = localStorage.getItem(LS_ORGANIZATION_ID);

  useEffect(() => {
    if (!organizationId) {
      history.push('/organizations');
    }
  }, [organizationId, history]);

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid item lg={6} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <Typography className={classes.title} gutterBottom variant="h2">
                <FormattedMessage
                  defaultMessage="Jump into Str8line and bring transparency to complexity"
                  id="welcome.WELCOME_MESSAGE_1"
                />
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                <FormattedMessage
                  defaultMessage="We believe that handling the complex requires simplification. Our system is built to minimize the required user inputs, and to maximize the benefit for both the projects and the companies."
                  id="welcome.WELCOME_MESSAGE_2"
                />
              </Typography>

              <Button
                className={classes.projectButton}
                color="primary"
                fullWidth
                id="project-list-btn"
                name="project-list-btn"
                onClick={goToNext}
                size="large"
                variant="contained">
                <FormattedMessage
                  defaultMessage="See your projects"
                  id="welcome.SEE_YOUR_PROJECTS"
                />
              </Button>
            </div>
          </div>
        </Grid>

        <Grid item lg={6} xs={12}>
          <div className={classes.imageContent}>
            <img alt="" className={classes.image} src="/images/str8line.png" />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignIn);
