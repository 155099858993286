import { makeStyles } from '@material-ui/styles';

const drawerWidthRight = 'calc(100vw*0.40)';

export const useProjectStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    margin: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1)
    }
  },
  buttonLabel: {
    color: theme.palette.white
  },
  floatRight: {
    float: 'right'
  },
  emptyResultContainer: {
    height: '100%',
    display: 'flex',
    paddingTop: '10%',
    justifyContent: 'center'
  },
  emptyResultButton: {
    display: 'block',
    borderStyle: 'dashed',
    borderColor: theme.palette.secondary.dark,
    borderWidth: 2,
    borderRadius: 50,
    textTransform: 'none',
    '&:hover': {
      borderColor: theme.palette.primary.light
    }
  },
  centered: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center'
  },
  addIcon: {
    fontSize: '5.1875rem',
    paddingTop: 60,
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 30
  },
  textUpperCase: {
    textTransform: 'uppercase'
  },
  textLarge: {
    ...theme.typography.h4,
    fontWeight: 400,
    paddingBottom: 60
  },
  data: {
    margin: theme.spacing(0, 10)
  },
  tableContainer: {
    marginTop: 30,
    maxHeight: 'calc(100vh - 250px)'
  },
  table: {
    minWidth: 750
  },
  deleteIcon: {
    color: '#e86d6f'
  },
  popupRoot: {
    height: '100%',
    overflowY: 'auto',
    padding: theme.spacing(2),
    width: 500,
    [theme.breakpoints.down('sm')]: {
      padding: 0
    },
    [theme.breakpoints.only('xs')]: {
      '@media (orientation: landscape)': {
        width: 480
      },
      '@media (orientation: portrait)': {
        width: 230
      }
    }
  },
  popupDivider: {
    margin: theme.spacing(2, 0)
  },
  alert: {
    marginBottom: theme.spacing(2)
  },
  button: {
    marginTop: theme.spacing(2)
  },
  helpIcon: {
    margin: 'auto'
  },
  membersIconWithLabel: {
    display: 'inline-flex',
    verticalAlign: 'middle'
  },
  peopleIcon: {
    marginLeft: theme.spacing(1)
  },
  disableHoverEffect: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  contentShiftRight: {
    marginRight: 'calc(100vw*0.406)',
    paddingRight: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperRight: {
    border: 0,
    padding: '120px 10px 20px 0px',
    width: drawerWidthRight
  },
  drawerViewRoot: {
    margin: theme.spacing(0, 2, 4, 3),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1)
    },
    maxHeight: 'calc(100vh - 138px)',
    overflowX: 'auto',
    overflowY: 'auto'
  },
  noPadding: {
    padding: '0 !important'
  },
  showButton: {
    paddingTop: theme.spacing(3),
    textAlign: 'right'
  },
  showButtonLabel: {
    color: theme.palette.white
  }
}));
