import React from 'react';
import { FormattedMessage } from 'react-intl';

export const LBL_CREATE_NEW_DOC_OBJECT = (
  <FormattedMessage
    defaultMessage="Create new document"
    id="object.DOCUMENT_NEW"
  />
);
export const LBL_CREATE_NEW_EXEC_OBJECT = (
  <FormattedMessage defaultMessage="New object" id="object.NEW_OBJECT" />
);
export const LBL_SELECT_DISCIPLINE_TYPE = (
  <FormattedMessage
    defaultMessage="Select Discipline Type"
    id="object.SELECT_DISCIPLINE_TYPE"
  />
);
export const LBL_SELECT_RECIPIENT = (
  <FormattedMessage defaultMessage="Recipient" id="common.RECIPIENT" />
);
