import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Autocomplete } from '@material-ui/lab';
import { Button, TextField, Typography } from '@material-ui/core';
import { objectCreateOrUpdate } from 'redux/object/ObjectAction';
import { getCategoryInfo } from 'views/Object/components/helper';
import {
  CATEGORIES,
  MULTIPLE,
  SELECTED_CATEGORY,
  SS_STORED_PROJECT_ID
} from 'common/constants';
import { fetchProjectCategories } from 'redux/document/DocumentAction';
import { formatCategories } from 'common/helper';

const CategorySelector = ({ classes, objects }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [state, setState] = useState({
    categories: [],
    category: null
  });
  const { category, categories } = state;

  const {
    document: { categories: documentCategories }
  } = useSelector(state => state);

  useEffect(() => {
    dispatch(
      fetchProjectCategories(
        CATEGORIES,
        sessionStorage.getItem(SS_STORED_PROJECT_ID)
      )
    );
  }, [dispatch]);

  useEffect(() => {
    if (documentCategories)
      setState(state => ({
        ...state,
        categories: formatCategories(documentCategories)
      }));
  }, [documentCategories]);

  const updateCategory = (event, value) => {
    event.persist();
    const category = {
      id: value.id,
      name: value.name,
      ancestry_id: value.ancestry_id,
      revision_type_id: value.revision_type_id,
      project_id: sessionStorage.getItem(SS_STORED_PROJECT_ID)
    };
    setState(state => ({ ...state, category }));
  };

  const handleSubmit = () => {
    dispatch(
      objectCreateOrUpdate({
        id: MULTIPLE,
        category: category,
        selected_category_path: getCategoryInfo(categories, category),
        objects: objects.map(obj => obj.id),
        project_id: sessionStorage.getItem(SS_STORED_PROJECT_ID)
      })
    );
  };

  return (
    <div className={classes.componentPopupRoot}>
      <Autocomplete
        getOptionLabel={option => {
          return option.name
            ? intl.formatMessage({
                defaultMessage: option.name,
                id: 'object.' + option.name.toUpperCase()
              })
            : '';
        }}
        id={SELECTED_CATEGORY}
        name={SELECTED_CATEGORY}
        onChange={updateCategory}
        options={categories}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            label={
              <FormattedMessage
                defaultMessage="Select category"
                id="object.SELECT_CATEGORY"
              />
            }
            required
            size="small"
            variant="outlined"
          />
        )}
        renderOption={option => (
          <Typography
            style={{
              marginLeft: option.indent_count
            }}>
            {intl.formatMessage({
              defaultMessage: option.name,
              id: 'object.' + option.name.toUpperCase()
            })}
          </Typography>
        )}
        size="small"
      />
      <Button
        className={classes.marginTop2}
        color="secondary"
        disabled={!category}
        onClick={handleSubmit}
        type="submit"
        variant="contained">
        <FormattedMessage
          defaultMessage="Save changes"
          id="common.SAVE_CHANGES"
        />
      </Button>
    </div>
  );
};

CategorySelector.propTypes = {
  classes: PropTypes.object,
  objects: PropTypes.array
};

CategorySelector.defaultProps = {
  objects: []
};

export default CategorySelector;
