import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Clear as ClearIcon, SaveAlt as SaveIcon } from '@material-ui/icons';
import { Button, Card, CardContent, CardHeader } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import {
  Boq as ObjectBoq,
  Eo as ObjectEo,
  Ico as ObjectIco,
  Koe as ObjectKoe,
  Letter as ObjectLetter,
  Meeting as ObjectMeeting,
  Ta as ObjectTa
} from './components';
import { Attachment, History /*, LinkedObject*/ } from '../components';
import { NotAuthorized } from 'views';
import { generate } from 'redux/pdf/PdfAction';
import {
  getObjectCreator,
  getOrganizationName,
  isFromSenderOrg,
  processPdfDownloadParams
} from '../components/helper';
import { isObjectEmpty } from 'common/helper';
import { revisionFetch, singleObjectFetch } from '../../../redux';
import { useObjectInfo } from 'hooks';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { useStyles } from '../styles';
import { LS_ORGANIZATION_NAME } from 'common/constants';

const ObjectView = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const components = {
    bhm: ObjectTa,
    boq: ObjectBoq,
    eo: ObjectEo,
    ico: ObjectIco,
    koe: ObjectKoe,
    le: ObjectLetter,
    ref: ObjectMeeting,
    ta: ObjectTa
  };
  const {
    className,
    displayObjectView,
    goToObjectInfo,
    isProjectArchived,
    objectId,
    organizations,
    toggleDrawer
  } = props;
  const {
    object: {
      deleted: objectDeleted,
      disciplineTypes,
      fetchViewError,
      objectView,
      revLoading,
      viewRevisions
    },
    project: { projectSelected },
    screen: { isMobileDevice },
    user
  } = useSelector(state => state);
  // TODO: should not rely on formState in Section
  const [formState, setFormState] = useState({ values: {} });

  useObjectInfo({ objectId: objectId, view: true });

  useEffect(() => {
    if (
      objectView?.id &&
      !revLoading &&
      (viewRevisions.length === 0 ||
        !viewRevisions.some(r => r.id === objectView.id))
    )
      dispatch(
        revisionFetch(objectView.id, `project_id=${projectSelected.id}`, true)
      );

    setFormState(prevState => ({
      ...prevState,
      values: { ...objectView }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectView]);

  useEffect(() => {
    if (objectDeleted && objectView.id)
      dispatch(singleObjectFetch(objectView.id, false, true));
  }, [objectDeleted, objectView.id, dispatch]);

  const {
    discipline_type,
    history = [],
    id: viewId,
    metadata = {},
    object_type,
    organization,
    status
  } = objectView || {};
  const {
    created_by_id,
    created_by_org_id,
    revision: currRevision,
    revision_sequence_display: revisionDisplay
  } = metadata;
  const organization_id = organization?.id;
  const objType = object_type?.ref_name.toLowerCase();
  const TagName = components[objType];
  const { org: senderInfo, user: created_by } = getObjectCreator(
    organizations,
    created_by_org_id,
    created_by_id,
    user
  );
  const isSenderOrg = isFromSenderOrg(user, created_by_org_id);
  const recipientName = getOrganizationName(organizations, organization_id);
  const disciplines = disciplineTypes[recipientName] || [];
  const disciplineName = discipline_type?.id
    ? disciplines.find(disc => disc.id === discipline_type.id)?.name || ''
    : '';

  const downloadPdf = () => {
    const {
      newHistories,
      sortedDisciplines,
      org_users
    } = processPdfDownloadParams(
      currRevision,
      viewRevisions,
      objectView,
      organizations,
      projectSelected
    );

    dispatch(
      generate({
        ...objectView,
        metadata: {
          ...metadata,
          disciplineName: disciplineName,
          disciplines: sortedDisciplines
        },
        bhm_ta_reference_field: !!projectSelected?.settings
          ?.bhm_ta_reference_field,
        created_by: created_by,
        curr_org_name: localStorage.getItem(LS_ORGANIZATION_NAME),
        history: newHistories,
        is_sender_org: isSenderOrg,
        is_string: true,
        org_users: org_users,
        project_name: projectSelected.name,
        sender_info: senderInfo,
        requester: user?.info?.email
      })
    );
  };

  return fetchViewError ? (
    <NotAuthorized />
  ) : (
    <div className={className || classes.objViewRoot}>
      <Card>
        <CardHeader
          action={
            <div className={classes.whiteBackground}>
              {isMobileDevice || isProjectArchived ? null : (
                <Button
                  className={classes.viewBtnMarginRight}
                  color="primary"
                  onClick={() => goToObjectInfo(viewId)}
                  size="small"
                  variant="contained">
                  <FormattedMessage defaultMessage="Open" id="object.OPEN" />
                </Button>
              )}
              <Button
                color="secondary"
                onClick={downloadPdf}
                size="small"
                startIcon={<SaveIcon />}
                variant="contained">
                <FormattedMessage defaultMessage="PDF" id="common.PDF" />
              </Button>
              {toggleDrawer ? (
                <Button
                  className={classes.viewBtnMarginLeft}
                  onClick={toggleDrawer}
                  size="small"
                  startIcon={<ClearIcon />}
                  variant="contained">
                  <FormattedMessage defaultMessage="Close" id="common.CLOSE" />
                </Button>
              ) : null}
            </div>
          }
          className={classes.whiteBackground}
        />
        <CardContent>
          <div className={classes.imageHeader}>
            <img
              alt=""
              className={classes.image}
              src="/images/logos/logo--white.svg"
            />
            {status ? (
              <History
                component="button"
                currRevision={currRevision}
                histories={history}
                recipientName={recipientName}
                refName={objType.toUpperCase()}
                revisionDisplay={revisionDisplay}
                revisions={viewRevisions}
              />
            ) : null}
          </div>
          {TagName ? (
            <>
              {isObjectEmpty(objectView) ? null : (
                <TagName
                  createdBy={created_by}
                  disciplineName={disciplineName}
                  displayObjectView={displayObjectView}
                  isSenderOrg={isSenderOrg}
                  objectInfo={objectView}
                  organizationHierarchy={
                    projectSelected?.organization_hierarchy || []
                  }
                  organizations={organizations}
                  recipientName={recipientName}
                  senderOrgInfo={senderInfo}
                />
              )}
              <Attachment formState={formState} isView />
              {/*
              // https://trello.com/c/FVl7hFRH/549-mentioned-objects#comment-61d4023898c91a2d282b41e5
              <LinkedObject
                displayObjectView={displayObjectView}
                formState={formState}
                organizations={projectSelected?.organizations || []}
              />
              */}
            </>
          ) : null}
        </CardContent>
      </Card>
    </div>
  );
};

ObjectView.propTypes = {
  className: PropTypes.string,
  displayObjectView: PropTypes.func.isRequired,
  goToObjectInfo: PropTypes.func.isRequired,
  isProjectArchived: PropTypes.bool,
  objectId: PropTypes.string,
  organizations: PropTypes.array,
  toggleDrawer: PropTypes.func
};

export default ObjectView;
