import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Alert, Autocomplete } from '@material-ui/lab';
import { FormattedMessage } from 'react-intl';
import {
  AddCircle as AddCircleIcon,
  RemoveCircle as RemoveCircleIcon
} from '@material-ui/icons';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import { BrregField } from 'components';
import classNames from 'classnames';
import validate from 'validate.js';
import _ from 'lodash';
import { emailSchema } from 'common/schema';
import { generateCompanyCode, isObjectEmpty } from 'common/helper';
import { getEmailExclusionSchema } from 'common/validators';
import { clearInviteError, projectInvite } from '../../../../../redux';
import { useStyles } from '../../../styles';
import {
  BRREG,
  COUNTRY_CODE,
  COUNTRY_NO_CODE,
  ORGANIZATION_CONTRACT,
  ORGANIZATION_EMAIL,
  ORGANIZATION_TYPE,
  ORG_TYPE_CLIENT
} from 'common/constants';

const ProjectInvite = ({ organizations, projectId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    organization: {
      organizationsAll,
      types: { data: orgTypes = [] }
    },
    project: { invited, invite_error, updated },
    standard: { contracts: standardContracts }
  } = useSelector(state => state);

  const [states, setStates] = useState({
    allOrgs: [],
    systemOrgs: [],
    countryCode: COUNTRY_NO_CODE,
    input: null,
    orgEmail: '',
    orgName: '',
    showInvite: false,
    touched: false
  });

  const initItem = {
    values: { countryCode: COUNTRY_NO_CODE },
    errors: null
  };
  const [rows, setRows] = useState([_.cloneDeep(initItem)]);

  const setShowInvite = value =>
    setStates(states => ({ ...states, showInvite: value }));

  const schema = {
    organization_contract: {
      presence: {
        allowEmpty: false,
        message: (
          <FormattedMessage
            defaultMessage="{name} is required"
            id="error.REQUIRED"
            values={{
              name: (
                <FormattedMessage
                  defaultMessage="Organization Contract"
                  id="project.ORGANIZATION_CONTRACT"
                />
              )
            }}
          />
        )
      }
    },
    organization_email: (value, attributes) => {
      const exclusion = getEmailExclusionSchema(
        organizations,
        organizationsAll,
        value,
        attributes
      );
      return {
        ...emailSchema.email,
        ...exclusion
      };
    },
    organization_type: {
      presence: {
        allowEmpty: false,
        message: (
          <FormattedMessage
            defaultMessage="{name} is required"
            id="error.REQUIRED"
            values={{
              name: (
                <FormattedMessage
                  defaultMessage="Organization Type"
                  id="project.ORGANIZATION_TYPE"
                />
              )
            }}
          />
        )
      }
    }
  };

  const orgNameSchema = {
    name: {
      presence: {
        allowEmpty: false,
        message: (
          <FormattedMessage
            defaultMessage="{name} is required"
            id="error.REQUIRED"
            values={{
              name: (
                <FormattedMessage
                  defaultMessage="Organization"
                  id="organization.ORGANIZATION"
                />
              )
            }}
          />
        )
      },
      exclusion: {
        within: organizations.map(org => org.name),
        message: (
          <FormattedMessage
            defaultMessage="Organization is already a member of the project"
            id="organization.INVALID_ORGANIZATION_EXIST"
          />
        )
      }
    }
  };

  //////////////////////////////////////////////////////////////////////////////
  //                      START OF USEEFFECT FUNCTIONS                        //
  //////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (updated && invited.length > 0 && !invite_error && rows.length > 0) {
      setShowInvite(false);
      setRows([_.cloneDeep(initItem)]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, invited, invite_error, projectId, rows, updated]);

  //////////////////////////////////////////////////////////////////////////////
  //                        END OF USEEFFECT FUNCTIONS                        //
  //                         START OF LOCAL FUNCTIONS                         //
  //////////////////////////////////////////////////////////////////////////////

  const isClientOrg = orgTypeId =>
    orgTypeId &&
    orgTypes.find(ot => ot.id === orgTypeId)?.name === ORG_TYPE_CLIENT;

  const handleAddRow = () => setRows([...rows, _.cloneDeep(initItem)]);
  const handleRemoveRow = index => {
    rows.splice(index, 1);
    setRows([...rows]);
  };

  const handleChange = (event, index) => {
    event.persist();
    const currRow = rows[index];
    const newRow = {
      values: {
        ...currRow.values,
        [event.target.name]: event.target.value
      }
    };

    if (
      event.target.name === ORGANIZATION_TYPE &&
      isClientOrg(event.target.value)
    )
      newRow.values.parent_organization = null;

    newRow.errors = validate(newRow.values, schema);
    rows[index] = newRow;
    setRows([...rows]);

    if (ORGANIZATION_EMAIL === event.target.name)
      setStates(prevState => ({
        ...prevState,
        orgEmail: event.target.value
      }));

    if (event.target.name === COUNTRY_CODE) {
      setStates(prevState => ({
        ...prevState,
        countryCode: event.target.value
      }));
    }
  };

  const handleSelectChange = (event, value, errors, index) => {
    event.persist();
    const currRow = rows[index];
    const newRow = {
      values: {
        ...currRow.values,
        organization_name: value?.name || '',
        org_tag: value?.org_tag || '',
        org_meta: value
          ? {
              ...value.org_meta,
              company_code:
                value.type === BRREG
                  ? generateCompanyCode(value)
                  : value.org_meta.company_code
            }
          : {}
      }
    };

    newRow.errors = { ...validate(newRow.values, schema), ...errors };
    rows[index] = newRow;
    setRows([...rows]);
  };

  const handleChangeContractCounterpart = (event, value, index) => {
    event.persist();
    const currRow = rows[index];
    const newRow = {
      values: {
        ...currRow.values,
        parent_organization: value
      }
    };

    newRow.errors = validate(newRow.values, schema);
    rows[index] = newRow;
    setRows([...rows]);
  };

  const handleInvite = () => {
    const params = {
      org_invitees: rows.reduce((acc, input) => {
        if (input.values.organization_name) {
          return acc.concat({
            standard_id: input.values.organization_contract,
            org_email: input.values.organization_email,
            org_name: input.values.organization_name,
            org_meta: input.values.org_meta,
            org_tag: input.values.org_tag,
            organization_type_id: input.values.organization_type,
            parent_organization: input.values.parent_organization,
            parent_organization_id: input.values.parent_organization?.id
          });
        }
        return acc;
      }, [])
    };

    if (params.org_invitees.length) dispatch(projectInvite(projectId, params));
  };

  const cancel = () => {
    setShowInvite(false);
    setRows([_.cloneDeep(initItem)]);
    dispatch(clearInviteError());
  };

  const hasError = (rowErrors, field) =>
    rowErrors && rowErrors[field] ? true : false;
  const allErrors = rows.reduce((acc, row) => {
    return isObjectEmpty(row.errors) ? acc : acc.concat(row.errors);
  }, []);
  const isValid = rows.length > 0 && allErrors.length === 0 && !invite_error;
  return (
    <div className={classNames(classes.projectContainer, classes.fullWidth)}>
      {states.showInvite ? (
        <>
          <Divider className={classes.inviteDivider} />
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between">
            <Typography variant="h4">
              <FormattedMessage
                defaultMessage="Invite organizations to collaborate"
                id="project.INVITE_ORG"
              />
            </Typography>
            <IconButton onClick={handleAddRow} size="small">
              <AddCircleIcon className={classes.addIcon} />
            </IconButton>
          </Box>
          {invite_error ? (
            <Alert className={classes.noMargin} severity="error">
              {invite_error}
            </Alert>
          ) : null}
          {rows.map((item, index) => {
            const rowErrors = item.errors;
            return (
              <Fragment key={index}>
                <Grid
                  alignItems="flex-start"
                  container
                  className={classes.margin2}
                  key={index}
                  spacing={1}>
                  <Grid item md={3} xs={12}>
                    <BrregField
                      index={index}
                      label={
                        <FormattedMessage
                          defaultMessage="Organization"
                          id="organization.ORGANIZATION"
                        />
                      }
                      onChange={handleSelectChange}
                      orgList={rows}
                      schema={orgNameSchema}
                    />
                  </Grid>
                  <Grid item md={2} xs={6}>
                    <TextField
                      error={hasError(rowErrors, ORGANIZATION_TYPE)}
                      fullWidth
                      helperText={
                        hasError(rowErrors, ORGANIZATION_TYPE)
                          ? rowErrors.organization_type[0]
                          : null
                      }
                      label={
                        <FormattedMessage
                          defaultMessage="Organization type"
                          id="project.ORGANIZATION_TYPE"
                        />
                      }
                      name={ORGANIZATION_TYPE}
                      onChange={event => handleChange(event, index)}
                      required
                      select
                      SelectProps={{ native: true }}
                      size="small"
                      value={item.values.organization_type || ''}
                      variant="outlined">
                      <option disabled key="defaultValue" value="" />
                      {orgTypes.map(option => (
                        <FormattedMessage
                          defaultMessage={option.name}
                          id={'common.' + option.name.toUpperCase()}
                          key={option.id}>
                          {message => (
                            <option value={option.id}>{message}</option>
                          )}
                        </FormattedMessage>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    <Autocomplete
                      disabled={isClientOrg(item.values.organization_type)}
                      filterSelectedOptions
                      getOptionLabel={option => option.name}
                      onChange={(event, value) =>
                        handleChangeContractCounterpart(event, value, index)
                      }
                      options={organizations}
                      renderInput={params => (
                        <TextField
                          {...params}
                          fullWidth
                          label={
                            <FormattedMessage
                              defaultMessage="Contract Counterpart"
                              id="admin.CONTRACT_COUNTERPART"
                            />
                          }
                          size="small"
                          variant="outlined"
                        />
                      )}
                      size="small"
                      value={item.values.parent_organization || null}
                    />
                  </Grid>
                  <Grid item md={3} xs={6}>
                    <TextField
                      error={hasError(rowErrors, ORGANIZATION_EMAIL)}
                      fullWidth
                      helperText={
                        hasError(rowErrors, ORGANIZATION_EMAIL)
                          ? rowErrors.organization_email[0]
                          : null
                      }
                      label={
                        <FormattedMessage
                          defaultMessage="Email Address"
                          id="common.EMAIL_ADDRESS"
                        />
                      }
                      name={ORGANIZATION_EMAIL}
                      onChange={event => handleChange(event, index)}
                      required
                      size="small"
                      value={item.values.organization_email || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid className={classes.inlineFlex} item md={2} xs={6}>
                    <TextField
                      error={hasError(rowErrors, ORGANIZATION_CONTRACT)}
                      fullWidth
                      helperText={
                        hasError(rowErrors, ORGANIZATION_CONTRACT)
                          ? rowErrors.organization_contract[0]
                          : null
                      }
                      label={
                        <FormattedMessage
                          defaultMessage="STANDARD"
                          id="admin.STANDARD"
                        />
                      }
                      name={ORGANIZATION_CONTRACT}
                      onChange={event => handleChange(event, index)}
                      required
                      select
                      SelectProps={{ native: true }}
                      size="small"
                      value={item.values.organization_contract || ''}
                      variant="outlined">
                      <option disabled key="defaultValue" value="" />
                      {standardContracts.map(option => (
                        <FormattedMessage
                          defaultMessage={option.name}
                          id={'common.' + option.name.toUpperCase()}
                          key={option.id}>
                          {message => (
                            <option value={option.id}>{message}</option>
                          )}
                        </FormattedMessage>
                      ))}
                    </TextField>
                    <IconButton
                      onClick={() => handleRemoveRow(index)}
                      size="small">
                      <RemoveCircleIcon className={classes.deleteIcon} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Fragment>
            );
          })}
          <div className={classNames(classes.floatRight, classes.margin2)}>
            <Button
              className={classes.marginRight2}
              color="primary"
              onClick={cancel}
              variant="contained">
              <FormattedMessage defaultMessage="Cancel" id="common.CANCEL" />
            </Button>
            <Button
              color="primary"
              disabled={!isValid}
              onClick={handleInvite}
              variant="contained">
              <FormattedMessage
                defaultMessage="Send Invite"
                id="organization.SEND_INVITE"
              />
            </Button>
          </div>
        </>
      ) : (
        <Button
          color="primary"
          className={classes.floatRight}
          onClick={() => setShowInvite(true)}
          variant="contained">
          <FormattedMessage
            defaultMessage="Invite Organizations"
            id="organization.INVITE_ORGANIZATIONS"
          />
        </Button>
      )}
    </div>
  );
};

ProjectInvite.propTypes = {
  organizations: PropTypes.array,
  projectId: PropTypes.string
};

export default ProjectInvite;
