import Request from '../../common/request';

export default class UserApi extends Request {
  constructor(token, url) {
    super(url, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    this.endpoint = '/users';
  }

  all() {
    return super.get(this.endpoint);
  }

  info(id) {
    return super.get(`${this.endpoint}/${id}`);
  }

  update(id, params) {
    return super.put(`${this.endpoint}/${id}`, params);
  }

  delete(id) {
    return super.delete(`${this.endpoint}/${id}`);
  }

  invite(params) {
    return super.post(`${this.endpoint}/invite`, params);
  }

  getRoles() {
    return super.get('/roles');
  }

  configurationCreate(params) {
    return super.post(`${this.endpoint}/settings`, params);
  }

  configurationFetch(id) {
    return super.get(`${this.endpoint}/settings?user_id=${id}`);
  }

  configurationUpdate(params) {
    const { id, ...rest } = params;

    return super.put(`${this.endpoint}/settings/${id}`, rest);
  }

  settingFetch(id) {
    return super.get(`${this.endpoint}/settings/global/${id}`);
  }

  settingUpdate(params) {
    const { id, ...rest } = params;

    return super.put(`${this.endpoint}/settings/global/${id}`, rest);
  }
}
