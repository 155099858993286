export const DOCUMENT_REQUEST = 'DOCUMENT_REQUEST';
export const DOCUMENT_FAILURE = 'DOCUMENT_FAILURE';
export const DOCUMENT_SUCCESS = 'DOCUMENT_SUCCESS';
export const CATEGORY_REQUEST = 'CATEGORY_REQUEST';
export const CATEGORY_FAILURE = 'CATEGORY_FAILURE';
export const CATEGORY_SUCCESS = 'CATEGORY_SUCCESS';
export const CATEGORY_DELETE_SUCCESS = 'CATEGORY_DELETE_SUCCESS';
export const CLEAR_ADDED_FIELDS = 'CLEAR_ADDED_FIELDS';
export const CATEGORY_TEMPLATE_REQUEST = 'CATEGORY_TEMPLATE_REQUEST';
export const CATEGORY_TEMPLATE_FAILURE = 'CATEGORY_TEMPLATE_FAILURE';
export const CATEGORY_TEMPLATE_SUCCESS = 'CATEGORY_TEMPLATE_SUCCESS';
export const CATEGORY_TEMPLATE_UPDATE_SUCCESS =
  'CATEGORY_TEMPLATE_UPDATE_SUCCESS';
export const DOCUMENT_CLEAR_MESSAGES = 'DOCUMENT_CLEAR_MESSAGES';
