import axios from 'axios';
import DocumentApi from '../document/DocumentApi';
import ExportApi from '../export/ExportApi';
import ObjectApi from '../object/ObjectApi';
import OrganizationApi from '../organization/OrganizationApi';
import ProjectApi from '../project/ProjectApi';
import StandardApi from '../standard/StandardApi';
import UserApi from '../user/UserApi';
import {
  LS_STR8LINE_TOKEN,
  POLARIS_URL,
  SSO_NAME_ENTRA_ID
} from 'common/constants';

export default class AuthApi {
  constructor(token) {
    const baseUrl = process.env.REACT_APP_POLARIS_URL
      ? process.env.REACT_APP_POLARIS_URL
      : POLARIS_URL;

    this.baseUrl = baseUrl;
    this.endpoint = '/login';
    this.userEndpoint = '/users';
    this.otpEndpoint = '/otp';
    this.token = token;

    this.document = new DocumentApi(this.token, this.baseUrl);
    this.export = new ExportApi(this.token, this.baseUrl);
    this.object = new ObjectApi(this.token, this.baseUrl);
    this.organization = new OrganizationApi(this.token, this.baseUrl);
    this.project = new ProjectApi(this.token, this.baseUrl);
    this.standard = new StandardApi(this.baseUrl);
    this.user = new UserApi(this.token, this.baseUrl);
  }

  login(email, password) {
    return axios
      .post(this.baseUrl + this.endpoint, {
        email,
        password
      })
      .then(resp => resp.data)
      .catch(err => {
        if (err.response) {
          if (err.response.status === 401)
            throw new Error('error.INVALID_CREDENTIALS');
          if (
            err.response.status === 403 &&
            err.response.data?.error === 'Forbidden sign-in attempt'
          ) {
            throw new Error('error.SSO_LOGIN_ONLY');
          }
        }
        throw new Error('error.UNEXPECTED_RESPONSE');
      });
  }

  loginWithToken(token, idpName) {
    let url = `${this.baseUrl}${this.endpoint}/${token}`;
    if (idpName) {
      url += `?idp_type_name=${idpName}`;
    }
    return axios
      .post(url)
      .then(resp => resp.data)
      .catch(err => {
        if (idpName === SSO_NAME_ENTRA_ID) {
          throw err;
        }
        if (err.response && err.response.status === 401)
          throw new Error('error.INVALID_CREDENTIALS');
        throw new Error('error.UNEXPECTED_RESPONSE');
      });
  }

  otp(param) {
    return axios
      .post(this.baseUrl + this.otpEndpoint, param)
      .then(resp => {
        localStorage.setItem(LS_STR8LINE_TOKEN, resp.data.jwt);
        return resp.data;
      })
      .catch(err => {
        if (err.response && err.response.status === 401)
          throw new Error('error.INVALID_OTP');
        throw new Error('error.UNEXPECTED_RESPONSE');
      });
  }

  signup(email, userTypeId, name) {
    let params = {
      email: email,
      user_type_id: userTypeId,
      name: name
    };

    return axios
      .post(`${this.baseUrl}${this.userEndpoint}`, params)
      .catch(err => {
        if (err.response) {
          if (
            err.response.status === 403 &&
            err.response.data?.error === 'Forbidden sign-up attempt'
          ) {
            throw new Error('error.SSO_CREATE_ACCOUNT');
          }
          if (err.response.status === 409) {
            throw new Error('error.SIGNUP_EMAIL_EXISTS');
          }
        }
      });
  }

  forgot(email) {
    return axios
      .post(`${this.baseUrl}${this.userEndpoint}/${email}/forgot-password`)
      .catch(err => {
        if (err.response) {
          if (
            err.response.status === 403 &&
            err.response.data?.error === 'Forbidden sign-in attempt'
          ) {
            throw new Error('error.SSO_RESET_PASSWORD');
          }
          if (err.response.status === 404) {
            throw new Error('error.EMAIL_DOES_NOT_EXIST');
          }
        }
      });
  }
}
