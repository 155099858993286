import { VIRGO_URL } from 'common/constants';
import Request from '../../common/request';

const baseUrl = process.env.REACT_APP_VIRGO_URL
  ? process.env.REACT_APP_VIRGO_URL
  : VIRGO_URL;

export default class ForgApi extends Request {
  constructor() {
    super(baseUrl, {
      headers: { 'Content-Type': 'application/json' }
    });
    this.endpoint = '/virgo';
  }

  generate() {
    return super.post(this.endpoint, { request: 'tokenRequest' }, true);
  }
}
