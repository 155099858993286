import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    margin: theme.spacing(4),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1)
    }
  },
  objViewRoot: {
    borderRadius: 4,
    boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)',
    margin: '0 14px',
    maxHeight: 'calc(100vh - 153px)',
    overflowX: 'auto',
    overflowY: 'auto'
  },
  objOverflow: {
    overflowX: 'auto'
  },
  marginRight: {
    marginRight: theme.spacing(2)
  },
  objectRoot: {
    margin: theme.spacing(4)
  },
  disableHoverEffect: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  form: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  },
  formLabel: {
    alignItems: 'start',
    width: '100%',
    margin: 0
  },
  formHeader: {
    padding: theme.spacing(0.2, 2)
  },
  formControlInline: {
    flexFlow: 'row wrap',
    alignItems: 'center'
  },
  formLabelMargin: {
    marginRight: theme.spacing(1)
  },
  formContainer: {
    paddingRight: 'calc(100vw*0.4) !important'
  },
  alignRightButton: {
    marginLeft: 'auto'
  },
  divider: {
    backgroundColor: theme.palette.background.cardHeader,
    padding: 5,
    paddingLeft: 10
  },
  dividerDashed: {
    border: 'none',
    borderBottom: 'dashed',
    borderWidth: '2px'
  },
  drawerPaperLeft: {
    border: 0,
    height: 'calc(100% - 120px)',
    paddingTop: 120
  },
  drawerPaperLeftMaximize: {
    width: 220,
    [theme.breakpoints.down('md')]: {
      width: 250
    }
  },
  drawerPaperLeftMinimize: {
    width: 50
  },
  drawerPaperRight: {
    border: 0,
    width: 700
  },
  drawerPaperRightDesktop: {
    padding: '120px 0 0',
    [theme.breakpoints.between('sm', 'lg')]: {
      width: 550
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  textUpperCase: {
    textTransform: 'uppercase'
  },
  textLarge: {
    ...theme.typography.h4,
    fontWeight: 400,
    paddingBottom: 60
  },
  filterLabel: {
    ...theme.typography.subtitle2,
    fontWeight: 500
  },
  filterListIcon: {
    minWidth: 30,
    padding: 0
  },
  filterListItemText: {
    ...theme.typography.subtitle2
  },
  filterListContainer: {
    marginTop: 15,
    maxHeight: 'calc(100vh - 610px)',
    overflowY: 'auto'
  },
  emptyResultContainer: {
    height: '100%',
    display: 'flex',
    paddingTop: '20%',
    justifyContent: 'center'
  },
  emptyResultButton: {
    display: 'block',
    borderStyle: 'dashed',
    borderColor: theme.palette.secondary.dark,
    borderWidth: 2,
    borderRadius: 50,
    textTransform: 'none',
    '&:hover': {
      borderColor: theme.palette.primary.light
    }
  },
  addIcon: {
    fontSize: '5.1875rem',
    paddingTop: 60,
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 30
  },
  table: {
    minWidth: 450
  },
  content: {
    marginTop: 30,
    [theme.breakpoints.down('md')]: {
      marginLeft: 10,
      marginRight: 10
    }
  },
  contentShiftLeft: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  contentShiftLeftMaximize: {
    marginLeft: 225,
    width: 'calc(100% - 240px)',
    [theme.breakpoints.down('md')]: {
      marginLeft: 10,
      width: 'initial'
    }
  },
  contentShiftLeftMinimize: {
    marginLeft: 55,
    width: 'calc(100% - 70px)'
  },
  contentShiftRight: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    width: 'calc(100% - 560px)',
    [theme.breakpoints.down('md')]: {
      width: 'initial'
    }
  },
  contentShiftBothMaximize: {
    width: 'calc(100% - 930px)',
    [theme.breakpoints.down('lg')]: {
      width: 'calc(100% - 780px)'
    },
    [theme.breakpoints.down('md')]: {
      width: 'initial'
    }
  },
  contentShiftBothMinimize: {
    width: 'calc(100% - 760px)',
    [theme.breakpoints.down('lg')]: {
      width: 'calc(100% - 610px)'
    },
    [theme.breakpoints.down('md')]: {
      width: 'initial'
    }
  },
  tableContainer: {
    margin: 0,
    maxHeight: 'calc(100vh - 220px)',
    width: '100%'
  },
  tableMainHeader: {
    paddingBottom: 30,
    width: '100%'
  },
  tableNoBorder: {
    borderBottom: 'none'
  },
  tableCellBorderError: {
    border: '2px solid #e53935'
  },
  tableCellCheckbox: {
    padding: 0,
    paddingLeft: theme.spacing(1)
  },
  buttonLabel: {
    color: theme.palette.white
  },
  sectionContainer: {
    maxHeight: '290px',
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  sectionTable: {
    padding: theme.spacing(2, 0)
  },
  sectionButton: {
    padding: theme.spacing(2)
  },
  sectionLoButton: {
    float: 'right',
    padding: theme.spacing(0, 1, 2, 0)
  },
  historyRoot: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
    maxWidth: '25em'
  },
  cardContainer: {
    padding: theme.spacing(2, 0)
  },
  cardContentLeft: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0),
      paddingLeft: theme.spacing(1)
    },
    paddingBottom: theme.spacing(1)
  },
  cardContentRight: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 1, 0, 2)
    },
    paddingTop: theme.spacing(1)
  },
  cardHeader: {
    backgroundColor: theme.palette.background.cardHeader,
    padding: theme.spacing(0.5)
  },
  title: {
    color: theme.palette.white,
    fontSize: '14px',
    padding: theme.spacing(0)
  },
  avatar: {
    color: theme.palette.white,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  icon: {
    backgroundColor: theme.palette.background.cardHeader,
    color: theme.palette.white
  },
  dividerMargin: {
    margin: theme.spacing(2, 0)
  },
  dividerMarginTop: {
    marginTop: theme.spacing(1)
  },
  dividerMarginTopLeft: {
    marginTop: theme.spacing(1),
    marginLeft: 32
  },
  status: {
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(2)
  },
  discipline: {
    backgroundColor: theme.palette.background.cardHeader,
    color: theme.palette.white,
    display: 'inline-block',
    padding: '.1rem .4rem',
    minWidth: '3em',
    textAlign: 'center',
    borderRadius: '2px'
  },
  disciplineHeader: {
    padding: theme.spacing(1),
    color: theme.palette.white,
    fontWeight: 600
  },
  disciplineBox: {
    border: '1px solid #ddd',
    borderRadius: '4px',
    minHeight: '42px',
    padding: theme.spacing(1)
  },
  disciplineOwner: {
    padding: theme.spacing(0.3),
    textTransform: 'none'
  },
  checkBox: {
    border: '1px solid #ddd',
    borderRadius: '4px',
    boxSizing: 'border-box',
    padding: theme.spacing(0, 1),
    width: '100%'
  },
  checkBoxErr: {
    border: '1px solid #e53935',
    borderRadius: '4px',
    boxSizing: 'border-box',
    padding: theme.spacing(0, 1),
    width: '100%'
  },
  buttonList: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#ddd',
    marginBottom: theme.spacing(1),
    borderRadius: 4
  },
  popupDivider: {
    margin: theme.spacing(2, 0)
  },
  smallPopupDivider: {
    margin: theme.spacing(1, 0)
  },
  componentPopupRoot: {
    height: '100%',
    overflowY: 'auto',
    padding: theme.spacing(2),
    width: 550,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      width: 500
    },
    [theme.breakpoints.only('xs')]: {
      '@media (orientation: landscape)': {
        width: 480
      },
      '@media (orientation: portrait)': {
        height: 500,
        width: 230
      }
    }
  },
  popupNewUser: {
    height: '100%',
    width: 650,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      width: '100%'
    }
  },
  tableNewUser: {
    [theme.breakpoints.down('sm')]: {
      width: 600
    }
  },
  imageHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  image: {
    width: 120
  },
  grayBackground: {
    backgroundColor: '#ddd'
  },
  grayContent: {
    backgroundColor: '#eee'
  },
  grayIcon: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  greenBackground: {
    backgroundColor: '#dcfde1',
    transition: 'backgroundColor 0.2s ease'
  },
  whiteBackground: {
    backgroundColor: theme.palette.white
  },
  noPadding: {
    padding: '0 !important'
  },
  noPaddingCardContent: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  noTopBottomPadding: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important'
  },
  noTopPadding: {
    paddingTop: '0 !important'
  },
  noBottomPadding: {
    paddingBottom: '0 !important'
  },
  noRightPadding: {
    paddingRight: '0 !important'
  },
  noLeftPadding: {
    paddingLeft: '0 !important'
  },
  paddingTop: {
    paddingTop: theme.spacing(3)
  },
  paddingTop2: {
    paddingTop: theme.spacing(2)
  },
  paddingTop1: {
    paddingTop: theme.spacing(1)
  },
  paddingBottom: {
    paddingBottom: theme.spacing(1)
  },
  paddingRight: {
    paddingRight: theme.spacing(1)
  },
  paddingRightHalf: {
    paddingRight: theme.spacing(0.5)
  },
  padding1: {
    padding: theme.spacing(1)
  },
  padding2: {
    padding: theme.spacing(2)
  },
  noMargin: {
    margin: '0 !important'
  },
  noMarginTop: {
    marginTop: 0
  },
  noMarginBottom: {
    marginBottom: 0
  },
  marginBottom1: {
    marginBottom: theme.spacing(1)
  },
  marginBottom2: {
    marginBottom: theme.spacing(2)
  },
  marginTop1: {
    marginTop: theme.spacing(1)
  },
  marginTop2: {
    marginTop: theme.spacing(2)
  },
  marginLeft1: {
    marginLeft: theme.spacing(1)
  },
  marginLeft2: {
    marginLeft: theme.spacing(2)
  },
  marginLeft3: {
    marginLeft: theme.spacing(3)
  },
  justifyEnd: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  justifyStart: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  justifySpaceBetween: {
    display: 'inline-flex',
    justifyContent: 'space-between'
  },
  link: {
    cursor: 'pointer'
  },
  centerAlign: {
    alignSelf: 'center'
  },
  black: {
    color: '#263238',
    '&$disabled': {
      color: '#263238'
    }
  },
  white: {
    color: theme.palette.white,
    '&$disabled': {
      color: theme.palette.white
    }
  },
  disabled: {
    /* this goes with above, do not delete */
  },
  revisionButton: {
    backgroundColor: '#53a3c0',
    borderRight: 'none !important',
    color: theme.palette.white,
    minWidth: 23,
    padding: 0,
    '&:hover': {
      backgroundColor: '#53a3c0'
    },
    '&:disabled': {
      color: theme.palette.white,
      backgroundColor: '#53a3c0'
    }
  },
  revisionButtonUnderline: {
    borderBottom: '1px solid white'
  },
  revisionErrorText: {
    color: theme.palette.error.main
  },
  revisionLabel: {
    color: theme.palette.white,
    fontSize: '0.8125rem',
    fontWeight: 500,
    letterSpacing: '0.02857em',
    lineHeight: 1.75,
    paddingLeft: 8,
    textTransform: 'uppercase'
  },
  boldLabel: {
    fontWeight: 500
  },
  docflowRoot: {
    height: '100%',
    maxHeight: 'calc(100vh - 110px)',
    position: 'sticky',
    top: 100
  },
  docflowListContainer: {
    height: 130,
    overflowY: 'auto'
  },
  docflowHeader: {
    height: 36,
    padding: theme.spacing(0.2, 2)
  },
  docFlowContent: {
    padding: '8px 0 0!important'
  },
  docflowButton: {
    display: 'inline-block',
    height: 40,
    lineHeight: 1,
    margin: '0 auto',
    width: '60%',
    '&:not(:first-child)': {
      marginTop: theme.spacing(1)
    }
  },
  docflowButtonName: {
    color: theme.palette.white,
    lineHeight: 1
  },
  docflowButtonTitle: {
    color: theme.palette.white,
    fontSize: 12,
    padding: theme.spacing(0),
    textTransform: 'none'
  },
  docflowObjView: {
    marginTop: theme.spacing(1),
    maxHeight: 'calc(100vh - 311px)',
    overflowY: 'auto',
    padding: theme.spacing(1, 2)
  },
  docFlowObjectList: {
    justifyContent: 'center',
    maxHeight: 200,
    overflowY: 'auto',
    margin: '0 !important'
  },
  filters: {
    padding: '1.2px !important'
  },
  filterItemIcon: {
    minWidth: 30
  },
  addItemIcon: {
    color: '#77d353'
  },
  delItemIcon: {
    color: '#e86d6f'
  },
  saveItemIcon: {
    color: '#263238'
  },
  lockItemIcon: {
    color: '#263238'
  },
  objListIcon: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    marginLeft: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  agendaIconPadding: {
    padding: theme.spacing(0.5, 2, 0)
  },
  agendaSubIconPadding: {
    padding: theme.spacing(0.5, 2, 0, 3)
  },
  agendaIconDisplay: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  agendaItemPadding: {
    padding: theme.spacing(0, 2, 1)
  },
  agendaSubItemPadding: {
    padding: theme.spacing(0, 2, 1, 3.5)
  },
  agendaItemTextField: {
    width: 200,
    margin: theme.spacing(2)
  },
  agendaButton: {
    textTransform: 'capitalize'
  },
  agendaView: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(4)
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'baseline'
  },
  historyContent: {
    maxHeight: '700px',
    overflow: 'auto',
    '& > div:last-child': {
      '& > hr': {
        display: 'none'
      }
    }
  },
  errorBox: {
    border: '1px solid #e53935'
  },
  errorText: {
    marginLeft: 14,
    width: '100%'
  },
  revisionEditorEdit: {
    padding: '8px 8px 0px 20px !important'
  },
  revisionEditorView: {
    padding: '0 0 8px 10px !important'
  },
  breadCrumbs: {
    alignItems: 'center',
    display: 'flex',
    height: 53
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  floatRight: {
    float: 'right'
  },
  dateLabel: {
    transform: 'translate(14px, 12px) scale(1)'
  },
  dateLabelError: {
    color: theme.palette.error.main
  },
  inlineFlex: {
    display: 'inline-flex'
  },
  moreIconButton: {
    color: '#FFFFFF',
    paddingTop: 6,
    paddingBottom: 6
  },
  halfWidth: {
    width: '50%'
  },
  fullWidth: {
    width: '100%'
  },
  fullHeight: {
    height: '100%'
  },
  moreIconTmpl: {
    padding: '6px 0',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  projectSelectMenu: {
    color: '#66adc7',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    },
    display: 'inline-flex',
    WebkitBorderRadius: 0
  },
  viewTypeContainer: {
    marginLeft: 15
  },
  filterClear: {
    float: 'right',
    paddingTop: 10
  },
  tmplSnackbar: {
    marginTop: '50px'
  },
  attachmentAlert: {
    padding: theme.spacing(0, 1)
  },
  attachmentButton: {
    fontWeight: 400,
    justifyContent: 'flex-start',
    lineHeight: 1,
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: 'start',
    textTransform: 'initial'
  },
  attachmentPreviewList: {
    maxHeight: 380,
    overflowY: 'auto'
  },
  revisionList: {
    maxHeight: '200px'
  },
  wyswyg: {
    '& > div': {
      borderRadius: '0 0 4px 4px!important',
      padding: '0!important',
      overflow: 'hidden',
      '& > div:first-child': {
        borderRadius: 0
      }
    }
  },
  objectCards: {
    borderRadius: 0,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  organizationCard: {
    alignItems: 'center',
    display: 'flex',
    minWidth: 50
  },
  organizationDiscipline: {
    minWidth: 92
  },
  borderGradient: {
    border: 0,
    borderBottom: '3px solid',
    borderImageSlice: 1
  },
  borderGradientBlue: {
    borderImageSource: 'linear-gradient(to left, #53a3c0, #edf5f8)'
  },
  borderGradientGreen: {
    borderImageSource: 'linear-gradient(to left, #6cc053, #f0f8ed)'
  },
  borderGradientMagenta: {
    borderImageSource: 'linear-gradient(to left, #a653c0, #f6edf8)'
  },
  borderGradientOrange: {
    borderImageSource: 'linear-gradient(to left, #c07053, #f8f0ed)'
  },
  backgroundDarkBlue: { background: '#518a9f' },
  backgroundBlue: { background: '#53a3c0' },
  backgroundGreen: { background: '#6cc053' },
  backgroundMagenta: { background: '#a653c0' },
  backgroundOrange: { background: '#c07053' },
  discOrgLabelContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  discOrgLabelContent: {
    borderRadius: '3px',
    color: theme.palette.white,
    fontWeight: 500,
    padding: theme.spacing(0, 1)
  },
  objViewStatus: {
    color: '#53a3c0',
    fontSize: '15px',
    fontWeight: 500,
    textTransform: 'uppercase'
  },
  historyContainer: {
    padding: theme.spacing(1),
    '& > div:not(:last-child)': {
      paddingBottom: theme.spacing(1)
    }
  },
  historyCurrentTitle: {
    fontWeight: 500,
    paddingRight: theme.spacing(1)
  },
  historyPopper: {
    zIndex: 5
  },
  historyTitle: {
    background: '#53a3c0',
    borderRadius: 4,
    color: '#ffffff',
    fontWeight: 500,
    marginRight: theme.spacing(1),
    padding: 4
  },
  spanButton: {
    color: '#000000',
    fontWeight: 500,
    lineHeight: 1.75,
    maxWidth: 64,
    padding: '6px 8px',
    textTransform: 'uppercase'
  },
  viewBtnMarginLeft: {
    marginLeft: 12
  },
  viewBtnMarginRight: {
    marginRight: 12
  },
  disciplineType: {
    backgroundColor: theme.palette.white,
    '& > select:focus': {
      backgroundColor: theme.palette.white
    }
  },
  popupTableBorder: {
    border: 'none',
    borderTop: `1px solid ${theme.palette.divider}`
  },
  popupTableBorderUser: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  popupTableCell: {
    padding: '6px !important',
    verticalAlign: 'top'
  },
  categorySelect: {
    height: 30
  },
  filterView: {
    margin: '0 14px'
  },
  actionsMenu: {
    background: '#dd882e',
    '& > li:hover': {
      background: '#fa8505'
    },
    '& > li > div': {
      color: 'white'
    },
    '& > li > p': {
      color: 'white'
    }
  },
  hidden: {
    visibility: 'hidden'
  },
  noDisplay: {
    display: 'none'
  },
  autocompleteOption: {
    justifyContent: 'space-between',
    '&[data-focus="true"] > div > button': {
      visibility: 'inherit'
    },
    '&[data-focus="false"] > div > button': {
      visibility: 'hidden'
    }
  },
  textAlignJustify: {
    textAlign: 'justify'
  },
  dragAgendaHighlight: {
    border: '1px solid #77d353',
    transition: 'border 0.2s ease'
  },
  noBoxShadow: {
    boxShadow: 'none'
  },
  addObjButtonMobile: {
    minWidth: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  projectHeight: {
    height: 32
  },
  addInviteIcon: {
    color: '#77d353'
  },
  deleteIcon: {
    color: '#e86d6f'
  },
  boqDescription: {
    height: 100,
    '&:last-child': {
      padding: theme.spacing(2)
    }
  },
  orgAvatar: {
    '& > div': {
      height: theme.spacing(4),
      width: theme.spacing(4),
      '& > span': {
        color: '#ffffff'
      }
    }
  },
  orgAvatarBackgroundBlue: { backgroundColor: '#137de0' },
  orgAvatarBackgroundGreen: { backgroundColor: '#66Ab09' },
  orgAvatarBackgroundOrange: { backgroundColor: '#f76A0e' },
  orgAvatarBackgroundPink: { backgroundColor: '#e91e63' },
  orgAvatarBackgroundPurple: { backgroundColor: '#8809b5' },
  orgAvatarBackgroundRed: { backgroundColor: '#c31418' },
  orgAvatarBackgroundViolet: { backgroundColor: '#ee82ee' },
  orgAvatarBackgroundYellow: { backgroundColor: '#eacd1e' },
  orgTooltipContainer: {
    right: 45
  },
  listDivider: {
    margin: theme.spacing(1, 0)
  },
  listDividerContainer: {
    pointerEvents: 'none'
  },
  archivedProject: {
    alignItems: 'center',
    backgroundColor: '#e86d6f',
    color: theme.palette.white,
    padding: '4px 16px',
    textTransform: 'uppercase',
    '& .MuiAlert-message': {
      alignItems: 'center'
    }
  },
  archivedProjectTitle: {
    margin: 0,
    paddingBottom: 0
  },
  fileIcon: {
    width: theme.spacing(2.8),
    marginLeft: theme.spacing(1),
    padding: 0,
    '& > svg > g > text': {
      fontSize: 12
    }
  },
  fileIconCell: {
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(0.5)
  }
}));

export const inputStyles = {
  label: { textAlign: 'center' },
  numeric: { textAlign: 'right' },
  multiline: { textAlign: 'center', whiteSpace: 'pre-line' },
  multilineDesc: { whiteSpace: 'pre-line' }
};

export const overrideProps = {
  content: {
    padding: '10px'
  },
  title: {
    display: 'inline-block'
  },
  subheader: {
    display: 'inline-block',
    paddingLeft: '16px',
    width: '50%'
  }
};
