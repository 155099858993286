import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Alert, Autocomplete } from '@material-ui/lab';
import {
  Button,
  Divider,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { CategoryTemplate } from 'views/Admin/OrgSettings/components';
import { hasError, nullifyCategoryIds } from 'common/helper';
import {
  organizationTypeFetch,
  orgCategoryTemplatesFetch,
  projectCreate,
  standardFetch
} from '../../../../redux';
import { useFetch } from '../../../../hooks';
import { useProjectStyles } from '../../styles';
import validate from 'validate.js';
import _ from 'lodash';
import {
  LS_ORGANIZATION_ID,
  NAME,
  ORGANIZATION_TYPE_ID,
  ORG_TYPE_CLIENT,
  STANDARD,
  STANDARD_ID,
  TEMPLATE
} from 'common/constants';

const NewProject = ({ onPopupClose }) => {
  const classes = useProjectStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const orgId = localStorage.getItem(LS_ORGANIZATION_ID);
  const {
    document: { categoryTemplates },
    project: { error: projectError, projects, updated: projectUpdated },
    organization,
    standard: { contracts: standardContracts }
  } = useSelector(state => state);
  const orgTypes = organization.types?.data;
  const [formState, setFormState] = useState({
    isClientOrg: false,
    isValid: false,
    values: {
      organization_id: orgId,
      document_categories: [],
      prev_categories: []
    },
    errors: {}
  });
  const existingNames = projects.map(project => project.name);
  const baseSchema = {
    name: {
      presence: {
        allowEmpty: false,
        message: (
          <FormattedMessage
            defaultMessage="Name is required"
            id="error.REQUIRED_NAME"
          />
        )
      },
      length: {
        maximum: 64
      },
      exclusion: existingNames
    },
    organization_type_id: {
      presence: {
        allowEmpty: false,
        message: (
          <FormattedMessage
            defaultMessage="Organization type is required"
            id="error.REQUIRED_ORGANIZATION_TYPE"
          />
        )
      }
    }
  };
  const standardSchema = {
    standard_id: {
      presence: {
        allowEmpty: false,
        message: (
          <FormattedMessage
            defaultMessage="{name} is required"
            id="error.REQUIRED"
            values={{
              name: (
                <FormattedMessage
                  defaultMessage="STANDARD"
                  id="admin.STANDARD"
                />
              )
            }}
          />
        )
      }
    }
  };

  useFetch(organizationTypeFetch, orgTypes);

  useEffect(() => {
    if (orgId) {
      dispatch(orgCategoryTemplatesFetch(orgId));
      dispatch(standardFetch());
    }
  }, [orgId, dispatch]);

  useEffect(() => {
    const schema = {
      ...baseSchema,
      ...(formState.isClientOrg ? {} : standardSchema)
    };
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
    // disable warning regarding schema
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values]);

  useEffect(() => {
    if (projectUpdated) onPopupClose();
  }, [projectUpdated, onPopupClose]);

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value
      }
    }));
  };

  const handleSelectionChange = (event, value, field) => {
    event.persist();
    setFormState(formState => {
      let isClientOrg = formState.isClientOrg,
        newValues = {};

      switch (field) {
        case ORGANIZATION_TYPE_ID:
          isClientOrg = value?.name === ORG_TYPE_CLIENT;
          newValues = {
            organization_type: value,
            organization_type_id: value?.id
          };
          break;
        case STANDARD:
          newValues = { standard: value, standard_id: value?.id };
          break;
        case TEMPLATE: {
          const dc =
            value?.document_categories?.map(category =>
              nullifyCategoryIds(category)
            ) || [];
          newValues = {
            selected_template: { name: value?.name || '' },
            document_categories: _.cloneDeep(dc),
            prev_categories: _.cloneDeep(dc)
          };
          break;
        }
        default:
      }

      return {
        ...formState,
        isClientOrg,
        values: { ...formState.values, ...newValues }
      };
    });
  };

  const updateCategories = (event, _index, field, value, extra = {}) => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [field]: value,
        prev_categories: extra.updatePrev
          ? _.cloneDeep(value)
          : formState.values.prev_categories
      }
    }));
  };

  const handleAddProject = event => {
    event.preventDefault();
    dispatch(projectCreate(formState.values));
  };

  const {
    document_categories = [],
    name,
    organization_type,
    prev_categories,
    selected_template,
    standard
  } = formState.values;
  const showStandardField = organization_type && !formState.isClientOrg;
  return (
    <div className={classes.popupRoot}>
      {projectError ? (
        <Alert className={classes.alert} severity="error">
          {projectError}
        </Alert>
      ) : null}
      <Typography variant="h3">
        <FormattedMessage
          defaultMessage="New Project"
          id="project.NEW_PROJECT"
        />
      </Typography>
      <Divider className={classes.popupDivider} />
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <TextField
            autoFocus
            error={hasError(formState.errors, NAME)}
            fullWidth
            helperText={
              hasError(formState.errors, NAME) ? formState.errors.name[0] : null
            }
            label={
              <FormattedMessage
                defaultMessage="Project Name"
                id="project.PROJECT_NAME"
              />
            }
            name={NAME}
            onChange={handleChange}
            required
            size="small"
            type="text"
            value={name || ''}
            variant="outlined"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Autocomplete
            getOptionLabel={option =>
              intl.formatMessage({
                defaultMessage: option.name,
                id: `common.${option.name.toUpperCase()}`
              })
            }
            id={ORGANIZATION_TYPE_ID}
            name={ORGANIZATION_TYPE_ID}
            onChange={(e, v) =>
              handleSelectionChange(e, v, ORGANIZATION_TYPE_ID)
            }
            options={orgTypes}
            renderInput={params => (
              <TextField
                {...params}
                error={hasError(formState.errors, ORGANIZATION_TYPE_ID)}
                fullWidth
                helperText={
                  hasError(formState.errors, ORGANIZATION_TYPE_ID)
                    ? formState.errors.organization_type_id[0]
                    : null
                }
                label={
                  <FormattedMessage
                    defaultMessage="Organization Type"
                    id="project.ORGANIZATION_TYPE"
                  />
                }
                required
                size="small"
                variant="outlined"
              />
            )}
            size="small"
            value={organization_type || null}
          />
        </Grid>
        {showStandardField ? (
          <Grid item md={6} xs={12}>
            <Autocomplete
              getOptionLabel={option => option.name || ''}
              id={STANDARD}
              name={STANDARD}
              onChange={(e, v) => handleSelectionChange(e, v, STANDARD)}
              options={standardContracts}
              renderInput={params => (
                <TextField
                  {...params}
                  error={hasError(formState.errors, STANDARD_ID)}
                  fullWidth
                  helperText={
                    hasError(formState.errors, STANDARD_ID)
                      ? formState.errors.standard_id[0]
                      : null
                  }
                  label={
                    <FormattedMessage
                      defaultMessage="STANDARD"
                      id="admin.STANDARD"
                    />
                  }
                  required
                  size="small"
                  variant="outlined"
                />
              )}
              size="small"
              value={standard || null}
            />
          </Grid>
        ) : null}
        <Grid item md={showStandardField ? 12 : 6} xs={12}>
          <Autocomplete
            getOptionLabel={option => option.name || ''}
            id={TEMPLATE}
            name={TEMPLATE}
            onChange={(e, v) => handleSelectionChange(e, v, TEMPLATE)}
            options={categoryTemplates}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                label={
                  <FormattedMessage
                    defaultMessage="Category Template"
                    id="common.CATEGORY_TEMPLATE"
                  />
                }
                size="small"
                variant="outlined"
              />
            )}
            size="small"
            value={selected_template || null}
          />
        </Grid>
        {selected_template?.name ? (
          <Grid item md={12} xs={12}>
            <CategoryTemplate
              categories={document_categories}
              className={classes.noPadding}
              prevCategories={prev_categories}
              updateTemplateValues={updateCategories}
            />
          </Grid>
        ) : null}
      </Grid>
      <Divider className={classes.popupDivider} />
      <Button
        color="primary"
        disabled={!formState.isValid || !name}
        onClick={handleAddProject}
        type="submit"
        variant="contained">
        <FormattedMessage
          defaultMessage="Add Project"
          id="project.ADD_PROJECT"
        />
      </Button>
    </div>
  );
};

NewProject.propTypes = {
  onPopupClose: PropTypes.func
};

export default NewProject;
