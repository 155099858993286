import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Button, Popover, MenuList, MenuItem } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useStyles } from '../../styles';
import { objectCreateOrUpdate } from 'redux/object/ObjectAction';
import {
  getDiscStatusList,
  getMainStatusList,
  isDisabledStatus,
  nextStatusOutsideOrg
} from '../statusProcessor.js';
import { getStatusById } from '../helper';
import { isCurrentOrg, isObjectEmpty, valueInArray } from 'common/helper';
import {
  validateBoQObject,
  validateDocObject,
  validateExecObject
} from '../../validations/validator';
import {
  HEX_CODE_CHECK_MARK,
  OBJECT_DOC,
  OBJECT_DRAW,
  OBJECT_EO,
  OBJECT_KOE,
  OBJECT_MISC,
  OBJECT_TA,
  REVISION_VALUES,
  STATUS_APPROVED,
  STATUS_APPROVED_AND_CLOSED,
  STATUS_APPROVED_AND_PUBLISHED,
  STATUS_BOQ_REQUESTED,
  STATUS_DRAFT,
  STATUS_FINAL_QUANTITIES_ISSUED,
  STATUS_HOLD,
  STATUS_ISSUED_FOR_APPROVAL,
  STATUS_NOT_USED,
  STATUS_PRELIM_BOQ_APPROVED,
  STATUS_PUBLISHED,
  STATUS_REJECTED,
  STATUS_SEND,
  STATUS_SIGNED_AND_CLOSED,
  STATUS_UPDATED_QUANTITIES_ISSUED,
  STATUS_VOID,
  STATUS_WITHDRAWN,
  VALUES
} from 'common/constants';

const Status = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const {
    btnStyle,
    canSetEvaluate,
    className,
    disabled,
    disciplineType,
    formState,
    handleSubmit,
    isBoQ,
    isCreatorOrg,
    isCreatorUser,
    isDiscOwner,
    isDoc,
    isPreview,
    isView,
    mainStatus,
    name,
    recipientId,
    recipientName,
    setFormState,
    setHasPopup,
    status,
    topAccessLevel
  } = props;
  const object = useSelector(state => state.object);
  const { isRevision } = formState || false;
  const valsKey = isRevision ? REVISION_VALUES : VALUES;
  const revNo =
    formState?.[valsKey]?.metadata?.revision ||
    formState?.metadata?.revision ||
    0;
  const showVoid =
    props.showVoid &&
    !(valueInArray([OBJECT_EO, OBJECT_KOE, OBJECT_TA], name) && revNo > 0);

  const [state, setExpandState] = useState({
    open: false,
    anchorEl: null,
    status: STATUS_DRAFT
  });
  const { open, anchorEl } = state;

  const isDisciplineType = !!disciplineType;
  const statusList = isDisciplineType
    ? getDiscStatusList(
        mainStatus,
        status,
        object.disciplineStatus,
        isDiscOwner,
        canSetEvaluate
      )
    : getMainStatusList(
        name,
        status,
        object.status,
        topAccessLevel,
        isCreatorOrg,
        showVoid,
        object.infoRevisions,
        isCurrentOrg(recipientId) && !isCreatorUser
      );
  useEffect(() => {
    if (status)
      setExpandState(currState => ({
        ...currState,
        status: status
      }));
  }, [status]);

  const handleExpand = e => {
    if (setHasPopup) setHasPopup(!open);
    setExpandState(currState => ({
      ...currState,
      open: !open,
      anchorEl: e.currentTarget
    }));
  };

  const handleClose = () => {
    if (setHasPopup) setHasPopup(!open);
    setExpandState(currState => ({
      ...currState,
      open: !open,
      anchorEl: null
    }));
  };

  // hasNoEffect can be used by other object types as well with
  // their corresponding metadata that has no effect when status is changed
  // so make sure to add object type checking when calling this fn and addin hasNoEffect
  const checkNextStatus = (nextStatus, hasNoEffect) => {
    let id = '',
      params = {};
    if (nextStatus === STATUS_WITHDRAWN) {
      id = 'object.CONFIRM_STATUS_CHANGE_WITHDRAW';
      params = { name: name };
    } else if (
      nextStatus === STATUS_VOID &&
      valueInArray([OBJECT_DRAW, OBJECT_MISC], name) &&
      revNo > 0
    )
      id = 'object.CONFIRM_STATUS_CHANGE_TO_VOID';
    else if (hasNoEffect && nextStatus === STATUS_SIGNED_AND_CLOSED)
      id = 'object.CONFIRM_STATUS_CHANGE_COST';
    else if (nextStatus === STATUS_FINAL_QUANTITIES_ISSUED)
      id = 'object.BOQ_CONFIRM_FINAL_QUANTITIES';
    else if (nextStatusOutsideOrg(name, status, nextStatus)) {
      id = 'object.CONFIRM_STATUS_CHANGE_OUTSIDE_ORG';
      params = {
        name: valueInArray([OBJECT_DRAW, OBJECT_MISC], name)
          ? OBJECT_DOC
          : name,
        status: intl.formatMessage(
          { id: 'object.' + nextStatus.toUpperCase() },
          { name: recipientName }
        )
      };
    }
    return id ? window.confirm(intl.formatMessage({ id: id }, params)) : true;
  };

  const handleChangeStatus = (e, selected) => {
    let change = true;
    if (setHasPopup) setHasPopup(!open);

    if (isBoQ && selected.name === STATUS_SEND) {
      const delmalebrev = formState[valsKey].metadata?.delmalebrev || [];
      const actualStatus = delmalebrev.some(dm => dm.status !== STATUS_APPROVED)
        ? STATUS_REJECTED
        : state.status === STATUS_UPDATED_QUANTITIES_ISSUED
        ? STATUS_PRELIM_BOQ_APPROVED
        : STATUS_APPROVED_AND_CLOSED;
      selected = statusList.find(s => s.name === actualStatus);
    }

    if (isDisciplineType)
      setFormState(formState => {
        const { metadata, updated_disciplines } = formState[valsKey];
        const updatedDisc = updated_disciplines
          ? updated_disciplines.filter(
              d => d.discipline_type_id !== disciplineType.id
            )
          : [];

        const [orgName, discName] = name.split('_');
        const newDisciplines = metadata.disciplines[orgName].map(disc => {
          if (disc.name === discName) return { ...disc, status: selected.name };
          return disc;
        });

        return {
          ...formState,
          [valsKey]: {
            ...formState[valsKey],
            discipline_status: true,
            updated_disciplines: [
              ...updatedDisc,
              { discipline_type_id: disciplineType.id, status_id: selected.id }
            ],
            metadata: {
              ...metadata,
              disciplines: {
                ...metadata.disciplines,
                [orgName]: newDisciplines
              }
            }
          }
        };
      });
    else if (!isPreview) {
      const params = {
        ...formState,
        [valsKey]: {
          ...formState[valsKey],
          status_id: selected.id,
          main_status: true
        }
      };
      const errors = isDoc
        ? validateDocObject(object.status, params[valsKey])
        : isBoQ
        ? validateBoQObject(object.status, params[valsKey])
        : validateExecObject(object.status, params, name);

      if (isObjectEmpty(errors)) {
        const nextStatus = getStatusById(object.status, selected.id).name;
        change = checkNextStatus(
          nextStatus,
          name === OBJECT_EO && !params[valsKey].metadata.total_cost_nok
        );

        if (change) {
          if (
            isDoc &&
            valueInArray(
              [STATUS_APPROVED_AND_PUBLISHED, STATUS_PUBLISHED],
              nextStatus
            )
          ) {
            const { discipline_type_id, history } = params[valsKey];
            if (
              discipline_type_id &&
              (!history ||
                !valueInArray(
                  [STATUS_HOLD, STATUS_ISSUED_FOR_APPROVAL],
                  history[0].status.name
                ))
            ) {
              params[valsKey].discipline_type_id = null;
              params[valsKey].organization = null;
            }
          } else if (
            isBoQ &&
            valueInArray(
              [STATUS_BOQ_REQUESTED, STATUS_FINAL_QUANTITIES_ISSUED],
              nextStatus
            ) &&
            !isRevision // when status is Approved / Rejected
          ) {
            const prevStatus = object.infoRevisions.slice(-1)[0]?.history[1]
              ?.status?.name;

            if (
              prevStatus &&
              state.status !== STATUS_APPROVED_AND_CLOSED &&
              prevStatus !== STATUS_DRAFT
            ) {
              delete params[valsKey].id; // create new revision object
              params[valsKey].metadata.revision++;
            }
          }
          setFormState(formState => ({
            ...formState,
            [valsKey]: {
              ...formState[valsKey],
              ...params[valsKey]
            }
          }));
          handleSubmit(null, params[valsKey]);
        }
      } else {
        change = window.alert(
          intl.formatMessage({ id: 'object.STATUS_CHANGE_ERROR' })
        );
        setFormState(formState => ({
          ...formState,
          errors: errors,
          statusChange: true
        }));
      }
    } else {
      const params = {
        ...formState,
        ...formState.values,
        status_id: selected.id,
        object_type_id: formState.object_type.id,
        main_status: true
      };

      if (params.object_cache)
        params.lock_version = params.object_cache.lock_version;

      const errors = validateDocObject(object.status, {
        ...params,
        // add here since passing this in objectCreateOrUpdate will return error
        discipline_type_id: formState.organization?.id
      });

      if (isObjectEmpty(errors)) {
        const nextStatus = getStatusById(object.status, selected.id);
        change = checkNextStatus(nextStatus.name);

        if (change) {
          dispatch(objectCreateOrUpdate(params));
          setFormState({ ...params, status: nextStatus, hasError: false });
        }
      } else {
        setFormState(formState => ({ ...formState, hasError: true }));
      }
    }

    if (change)
      setExpandState(currState => ({
        ...currState,
        open: !open,
        anchorEl: null
      }));
    else handleClose();
  };

  const getBoQStatusOptions = (statusList, status) => {
    switch (status) {
      case STATUS_UPDATED_QUANTITIES_ISSUED:
      case STATUS_FINAL_QUANTITIES_ISSUED:
        const list = [{ name: STATUS_SEND }];
        const snu = statusList.find(s => s.name === STATUS_NOT_USED);
        return snu ? list.concat(snu) : list;
      default:
        return statusList;
    }
  };

  const statusListOptions = isBoQ
    ? getBoQStatusOptions(statusList, state.status)
    : statusList;
  const disableExpand =
    disabled ||
    !!isView ||
    (isRevision && isDoc && !isCreatorOrg) ||
    isDisabledStatus(
      status,
      mainStatus,
      name,
      isDisciplineType,
      isCreatorOrg,
      canSetEvaluate
    ) ||
    statusList.length === 0;
  return (
    <>
      <Button
        classes={{
          root: isDisciplineType ? classes.black : classes.white,
          disabled: classes.disabled
        }}
        className={className}
        component="span"
        disabled={disableExpand}
        endIcon={disableExpand ? null : open ? <ExpandLess /> : <ExpandMore />}
        id="status-btn"
        onClick={handleExpand}
        size={isPreview ? 'small' : 'medium'}
        style={btnStyle}>
        <>
          {isView ? `${String.fromCharCode(HEX_CODE_CHECK_MARK)} ` : null}
          <FormattedMessage
            defaultMessage={state.status}
            id={'object.' + state.status.toUpperCase()}
            values={{ name: recipientName }}
          />
        </>
      </Button>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        data-testid="status-popover"
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <MenuList autoFocusItem={open}>
          {statusListOptions.map((s, idx) => (
            <MenuItem
              id={`status-${s.name}`}
              key={idx}
              onClick={e => handleChangeStatus(e, s)}>
              <FormattedMessage
                defaultMessage={s.name}
                id={'object.' + (s.action_name ?? s.name).toUpperCase()}
              />
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </>
  );
};

Status.propTypes = {
  btnStyle: PropTypes.object,
  canSetEvaluate: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  disciplineType: PropTypes.object,
  formState: PropTypes.object,
  handleSubmit: PropTypes.func,
  isBoQ: PropTypes.bool,
  isCreatorOrg: PropTypes.bool,
  isCreatorUser: PropTypes.bool,
  isDiscOwner: PropTypes.bool,
  isDoc: PropTypes.bool,
  isPreview: PropTypes.bool,
  isView: PropTypes.bool,
  mainStatus: PropTypes.string,
  name: PropTypes.string,
  recipientId: PropTypes.string,
  recipientName: PropTypes.string,
  setFormState: PropTypes.func,
  setHasPopup: PropTypes.func,
  showVoid: PropTypes.bool,
  status: PropTypes.string,
  topAccessLevel: PropTypes.string
};

export default Status;
