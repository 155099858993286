import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import { CostTable } from '../../components';
import { DisciplineHeader, WysWyg } from 'views/Object/components';
import { isObjectEmpty } from 'common/helper';
import { sortDisciplinesByOrganization } from 'common/sorting';
import { useStyles } from '../../../styles';
import { BACKGROUND, DISC_BG_LIST, LS_ORGANIZATION_NAME } from 'common/constants';

const Ico = ({
  displayObjectView,
  objectInfo,
  organizationHierarchy,
  organizations
}) => {
  const classes = useStyles();
  const objectIco = objectInfo.metadata;

  let description = '',
    disciplines = [],
    total_cost_nok = 'None',
    total_schedule_impact_days = 'None',
    impact_price_description = [];
  if (!isObjectEmpty(objectIco)) {
    if (objectIco.total_cost_nok) total_cost_nok = objectIco.total_cost_nok;
    if (objectIco.total_schedule_impact_days)
      total_schedule_impact_days = objectIco.total_schedule_impact_days;
    if (objectIco.description) description = objectIco.description;
    if (objectIco.disciplines) disciplines = objectIco.disciplines;
    if (objectIco.impact_price_description)
      impact_price_description =
        objectIco.impact_price_description[localStorage.getItem(LS_ORGANIZATION_NAME)] || [];
  }

  const sortedDisciplines = sortDisciplinesByOrganization(
    disciplines,
    organizationHierarchy
  );

  return (
    <div>
      <Typography component="div">
        <Box
          fontSize="h4.fontSize"
          fontWeight="fontWeightBold"
          m={1}
          textAlign="left">
          {objectInfo.name} {objectInfo.title}
        </Box>
        <Box m={1} textAlign="left">
          <FormattedMessage
            id="object.COMMERCIAL_IMPACT"
            defaultMessage="Commercial Impact"
          />{' '}
          (NOK) : {total_cost_nok}
        </Box>
        <Box lineHeight={0} m={1} textAlign="left">
          <FormattedMessage
            id="object.SCHEDULE_IMPACT"
            defaultMessage="Schedule Impact "
          />
          : {total_schedule_impact_days}
        </Box>

        <Box
          className={classes.paddingTop}
          fontWeight="fontWeightBold"
          m={1}
          textAlign="left">
          <FormattedMessage
            id="common.DESCRIPTION"
            defaultMessage="Description"
          />
          :
        </Box>
      </Typography>
      <WysWyg
        defaultValue={description}
        displayObjectView={displayObjectView}
        name="description"
        readOnly
      />
      {sortedDisciplines.map(([orgName, discs], index) => {
        const bgColor = DISC_BG_LIST[index % 4];
        return (
          <Fragment key={`fr_${orgName}`}>
            <div className={classes.paddingTop}>
              <div className={classes.discOrgLabelContainer}>
                <Typography
                  className={classNames(
                    classes.discOrgLabelContent,
                    classes[BACKGROUND + bgColor]
                  )}>
                  {orgName}
                </Typography>
              </div>
            </div>
            {discs.map(disc => (
              <Card className={classes.marginTop1} key={`${disc.id}`}>
                <DisciplineHeader
                  actionOwner={disc.action_owner}
                  bgColor={bgColor}
                  disciplineType={{
                    name: disc.name,
                    organization_code: disc.org
                  }}
                  organizations={organizations}
                  orgName={orgName}
                  status={disc.status}
                  view
                />
                <CardContent
                  className={classNames(
                    classes.noPaddingCardContent,
                    classes.wyswyg
                  )}>
                  <WysWyg
                    defaultValue={disc.description || ''}
                    displayObjectView={displayObjectView}
                    name={disc.name}
                    readOnly
                  />
                </CardContent>
              </Card>
            ))}
          </Fragment>
        );
      })}
      {objectIco.hasCostEffects ? (
        <CostTable data={impact_price_description} />
      ) : null}
    </div>
  );
};

Ico.propTypes = {
  displayObjectView: PropTypes.func,
  objectInfo: PropTypes.object,
  organizationHierarchy: PropTypes.array,
  organizations: PropTypes.array
};

export default Ico;
