import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';

import theme from './theme';
import validators from './common/validators';
import Routes from './Routes';

import { ThemeProvider as LanguageProvider } from 'context/LanguageContext';
import MsalContextProvider from 'context/MsalContext';

const browserHistory = createBrowserHistory();

validate.validators = {
  ...validate.validators,
  ...validators
};

const App = () => {
  return (
    <MsalContextProvider>
      <LanguageProvider>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <Router history={browserHistory}>
              <Routes />
            </Router>
          </ThemeProvider>
        </Provider>
      </LanguageProvider>
    </MsalContextProvider>
  );
};
export default App;
