import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { People as PeopleIcon } from '@material-ui/icons';
import { formatDate } from 'common/helper';
import { useProjectStyles } from '../../styles';

const ProjectTable = ({ goToObjects, handleClick, projects }) => {
  const classes = useProjectStyles();

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table
        className={classes.table}
        id="project-table"
        name="project-table"
        size="small"
        stickyHeader>
        <colgroup>
          <col width="30%" />
          <col width="25%" />
          <col width="25%" />
          <col width="20%" />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell align="left">
              <FormattedMessage defaultMessage="Name" id="common.NAME" />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage defaultMessage="Created" id="project.CREATED" />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage
                defaultMessage="Creator"
                id="common.PRIMARY_CONTACT"
              />
            </TableCell>
            <TableCell align="left">
              <FormattedMessage defaultMessage="Members" id="project.MEMBERS" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projects.map(row => {
            return (
              <TableRow
                hover
                id={`project-${row.name}-btn`}
                key={row.id}
                onClick={event => goToObjects(event, row.id)}
                role="checkbox"
                tabIndex={-1}>
                <TableCell align="left" component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="left">{formatDate(row.created_at)}</TableCell>
                <TableCell align="left">{row.primary_contact}</TableCell>
                <TableCell align="left">
                  <Button
                    className={classes.disableHoverEffect}
                    onClick={event => handleClick(event, 'members', row.id)}>
                    <Typography
                      className={classes.membersIconWithLabel}
                      color="primary"
                      display="inline"
                      variant="h4">
                      {row.team}
                      <PeopleIcon className={classes.peopleIcon} />
                    </Typography>
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ProjectTable.propTypes = {
  goToObjects: PropTypes.func,
  handleClick: PropTypes.func,
  projects: PropTypes.array
};

export default ProjectTable;
