import ForgeApi from './ForgeApi';
import * as forgeTypes from './ForgeTypes';

const tokenRequest = () => {
  return { type: forgeTypes.TOKEN_REQUEST };
};

const tokenSuccess = credentials => {
  return { type: forgeTypes.TOKEN_SUCCESS, payload: credentials };
};

const tokenFailed = err => {
  return { type: forgeTypes.TOKEN_FAILURE, payload: err };
};

export const getToken = () => {
  return dispatch => {
    dispatch(tokenRequest());
    const api = new ForgeApi();
    api
      .generate()
      .then(response => {
        dispatch(tokenSuccess(response.data.body));
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(tokenFailed(errorMsg));
      });
  };
};
