import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Popover,
  TextField,
  Typography
} from '@material-ui/core';
import { Clear as ClearIcon, ExpandLess, ExpandMore } from '@material-ui/icons';
import { Status } from '../../components';
import { valueInArray } from 'common/helper';
import { useStyles } from '../../styles';
import classNames from 'classnames';
import {
  ACTION_OWNER,
  ACTION_OWNER_LABEL,
  AT_SIGN,
  BACKGROUND,
  BORDER_GRADIENT,
  DEFAULT_BG_COLOR,
  POPOVER_ACTION_OWNER,
  STATUS_DRAFT,
  STATUS_ISSUED_FOR_APPROVAL,
  STATUS_APPROVED_BY_DL,
  UNDERSCORE
} from 'common/constants';

const DisciplineHeader = ({
  actionOwner,
  bgColor,
  canSetEvaluate,
  disableActionOwner,
  disableStatus,
  disciplineType,
  handleDisciplineChange,
  handleDisciplines,
  isDiscOwner,
  mainStatus,
  organizations,
  orgName,
  setFormState,
  setHasPopup,
  status,
  view
}) => {
  const classes = useStyles();
  const disciplineName = disciplineType?.name || '';
  const orgUsers = organizations.find(org => org.name === orgName)?.users || [];
  const ao_defVal = actionOwner
    ? orgUsers.find(ou => ou.id === actionOwner)
    : '';

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = event => {
    setAnchorEl(event.currentTarget);
    if (setHasPopup) setHasPopup(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (setHasPopup) setHasPopup(false);
  };

  const handleAoChange = (event, value) => {
    event.persist();
    handleDisciplineChange(
      orgName + UNDERSCORE + disciplineName,
      value?.id || '',
      ACTION_OWNER
    );
  };

  return (
    <Grid container>
      <Grid item md={12} xs={12}>
        <Box
          alignItems="center"
          className={classes[BACKGROUND + (bgColor || DEFAULT_BG_COLOR)]}
          display="flex"
          justifyContent="space-between">
          <Typography className={classes.disciplineHeader} noWrap>
            {disciplineName}
          </Typography>
          {view ||
          !valueInArray(
            [STATUS_APPROVED_BY_DL, STATUS_DRAFT, STATUS_ISSUED_FOR_APPROVAL],
            mainStatus
          ) ? null : (
            <IconButton
              className={classNames(classes.disableHoverEffect, classes.white)}
              onClick={event =>
                handleDisciplines(event, disciplineType, orgName)
              }
              size="small">
              <ClearIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      </Grid>
      <Grid className={classes.grayContent} item md={6} xs={12}>
        {view ? (
          <Typography className={classes.padding1}>
            {ao_defVal?.name || ao_defVal?.email || '-'}
          </Typography>
        ) : (
          <>
            <Button
              classes={{ root: classes.black, disabled: classes.disabled }}
              className={classes.justifyStart}
              component="span"
              disabled={disableActionOwner}
              endIcon={
                disableActionOwner ? null : open ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )
              }
              fullWidth
              onClick={handleOpen}>
              <Typography className={classes.disciplineOwner} variant="body1">
                {ao_defVal?.name ||
                  ao_defVal?.email?.split(AT_SIGN)[0] ||
                  ACTION_OWNER_LABEL}
              </Typography>
            </Button>
            <Popover
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              className={classes.popover}
              id={POPOVER_ACTION_OWNER}
              onClose={handleClose}
              open={open}
              PaperProps={{
                style: { width: '10%' }
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}>
              <Autocomplete
                filterSelectedOptions
                getOptionLabel={option => option.name || option.email}
                id={ACTION_OWNER}
                name={ACTION_OWNER}
                onChange={handleAoChange}
                options={orgUsers}
                renderInput={params => <TextField {...params} fullWidth />}
                value={ao_defVal || null}
              />
            </Popover>
          </>
        )}
      </Grid>
      <Grid item md={6} xs={12}>
        <Status
          canSetEvaluate={canSetEvaluate}
          className={classNames(
            classes.borderGradient,
            classes.justifyEnd,
            classes[BORDER_GRADIENT + (bgColor || DEFAULT_BG_COLOR)]
          )}
          disabled={disableStatus}
          disciplineType={disciplineType}
          isDiscOwner={isDiscOwner}
          isView={view}
          mainStatus={mainStatus}
          name={`${orgName}_${disciplineName}`}
          setFormState={setFormState}
          status={status}
        />
      </Grid>
    </Grid>
  );
};

DisciplineHeader.propTypes = {
  actionOwner: PropTypes.string,
  bgColor: PropTypes.string,
  canSetEvaluate: PropTypes.bool,
  disableActionOwner: PropTypes.bool,
  disableStatus: PropTypes.bool,
  disciplineType: PropTypes.object,
  handleDisciplineChange: PropTypes.func,
  handleDisciplines: PropTypes.func,
  isDiscOwner: PropTypes.bool,
  mainStatus: PropTypes.string,
  organizations: PropTypes.array,
  orgName: PropTypes.string,
  setFormState: PropTypes.func,
  setHasPopup: PropTypes.func,
  status: PropTypes.string.isRequired,
  view: PropTypes.bool
};

export default DisciplineHeader;
