import Request from '../../common/request';
import {
  ACTION_DELETE,
  ACTION_GET,
  ACTION_POST,
  ACTION_PUT
} from 'common/constants';

export default class ProjectApi extends Request {
  constructor(token, url) {
    super(url, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    this.endpoint = '/projects';
  }

  all(id) {
    const url = `${this.endpoint}`.concat(id ? `?organization_id=${id}` : '');
    return super.get(url);
  }

  info(id, params) {
    return super.get(`${this.endpoint}/${id}`, params);
  }

  create(params) {
    return super.post(this.endpoint, params);
  }

  update(id, params) {
    return super.put(`${this.endpoint}/${id}`, params);
  }

  delete(id, params) {
    const url = `${this.endpoint}/${id}`.concat(params ? `?${params}` : '');
    return super.delete(url);
  }

  invite(id, params) {
    return super.post(`${this.endpoint}/${id}/invite`, params);
  }

  billOfQuantity(method, id, params, boq_id) {
    const url = `${id}/bill_of_quantities`.concat(boq_id ? `/${boq_id}` : '');

    switch (method) {
      case ACTION_DELETE:
        return this.delete(url);
      case ACTION_GET:
        return this.info(url);
      case ACTION_POST:
        return super.post(`${this.endpoint}/${url}`, params);
      case ACTION_PUT:
        return super.put(`${this.endpoint}/${url}`, params);
      default:
        return new Promise(() => {
          throw new Error('UNSUPPORTED');
        });
    }
  }
}
