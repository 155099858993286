import React from 'react';
import * as documentTypes from './DocumentTypes';
import Str8line from '../auth/AuthApi';
import { FormattedMessage } from 'react-intl';
import {
  API_RESPONSE_CATEGORY_IS_IN_USE,
  CATEGORIES,
  CATEGORY_TEMPLATES,
  LS_STR8LINE_TOKEN,
  SELECTED_CATEGORY_TEMPLATE
} from 'common/constants';

const documentRequest = () => {
  return { type: documentTypes.DOCUMENT_REQUEST };
};

const documentFailure = error => {
  return { type: documentTypes.DOCUMENT_FAILURE, payload: error };
};

const documentSuccess = (route, data) => {
  return { type: documentTypes.DOCUMENT_SUCCESS, payload: data, route: route };
};

export const documentFetch = (route, params) => {
  return dispatch => {
    dispatch(documentRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.document
      .all(route, params)
      .then(response => {
        dispatch(documentSuccess(route, response.data.data));
      })
      .catch(error => {
        dispatch(documentFailure(error.message));
      });
  };
};

export const fetchProjectCategories = (route, id) => {
  return dispatch => {
    dispatch(documentRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.document
      .getCategories(route, id)
      .then(response => {
        dispatch(documentSuccess(route, response.data.data));
      })
      .catch(error => {
        dispatch(documentFailure(error.message));
      });
  };
};

const categoryRequest = () => {
  return { type: documentTypes.CATEGORY_REQUEST };
};

const categoryFailure = error => {
  return { type: documentTypes.CATEGORY_FAILURE, payload: error };
};

const categorySuccess = (key, data) => {
  return { type: documentTypes.CATEGORY_SUCCESS, payload: data, key: key };
};

export const createOrUpdateDocumentCategory = (route, key, params) => {
  return dispatch => {
    dispatch(categoryRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    const promise = params.id
      ? api.document.update(route, params.id, params)
      : api.document.create(route, params);
    promise
      .then(response => {
        dispatch(categorySuccess(key, response.data.data));
      })
      .catch(error => {
        dispatch(categoryFailure(error.message));
      });
  };
};

const categoryDeleteSuccess = () => {
  return { type: documentTypes.CATEGORY_DELETE_SUCCESS };
};
export const deleteDocumentCategory = (route, id) => {
  return dispatch => {
    dispatch(categoryRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.document
      .delete(route, id)
      .then(() => {
        dispatch(categoryDeleteSuccess());
      })
      .catch(error => {
        const errorMsg =
          error?.response?.data?.error === API_RESPONSE_CATEGORY_IS_IN_USE ? (
            <FormattedMessage
              defaultMessage="Category is in use and cannot be deleted."
              id="error.CATEGORY_IN_USE"
            />
          ) : (
            error.message
          );
        dispatch(categoryFailure(errorMsg));
      });
  };
};

const categoryTemplateRequest = () => {
  return { type: documentTypes.CATEGORY_TEMPLATE_REQUEST };
};
const categoryTemplateFailure = error => {
  return { type: documentTypes.CATEGORY_TEMPLATE_FAILURE, payload: error };
};
const categoryTemplateSuccess = (data, field) => {
  return {
    type: documentTypes.CATEGORY_TEMPLATE_SUCCESS,
    payload: data,
    field: field
  };
};
const categoryTemplateUpdateSuccess = data => {
  return {
    type: documentTypes.CATEGORY_TEMPLATE_UPDATE_SUCCESS,
    payload: data
  };
};

export const orgCategoryTemplatesFetch = orgId => {
  return dispatch => {
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.document
      .getOrgCategoryTemplates(orgId)
      .then(response => {
        dispatch(
          categoryTemplateSuccess(response.data.data, CATEGORY_TEMPLATES)
        );
      })
      .catch(error => {
        dispatch(categoryTemplateFailure(error.message));
      });
  };
};

export const categoryTemplateFetch = id => {
  return dispatch => {
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.document
      .getCategoryTemplate(id)
      .then(response => {
        dispatch(
          categoryTemplateSuccess(
            response.data.data,
            SELECTED_CATEGORY_TEMPLATE
          )
        );
      })
      .catch(error => {
        dispatch(categoryTemplateFailure(error.message));
      });
  };
};

export const categoryTemplateCreateOrUpdate = (params, id) => {
  return dispatch => {
    dispatch(categoryTemplateRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    const promise = id
      ? api.document.updateCategoryTemplate(id, params)
      : api.document.createCategoryTemplate(params);
    promise
      .then(() => {
        dispatch(categoryTemplateUpdateSuccess());
      })
      .catch(error => {
        dispatch(categoryTemplateFailure(error.message));
      });
  };
};

export const categoryTemplateDelete = id => {
  return dispatch => {
    dispatch(categoryTemplateRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.document
      .deleteCategoryTemplate(id)
      .then(() => {
        dispatch(categoryTemplateUpdateSuccess());
      })
      .catch(error => {
        dispatch(categoryTemplateFailure(error.message));
      });
  };
};

export const templateCategoryDelete = id => {
  return dispatch => {
    dispatch(categoryTemplateRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.document
      .delete(CATEGORIES, id)
      .then(() => {
        dispatch(categoryTemplateUpdateSuccess());
      })
      .catch(error => {
        dispatch(categoryTemplateFailure(error.message));
      });
  };
};

export const clearAddedFields = () => {
  return { type: documentTypes.CLEAR_ADDED_FIELDS };
};

export const clearDocumentMessages = () => {
  return { type: documentTypes.DOCUMENT_CLEAR_MESSAGES };
};
