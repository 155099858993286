import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Autocomplete } from '@material-ui/lab';
import { FormattedMessage } from 'react-intl';
import { Delete as DeleteIcon } from '@material-ui/icons';
import {
  Box,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { actionOnS3File } from 'common/ursa';
import { getFilenameWithoutExt, getFilenameWithoutID } from 'common/helper';
import { getNextObjectRevision, getPreviousObjectRevision } from '../helper';
import { useStyles } from '../../styles';
import classNames from 'classnames';
import {
  ACTION_DELETE,
  HYPHEN,
  LS_ORGANIZATION_ID,
  OBJECT
} from 'common/constants';

const RevisionList = props => {
  const classes = useStyles();
  const {
    attachments,
    error,
    onPopupClose,
    setFormState,
    status,
    verifiedSubmit
  } = props;

  const updateAttachments = (event, value, index, field) => {
    event.persist();
    event.stopPropagation();
    setFormState(formState => {
      const newAtt = formState.values.attachments;
      if (field === OBJECT) {
        if (value)
          newAtt[index] = {
            ...newAtt[index],
            name: value.name,
            revisionObj: value,
            title: value.title,
            metadata: {
              description: value.description,
              prev_version_object_id: value.id,
              revised_by_org_id: localStorage.getItem(LS_ORGANIZATION_ID),
              revision: getNextObjectRevision(value.revision)
            }
          };
      } else {
        const checked = event.target.checked;
        newAtt[index] = {
          ...newAtt[index],
          isRevision: checked,
          title: getFilenameWithoutExt(
            getFilenameWithoutID(newAtt[index].file)
          ),
          metadata: {
            ...newAtt[index],
            revision: checked
              ? getNextObjectRevision(newAtt[index].metadata.revision)
              : getPreviousObjectRevision(newAtt[index].metadata.revision),
            description: checked
              ? newAtt[index].duplicates[0].description
              : null
          }
        };
      }
      return {
        ...formState,
        values: { ...formState.values, attachments: newAtt }
      };
    });
  };

  const handleDelete = (event, index) => {
    actionOnS3File(ACTION_DELETE, attachments[index].file);
    if (attachments.length === 1) onPopupClose();
    else {
      let submit = false;
      setFormState(formState => {
        const attachments = formState.values.attachments;
        attachments.splice(index, 1);
        if (!attachments.some(att => !!att.revisionObj)) submit = true;
        return {
          ...formState,
          values: {
            ...formState.values,
            attachments: attachments
          }
        };
      });
      if (submit) verifiedSubmit(status);
    }
  };

  return (
    <div className={classes.paddingTop2}>
      {error ? (
        <Alert className={classes.marginBottom1} severity="error">
          {error}
        </Alert>
      ) : null}
      <Typography>
        <FormattedMessage
          defaultMessage={
            'The following files already exist in this category. ' +
            'Uncheck the box if you want the file to be saved as a separate object. ' +
            'Otherwise, it will saved as a revision. ' +
            'You can also select which object it is a revision of, if there is more than one option.'
          }
          id="object.DUPLICATE_UPLOAD_MESSAGE"
        />
      </Typography>
      <TableContainer>
        <Table>
          <colgroup>
            <col />
            <col width="50%" />
            <col width="50%" />
          </colgroup>
          <TableBody>
            {attachments.map((attachment, index) => {
              const { duplicates, file, isRevision, revisionObj } = attachment;
              return revisionObj ? (
                <TableRow key={`attachment_${index}`}>
                  <TableCell
                    align="left"
                    className={classes.tableNoBorder}
                    padding="checkbox">
                    <Checkbox
                      checked={isRevision}
                      onClick={e => updateAttachments(e, null, index)}
                    />
                  </TableCell>
                  <TableCell align="left" className={classes.tableNoBorder}>
                    <Box alignItems="center" display="flex">
                      {getFilenameWithoutID(file)}
                      <IconButton
                        onClick={e => handleDelete(e, index)}
                        size="small">
                        <DeleteIcon
                          className={classes.delItemIcon}
                          fontSize="small"
                        />
                      </IconButton>
                    </Box>
                  </TableCell>
                  {isRevision ? (
                    <TableCell
                      align="left"
                      className={classNames(
                        classes.noRightPadding,
                        classes.tableNoBorder
                      )}>
                      <Autocomplete
                        disableClearable
                        getOptionLabel={option => {
                          return option.name
                            .substring(option.name.lastIndexOf(HYPHEN) + 1)
                            .concat(` ${getFilenameWithoutExt(option.file)}`);
                        }}
                        onChange={(e, v) =>
                          updateAttachments(e, v, index, OBJECT)
                        }
                        options={duplicates}
                        renderInput={params => (
                          <TextField
                            {...params}
                            fullWidth
                            label={
                              <FormattedMessage
                                defaultMessage="Object"
                                id="common.OBJECT"
                              />
                            }
                            size="small"
                            variant="outlined"
                          />
                        )}
                        size="small"
                        value={revisionObj}
                      />
                    </TableCell>
                  ) : null}
                </TableRow>
              ) : null;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

RevisionList.propTypes = {
  attachments: PropTypes.array,
  error: PropTypes.string,
  onPopupClose: PropTypes.func.isRequired,
  setFormState: PropTypes.func,
  status: PropTypes.string,
  verifiedSubmit: PropTypes.func
};
RevisionList.defaultProps = { attachments: [] };

export default RevisionList;
