import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  Clear as ClearIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  RemoveCircle as RemoveCircleIcon
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import validate from 'validate.js';
import { hasError, isMobileDevice } from 'common/helper';
import { orgMeetingAgendaDelete } from 'redux/organization/OrganizationAction';
import { agendaTemplateSchema } from '../../../validations/schema';
import { useStyles } from '../../../styles';

import {
  AGENDA,
  DEFAULT,
  RANK,
  SMALL,
  TITLE,
  WIDTH_XS
} from 'common/constants';

const MeetingAgendaItem = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { screenWidth } = useSelector(state => state.screen);
  const {
    draggableProvided,
    admin,
    agendas,
    agenda,
    indexes,
    prevAgenda,
    templateIndex,
    updateTemplateValues
  } = props;

  const { edit, errors, rank, title } = agenda;
  const adminEdit = admin && edit;
  const isMobile = isMobileDevice();
  const iconFontSize = screenWidth === WIDTH_XS || isMobile ? SMALL : DEFAULT;

  const handleEditAgenda = (event, value) => {
    agenda.edit = value;
    if (!value) {
      agenda.rank = prevAgenda.rank;
      agenda.title = prevAgenda.title;
      agenda.touched = false;
    }
    agenda.errors = validate(agenda, agendaTemplateSchema) || {};
    updateTemplateValues(event, templateIndex, AGENDA, agendas);
  };

  const handleDelete = event => {
    const del = admin
      ? window.confirm(
          intl.formatMessage(
            { id: 'object.DELETE_ITEM_CONFIRMATION' },
            {
              name: intl.formatMessage({ id: 'object.AGENDA' }).toLowerCase()
            }
          ) +
            '\n' +
            intl.formatMessage({ id: 'common.ACTION_CANNOT_UNDONE' })
        )
      : true;
    if (del) {
      if (admin && agenda.id) {
        dispatch(orgMeetingAgendaDelete(agenda.id));
      } else {
        const delIndex = indexes.pop();
        if (indexes.length === 0) agendas.splice(delIndex, 1);
        updateTemplateValues(event, templateIndex, AGENDA, agendas, {
          hasDeleted: true,
          updatePrev: true
        });
      }
    }
  };

  const updateAgendaValues = (event, field) => {
    event.persist();
    const value = event.target.value;
    agenda.touched = true;
    switch (field) {
      case TITLE:
        agenda.title = value;
        agenda.errors = validate(agenda, agendaTemplateSchema) || {};
        break;
      case RANK:
        agenda.rank = value;
        break;
      default:
        break;
    }
    updateTemplateValues(event, templateIndex, AGENDA, agendas);
  };

  const NoWrapTooltip = withStyles({
    tooltip: {
      maxWidth: 'none',
      whiteSpace: 'nowrap'
    }
  })(Tooltip);

  return (
    <TableRow
      className={classes.categoryRowHover}
      hover
      ref={draggableProvided.innerRef}
      {...draggableProvided.draggableProps}
      {...draggableProvided.dragHandleProps}>
      <TableCell style={{ width: '10%' }}>
        <Box alignItems="center" justifyContent="space-between" display="flex">
          <NoWrapTooltip title={rank}>
            <Typography noWrap>{rank}</Typography>
          </NoWrapTooltip>
        </Box>
      </TableCell>
      <TableCell style={{ width: '70%' }}>
        {adminEdit ? (
          <TextField
            error={hasError(errors, TITLE)}
            fullWidth
            helperText={hasError(errors, TITLE) ? errors.title[0] : null}
            multiline
            onChange={e => updateAgendaValues(e, TITLE)}
            onClick={e => e.stopPropagation()}
            size="small"
            value={title || ''}
            variant="outlined"
          />
        ) : (
          <NoWrapTooltip title={title}>
            <Typography noWrap>{title}</Typography>
          </NoWrapTooltip>
        )}
      </TableCell>
      <TableCell
        align="right"
        style={
          screenWidth === WIDTH_XS
            ? { minWidth: '80px' }
            : {
                width:
                  '2000px' /* setting width to be larger than what it would be calculated in the browser to maintain row width when dragging */
              }
        }>
        <IconButton onClick={e => handleEditAgenda(e, !edit)} size="small">
          {edit ? (
            <ClearIcon fontSize={iconFontSize} />
          ) : (
            <EditIcon fontSize={iconFontSize} />
          )}
        </IconButton>
        <IconButton onClick={handleDelete} size="small">
          {admin ? (
            <DeleteIcon
              className={classes.deleteIcon}
              fontSize={iconFontSize}
            />
          ) : (
            <RemoveCircleIcon
              className={classes.deleteIcon}
              fontSize={iconFontSize}
            />
          )}
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

MeetingAgendaItem.propTypes = {
  draggableProvided: PropTypes.object,
  admin: PropTypes.bool,
  agendas: PropTypes.array,
  agenda: PropTypes.object,
  defaultAgenda: PropTypes.object,
  indexes: PropTypes.array,
  prevAgenda: PropTypes.object,
  templateIndex: PropTypes.number,
  updateTemplateValues: PropTypes.func
};

MeetingAgendaItem.defaultProps = { admin: false };

export default MeetingAgendaItem;
