import BrregApi from './BrregApi';
import * as brregTypes from './BrregTypes';
import { serializeParams } from 'common/helper';

const brregSuccess = (endpoint, response) => {
  const { _embedded: embedded, page } = response;
  const address = {
    enheter: 'forretningsadresse',
    underenheter: 'beliggenhetsadresse'
  };

  return {
    type: brregTypes.BRREG_SUCCESS,
    payload: {
      organizations: embedded[endpoint].map(org => ({
        name: org.navn,
        org_num: org.organisasjonsnummer,
        business_address: {
          ...org[address[endpoint]]
        },
        ...(org.postadresse && {
          postal_address: {
            ...org.postadresse
          }
        })
      })),
      page
    }
  };
};

export const brregFetchFailure = error => {
  return { type: brregTypes.BRREG_FAILURE, payload: error };
};

export const brregRequest = () => {
  return { type: brregTypes.BRREG_REQUEST };
};

export const brregUnrequest = () => {
  return { type: brregTypes.BRREG_UNREQUEST };
};

export const brregFetch = params => {
  return dispatch => {
    const opts = {
      default: 'enheter',
      subunit: 'underenheter'
    };
    const endpoint = opts[params.endpoint || 'default'];
    const api = new BrregApi();

    dispatch(brregRequest());
    api
      .fetch(serializeParams(params))
      .then(response => dispatch(brregSuccess(endpoint, response.data)))
      .catch(error => {
        const errorMsg = error.message;
        dispatch(brregFetchFailure(errorMsg));
      });
  };
};
