export const ORGANIZATION_REQUEST = 'ORGANIZATION_REQUEST';
export const ORGANIZATION_FAILURE = 'ORGANIZATION_FAILURE';
export const ORGANIZATION_SUCCESS = 'ORGANIZATION_SUCCESS';
export const ORGANIZATION_ALL_SUCCESS = 'ORGANIZATION_ALL_SUCCESS';
export const ORGANIZATION_CREATE_SUCCESS = 'ORGANIZATION_CREATE_SUCCESS';
export const ORGANIZATION_CREATE_FAILURE = 'ORGANIZATION_CREATE_FAILURE';
export const ORGANIZATION_UPDATE_SUCCESS = 'ORGANIZATION_UPDATE_SUCCESS';
export const ORGANIZATION_UPDATE_FAILURE = 'ORGANIZATION_UPDATE_FAILURE';
export const ORGANIZATION_DELETE_SUCCESS = 'ORGANIZATION_DELETE_SUCCESS';
export const ORGANIZATION_DELETE_FAILURE = 'ORGANIZATION_DELETE_FAILURE';
export const ORGANIZATION_INVITE_SUCCESS = 'ORGANIZATION_INVITE_SUCCESS';
export const ORGANIZATION_INVITE_FAILURE = 'ORGANIZATION_INVITE_FAILURE';
export const ORGANIZATION_TYPE_LOADING = 'ORGANIZATION_TYPE_LOADING';
export const ORGANIZATION_TYPE_SUCCESS = 'ORGANIZATION_TYPE_SUCCESS';
export const ORGANIZATION_TYPE_FAILURE = 'ORGANIZATION_TYPE_FAILURE';
export const ORGANIZATION_USER_DELETE_LOADING =
  'ORGANIZATION_USER_DELETE_LOADING';
export const ORGANIZATION_USER_DELETE_SUCCESS =
  'ORGANIZATION_USER_DELETE_SUCCESS';
export const ORGANIZATION_USER_DELETE_FAILURE =
  'ORGANIZATION_USER_DELETE_FAILURE';
export const ORGANIZATION_USER_UPDATE_SUCCESS =
  'ORGANIZATION_USER_UPDATE_SUCCESS';
export const ORGANIZATION_USER_UPDATE_FAILURE =
  'ORGANIZATION_USER_UPDATE_FAILURE';
export const ORGANIZATION_USER_UPDATE_LOADING =
  'ORGANIZATION_USER_UPDATE_LOADING';
export const ORGANIZATION_PROJECT_UPDATE_SUCCESS =
  'ORGANIZATION_PROJECT_UPDATE_SUCCESS';
export const ORGANIZATION_PROJECT_UPDATE_FAILURE =
  'ORGANIZATION_PROJECT_UPDATE_FAILURE';
export const ORGANIZATION_PROJECT_INVITE_SUCCESS =
  'ORGANIZATION_PROJECT_INVITE_SUCCESS';
export const ORGANIZATION_PROJECT_INVITE_FAILURE =
  'ORGANIZATION_PROJECT_INVITE_FAILURE';
export const ORGANIZATION_DISC_CREATE_SUCCESS =
  'ORGANIZATION_DISC_CREATE_SUCCESS';
export const ORGANIZATION_DISC_CREATE_FAILURE =
  'ORGANIZATION_DISC_CREATE_FAILURE';
export const ORGANIZATION_DISC_UPDATE_SUCCESS =
  'ORGANIZATION_DISC_DELETE_SUCCESS';
export const ORGANIZATION_DISC_UPDATE_FAILURE =
  'ORGANIZATION_DISC_DELETE_FAILURE';
export const ORGANIZATION_MEETING_TEMPLATE_REQUEST =
  'ORGANIZATION_MEETING_TEMPLATE_REQUEST';
export const ORGANIZATION_MEETING_TEMPLATE_FAILURE =
  'ORGANIZATION_MEETING_TEMPLATE_FAILURE';
export const ORGANIZATION_MEETING_TEMPLATE_SUCCESS =
  'ORGANIZATION_MEETING_TEMPLATE_SUCCESS';
export const ORGANIZATION_MEETING_TEMPLATE_SUBMIT_SUCCESS =
  'ORGANIZATION_MEETING_TEMPLATE_SUBMIT_SUCCESS';
export const ORGANIZATION_INITIAL_STATE = 'ORGANIZATION_INITIAL_STATE';
export const ORGANIZATION_CLEAR_MESSAGES = 'ORGANIZATION_CLEAR_MESSAGES';
