import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, Grid, Typography } from '@material-ui/core';
import DtTable from './DtTable';
import { organizationFetch } from 'redux/organization/OrganizationAction';
import { categorizeDiscTypes } from 'common/helper';
import { useStyles } from '../../../styles';
import { ARCHIVED, NOT_ARCHIVED } from 'common/constants';

const DisciplineTypes = ({ organizationId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const orgData = useSelector(state => state.organization);
  const org = orgData.organizations.find(org => org.id === organizationId);

  const [state, setState] = useState({
    types: categorizeDiscTypes(org.discipline_types),
    selected: { archived: [], notArchived: [] },
    showArchived: false
  });

  useEffect(() => {
    setState(state => ({
      ...state,
      types: categorizeDiscTypes(org.discipline_types)
    }));
  }, [org]);

  useEffect(() => {
    if (orgData.discUpdated && !orgData.discUpdated.error)
      dispatch(organizationFetch());
  }, [orgData.discUpdated, dispatch]);

  return (
    <div className={classes.userRoot}>
      <Grid container spacing={3}>
        <DtTable
          discAdded={org.discAdded}
          field={NOT_ARCHIVED}
          orgData={orgData}
          orgId={org.id}
          state={state}
          setState={setState}
        />
        {state.types.archived.length > 0 ? (
          <>
            <Grid item xs={12}>
              <Button
                className={classes.floatRight}
                data-testid="button-show-archive"
                color="primary"
                onClick={() =>
                  setState({ ...state, showArchived: !state.showArchived })
                }
                variant="contained">
                <Typography className={classes.buttonLabel} variant="body1">
                  {state.showArchived ? (
                    <FormattedMessage
                      defaultMessage="Hide archived disciplines"
                      id="admin.HIDE_ARCHIVED_DISCIPLINES"
                    />
                  ) : (
                    <FormattedMessage
                      defaultMessage="Show archived disciplines"
                      id="admin.SHOW_ARCHIVED_DISCIPLINES"
                    />
                  )}
                </Typography>
              </Button>
            </Grid>
            {state.showArchived ? (
              <DtTable
                field={ARCHIVED}
                orgData={orgData}
                orgId={org.id}
                state={state}
                setState={setState}
              />
            ) : null}
          </>
        ) : null}
      </Grid>
    </div>
  );
};

DisciplineTypes.propTypes = {
  organizationId: PropTypes.string
};

export default DisciplineTypes;
