export const USER_REQUEST = 'USER_REQUEST';
export const USER_FAILURE = 'USER_FAILURE';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_OTP_SUCCESS = 'USER_OTP_SUCCESS';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_REFRESH = 'USER_REFRESH';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_ROLE_SUCCESS = 'USER_ROLE_SUCCESS';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_INVITE_SUCCESS = 'USER_INVITE_SUCCESS';
export const USER_INVITE_FAILURE = 'USER_INVITE_FAILURE';
export const USER_CONFIGURATION_CREATE_SUCCESS =
  'USER_CONFIGURATION_CREATE_SUCCESS';
export const USER_CONFIGURATION_FETCH_SUCCESS =
  'USER_CONFIGURATION_FETCH_SUCCESS';
export const USER_CONFIGURATION_UPDATE_SUCCESS =
  'USER_CONFIGURATION_UPDATE_SUCCESS';
export const USER_SETTING_FETCH_SUCCESS = 'USER_SETTING_FETCH_SUCCESS';
export const USER_SETTING_UPDATE_SUCCESS = 'USER_SETTING_UPDATE_SUCCESS';
