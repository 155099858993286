import React from 'react';
import * as objTypes from './ObjectTypes';
import Str8line from '../auth/AuthApi';
import { FormattedMessage } from 'react-intl';
import {
  API_RESPONSE_ACTIVE_MEETING_TEMPLATE,
  API_RESPONSE_NO_ACCESS_CHANGE_CATEGORY,
  API_RESPONSE_NO_ACCESS_MEETING_TEMPLATE,
  BOQ,
  DISCIPLINE,
  LS_STR8LINE_TOKEN,
  MENTION,
  OBJECTS,
  TEMPLATE
} from 'common/constants';
import axios from 'axios';

let source;

const objectRequest = () => {
  return { type: objTypes.OBJECT_REQUEST };
};

const objectFailure = error => {
  return { type: objTypes.OBJECT_FAILURE, payload: error };
};

const objectConflict = updatedObject => {
  return { type: objTypes.OBJECT_CONFLICT, payload: updatedObject };
};

const objectTypeSuccess = types => {
  return { type: objTypes.OBJECT_TYPE_SUCCESS, payload: types };
};
export const objectTypeFetch = () => {
  return dispatch => {
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.object
      .types()
      .then(response => {
        dispatch(objectTypeSuccess(response.data.data));
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(objectFailure(errorMsg));
      });
  };
};

const objectTypeFilterSuccess = types => {
  return { type: objTypes.OBJECT_TYPE_FILTER_SUCCESS, payload: types };
};
export const objectTypeFilterFetch = params => {
  return dispatch => {
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.object
      .filter(params)
      .then(response => {
        dispatch(objectTypeFilterSuccess(response.data.data));
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(objectFailure(errorMsg));
      });
  };
};

const disciplineTypeSuccess = types => {
  return { type: objTypes.DISCIPLINE_TYPE_SUCCESS, payload: types };
};
export const disciplineTypeFetch = id => {
  return dispatch => {
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.object
      .disciplineTypes(id)
      .then(response => {
        dispatch(disciplineTypeSuccess(response.data.data));
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(objectFailure(errorMsg));
      });
  };
};

const statusSuccess = (type, status, isAll) => {
  const objType =
    type === DISCIPLINE
      ? objTypes.DISCIPLINE_STATUS_SUCCESS
      : isAll
      ? objTypes.OBJECT_STATUS_ALL_SUCCESS
      : objTypes.OBJECT_STATUS_SUCCESS;
  return { type: objType, payload: status };
};

export const objectStatusFetch = data => {
  return dispatch => {
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.object
      .getStatus(data.params)
      .then(response => {
        dispatch(statusSuccess(data.type, response.data.data, !data.params));
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(objectFailure(errorMsg));
      });
  };
};

const objectsSuccess = (data, params, field) => {
  let pType = params?.params?.type;
  let type =
    pType === BOQ
      ? objTypes.OBJECT_BOQ_FETCH_SUCCESS
      : pType === TEMPLATE
      ? objTypes.OBJECT_TEMPLATE_FETCH_SUCCESS
      : objTypes.OBJECT_SUCCESS;
  return {
    type: type,
    payload: data,
    field: field || OBJECTS,
    objectType: pType
  };
};
export const objectsFetch = (id, params, field) => {
  return dispatch => {
    dispatch(objectRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    if (api.object) {
      let paramSource = null;
      if (!field || field === OBJECTS) {
        if (source) {
          source.cancel('New fetch request. Previous call cancelled.');
        }
        source = axios.CancelToken.source();
        paramSource = source;
      }
      api.object
        .all(id, params, paramSource)
        .then(response => {
          dispatch(objectsSuccess(response.data.data, params, field));
        })
        .catch(error => {
          if (!axios.isCancel(error)) {
            const errorMsg = error.message;
            dispatch(objectFailure(errorMsg));
          }
        });
    } else {
      dispatch(objectFailure('User not logged in'));
    }
  };
};

const objectFetchSuccess = (data, updated, view) => {
  const objType = view
    ? objTypes.OBJECT_VIEW_SUCCESS
    : objTypes.OBJECT_FETCH_SUCCESS;
  return { type: objType, payload: data, updated: updated };
};
const objectFetchFailure = (error, view) => {
  const objType = view
    ? objTypes.OBJECT_VIEW_FAILURE
    : objTypes.OBJECT_FETCH_FAILURE;
  return { type: objType, payload: error };
};
export const singleObjectFetch = (id, updated, view) => {
  return dispatch => {
    dispatch(objectRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.object
      .info(id)
      .then(response => {
        dispatch(objectFetchSuccess(response.data, updated, view));
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(objectFetchFailure(errorMsg, view));
      });
  };
};

const objectUpdateSuccess = (data, type, isNew) => {
  let objType;
  switch (type) {
    case MENTION:
      objType = objTypes.OBJECT_MENTION_CREATE_SUCCESS;
      break;
    case TEMPLATE:
      objType = isNew
        ? objTypes.OBJECT_TEMPLATE_CREATE_SUCCESS
        : objTypes.OBJECT_TEMPLATE_UPDATE_SUCCESS;
      break;
    default:
      objType = isNew
        ? objTypes.OBJECT_CREATE_SUCCESS
        : objTypes.OBJECT_UPDATE_SUCCESS;
      break;
  }

  return { type: objType, payload: data };
};

const objectUpdateCustomResponse = data => {
  return { type: objTypes.OBJECT_UPDATE_CUSTOM_RESPONSE, payload: data };
};

export const objectCreateOrUpdate = (params, type) => {
  return dispatch => {
    dispatch(objectRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    let promise;
    if (!params.id) promise = api.object.create(params);
    else promise = api.object.update(params.id, params);
    promise
      .then(response => {
        if (response.data === API_RESPONSE_NO_ACCESS_CHANGE_CATEGORY) {
          dispatch(objectUpdateCustomResponse(response.data));
        } else
          dispatch(
            objectUpdateSuccess(
              {
                ...response.data.data,
                object_type: params.objectType,
                status: params.status
              },
              type,
              !params.id
            )
          );
      })
      .catch(error => {
        const { message, response } = error;
        const { status, data } = response;
        if (status === 409) dispatch(objectConflict(data));
        else dispatch(objectFailure(message));
      });
  };
};

const deleteSuccess = (route, isTemplate, isMention) => {
  const objType = isTemplate
    ? objTypes.OBJECT_TEMPLATE_DELETE_SUCCESS
    : objTypes.DELETE_SUCCESS;
  return { type: objType, route: route, isMention };
};
const deleteFailure = error => {
  return { type: objTypes.DELETE_FAILURE, payload: error };
};
export const deleteWithRoute = (
  route,
  id,
  params,
  isTemplate = false,
  isMention = false
) => {
  return dispatch => {
    dispatch(objectRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.object
      .deleteWithRoute(route, id, params)
      .then(() => {
        dispatch(deleteSuccess(route, isTemplate, isMention));
      })
      .catch(error => {
        dispatch(deleteFailure(error.message));
      });
  };
};

const updateRouteSuccess = () => {
  return { type: objTypes.OBJECT_UPDATE_ROUTE_SUCCESS };
};
export const updateWithRoute = (route, id, params) => {
  return dispatch => {
    dispatch(objectRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.object
      .updateWithRoute(route, id, params)
      .then(() => {
        dispatch(updateRouteSuccess());
      })
      .catch(error => {
        dispatch(objectFailure(error.message));
      });
  };
};

const accessTypeSuccess = data => {
  return { type: objTypes.ACCESS_TYPE_SUCCESS, payload: data };
};

const accessTypeFailure = error => {
  return { type: objTypes.ACCESS_TYPE_FAILURE, payload: error };
};

export const objectAccessTypeFetch = () => {
  return dispatch => {
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.object
      .accessTypes()
      .then(response => {
        dispatch(accessTypeSuccess(response.data.data));
      })
      .catch(error => {
        dispatch(accessTypeFailure(error.message));
      });
  };
};

const revisionFetchSuccess = (data, isView) => {
  return {
    type: objTypes.OBJECT_REVISION_FETCH_SUCCESS,
    payload: data,
    isView: isView
  };
};
const revisionFetchFailure = error => {
  return { type: objTypes.OBJECT_REVISION_FETCH_FAILURE, payload: error };
};

const revisionRequest = () => {
  return { type: objTypes.OBJECT_REVISION_REQUEST };
};

export const revisionFetch = (id, params, isView) => {
  return dispatch => {
    dispatch(revisionRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.object
      .revisions(id, params)
      .then(response => {
        dispatch(revisionFetchSuccess(response.data, isView));
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(revisionFetchFailure(errorMsg));
      });
  };
};

export const revisionTypesFetch = () => {
  return dispatch => {
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.object
      .revisionTypes()
      .then(response => {
        dispatch({
          type: objTypes.OBJECT_REVISION_TYPES_FETCH_SUCCESS,
          payload: response.data.data
        });
      })
      .catch(error => dispatch(objectFetchFailure(error)));
  };
};

export const clearObjectMessages = () => {
  return { type: objTypes.OBJECT_CLEAR_MESSAGES };
};

export const clearObjectUpdated = () => {
  return { type: objTypes.OBJECT_CLEAR_UPDATED };
};

export const clearCustomNotice = () => {
  return { type: objTypes.OBJECT_CLEAR_CUSTOM_NOTICE };
};

export const updateObjectSelected = data => {
  return { type: objTypes.UPDATE_OBJECT_SELECTED, payload: data };
};

export const objectsSearch = (query, field) => {
  return dispatch => {
    dispatch(objectRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.object
      .search(query)
      .then(response => {
        dispatch(objectsSuccess(response.data.data, query, field));
      })
      .catch(error => {
        dispatch(objectFailure(error.message));
      });
  };
};

export const objectResetBoQ = () => {
  return { type: objTypes.OBJECT_BOQ_RESET };
};

export const duplicateCheckReset = (loading = true) => {
  return {
    type: objTypes.ATTACHMENT_DUPLICATES_RESET,
    payload: loading
  };
};
const duplicateCheckSuccess = data => {
  return { type: objTypes.ATTACHMENT_DUPLICATES_SUCCESS, payload: data };
};
const duplicateCheckFailure = error => {
  return { type: objTypes.ATTACHMENT_DUPLICATES_FAILURE, payload: error };
};
export const duplicateFilesCheck = params => {
  return dispatch => {
    dispatch(duplicateCheckReset());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.object
      .checkDuplicates(params)
      .then(response => {
        dispatch(duplicateCheckSuccess(response.data.data));
      })
      .catch(error => {
        dispatch(duplicateCheckFailure(error.message));
      });
  };
};

const meetingCheckSuccess = data => {
  return { type: objTypes.MEETING_CHECK_SUCCESS, payload: data };
};
const meetingCheckError = error => {
  return { type: objTypes.MEETING_CHECK_FAILURE, payload: error };
};
export const meetingCreationCheck = params => {
  return dispatch => {
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.object
      .checkMeetingCreationAccess(params)
      .then(response => dispatch(meetingCheckSuccess(response.data)))
      .catch(error => {
        let errorMsg = error.message;
        switch (error?.response.data?.error) {
          case API_RESPONSE_ACTIVE_MEETING_TEMPLATE:
            errorMsg = (
              <FormattedMessage
                defaultMessage="There is already an active draft in this meeting series."
                id="object.ACTIVE_DRAFT_TEMPLATE"
              />
            );
            break;
          case API_RESPONSE_NO_ACCESS_MEETING_TEMPLATE:
            errorMsg = (
              <FormattedMessage
                defaultMessage={
                  'You do not have access to this meeting series. ' +
                  'To obtain access you need higher credentials, or you can be added to distribution.'
                }
                id="error.NO_MEETING_SERIES_ACCESS"
              />
            );
            break;
          default:
            break;
        }

        dispatch(meetingCheckError(errorMsg));
      });
  };
};

export const clearMeetingCheck = () => ({ type: objTypes.MEETING_CHECK_CLEAR });
