import * as pdfTypes from './PdfTypes';
const initialState = {
  loading: false,
  error: ''
};

const pdfReducer = (state = initialState, action) => {
  switch (action.type) {
    case pdfTypes.PDF_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case pdfTypes.PDF_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ''
      };
    case pdfTypes.PDF_FETCH_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default pdfReducer;
