import React, { Fragment, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { Autocomplete } from '@material-ui/lab';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  AccountBox as AccountBoxIcon,
  AddCircle as AddCircleIcon,
  Delete as DeleteIcon,
  Event as EventIcon,
  LockOpen as LockOpenIcon,
  Lock as LockIcon
} from '@material-ui/icons';
import {
  Button,
  CardActions,
  Popover,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { formatDate, valueInArray } from 'common/helper';
import {
  formatNumberDisplay,
  getAgendaItemNumber,
  getHighestItemNo,
  isSameMeeting,
  parseItemNo
} from '../helper';
import { useStyles } from '../../styles';
import classNames from 'classnames';
import {
  ACTION_OWNER,
  AGENDA,
  DEFAULT_DATE_FORMAT,
  DISPLAY_DATE_FORMAT,
  DOT,
  DUE_DATE,
  LANGUAGE_EN_GB,
  POPOVER_ACTION_OWNER,
  POPOVER_STATUS,
  REGEX_LAST_DOT,
  STATUS,
  STATUS_CLOSED,
  STATUS_INFORMATION,
  STATUS_OPEN
} from 'common/constants';

const AgendaIcons = props => {
  const {
    agendaPrefix,
    agendaRank,
    comment,
    commentRank,
    disableEdit,
    disableStatus,
    dragHandleProps,
    formState,
    getNewCommentItem,
    handleMentionsInDeletedAgenda,
    hasNewMomNumbering,
    indexComment,
    indexMain,
    indexSub,
    isDragging,
    isSubItem,
    setHasPopup,
    updateAgendaNos,
    updateStateVals,
    users
  } = props;
  const classes = useStyles();
  const intl = useIntl();

  const [isOpen, setIsOpen] = useState(false);
  const [anchor, setAnchor] = useState({
    [ACTION_OWNER]: null,
    [STATUS]: null
  });
  const openAnchor = name => Boolean(anchor[name]);
  const idAo = openAnchor(ACTION_OWNER) ? POPOVER_ACTION_OWNER : undefined;
  const idSt = openAnchor(STATUS) ? POPOVER_STATUS : undefined;
  const handleClick = (event, name) => {
    setAnchor(anchor => ({ ...anchor, [name]: event.currentTarget }));
    setHasPopup(true);
  };
  const handleClose = name => {
    setAnchor(anchor => ({ ...anchor, [name]: null }));
    setHasPopup(false);
  };
  const handleDueDate = value => {
    setIsOpen(value);
    setHasPopup(value);
  };

  const updateStateAgenda = thisAgenda => updateStateVals(AGENDA, thisAgenda);
  const updateData = (field, value) => {
    let agenda = formState.values.agenda;
    let isSubTrigger = false;
    if (indexSub >= 0) {
      agenda[indexMain].comment[indexComment].sub_item[indexSub][field] = value;
      isSubTrigger = true;
    }
    if (indexSub < 0 || (field === STATUS && value !== STATUS_CLOSED)) {
      agenda[indexMain].comment[indexComment][field] = isSubTrigger
        ? STATUS_OPEN
        : value;
    }
    updateStateAgenda(agenda);
  };

  const handleStatusChange = (event, status) => {
    event.persist();
    updateData(STATUS, status.value);
  };

  const handleDateChange = dueDate => {
    updateData(
      DUE_DATE,
      dueDate ? formatDate(dueDate, DEFAULT_DATE_FORMAT) : ''
    );
  };

  const handleActionOwner = (event, value) => {
    event.persist();
    updateData(ACTION_OWNER, value?.name || value?.email || '');
  };

  const handleAddSubItem = event => {
    event.persist();
    let { agenda } = formState.values;
    const comment = agenda[indexMain].comment[indexComment];

    let itemNo, lastRank;
    if (hasNewMomNumbering) {
      const highestNo = getHighestItemNo(comment.sub_item);
      lastRank =
        comment.total_sub_item_count >= highestNo
          ? comment.total_sub_item_count
          : highestNo;
      itemNo = hasNewMomNumbering
        ? agendaPrefix.concat(DOT).concat(formatNumberDisplay(lastRank + 1))
        : null;
    } else {
      lastRank =
        comment.sub_item.length > 0 ? comment.sub_item.slice(-1)[0].rank : 0;
    }

    comment.sub_item.push(getNewCommentItem(parseInt(lastRank) + 1, itemNo));
    // set parent item status to open if closed or information
    if (comment.status !== STATUS_OPEN) comment.status = STATUS_OPEN;
    updateStateAgenda(agenda);
  };

  const updateSubItemNos = comment => {
    if (hasNewMomNumbering) {
      let updatedNo = comment.total_sub_item_count || 0;
      comment.sub_item.forEach(si => {
        let [siPrefix, siItemNo] = si.item_no.split(REGEX_LAST_DOT);
        if (siItemNo > updatedNo) {
          updatedNo = siItemNo - 1 > updatedNo ? siItemNo - 1 : siItemNo;
          si.rank = formatNumberDisplay(updatedNo);
          si.item_no = siPrefix.concat(DOT).concat(si.rank);
        }
      });
    }
  };

  // delete comment item from specified agenda
  const handleDelCommentItem = event => {
    event.persist();
    const del = window.confirm(
      intl.formatMessage(
        { id: 'object.DELETE_ITEM_CONFIRMATION' },
        { name: intl.formatMessage({ id: 'object.ITEM' }).toLowerCase() }
      )
    );
    if (del) {
      let {
        agenda,
        metadata: { meeting_no }
      } = formState.values;
      let toUpdate = isSubItem
        ? agenda[indexMain].comment[indexComment]
        : agenda[indexMain].comment;

      //eslint-disable-next-line no-unused-vars
      let [deleted, ...rest] =
        indexSub > -1
          ? toUpdate.sub_item.splice(indexSub, 1)
          : toUpdate.splice(indexComment, 1);

      if (isSubItem) updateSubItemNos(toUpdate);
      else if (
        deleted.item_no &&
        isSameMeeting(deleted.item_no, meeting_no, true)
      ) {
        updateAgendaNos(agenda, parseItemNo(deleted.item_no), true);
      }

      handleMentionsInDeletedAgenda([deleted], agendaRank, commentRank);
      updateStateAgenda(agenda);
    }
  };

  const action_owner = comment.action_owner
    ? intl.formatMessage({ id: 'object.OWNER' }) + `: ${comment.action_owner}`
    : intl.formatMessage({ id: 'object.ACTION_OWNER' });
  const action_owner_defVal = users?.find(user =>
    valueInArray([user.name, user.email], comment.action_owner)
  );
  const due_date = comment.due_date
    ? intl.formatMessage({ id: 'object.DUE' }) +
      `: ${formatDate(comment.due_date, DISPLAY_DATE_FORMAT, LANGUAGE_EN_GB)}`
    : intl.formatMessage({ id: 'object.SET_DUE_DATE' });

  const statusList = [
    {
      name: intl.formatMessage({ id: 'object.STATUS_OPEN' }),
      value: STATUS_OPEN
    },
    {
      name: intl.formatMessage({ id: 'object.STATUS_INFORMATION' }),
      value: STATUS_INFORMATION
    },
    {
      name: intl.formatMessage({ id: 'object.STATUS_CLOSED' }),
      value: STATUS_CLOSED
    }
  ];
  const statusLabel = (
    <FormattedMessage
      defaultMessage="Status: {value}"
      id="common.STATUS_WITH_VALUE"
      values={{
        value: (
          <FormattedMessage
            defaultMessage={comment.status}
            id={'object.STATUS_' + comment.status.toUpperCase()}
          />
        )
      }}
    />
  );

  return (
    <Fragment>
      <CardActions
        {...dragHandleProps}
        className={classNames(
          isDragging ? classes.greenBackground : classes.grayContent,
          isSubItem ? classes.agendaSubIconPadding : classes.agendaIconPadding,
          classes.agendaIconDisplay
        )}>
        <Typography
          style={isSubItem ? { paddingLeft: '12px' } : null}
          variant="h6">
          {hasNewMomNumbering
            ? agendaPrefix
            : getAgendaItemNumber(
                indexMain,
                indexComment,
                isSubItem ? indexSub : null
              )}
        </Typography>
        <div>
          <Tooltip arrow title={statusLabel}>
            <span>
              <Button
                aria-owns={idSt}
                className={classes.agendaButton}
                color="secondary"
                disabled={disableEdit || disableStatus}
                onClick={e => handleClick(e, STATUS)}
                size="small"
                startIcon={
                  comment.status === STATUS_CLOSED ? (
                    <LockIcon />
                  ) : (
                    <LockOpenIcon />
                  )
                }>
                {statusLabel}
              </Button>
            </span>
          </Tooltip>
          <Tooltip arrow title={due_date}>
            <span>
              <Button
                className={classes.agendaButton}
                color="secondary"
                disabled={disableEdit}
                onClick={() => handleDueDate(true)}
                size="small"
                startIcon={<EventIcon />}>
                {due_date}
              </Button>
            </span>
          </Tooltip>
          <Tooltip arrow title={action_owner}>
            <span>
              <Button
                aria-owns={idAo}
                className={classes.agendaButton}
                color="secondary"
                disabled={disableEdit}
                onClick={e => handleClick(e, ACTION_OWNER)}
                size="small"
                startIcon={<AccountBoxIcon />}>
                {action_owner}
              </Button>
            </span>
          </Tooltip>
          {indexSub < 0 && !disableEdit ? (
            <Tooltip
              arrow
              title={
                <FormattedMessage
                  defaultMessage="Add Sub-item"
                  id={'object.ADD_SUB-ITEM'}
                />
              }>
              <Button
                className={classNames(
                  classes.addItemIcon,
                  classes.agendaButton
                )}
                onClick={handleAddSubItem}
                size="small"
                startIcon={<AddCircleIcon />}>
                <FormattedMessage
                  defaultMessage="Add Sub-item"
                  id={'object.ADD_SUB-ITEM'}
                />
              </Button>
            </Tooltip>
          ) : null}
          {disableEdit ? null : (
            <Tooltip
              arrow
              title={
                <FormattedMessage
                  defaultMessage="Delete"
                  id={'common.DELETE'}
                />
              }>
              <Button
                className={classNames(
                  classes.agendaButton,
                  classes.delItemIcon
                )}
                color="secondary"
                onClick={handleDelCommentItem}
                size="small"
                startIcon={<DeleteIcon />}>
                <FormattedMessage
                  defaultMessage="Delete"
                  id={'common.DELETE'}
                />
              </Button>
            </Tooltip>
          )}
        </div>
      </CardActions>
      <Fragment>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            clearable
            onAccept={() => handleDueDate(false)}
            onChange={handleDateChange}
            onClose={() => handleDueDate(false)}
            open={isOpen}
            TextFieldComponent={() => null}
            value={due_date}
          />
        </MuiPickersUtilsProvider>
      </Fragment>
      <Popover
        anchorEl={anchor[ACTION_OWNER]}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        className={classes.popover}
        id={POPOVER_ACTION_OWNER}
        onClose={() => handleClose(ACTION_OWNER)}
        open={openAnchor(ACTION_OWNER)}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}>
        <Autocomplete
          filterSelectedOptions
          getOptionLabel={user => user.name || user.email}
          groupBy={user => user.organization?.name}
          id={ACTION_OWNER}
          name={ACTION_OWNER}
          onChange={handleActionOwner}
          options={users}
          renderInput={params => (
            <TextField
              {...params}
              size="small"
              style={{ width: '200px', margin: '16px' }}
            />
          )}
          size="small"
          value={action_owner_defVal || null}
        />
      </Popover>
      <Popover
        anchorEl={anchor[STATUS]}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        className={classes.popover}
        id={POPOVER_STATUS}
        onClose={() => handleClose(STATUS)}
        open={openAnchor(STATUS)}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}>
        <Autocomplete
          disableClearable
          getOptionLabel={s => s.name}
          id={STATUS}
          name={STATUS}
          onChange={handleStatusChange}
          options={statusList}
          renderInput={params => (
            <TextField
              {...params}
              size="small"
              style={{ width: '110px', margin: '16px' }}
            />
          )}
          size="small"
        />
      </Popover>
    </Fragment>
  );
};

AgendaIcons.propTypes = {
  agendaPrefix: PropTypes.string,
  agendaRank: PropTypes.string,
  comment: PropTypes.object,
  commentRank: PropTypes.string,
  disableEdit: PropTypes.bool,
  disableStatus: PropTypes.bool,
  dragHandleProps: PropTypes.object,
  formState: PropTypes.object,
  getNewCommentItem: PropTypes.func,
  handleMentionsInDeletedAgenda: PropTypes.func,
  hasNewMomNumbering: PropTypes.bool,
  indexComment: PropTypes.number,
  indexMain: PropTypes.number,
  indexSub: PropTypes.number,
  isDragging: PropTypes.bool,
  isSubItem: PropTypes.bool,
  setHasPopup: PropTypes.func,
  updateAgendaNos: PropTypes.func,
  updateStateVals: PropTypes.func,
  users: PropTypes.array
};

AgendaIcons.defaultProps = {
  hasNewMomNumbering: false
};

export default AgendaIcons;
