import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';
import { BrregField, HelpPopover } from 'components';
import {
  Button,
  Divider,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import {
  generateCompanyCode,
  getCompanyId,
  hasError,
  isObjectEmpty
} from 'common/helper';
import { organizationCreate, userUpdateSuccess } from '../../../../redux';
import { useProjectStyles } from '../../../Project/styles';
import validate from 'validate.js';
import { COUNTRY_NO_CODE, REGEX_ONE_LETTER } from 'common/constants';

const NewOrganization = ({ onPopupClose }) => {
  const classes = useProjectStyles();
  const dispatch = useDispatch();
  const {
    organization: { created: orgCreated },
    user
  } = useSelector(state => state);

  const [formState, setFormState] = useState({
    values: { countryCode: COUNTRY_NO_CODE },
    errors: {}
  });

  const schema = {
    'org_meta.company_code': {
      presence: {
        allowEmpty: false,
        message: (
          <FormattedMessage
            defaultMessage="Company Code is required"
            id="error.REQUIRED_COMPANY_CODE"
          />
        )
      },
      length: {
        minimum: 2,
        maximum: 4
      },
      format: {
        pattern: REGEX_ONE_LETTER,
        message: (
          <FormattedMessage
            defaultMessage="Must contain at least one letter"
            id="error.CONTAIN_A_LETTER"
          />
        )
      }
    },
    'org_meta.company_id': {
      presence: {
        allowEmpty: false,
        message: (
          <FormattedMessage
            defaultMessage="Company Id is required"
            id="error.REQUIRED_COMPANY_ID"
          />
        )
      },
      length: {
        maximum: 20
      }
    }
  };

  useEffect(() => {
    if (orgCreated && !orgCreated.error) {
      user.info.organizations = [orgCreated.organization];
      dispatch(userUpdateSuccess(user.info));
      onPopupClose();
    }
  }, [dispatch, user, orgCreated, onPopupClose]);

  const handleSelectChange = (event, value, errors) => {
    event.persist();
    setFormState(formState => {
      let { name = '', org_meta = {}, org_tag = '' } = value || {};
      org_meta = {
        ...formState.values.org_meta,
        ...org_meta,
        company_code:
          org_meta.company_code || generateCompanyCode({ name, org_tag }),
        company_id: org_meta.company_id || getCompanyId(org_tag)
      };

      const values = { ...formState.values, name, org_meta, org_tag };
      return {
        ...formState,
        values,
        errors: { ...validate(values, schema), ...errors }
      };
    });
  };

  const handleChange = event => {
    event.persist();
    setFormState(formState => {
      const values = {
        ...formState.values,
        org_meta: {
          ...formState.values.org_meta,
          [event.target.name]: event.target.value?.toUpperCase()
        }
      };
      return { ...formState, values, errors: validate(values, schema) };
    });
  };

  const handleAddOrganization = event => {
    event.preventDefault();
    dispatch(organizationCreate(formState.values));
  };

  return (
    <div className={classes.popupRoot}>
      {orgCreated?.error ? (
        <Alert className={classes.alert} severity="error">
          {orgCreated.error}
        </Alert>
      ) : null}
      <Typography variant="h3">
        <FormattedMessage
          defaultMessage="New Organization"
          id="organization.NEW_ORGANIZATION"
        />
      </Typography>
      <Divider className={classes.popupDivider} />
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <BrregField
            label={
              <FormattedMessage
                defaultMessage="Organization"
                id="organization.ORGANIZATION"
              />
            }
            onChange={handleSelectChange}
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <Grid container>
            <Grid item md={10} xs={10}>
              <TextField
                className={classes.popupDivider}
                error={hasError(formState.errors, 'org_meta.company_code')}
                fullWidth
                helperText={
                  hasError(formState.errors, 'org_meta.company_code')
                    ? formState.errors['org_meta.company_code'][0]
                    : null
                }
                inputProps={{ style: { textTransform: 'uppercase' } }}
                label={
                  <FormattedMessage defaultMessage="Code" id="common.CODE" />
                }
                name={'company_code'}
                onChange={handleChange}
                size="small"
                type="text"
                value={formState.values.org_meta?.company_code || ''}
                variant="outlined"
              />
            </Grid>
            <Grid className={classes.helpIcon} item md={2} xs={2}>
              <HelpPopover
                message={
                  <Typography>
                    <FormattedMessage
                      defaultMessage="The code is usually the acronym of your company.{br}It will be used as reference in the object name."
                      id="help.COMPANY_CODE_REFERENCE"
                      values={{ br: <br /> }}
                    />
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={9} xs={6}>
          <TextField
            className={classes.popupDivider}
            disabled
            error={hasError(formState.errors, 'org_meta.company_id')}
            fullWidth
            helperText={
              hasError(formState.errors, 'org_meta.company_id')
                ? formState.errors['org_meta.company_id'][0]
                : null
            }
            label={
              <FormattedMessage
                defaultMessage="Company ID"
                id="organization.COMPANY_ID"
              />
            }
            size="small"
            value={formState.values.org_meta?.company_id || ''}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Divider className={classes.popupDivider} />
      <Button
        className={classes.floatRight}
        color="primary"
        disabled={!isObjectEmpty(formState.errors) || !formState.values.name}
        onClick={handleAddOrganization}
        type="submit"
        variant="contained">
        <FormattedMessage
          defaultMessage="Add Organization"
          id="organization.ORGANIZATION_ADD"
        />
      </Button>
    </div>
  );
};

NewOrganization.propTypes = {
  onPopupClose: PropTypes.func
};

export default NewOrganization;
