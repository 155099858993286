import { combineReducers } from 'redux';
import adminReducer from './admin/AdminReducer';
import brregReducer from './brreg/BrregReducer';
import documentReducer from './document/DocumentReducer';
import exportReducer from './export/ExportReducer';
import forgeReducer from './forge/ForgeReducer';
import objectReducer from './object/ObjectReducer';
import organizationReducer from './organization/OrganizationReducer';
import pdfReducer from './pdf/PdfReducer';
import projectReducer from './project/ProjectReducer';
import screenReducer from './screen/ScreenReducer';
import standardReducer from './standard/StandardReducer';
import userReducer from './user/UserReducer';

const rootReducer = combineReducers({
  admin: adminReducer,
  brreg: brregReducer,
  document: documentReducer,
  export: exportReducer,
  forge: forgeReducer,
  object: objectReducer,
  organization: organizationReducer,
  pdf: pdfReducer,
  project: projectReducer,
  screen: screenReducer,
  standard: standardReducer,
  user: userReducer
});

export default rootReducer;
