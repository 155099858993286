import React, { createRef, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import AgendaIcons from './AgendaIcons';
import { WysWyg } from '../../components';
import { Box, Card, CardContent } from '@material-ui/core';
import classNames from 'classnames';
import { useStyles } from '../../styles';
import {
  HYPHEN,
  ITEM,
  OBJECT_REF,
  STATUS_OPEN,
  SUBITEM
} from 'common/constants';

const scrollToRef = (ref, isSubItem) => {
  let offset = ref.current.offsetTop + ref.current.offsetParent.offsetTop;
  if (isSubItem)
    offset =
      offset +
      ref.current.offsetParent.offsetParent.offsetTop +
      ref.current.offsetParent.offsetParent.offsetParent.offsetTop;
  window.scrollTo(0, offset);
};

const AgendaItem = props => {
  const {
    agendaPrefix,
    agendaRank,
    clearTimer,
    commentRank,
    comments,
    disableEdit,
    displayObjectView,
    formState,
    getNewCommentItem,
    handleAgendaMove,
    handleEditorChange,
    handleMentionItems,
    handleMentionsInDeletedAgenda,
    hasNewMomNumbering,
    indexComment,
    indexMain,
    isDraggingParent,
    objectTypes,
    projectId,
    queryParams,
    setHasPopup,
    suggestions,
    title,
    updateAgendaNos,
    updateStateVals,
    users
  } = props;
  const classes = useStyles();
  const isSubItem = !!commentRank;

  const myRef = createRef();
  useEffect(() => {
    if (myRef.current) scrollToRef(myRef, isSubItem);
  }, [myRef, isSubItem]);

  const focusParams = queryParams.replace('?', '').split(HYPHEN);

  return (
    <DragDropContext
      onDragEnd={handleAgendaMove}
      onDragStart={() => setHasPopup(true)}>
      <Droppable droppableId={isSubItem ? SUBITEM : ITEM} type={agendaRank}>
        {provided => (
          <>
            <div
              {...provided.droppableProps}
              className={classes.fullWidth}
              ref={provided.innerRef}>
              {comments?.map((comment, index) => {
                const itemPrefix = hasNewMomNumbering
                  ? comment.item_no
                  : agendaPrefix;
                const linkedObjRank = commentRank
                  ? `${commentRank}${HYPHEN}${comment.rank}`
                  : comment.rank;
                const contentKey = comment.content
                  ? `_${JSON.parse(comment.content).blocks[0].key}`
                  : '';
                let disableStatus = false;
                let cIndex = indexComment === undefined ? index : indexComment;
                let sIndex = !isSubItem ? -1 : index;
                let wyswygName = `agenda_${indexMain}_comment_content_${cIndex}`;
                let draggableId = `${indexMain}_${cIndex}`;
                let key = `item_${draggableId}`;

                if (isSubItem) {
                  wyswygName = wyswygName.concat(`_sub_content_${index}`);
                  draggableId = draggableId.concat(`_${sIndex}`);
                  key = `sub_item_${draggableId}`;
                } else {
                  disableStatus = comment.sub_item.some(
                    si => si.status === STATUS_OPEN
                  );
                }
                return (
                  <Draggable
                    draggableId={draggableId}
                    index={index}
                    isDragDisabled={disableEdit}
                    key={key}>
                    {(provided, snapshot) => (
                      <Card
                        {...provided.draggableProps}
                        className={classes.noBoxShadow}
                        ref={provided.innerRef}>
                        <AgendaIcons
                          agendaPrefix={itemPrefix}
                          agendaRank={agendaRank}
                          comment={comment}
                          commentRank={linkedObjRank}
                          disableEdit={disableEdit}
                          disableStatus={disableStatus}
                          dragHandleProps={provided.dragHandleProps}
                          formState={formState}
                          getNewCommentItem={getNewCommentItem}
                          handleMentionsInDeletedAgenda={
                            handleMentionsInDeletedAgenda
                          }
                          hasNewMomNumbering={hasNewMomNumbering}
                          indexComment={cIndex}
                          indexMain={indexMain}
                          indexSub={sIndex}
                          isDragging={snapshot.isDragging || isDraggingParent}
                          isSubItem={isSubItem}
                          setHasPopup={setHasPopup}
                          updateAgendaNos={updateAgendaNos}
                          updateStateVals={updateStateVals}
                          users={users}
                        />
                        <CardContent
                          className={classNames(
                            snapshot.isDragging || isDraggingParent
                              ? classes.greenBackground
                              : classes.grayContent,
                            classes.noPadding
                          )}>
                          <Box
                            alignItems="center"
                            className={
                              isSubItem
                                ? classes.agendaSubItemPadding
                                : classes.agendaItemPadding
                            }
                            display="flex"
                            ref={
                              ((!isSubItem &&
                                !focusParams[2] &&
                                comment.rank === focusParams[1]) ||
                                (isSubItem &&
                                  comment.rank === focusParams[2])) &&
                              agendaRank === focusParams[0]
                                ? myRef
                                : null
                            }>
                            <WysWyg
                              agenda={{
                                objectId: formState.values.id,
                                agendaItemRank: agendaRank,
                                rank: linkedObjRank,
                                isSubItem: isSubItem,
                                title: title
                              }}
                              clearTimer={clearTimer}
                              defaultValue={comment.content}
                              displayObjectView={displayObjectView}
                              handleMentionItems={handleMentionItems}
                              handleParentEditorChange={handleEditorChange}
                              key={wyswygName.concat(contentKey)}
                              mentionedObjs={formState.mentionedObjs}
                              mentionedUsers={formState.mentionedUsers}
                              name={wyswygName}
                              noToolbar
                              objectTypes={objectTypes}
                              projectId={projectId}
                              readOnly={disableEdit}
                              refName={OBJECT_REF}
                              setHasPopup={setHasPopup}
                              suggestions={suggestions}
                            />
                          </Box>
                          {comment.sub_item?.length > 0 ? (
                            <AgendaItem
                              agendaPrefix={itemPrefix}
                              agendaRank={agendaRank}
                              commentRank={comment.rank}
                              comments={comment.sub_item}
                              disableEdit={disableEdit}
                              displayObjectView={displayObjectView}
                              formState={formState}
                              handleAgendaMove={handleAgendaMove}
                              handleEditorChange={handleEditorChange}
                              handleMentionItems={handleMentionItems}
                              handleMentionsInDeletedAgenda={
                                handleMentionsInDeletedAgenda
                              }
                              hasNewMomNumbering={hasNewMomNumbering}
                              indexComment={cIndex}
                              indexMain={indexMain}
                              isDraggingParent={snapshot.isDragging}
                              mentionedObjs={formState.mentionedObjs}
                              mentionedUsers={formState.mentionedUsers}
                              objectTypes={objectTypes}
                              projectId={projectId}
                              queryParams={queryParams}
                              setHasPopup={setHasPopup}
                              suggestions={suggestions}
                              title={title}
                              updateAgendaNos={updateAgendaNos}
                              updateStateVals={updateStateVals}
                              users={users}
                            />
                          ) : null}
                          {provided.placeholder}
                        </CardContent>
                      </Card>
                    )}
                  </Draggable>
                );
              })}
            </div>
            {provided.placeholder}
          </>
        )}
      </Droppable>
    </DragDropContext>
  );
};

AgendaItem.propTypes = {
  agendaPrefix: PropTypes.string,
  agendaRank: PropTypes.string,
  clearTimer: PropTypes.func,
  commentRank: PropTypes.string,
  comments: PropTypes.array,
  disableEdit: PropTypes.bool,
  displayObjectView: PropTypes.func,
  formState: PropTypes.object,
  getNewCommentItem: PropTypes.func,
  handleAgendaMove: PropTypes.func,
  handleEditorChange: PropTypes.func,
  handleMentionItems: PropTypes.func,
  handleMentionsInDeletedAgenda: PropTypes.func,
  hasNewMomNumbering: PropTypes.bool,
  indexComment: PropTypes.number,
  indexMain: PropTypes.number,
  isDraggingParent: PropTypes.bool,
  objectTypes: PropTypes.array,
  projectId: PropTypes.string,
  queryParams: PropTypes.string,
  setHasPopup: PropTypes.func,
  suggestions: PropTypes.array,
  title: PropTypes.string,
  updateAgendaNos: PropTypes.func,
  updateStateVals: PropTypes.func,
  users: PropTypes.array
};

AgendaItem.defaultProps = {
  hasNewMomNumbering: false
};

export default AgendaItem;
