import React, { useCallback, useContext, useState } from 'react';
import { IntlProvider } from 'react-intl';

import messages_en from '../languages/en.json';
import messages_nb from '../languages/nb.json';

import {
  LANGUAGE_NB,
  LANGUAGE_EN_GB,
  LANGUAGE_NAME_EN_GB,
  LANGUAGE_NAME_NORSK_BOKMAL,
  LS_LANGUAGE
} from 'common/constants';

export const LanguageContext = React.createContext();

export const useLanguage = () => useContext(LanguageContext);

export const languageList = {
  [LANGUAGE_EN_GB]: {
    name: LANGUAGE_NAME_EN_GB,
    messages: messages_en
  },
  [LANGUAGE_NB]: {
    name: LANGUAGE_NAME_NORSK_BOKMAL,
    messages: messages_nb
  }
};

export const ThemeProvider = ({ children }) => {
  const [locale, setLocale] = useState(
    localStorage.getItem(LS_LANGUAGE) || LANGUAGE_NB
  );

  const updateLocale = useCallback(language => {
    setLocale(language);
    localStorage.setItem(LS_LANGUAGE, language);
  }, [ setLocale ]);

  return (
    <LanguageContext.Provider value={[locale, updateLocale]}>
      <IntlProvider
        defaultLocale={LANGUAGE_EN_GB}
        locale={locale}
        messages={languageList[locale].messages}
        onError={err => {
          if (err.code === 'MISSING_TRANSLATION') {
            return;
          }
        }}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};
