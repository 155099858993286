import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Box, Typography } from '@material-ui/core';
import { WysWyg } from '../../../components';
import { isCurrentOrg, isObjectEmpty } from 'common/helper';
import { objectCreateOrUpdate } from '../../../../../redux';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from '../../../styles';
import { STATUS_READ, STATUS_SENT } from 'common/constants';
import classNames from 'classnames';

const ObjectLetter = ({
  createdBy,
  displayObjectView,
  objectInfo,
  senderOrgInfo
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const objectLetter = objectInfo.metadata;
  let content = '',
    reference = '';
  if (!isObjectEmpty(objectLetter)) {
    content = objectLetter.content || '';
    reference = objectLetter.reference || '';
  }

  const { object, user } = useSelector(state => state);
  const { statusAll: statusList } = object;
  const currUserId = user?.info?.id;
  useEffect(() => {
    const { organization, history } = objectInfo;
    if (
      statusList.length > 0 &&
      isCurrentOrg(organization?.id) &&
      currUserId !== objectLetter.created_by_id &&
      history?.length > 0 &&
      history[0].status.name === STATUS_SENT
    ) {
      const read = statusList.find(s => s.name === STATUS_READ);
      if (read) {
        const params = {
          ...objectInfo,
          organization_id: organization.id,
          status_id: read.id
        };
        dispatch(objectCreateOrUpdate(params));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusList]);

  const sender = (createdBy
    ? `${createdBy}${senderOrgInfo ? '\n' : ''}`
    : ''
  ).concat(senderOrgInfo);
  const { name, org_meta } = objectInfo.organization;
  const recipient = name.concat(
    org_meta?.address ? `\n${org_meta.address}` : ''
  );
  return (
    <div>
      <Typography component="div">
        <Box
          fontSize="h4.fontSize"
          fontWeight="fontWeightBold"
          m={1}
          textAlign="left">
          {objectInfo.name} {objectInfo.title}
        </Box>
        <Box className={classes.noMarginBottom} m={1} textAlign="left">
          <FormattedMessage defaultMessage="Sender" id="object.SENDER" />:
          <Typography
            className={classNames(classes.marginLeft2, classes.multiline)}>
            {sender}
          </Typography>
        </Box>
        <Box className={classes.noMarginTop} m={1} textAlign="left">
          <FormattedMessage
            defaultMessage="Our ref"
            id="object.OUR_REFERENCE"
          />
          : {objectInfo.name}
        </Box>
        <Box className={classes.noMarginBottom} m={1} textAlign="left">
          <FormattedMessage defaultMessage="Recipient" id="common.RECIPIENT" />:
          <Typography
            className={classNames(classes.marginLeft2, classes.multiline)}>
            {recipient}
          </Typography>
        </Box>
        <Box className={classes.noMarginTop} m={1} textAlign="left">
          <FormattedMessage
            defaultMessage="Recipient Reference"
            id="object.RECIPIENT_REFERENCE"
          />
          : {reference}
        </Box>
        <Box fontWeight="fontWeightBold" m={1} textAlign="left">
          <FormattedMessage defaultMessage="Message" id="object.MESSAGE" />:
        </Box>
      </Typography>
      <WysWyg
        defaultValue={content}
        displayObjectView={displayObjectView}
        name="content"
        readOnly
      />
    </div>
  );
};

ObjectLetter.propTypes = {
  createdBy: PropTypes.string,
  displayObjectView: PropTypes.func,
  objectInfo: PropTypes.object
};

export default ObjectLetter;
