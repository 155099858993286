import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, TablePagination, TextField } from '@material-ui/core';
import { paginationDropdown } from 'common/helper';
import { useStyles } from '../styles';

const ObjectsPagination = props => {
  const { handleChangePage, limit, page, total } = props;
  const classes = useStyles();
  const paginationDropdownItem = paginationDropdown(total, limit);

  return (
    <div className={classes.tablePaginationSelect}>
      {paginationDropdownItem.length > 0 ? (
        <TextField
          onChange={evt => handleChangePage(evt, evt.target.value)}
          select
          size="small"
          value={page}
          variant="outlined">
          {paginationDropdownItem.map(item => (
            <MenuItem key={item} value={item}>
              {item + 1}
            </MenuItem>
          ))}
        </TextField>
      ) : null}
      <TablePagination
        component="div"
        count={total}
        onPageChange={handleChangePage}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[]}
      />
    </div>
  );
};

ObjectsPagination.propTypes = {
  handleChangePage: PropTypes.func.isRequired,
  limit: PropTypes.number,
  page: PropTypes.number,
  total: PropTypes.number
};

ObjectsPagination.defaultProps = {
  limit: 0,
  page: 0,
  total: 0
};

export default ObjectsPagination;
