import * as exportTypes from './ExportTypes';
import Str8line from '../auth/AuthApi';
import { LS_STR8LINE_TOKEN } from 'common/constants';

const exportRequest = () => ({ type: exportTypes.EXPORT_REQUEST });

const exportFailure = error => {
  return {
    type: exportTypes.EXPORT_FAILURE,
    payload: error
  };
};

const exportListSuccess = data => {
  return { type: exportTypes.EXPORT_LIST_SUCCESS, payload: data };
};

export const exportListFetch = () => {
  return dispatch => {
    dispatch(exportRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.export
      .all()
      .then(response => dispatch(exportListSuccess(response.data.data)))
      .catch(error => dispatch(exportFailure(error.message)));
  };
};

const exportCreateSuccess = data => {
  return { type: exportTypes.EXPORT_CREATE_SUCCESS, payload: data };
};
export const exportCreate = params => {
  return dispatch => {
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.export
      .create(params)
      .then(response => dispatch(exportCreateSuccess(response.data.data)))
      .catch(error => dispatch(exportFailure(error.message)));
  };
};

const deleteExportSuccess = () => ({ type: exportTypes.EXPORT_DELETE_SUCCESS });
export const deleteExport = exportId => {
  return dispatch => {
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.export
      .delete(exportId)
      .then(() => dispatch(deleteExportSuccess()))
      .catch(error => dispatch(exportFailure(error.message)));
  };
};

const exportStatusSuccess = data => {
  return { type: exportTypes.EXPORT_STATUS_SUCCESS, payload: data };
};

export const exportStatusFetch = () => {
  return dispatch => {
    dispatch(exportRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.object
      .getStatus('type=export')
      .then(response => dispatch(exportStatusSuccess(response.data.data)))
      .catch(error => dispatch(exportFailure(error.message)));
  };
};

export const clearExport = () => ({ type: exportTypes.EXPORT_CLEAR });
