import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import {
  CancelRounded as CancelRoundedIcon,
  CheckCircleRounded as CheckCircleRoundedIcon
} from '@material-ui/icons';
import { StyledCell } from 'components';
import classNames from 'classnames';
import { formatCurrency } from 'common/helper';
import { useStyles } from '../../../styles';
import {
  DELMALEBREV,
  STATUS_APPROVED,
  STATUS_REJECTED
} from 'common/constants';

const BoqTable = ({ data, showApprovalStatus }) => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <TableContainer>
      <Table size="small">
        <colgroup>
          <col width="22%" />
          <col width="25%" />
          <col width="18%" />
          <col width="20%" />
          <col width="15%" />
        </colgroup>
        <TableHead>
          <TableRow>
            <StyledCell header>
              <FormattedMessage
                defaultMessage="Description"
                id="common.DESCRIPTION"
              />
            </StyledCell>
            <StyledCell header>
              <FormattedMessage defaultMessage="Area" id="object.AREA" />
            </StyledCell>
            <StyledCell header>
              <FormattedMessage
                defaultMessage="Number of units"
                id="object.NUMBER_OF_UNITS"
              />
            </StyledCell>
            <StyledCell header>
              <FormattedMessage defaultMessage="Sum" id="object.SUM" />
            </StyledCell>
            <StyledCell header>
              <FormattedMessage
                defaultMessage="Approval"
                id="object.APPROVAL"
              />
            </StyledCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={`${DELMALEBREV}_${index}`}>
              <StyledCell>{`${DELMALEBREV} ${index + 1}`}</StyledCell>
              <StyledCell>{item.area}</StyledCell>
              <StyledCell>{item.number_of_units}</StyledCell>
              <StyledCell>
                <Typography>{formatCurrency(item.sum, intl.locale)}</Typography>
              </StyledCell>
              <StyledCell>
                <div>
                  <CancelRoundedIcon
                    className={classNames(
                      classes.paddingRightHalf,
                      item.status === STATUS_REJECTED &&
                        (showApprovalStatus || item.locked)
                        ? classes.delItemIcon
                        : classes.grayIcon
                    )}
                  />
                  <CheckCircleRoundedIcon
                    className={
                      item.status === STATUS_APPROVED &&
                      (showApprovalStatus || item.locked)
                        ? classes.addItemIcon
                        : classes.grayIcon
                    }
                  />
                </div>
              </StyledCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

BoqTable.propTypes = {
  data: PropTypes.array,
  showApprovalStatus: PropTypes.bool
};
BoqTable.defaultProps = { data: [] };

export default BoqTable;
