import React from 'react';
import { FormattedMessage } from 'react-intl';
import validate from 'validate.js';

export const getEmailExclusionSchema = (
  projectOrgs,
  systemOrgs,
  value,
  attributes,
  message
) => {
  value = value?.toLowerCase();
  const orgIsInTheSystem = systemOrgs.find(org =>
    org.users.find(u => u.email.toLowerCase() === value)
  );
  let exclusion = {};
  if (orgIsInTheSystem) {
    if (
      projectOrgs.some(org =>
        org.users.some(u => u.email.toLowerCase() === value)
      )
    ) {
      exclusion = {
        exclusion: {
          within: projectOrgs.reduce(
            (acc, org) => acc.concat(org.users.map(u => u.email)),
            []
          ),
          message: message || (
            <FormattedMessage
              defaultMessage="Email is already a member of the project"
              id="error.INVALID_EMAIL_EXIST_PROJECT"
            />
          )
        }
      };
    } else if (orgIsInTheSystem.name !== attributes.organization_name) {
      exclusion = {
        exclusion: {
          within: systemOrgs.reduce(
            (acc, org) => acc.concat(org.users.map(u => u.email)),
            []
          ),
          message: (
            <FormattedMessage
              defaultMessage="Email is already a member of another organization"
              id="error.INVALID_ORGANIZATION_EMAIL_EXIST"
            />
          )
        }
      };
    }
  }
  return exclusion;
};

const array = (arrayItems = [], itemConstraints) => {
  return arrayItems.reduce((errors, item, index) => {
    const error = validate(item, itemConstraints);
    errors = error ? { ...errors, [index]: error } : errors;
    return errors;
  }, {});
};

const checked = (value, options) => {
  if (value !== true) {
    return options.message || 'must be checked';
  }
};

const exclusion = (value, options) => {
  if (validate.isArray(options)) options = { within: options };
  if (
    !validate.isDefined(value) || // empty value
    !options.within.some(opt => opt.toLowerCase() === value.toLowerCase())
  )
    return;

  var message = options.message || (
    <FormattedMessage
      defaultMessage="already exists"
      id="error.ALREADY_EXISTS"
    />
  );
  const optValue = options.within[value];
  if (validate.isString(optValue)) value = optValue;
  return validate.format(message, { value: value });
};

export default {
  array,
  checked,
  exclusion
};
