import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, TextField } from '@material-ui/core';
import { objectCreateOrUpdate } from 'redux/object/ObjectAction';
import { useStyles } from '../../../styles';
import _ from 'lodash';
import { ALL, MULTIPLE, SS_STORED_PROJECT_ID } from 'common/constants';

const Distribution = ({ objects, organizations }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [distribution, setDistribution] = useState([]);

  const distributionList = [...organizations];
  if (
    distributionList.length > 0 &&
    distributionList.length !== distribution.length
  )
    distributionList.unshift({
      id: ALL,
      name: intl.formatMessage({
        defaultMessage: 'TO ALL',
        id: 'object.TO_ALL'
      })
    });

  const updateDistribution = (event, value) => {
    event.persist();
    const dataSource = value.find(v => v.id === ALL) ? organizations : value;
    const newValue = dataSource.map(org =>
      org.organization ? org : { organization: { id: org.id, name: org.name } }
    );
    setDistribution(newValue);
  };

  const handleSubmit = () => {
    const updatedObjs = objects.map(obj => {
      let newDistro;
      if (distribution.length === organizations.length)
        newDistro = distribution;
      else
        newDistro = _.uniqBy(
          [...distribution, ...obj.distribution],
          'organization.id'
        );

      return {
        ...obj,
        distribution: { internal: newDistro, guests: [] }
      };
    });
    dispatch(
      objectCreateOrUpdate({
        id: MULTIPLE,
        objects: updatedObjs,
        project_id: sessionStorage.getItem(SS_STORED_PROJECT_ID)
      })
    );
  };

  return (
    <div className={classes.componentPopupRoot}>
      <Autocomplete
        filterSelectedOptions
        getOptionLabel={option =>
          option.name || option.organization?.name || ''
        }
        getOptionSelected={(distributionList, distribution) => {
          return (
            distributionList.id ===
            (distribution.organization?.id || distribution.id)
          );
        }}
        id="distribution"
        multiple
        name="distribution"
        onChange={updateDistribution}
        options={distributionList}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            label={
              <FormattedMessage
                defaultMessage="Share with"
                id="object.SHARE_WITH"
              />
            }
            size="small"
            variant="outlined"
          />
        )}
        size="small"
        value={distribution}
      />
      <Button
        className={classes.marginTop2}
        color="secondary"
        disabled={distribution.length < 1}
        onClick={handleSubmit}
        type="submit"
        variant="contained">
        <FormattedMessage
          defaultMessage="Save changes"
          id="common.SAVE_CHANGES"
        />
      </Button>
    </div>
  );
};

Distribution.propTypes = {
  objects: PropTypes.array,
  organizations: PropTypes.array
};

Distribution.defaultProps = {
  objects: [],
  organizations: []
};

export default Distribution;
