import Request from '../../common/request';

export default class DocumentApi extends Request {
  constructor(token, url) {
    super(url, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    this.endpoint = '/documentation';
  }

  all(route, params) {
    return super.get(`${this.endpoint}/${route}`, params);
  }

  info(route, id) {
    return super.get(`${this.endpoint}/${route}/${id}`);
  }

  create(route, params) {
    return super.post(`${this.endpoint}/${route}`, params);
  }

  update(route, id, params) {
    return super.put(`${this.endpoint}/${route}/${id}`, params);
  }

  delete(route, id) {
    return super.delete(`${this.endpoint}/${route}/${id}`);
  }

  getCategories(route, id) {
    return super.get(`${this.endpoint}/${route}?project_id=${id}`);
  }

  getOrgCategoryTemplates(orgId) {
    return super.get(
      `${this.endpoint}/templates/categories?organization_id=${orgId}`
    );
  }

  getCategoryTemplate(id) {
    return super.get(`${this.endpoint}/templates/categories/${id}`);
  }

  createCategoryTemplate(params) {
    return super.post(`${this.endpoint}/templates/categories`, params);
  }

  updateCategoryTemplate(id, params) {
    return super.put(`${this.endpoint}/templates/categories/${id}`, params);
  }

  deleteCategoryTemplate(id) {
    return super.delete(`${this.endpoint}/templates/categories/${id}`);
  }
}
