import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import OrganizationChart from '@dabeng/react-orgchart';
import {
  Box,
  Card,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  TextField,
  Typography
} from '@material-ui/core';
import {
  Clear as ClearIcon,
  Edit as EditIcon,
  Save as SaveIcon
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { CLEAR, EDIT, MEMBERS, SAVE, SOFT_HYPHEN } from 'common/constants';
import { organizationProjectUpdate } from 'redux/organization/OrganizationAction';
import { UserList } from 'views/Admin/OrgSettings/components';
import { useStyles } from '../styles';
import './styles.css';
import { isCurrentOrg } from 'common/helper';

const OrgChart = props => {
  const { isAdmin, organizations, projOrgs, projectId } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [states, setStates] = useState({
    editing: null,
    org: null,
    orgs: [],
    view: null
  });

  useEffect(() => {
    setStates(prevStates => ({
      ...prevStates,
      orgs: organizations,
      view: null
    }));
  }, [organizations]);

  const handleView = (e, type, data) => {
    e.stopPropagation();

    let view = null;

    switch (type) {
      case MEMBERS:
        view = <UserList myOrg={data} projId={projectId} orgChartView />;
        break;
      default:
    }

    setStates(prevStates => ({
      ...prevStates,
      view: view
    }));
  };

  const handleClick = (e, data, type) => {
    e.stopPropagation();

    const opts = {
      clear: () =>
        setStates(prevStates => ({
          ...prevStates,
          editing: null,
          org: null
        })),
      edit: () =>
        setStates(prevStates => ({
          ...prevStates,
          editing: data.id,
          org: data
        })),
      save: () => {
        dispatch(
          organizationProjectUpdate(states.org.id, projectId, {
            parent_organization_id: states.org.parent_organization
              ? states.org.parent_organization.id
              : ''
          })
        );

        setStates(prevStates => ({
          ...prevStates,
          editing: null,
          org: null
        }));
      }
    };

    return opts[type]();
  };

  const getPrimaryContact = users =>
    users.find(user => user.project_settings.find(ps => ps.primary_contact));

  const updateOrg = value =>
    setStates(prevStates => ({
      ...prevStates,
      org: {
        ...prevStates.org,
        parent_organization: value
      }
    }));

  const orgNode = ({ nodeData }) => {
    const primaryContact = getPrimaryContact(nodeData.users);
    let userEmail = primaryContact?.email;
    if (userEmail && userEmail.length > 20) {
      const index = userEmail.indexOf('@');
      userEmail =
        userEmail.substring(0, index) + SOFT_HYPHEN + userEmail.substr(index);
    }

    return (
      <Card className={classes.orgNodeContainer}>
        <List
          className={classes.orgNodeContainerNav}
          component="nav"
          disablePadding>
          <ListItem
            button
            classes={{
              root:
                isCurrentOrg(nodeData.id)
                  ? classes.orgNodeOwn
                  : classes.orgNodePosition,
              disabled: classes.disabled
            }}
            disabled={isAdmin}
            onClick={e => handleView(e, MEMBERS, nodeData)}>
            <ListItemText
              className={classes.orgNodeContainerTitle}
              disableTypography
              primary={
                <Typography className={classes.boldLabel}>
                  {nodeData.name}
                </Typography>
              }
            />
            {states.editing === nodeData.id ? (
              <ListItemSecondaryAction>
                <IconButton
                  aria-label="edit"
                  edge="end"
                  onClick={e => handleClick(e, nodeData, CLEAR)}
                  size="small">
                  <ClearIcon color="action" fontSize="small" />
                </IconButton>
                <IconButton
                  aria-label="edit"
                  edge="end"
                  onClick={e => handleClick(e, nodeData, SAVE)}
                  size="small">
                  <SaveIcon color="action" fontSize="small" />
                </IconButton>
              </ListItemSecondaryAction>
            ) : isAdmin &&
              nodeData.parent_organization &&
              isCurrentOrg(nodeData.id) ? (
              <ListItemSecondaryAction>
                <IconButton
                  aria-label="edit"
                  edge="end"
                  onClick={e => handleClick(e, nodeData, EDIT)}
                  size="small">
                  <EditIcon color="action" fontSize="small" />
                </IconButton>
              </ListItemSecondaryAction>
            ) : null}
          </ListItem>
          {isAdmin ? (
            <>
              <ListItem component="div">
                <ListItemText
                  className={classes.orgNodeContainerDetailContent}
                  disableTypography
                  primary={
                    <>
                      <Typography
                        className={classes.orgNodeContainerDetailsTitle}>
                        <FormattedMessage
                          defaultMessage="ORG ID"
                          id="admin.ORG_ID"
                        />
                        :
                      </Typography>
                      <Typography className={classes.orgNodeContainerDetails}>
                        {nodeData.org_tag}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              <ListItem component="div">
                <ListItemText
                  className={classes.orgNodeContainerDetailContent}
                  disableTypography
                  primary={
                    <>
                      <Typography
                        className={classes.orgNodeContainerDetailsTitle}>
                        <FormattedMessage
                          defaultMessage="ORG TYPE"
                          id="admin.ORG_TYPE"
                        />
                        :
                      </Typography>
                      <Typography className={classes.orgNodeContainerDetails}>
                        <FormattedMessage
                          defaultMessage={nodeData.organization_type.name}
                          id={
                            'common.' +
                            nodeData.organization_type.name.toUpperCase()
                          }
                        />
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              {nodeData.parent_organization && (
                <ListItem component="div">
                  <ListItemText
                    className={classes.orgNodeContainerDetailContent}
                    disableTypography
                    primary={
                      <>
                        <Typography
                          className={classes.orgNodeContainerDetailsTitle}>
                          <FormattedMessage
                            defaultMessage="Contract Counterpart"
                            id="admin.CONTRACT_COUNTERPART"
                          />
                          :
                        </Typography>
                        {states.editing === nodeData.id ? (
                          <Autocomplete
                            className={classes.fullWidth}
                            filterSelectedOptions
                            getOptionLabel={option => option.name}
                            onChange={(e, value) => updateOrg(value)}
                            options={projOrgs.filter(d => d.id !== nodeData.id)}
                            renderInput={params => (
                              <TextField
                                {...params}
                                fullWidth
                                size="small"
                                variant="outlined"
                              />
                            )}
                            size="small"
                            value={states.org.parent_organization || null}
                          />
                        ) : (
                          <Typography
                            className={classes.orgNodeContainerDetails}>
                            {nodeData.parent_organization.name}
                          </Typography>
                        )}
                      </>
                    }
                  />
                </ListItem>
              )}
            </>
          ) : (
            <>
              <ListItem component="div">
                <ListItemText
                  className={classes.orgNodeContainerDetailContent}
                  disableTypography
                  primary={
                    <>
                      <Typography
                        className={classes.orgNodeContainerDetailsTitle}>
                        <FormattedMessage
                          defaultMessage="Contact"
                          id="common.CONTACT"
                        />
                        :
                      </Typography>
                      <Typography className={classes.orgNodeContainerDetails}>
                        {primaryContact?.name || userEmail}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              <ListItem component="div">
                <ListItemText
                  className={classes.orgNodeContainerDetailContent}
                  disableTypography
                  primary={
                    <>
                      <Typography
                        className={classes.orgNodeContainerDetailsTitle}>
                        <FormattedMessage
                          defaultMessage="E-mail"
                          id="common.EMAIL"
                        />
                        :
                      </Typography>
                      <Typography className={classes.orgNodeContainerDetails}>
                        {userEmail}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            </>
          )}
          {nodeData.parent_organization ? (
            <ListItem component="div">
              <ListItemText
                className={classes.orgNodeContainerDetailContent}
                disableTypography
                primary={
                  <>
                    <Typography
                      className={classes.orgNodeContainerDetailsTitle}>
                      <FormattedMessage
                        defaultMessage="Conditions of Contract"
                        id="common.CONDITIONS_OF_CONTRACT"
                      />
                      :
                    </Typography>
                    <Box alignItems="center" display="flex">
                      <Typography className={classes.orgNodeContainerDetails}>
                        {nodeData.standard?.name || null}
                      </Typography>
                    </Box>
                  </>
                }
              />
            </ListItem>
          ) : null}
        </List>
      </Card>
    );
  };

  return (
    <>
      {states.orgs.map(org => (
        <OrganizationChart
          NodeTemplate={orgNode}
          chartClass={classes.orgChartClass}
          collapsible={false}
          containerClass={classes.orgContainerClass}
          datasource={org}
          key={org.id}
        />
      ))}
      {states.view}
    </>
  );
};

OrgChart.propTypes = {
  isAdmin: PropTypes.bool,
  organizations: PropTypes.array,
  projOrgs: PropTypes.array,
  projectId: PropTypes.string.isRequired
};

OrgChart.defaultProps = {
  isAdmin: false,
  organizations: []
};

export default OrgChart;
