import * as screenTypes from './ScreenTypes';

const initialState = {
  isMobileDevice: false,
  screenOrientation: null,
  screenWidth: null
};

const screenReducer = (state = initialState, action) => {
  switch (action.type) {
    case screenTypes.SCREEN_SET_WIDTH:
      return {
        ...state,
        screenWidth: action.screenWidth
      };
    case screenTypes.SCREEN_SET_ORIENTATION:
      return {
        ...state,
        screenOrientation: action.orientation
      };
    case screenTypes.SET_IS_MOBILE_DEVICE:
      return {
        ...state,
        isMobileDevice: action.isMobileDevice
      };
    default:
      return state;
  }
};

export default screenReducer;
