import Parser from 'fast-xml-parser';
import { getValue, isObjectEmpty, valueInArray } from './helper';
import {
  ANDREKRAV,
  ANDREKRAVJA,
  ANDREKRAVNEI,
  ATTRIB_GRUPPE,
  ATTRIB_OVERSKRIFT,
  ATTRIB_STIKKORD,
  ATTRIB_TEKST,
  AVREGNING,
  BOQ_CODE_TEXT_KEYS_ORDER,
  BULLET_POINT,
  COMMA,
  HYPHEN,
  NS3459,
  OBJECT,
  PRISFORESPORSEL,
  PRISTILBUD,
  PROSJEKTDATA,
  PROSJEKTNS,
  PROSJEKTPROSESS
} from 'common/constants';

export const convertAndFormatBoQ = xml => {
  const xmlToJSON = Parser.parse(xml, {
    ignoreAttributes: false,
    parseNodeValue: true,
    trimValues: true
  });
  return formatBoQ(xmlToJSON);
};

export const formatBoQ = boq => {
  return new Promise((resolve, reject) => {
    const key1 = Object.keys(boq).findIndex(key => key === NS3459);

    if (key1 < 0) reject('object.XML_ERROR');

    const key2Boqs = Object.keys(boq[NS3459]);
    const key2 = key2Boqs.findIndex(key =>
      valueInArray([AVREGNING, PRISFORESPORSEL, PRISTILBUD, PROSJEKTDATA], key)
    );

    if (key2 < 0) reject('object.XML_ERROR');

    const key2Property = key2Boqs[key2];
    const key3Boqs = Object.keys(boq[NS3459][key2Property]);
    const key3 = key3Boqs.findIndex(key =>
      valueInArray([PROSJEKTNS, PROSJEKTPROSESS], key)
    );

    if (key3 < 0) reject('object.XML_ERROR');

    const key3Property = key3Boqs[key3];
    const {
      Poster: { Post }
    } = boq[NS3459][key2Property][key3Property];

    resolve({ key: key2Property, projectKey: key3Property, data: Post });
  });
};

export const getPostnrPath = postnrDel => {
  let path = [];
  if (postnrDel) {
    if (postnrDel.length) {
      for (let i = 0; i < postnrDel.length; i++)
        path.push(postnrDel[i].Kode.toString());
    } else path.push(postnrDel.Kode.toString()); // process only
  }
  return path;
};

const customConcat = (string1, string2, separator = '\n\n') => {
  const sep = string1 ? separator : '';
  return string2 ? `${string1}${sep}${string2}` : string1;
};

const generateText = (header, value = '', marker = BULLET_POINT) => {
  const formattedHeader = header ? marker + ' ' + header : '';
  return customConcat(formattedHeader, value);
};

const getGruppeAttrib = obj => getValue(obj?.[ATTRIB_GRUPPE]);
const getOverskriftAttrib = obj => getValue(obj?.[ATTRIB_OVERSKRIFT]);
const getStikkordAttrib = obj => getValue(obj?.[ATTRIB_STIKKORD]);
const getTekstAttrib = obj => getValue(obj?.[ATTRIB_TEKST]);
const getTekstUformatertValue = obj => getValue(obj?.Tekst?.Uformatert);
const getTekstValue = obj => getValue(obj?.Tekst);

/*
  ex: {
    KravA: {
      @_Gruppe: "a)",
      @_Overskrift: "Omfang og prisgrunnlag"
      Tekst: {
        Uformatert: "For avfukting av bygget medtas her leie og drift av avfuktingsaggregater (absorpsjonsavfuktere)",
      }
    },
    KravB: {
      @_Gruppe: "b)",
      @_Overskrift: "Materialer",
      Tekst: {
        Uformatert: "Avfukteraggregater må ha en kapasitet på min 20 l/døgn.",
      }
    },
    KravC: {
      @_Gruppe: "c)",
      @_Overskrift: "Utførelse",
      Tekst: {
        Uformatert: "Entreprenøren holder aggregater og sørger for døgnkontinuerlig drift, vedlikehold og tømming. Flytting i byggeperioden må medregnes",
      }
    },
    KravX: {
      @_Gruppe: "x)",
      @_Overskrift: "Mengderegler",
      Tekst: {
        Uformatert: "Det skal medtas leie i 2 apparatmåneder på hver avfukter\n\n\nYtelsen skal prises av Hovedentreprenør for bygningsmessige arbeider",
      }
    }
  }
  */
const getKravText = kravObject => {
  if (!isObjectEmpty(kravObject)) {
    const text = Object.keys(kravObject)
      .sort()
      .reduce((text, key) => {
        const obj = kravObject[key] || {};
        const kravText = generateText(
          getOverskriftAttrib(obj), // header
          getTekstUformatertValue(obj), // value
          getGruppeAttrib(obj) // Krav marker, e.g. a), b), x)
        );

        return customConcat(text, kravText);
      }, '');

    return text ? generateText(ANDREKRAV, text) : '';
  }
};

const generateKodeTekstText = (codeTextObj, key) => {
  const value = codeTextObj[key];

  if (key === ANDREKRAVJA) return getKravText(value);
  if (key === ANDREKRAVNEI) {
    const text = getTekstAttrib(value);
    return text ? generateText(ANDREKRAV, text) : '';
  }
  // ex: [
  //  {Tekst: 'Ut fra hovedtavlerom til kabelgrøft.', @_Stikkord: 'Lokalisering:'}
  //  {Tekst: 'Legegs i grøft iht. montasjeanbisning.', @_Stikkord: 'Leggekrav:'}
  //  {Tekst: '1%', @_Stikkord: 'Største deformasjon:'}
  // ]
  if (Array.isArray(value))
    return generateText(
      key,
      value
        .map(v => customConcat(getStikkordAttrib(v), getTekstValue(v), ' '))
        .join('\n')
    );
  // ex: {Tekst: 'Stål – galvanisert', @_Stikkord: 'Materiale:'}
  if (typeof value === OBJECT)
    return customConcat(getStikkordAttrib(value), getTekstValue(value), ' ');
  return value;
};

const getKodeTekstText = codeTextObj => {
  if (!isObjectEmpty(codeTextObj))
    return BOQ_CODE_TEXT_KEYS_ORDER.reduce((text, key) => {
      const codeText = generateKodeTekstText(codeTextObj, key);
      return customConcat(text, codeText);
    }, '');
};

export const formatBoQTable = (boq = [], projectKey) => {
  const boq2 = boq.data?.data || boq.data || boq;
  return boq2.map(data => {
    const {
      ID,
      Kode = {},
      object,
      Postnr = '',
      Postnrdeler = {},
      Prisinfo = {},
      Tekst
    } = data;
    const { Enhet = '', Enhetspris = 0, Mengde = 0, Sum = 0 } = Prisinfo;
    const { Postnrdel } = Postnrdeler;
    const path = getPostnrPath(Postnrdel);
    const post_nr_path = { path: path.join(COMMA), root: path[0] };

    let chapter, process;
    if (projectKey === PROSJEKTNS) {
      process = Postnr.toString();
      chapter = process.split(/-|\./)[0];
    } else {
      process = path.pop();
      chapter = path.join(HYPHEN);
    }

    const text =
      getKodeTekstText(Kode.Kodetekst) ||
      getTekstUformatertValue(Kode) ||
      Tekst?.Uformatert ||
      '';

    return {
      boq_xml_id: ID,
      chapter: chapter,
      process: process,
      post_nr: Postnr.toString(),
      post_nr_path: post_nr_path,
      description:
        Kode.Kodetekst?.Overskrift ||
        Kode.Overskrift?.Uformatert ||
        text.split('\n')[0] ||
        '',
      text: text,
      status: '',
      unit: Enhet,
      contractQuantity: Mengde,
      contractPercentage: 0,
      measuredQuantity: 0,
      approvedQuantity: 0,
      unitPrice: Enhetspris,
      contractSum: Sum || Mengde * Enhetspris,
      measuredSum: 0,
      approvedSum: 0,
      object: object
    };
  });
};
