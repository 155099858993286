import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import {
  CardContent,
  Chip,
  Divider,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { WysWyg } from '../../components';
import {
  clearAddedFields,
  documentFetch,
  objectTypeFetch,
  singleProjectFetch,
  userRolesFetch
} from '../../../../redux';
import { formatDate, serializeParams, valueInArray } from 'common/helper';
import { getStatusById, isOrgInDistribution } from '../helper';
import { useStyles } from '../../styles';
import {
  ACCESS_CONTROL_LEVEL,
  ACCESS_CONTROL_ORG,
  ALL,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
  DESCRIPTION,
  DISCIPLINE_TYPE_ID,
  DISTRIBUTION,
  LS_ORGANIZATION_ID,
  REVISION_VALUES,
  ROLE_OBSERVER,
  STATUS_APPROVED_AND_PUBLISHED,
  STATUS_DRAFT,
  STATUS_ISSUED_FOR_APPROVAL,
  STATUS_PUBLISHED,
  TYPES,
  VALUES
} from 'common/constants';

const Documentation = props => {
  const {
    clearTimer,
    createdBy,
    disableEdit: disabled,
    displayObjectView,
    formState,
    handleCommentChange,
    handleMentionItems,
    hasError,
    isCreatorOrg,
    isLatestRevision,
    isNew,
    isReloading,
    objectTypes,
    organizations,
    projectId,
    setFormState,
    setHasPopup,
    suggestions
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const organizationId = localStorage.getItem(LS_ORGANIZATION_ID);
  const {
    document,
    object,
    user: { roles }
  } = useSelector(state => state);
  const fsValuesField = formState.isRevision ? REVISION_VALUES : VALUES;
  const disableEdit = disabled || !isLatestRevision;

  const {
    access_policy_unshared,
    access_policy_write,
    comments = [],
    discipline_type_id,
    distribution,
    history,
    id: objId,
    metadata: {
      access_control_level,
      access_control_org,
      created_by_org_id,
      description
    },
    new_comment,
    organization,
    status,
    status_id
  } = formState[fsValuesField];
  const currStatus = getStatusById(object.status, status_id);
  const isDraft = status && status.name === STATUS_DRAFT;
  const senderOrgId = created_by_org_id || organizationId;
  const disableApprover = disableEdit || !isCreatorOrg || !isDraft;
  const allDistribution = distribution
    ? distribution.internal.concat(distribution.guests)
    : [];
  const notCreatorOrgs = organizations.filter(org => org.id !== senderOrgId);
  const distributionList = [...notCreatorOrgs];
  if (
    distributionList.length && // not empty
    distributionList.length !== allDistribution.length
  )
    distributionList.unshift({
      id: ALL,
      name: intl.formatMessage({
        defaultMessage: 'TO ALL',
        id: 'object.TO_ALL'
      })
    });
  // The organization should be able to remove restriction for own users
  // without falling back to the default set by the Doc creator.
  // However, if access_level is 0 (Observer), it should not display anything in the form
  // since Observer is not one of the options -- set role to null
  const role_access_level =
    access_control_org && organizationId in access_control_org
      ? access_control_org[organizationId]
      : access_control_level;
  const role =
    roles && role_access_level
      ? roles.find(r => r.access_level === role_access_level)
      : null;

  useEffect(() => {
    dispatch(clearAddedFields());
    if (object.types.length === 0) dispatch(objectTypeFetch());
    if (document.types.length === 0) dispatch(documentFetch(TYPES));
    if (roles.length === 0) dispatch(userRolesFetch());
    if (!organizations)
      dispatch(
        singleProjectFetch(
          projectId,
          serializeParams({ organization_id: organizationId })
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (
      currStatus &&
      status &&
      currStatus.id !== status.id &&
      valueInArray(
        [STATUS_APPROVED_AND_PUBLISHED, STATUS_PUBLISHED],
        currStatus.name
      ) &&
      discipline_type_id &&
      (!history ||
        (history[0] && history[0].status.name !== STATUS_ISSUED_FOR_APPROVAL))
    ) {
      setFormState(formState => ({
        ...formState,
        [fsValuesField]: {
          ...formState[fsValuesField],
          discipline_type_id: null,
          organization: null
        }
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currStatus]);

  const getCreatedDate = () => {
    if (isNew) {
      return formatDate(new Date(), DEFAULT_DATE_FORMAT);
    } else {
      if (
        formState[fsValuesField].history &&
        formState[fsValuesField].history.length > 0
      ) {
        return formatDate(
          formState[fsValuesField].history[0].ctime,
          DEFAULT_DATE_FORMAT
        );
      } else return '';
    }
  };

  const getLastUpdated = () => {
    if (!isNew)
      return formatDate(
        formState[fsValuesField].last_updated,
        DEFAULT_DATE_FORMAT
      );
  };

  const handleDataChange = (event, value, field, isMetadata = false) => {
    event.persist();
    if (isMetadata) {
      setFormState(formState => {
        let metadata = { ...formState[fsValuesField].metadata };
        value = field === ACCESS_CONTROL_LEVEL ? value?.access_level : value;

        if (
          field === ACCESS_CONTROL_LEVEL &&
          metadata.created_by_org_id !== organizationId
        ) {
          metadata = {
            ...metadata,
            [ACCESS_CONTROL_ORG]: {
              ...metadata[ACCESS_CONTROL_ORG],
              [organizationId]: value ?? 0
            }
          };
        } else {
          if (!value) delete metadata[field];
          else metadata = { ...metadata, [field]: value };
        }

        return {
          ...formState,
          [fsValuesField]: {
            ...formState[fsValuesField],
            metadata: metadata
          }
        };
      });
    } else {
      const newData =
        field === DISCIPLINE_TYPE_ID
          ? { [field]: value ? value.id : '', organization: value }
          : { [field]: value };
      setFormState(formState => ({
        ...formState,
        [fsValuesField]: {
          ...formState[fsValuesField],
          ...newData
        }
      }));
    }
  };

  const handleUserLists = (event, value, group) => {
    let dataSource;
    const creatorIsInDistro = isOrgInDistribution(
      distribution.internal,
      created_by_org_id
    );
    if (group === DISTRIBUTION && value.find(v => v.id === ALL))
      dataSource =
        organization?.id === created_by_org_id || creatorIsInDistro
          ? organizations
          : notCreatorOrgs;
    else {
      let appendOrg =
        organization?.id &&
        !value.find(v =>
          valueInArray([v.id, v.organization?.id], organization.id)
        )
          ? [organization] // approver
          : [];

      if (
        creatorIsInDistro &&
        !value.find(v =>
          valueInArray([v.id, v.organization?.id], created_by_org_id)
        )
      )
        appendOrg.push(organizations.find(org => org.id === created_by_org_id));

      dataSource = value.concat(appendOrg);
    }

    const newValue = dataSource.map(org => {
      return org.organization ? org : { organization: org };
    });
    handleDataChange(event, { internal: newValue, guests: [] }, group);
  };

  return (
    <div>
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <TextField
                  disabled={
                    !isLatestRevision ||
                    (!isCreatorOrg && (disableEdit || !isDraft))
                  }
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                  label={
                    <FormattedMessage
                      defaultMessage="Description"
                      id="common.DESCRIPTION"
                    />
                  }
                  name={DESCRIPTION}
                  onChange={e =>
                    handleDataChange(e, e.target.value, DESCRIPTION, true)
                  }
                  size="small"
                  type="text"
                  value={description || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={3} xs={4}>
                <TextField
                  disabled
                  fullWidth
                  label={
                    <FormattedMessage
                      defaultMessage="Created by"
                      id="common.CREATED_BY"
                    />
                  }
                  size="small"
                  value={createdBy || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={3} xs={4}>
                <TextField
                  disabled
                  fullWidth
                  label={
                    <FormattedMessage
                      defaultMessage="Created date"
                      id="object.CREATED_DATE"
                    />
                  }
                  size="small"
                  value={getCreatedDate() || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={3} xs={4}>
                <TextField
                  disabled
                  fullWidth
                  label={
                    <FormattedMessage
                      defaultMessage="Last updated"
                      id="object.LAST_UPDATED"
                    />
                  }
                  size="small"
                  value={getLastUpdated() || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={3} xs={4}>
                {organization || isDraft ? (
                  <Autocomplete
                    disabled={disableApprover}
                    filterSelectedOptions
                    getOptionLabel={option => option.name}
                    id="disicpline_type_id"
                    name="discipline_type_id"
                    onChange={(e, value) =>
                      handleDataChange(e, value, DISCIPLINE_TYPE_ID)
                    }
                    options={organizations}
                    renderInput={params => (
                      <TextField
                        {...params}
                        error={hasError(DISCIPLINE_TYPE_ID)}
                        fullWidth
                        helperText={
                          hasError(DISCIPLINE_TYPE_ID)
                            ? formState.errors.discipline_type_id[0]
                            : null
                        }
                        label={
                          <FormattedMessage
                            defaultMessage="Approver"
                            id="object.APPROVER"
                          />
                        }
                        size="small"
                        variant="outlined"
                      />
                    )}
                    size="small"
                    value={organization || null}
                  />
                ) : null}
              </Grid>
              <Grid item md={3} xs={8}>
                <Autocomplete
                  disabled={
                    !isLatestRevision ||
                    access_policy_unshared ||
                    !access_policy_write
                  }
                  filterSelectedOptions
                  getOptionLabel={option =>
                    option.name
                      ? intl.formatMessage({
                          id: 'roles.' + option.name.toUpperCase()
                        })
                      : ''
                  }
                  id={ACCESS_CONTROL_LEVEL}
                  name={ACCESS_CONTROL_LEVEL}
                  onChange={(event, value) =>
                    handleDataChange(event, value, ACCESS_CONTROL_LEVEL, true)
                  }
                  options={roles.filter(r => r.name !== ROLE_OBSERVER)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      label={
                        <FormattedMessage
                          defaultMessage="Restrict access"
                          id="object.SELECT_ACCESS_CONTROL"
                        />
                      }
                      size="small"
                      variant="outlined"
                    />
                  )}
                  size="small"
                  value={role || null}
                />
              </Grid>
              <Grid item md={9} xs={12}>
                <Autocomplete
                  disabled={
                    objId && status_id
                      ? !formState[fsValuesField].access_policy_distribution
                      : false
                  }
                  filterSelectedOptions
                  getOptionLabel={option =>
                    option.name ||
                    (option.organization && option.organization.name) ||
                    ''
                  }
                  getOptionSelected={(distributionList, allDistribution) => {
                    return (
                      distributionList.id ===
                      (allDistribution.organization
                        ? allDistribution.organization.id
                        : allDistribution.id)
                    );
                  }}
                  id="distribution"
                  multiple
                  name="distribution"
                  onChange={(event, value) =>
                    handleUserLists(event, value, DISTRIBUTION)
                  }
                  options={distributionList}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      label={
                        <FormattedMessage
                          defaultMessage="Share with"
                          id="object.SHARE_WITH"
                        />
                      }
                      size="small"
                      variant="outlined"
                    />
                  )}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => {
                      const tagProps = getTagProps({ index });
                      return (
                        <Chip
                          {...tagProps}
                          deleteIcon={
                            valueInArray(
                              [organization?.id, created_by_org_id],
                              option.organization.id
                            ) ? (
                              <></>
                            ) : null
                          }
                          label={option.organization.name}
                          onDelete={tagProps.onDelete}
                          size="small"
                        />
                      );
                    })
                  }
                  size="small"
                  value={allDistribution}
                />
              </Grid>
            </Grid>
            <Grid
              className={classes.marginTop2}
              item
              md={12}
              style={{ paddingBottom: 0 }}
              xs={12}>
              <Typography className={classes.boldLabel}>
                <FormattedMessage
                  defaultMessage="Comments"
                  id="object.COMMENTS"
                />
                :
              </Typography>
            </Grid>
            {comments.map((comment, index) => (
              <Fragment key={`grid_label_${index}`}>
                <Grid item md={12} style={{ paddingBottom: 0 }} xs={12}>
                  <Grid container justifyContent="space-between">
                    <Typography>
                      {comment.user?.name
                        ? `${comment.user.name}:`
                        : comment.user?.email
                        ? `${comment.user.email}:`
                        : ''}
                    </Typography>
                    <Typography>
                      {formatDate(comment.date, DEFAULT_DATE_TIME_FORMAT)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  className={classes.marginBottom1}
                  item
                  key={`grid_comment_${index}`}
                  md={12}
                  xs={12}>
                  <WysWyg
                    defaultValue={comment.content}
                    displayObjectView={displayObjectView}
                    handleMentionItems={handleMentionItems}
                    ignoreExistingUserMentions
                    key={`${objId}_comment_${index}`}
                    mentionedObjs={formState.mentionedObjs}
                    mentionedUsers={formState.mentionedUsers}
                    name={`${objId}_comment_${index}`}
                    readOnly
                  />
                </Grid>
              </Fragment>
            ))}
            {disableEdit && comments.length ? null : (
              <Grid item md={12} xs={12}>
                <WysWyg
                  clearTimer={clearTimer}
                  defaultValue={new_comment?.content}
                  displayObjectView={displayObjectView}
                  handleMentionItems={handleMentionItems}
                  handleParentEditorChange={handleCommentChange}
                  ignoreExistingUserMentions
                  isReloading={isReloading}
                  key={`${objId}_new_comment`}
                  mentionedObjs={formState.mentionedObjs}
                  mentionedUsers={formState.mentionedUsers}
                  name={`${objId}_new_comment`}
                  objectTypes={objectTypes}
                  projectId={projectId}
                  readOnly={disableEdit}
                  setHasPopup={setHasPopup}
                  suggestions={suggestions}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </div>
  );
};

Documentation.propTypes = {
  clearTimer: PropTypes.func,
  createdBy: PropTypes.string,
  disableEdit: PropTypes.bool,
  displayObjectView: PropTypes.func,
  formState: PropTypes.object,
  handleCommentChange: PropTypes.func,
  handleMentionItems: PropTypes.func,
  hasError: PropTypes.func,
  isCreatorOrg: PropTypes.bool,
  isLatestRevision: PropTypes.bool,
  isNew: PropTypes.bool,
  isReloading: PropTypes.bool,
  objectTypes: PropTypes.array,
  organizations: PropTypes.array,
  projectId: PropTypes.string,
  setFormState: PropTypes.func,
  setHasPopup: PropTypes.func,
  suggestions: PropTypes.array
};

export default Documentation;
