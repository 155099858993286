import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Alert } from '@material-ui/lab';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import {
  AddCircle as AddCircleIcon,
  RemoveCircle as RemoveCircleIcon
} from '@material-ui/icons';
import { hasError, isObjectEmpty } from 'common/helper';
import { organizationDiscAdd, organizationFetch } from '../../../../../redux';
import { useStyles } from '../../../styles';
import validate from 'validate.js';
import { CODE, NAME, WIDTH_XS } from 'common/constants';

const AddDisciplineType = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    screen: { screenWidth }
  } = useSelector(state => state);
  const {
    existingDisciplines,
    status,
    organizationId,
    orgData,
    onPopupClose
  } = props;
  const schema = {
    name: {
      presence: {
        allowEmpty: false,
        message: (
          <FormattedMessage
            defaultMessage="Name is required"
            id="error.REQUIRED_NAME"
          />
        )
      },
      length: {
        maximum: 64,
        message: (
          <FormattedMessage
            defaultMessage="{value} is too long (maximum is {num} characters)"
            id="error.MAX_LENGTH"
            values={{
              num: 64,
              value: <FormattedMessage defaultMessage="Name" id="common.NAME" />
            }}
          />
        )
      },
      exclusion: existingDisciplines.map(d => d.name)
    },
    code: {
      presence: {
        allowEmpty: false,
        message: (
          <FormattedMessage
            defaultMessage="Code is required"
            id="error.REQUIRED_CODE"
          />
        )
      },
      length: {
        maximum: 4,
        message: (
          <FormattedMessage
            defaultMessage="{value} is too long (maximum is {num} characters)"
            id="error.MAX_LENGTH"
            values={{
              num: 4,
              value: <FormattedMessage defaultMessage="Code" id="common.CODE" />
            }}
          />
        )
      },
      exclusion: existingDisciplines.map(d => d.code)
    }
  };

  const initItem = { values: {}, errors: validate({}, schema) };
  const [rows, setRows] = useState([initItem]);

  useEffect(() => {
    if (status && !status.error) onPopupClose();
  }, [status, onPopupClose]);

  useEffect(() => {
    if (orgData.discAdded && !orgData.discAdded.error) {
      dispatch(organizationFetch(null, true));
      onPopupClose();
    }
  }, [orgData.discAdded, dispatch, onPopupClose]);

  const handleAddRow = () => {
    setRows([...rows, initItem]);
  };

  const handleRemoveRow = index => {
    rows.splice(index, 1);
    setRows([...rows]);
  };

  const handleChange = (event, index) => {
    event.persist();
    const { name, value } = event.target;
    const currRow = rows[index];
    const newRow = Object.assign(
      {},
      {
        values: {
          ...currRow.values,
          [name]: name === CODE ? value.toUpperCase() : value
        }
      }
    );
    newRow.errors = validate(newRow.values, schema);
    rows[index] = newRow;
    setRows([...rows]);
  };

  const handleAdd = () => {
    dispatch(
      organizationDiscAdd({
        organization_id: organizationId,
        discipline_types: rows.map(row => row.values)
      })
    );
  };

  const allErrors = rows.reduce((acc, row) => {
    return isObjectEmpty(row.errors) ? acc : acc.concat(row.errors);
  }, []);
  return (
    <div className={classes.popupRoot}>
      {status?.error ? (
        <Alert className={classes.alert} severity="error">
          <FormattedMessage
            defaultMessage={status.error}
            id="error.UNEXPECTED_RESPONSE"
          />
        </Alert>
      ) : null}
      <Typography variant="h2">
        <FormattedMessage
          defaultMessage="Add Discipline Type"
          id="discipline.ADD_DISCIPLINE TYPE"
        />
      </Typography>
      <Divider className={classes.inviteDivider} />
      {rows.map((item, index) => {
        const rowErrors = item.errors;
        return (
          <Grid
            container
            key={index}
            spacing={screenWidth === WIDTH_XS ? 0 : 2}>
            <Grid item sm={4} xs={12}>
              <TextField
                className={classes.marginTop2}
                error={hasError(rowErrors, NAME)}
                fullWidth
                helperText={
                  hasError(rowErrors, NAME) ? rowErrors.name[0] : null
                }
                id={NAME}
                label={
                  <FormattedMessage defaultMessage="Name" id="common.NAME" />
                }
                name={NAME}
                onChange={event => handleChange(event, index)}
                size="small"
                type="text"
                value={item.values.name || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <TextField
                className={classes.marginTop2}
                error={hasError(rowErrors, CODE)}
                fullWidth
                helperText={
                  hasError(rowErrors, CODE) ? rowErrors.code[0] : null
                }
                id="abbreviation"
                inputProps={{ style: { textTransform: 'uppercase' } }}
                label={
                  <FormattedMessage
                    defaultMessage="Abbreviation"
                    id="admin.ABBREVIATION"
                  />
                }
                name={CODE}
                onChange={event => handleChange(event, index)}
                size="small"
                type="text"
                value={item.values.code || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item sm={4} xs={9}>
              <TextField
                className={classes.marginTop2}
                fullWidth
                id="description"
                label={
                  <FormattedMessage
                    defaultMessage="Description"
                    id="common.DESCRIPTION"
                  />
                }
                name="description"
                onChange={event => handleChange(event, index)}
                size="small"
                type="text"
                value={item.values.description || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item sm={1} xs={3}>
              <Box
                className={classes.fullHeight}
                display="flex"
                justifyContent="flex-end">
                <IconButton
                  data-testid="remove-row"
                  onClick={() => handleRemoveRow(index)}
                  size="small">
                  <RemoveCircleIcon className={classes.deleteIcon} />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        );
      })}
      <Grid container>
        <Grid item md={12} sm={12}>
          <Button
            className={classes.inviteButton}
            color="primary"
            data-testid="add-row"
            onClick={handleAddRow}
            size="medium"
            startIcon={<AddCircleIcon className={classes.addInviteIcon} />}>
            <FormattedMessage defaultMessage="Add More" id="common.ADD_MORE" />
          </Button>
        </Grid>
      </Grid>
      <Divider className={classes.inviteDivider} />
      <Button
        color="primary"
        data-testid="save"
        disabled={allErrors.length > 0}
        onClick={handleAdd}
        size="large"
        variant="contained">
        <FormattedMessage defaultMessage="Save" id="common.SAVE" />
      </Button>
    </div>
  );
};

AddDisciplineType.propTypes = {
  existingDisciplines: PropTypes.array,
  onPopupClose: PropTypes.func,
  organizationId: PropTypes.string,
  orgData: PropTypes.object,
  status: PropTypes.object
};

export default AddDisciplineType;
