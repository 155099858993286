import * as organizationTypes from './OrganizationTypes';
import Str8line from '../auth/AuthApi';
import { LS_STR8LINE_TOKEN } from 'common/constants';

export const organizationRequest = () => {
  return { type: organizationTypes.ORGANIZATION_REQUEST };
};
export const organizationSuccess = (organization, updated, isAll) => {
  return {
    type: isAll
      ? organizationTypes.ORGANIZATION_ALL_SUCCESS
      : organizationTypes.ORGANIZATION_SUCCESS,
    payload: organization,
    updated: updated
  };
};

export const organizationFailure = error => {
  return { type: organizationTypes.ORGANIZATION_FAILURE, payload: error };
};

export const organizationFetch = (params, updated) => {
  return dispatch => {
    dispatch(organizationRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.organization
      .all(params)
      .then(response => {
        dispatch(organizationSuccess(response.data.data, updated, !!params));
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(organizationFailure(errorMsg));
      });
  };
};

export const organizationCreateSuccess = organization => {
  return {
    type: organizationTypes.ORGANIZATION_CREATE_SUCCESS,
    payload: organization
  };
};

export const organizationCreateFailure = error => {
  return {
    type: organizationTypes.ORGANIZATION_CREATE_FAILURE,
    payload: error
  };
};

export const organizationCreate = params => {
  return dispatch => {
    dispatch(organizationRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.organization
      .create(params)
      .then(response => {
        dispatch(organizationCreateSuccess(response.data.data));
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(organizationCreateFailure(errorMsg));
      });
  };
};

export const organizationUpdateSuccess = organization => {
  return {
    type: organizationTypes.ORGANIZATION_UPDATE_SUCCESS,
    payload: organization
  };
};

export const organizationUpdateFailure = error => {
  return {
    type: organizationTypes.ORGANIZATION_UPDATE_FAILURE,
    payload: error
  };
};

export const organizationUpdate = (id, params) => {
  return dispatch => {
    dispatch(organizationRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.organization
      .update(id, params)
      .then(response => {
        dispatch(organizationUpdateSuccess(response.data.data));
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(organizationUpdateFailure(errorMsg));
      });
  };
};

export const organizationDeleteSuccess = () => {
  return { type: organizationTypes.ORGANIZATION_DELETE_SUCCESS };
};

export const organizationDeleteFailure = error => {
  return {
    type: organizationTypes.ORGANIZATION_DELETE_FAILURE,
    payload: error
  };
};

export const organizationDelete = id => {
  return dispatch => {
    dispatch(organizationRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.organization
      .delete(id)
      .then(() => {
        dispatch(organizationDeleteSuccess());
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(organizationDeleteFailure(errorMsg));
      });
  };
};

export const organizationUserDeleteSuccess = data => {
  return { type: organizationTypes.ORGANIZATION_USER_DELETE_SUCCESS };
};

export const organizationUserDeleteFailure = error => {
  return {
    type: organizationTypes.ORGANIZATION_USER_DELETE_FAILURE,
    payload: error
  };
};

export const organizationUserDelete = (id, params) => {
  return dispatch => {
    dispatch(organizationRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.organization
      .deleteUser(id, params)
      .then(() => {
        dispatch(organizationUserDeleteSuccess());
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(organizationUserDeleteFailure(errorMsg));
      });
  };
};

export const organizationUserUpdateSuccess = data => {
  return { type: organizationTypes.ORGANIZATION_USER_UPDATE_SUCCESS };
};

export const organizationUserUpdateFailure = error => {
  return {
    type: organizationTypes.ORGANIZATION_USER_UPDATE_FAILURE,
    payload: error
  };
};

export const organizationUserUpdate = (orgId, userId, params) => {
  return dispatch => {
    dispatch(organizationRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.organization
      .updateUser(orgId, userId, params)
      .then(() => {
        dispatch(organizationUserUpdateSuccess());
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(organizationUserUpdateFailure(errorMsg));
      });
  };
};

const organizationProjectUpdateSuccess = data => {
  return {
    type: organizationTypes.ORGANIZATION_PROJECT_UPDATE_SUCCESS,
    payload: data
  };
};
const organizationProjectUpdateFailure = error => {
  return {
    type: organizationTypes.ORGANIZATION_PROJECT_UPDATE_FAILURE,
    payload: error
  };
};
export const organizationProjectUpdate = (orgId, projectId, params) => {
  return dispatch => {
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.organization
      .updateOrgProject(orgId, projectId, params)
      .then(response => {
        dispatch(organizationProjectUpdateSuccess(response.data));
      })
      .catch(error => {
        dispatch(organizationProjectUpdateFailure(error.message));
      });
  };
};

const orgInviteSuccess = data => {
  return { type: organizationTypes.ORGANIZATION_INVITE_SUCCESS };
};
const orgInviteFailure = error => {
  return {
    type: organizationTypes.ORGANIZATION_INVITE_FAILURE,
    payload: error
  };
};
export const organizationInvite = (id, params) => {
  return dispatch => {
    dispatch(organizationRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.organization
      .invite(id, params)
      .then(() => {
        dispatch(orgInviteSuccess());
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(orgInviteFailure(errorMsg));
      });
  };
};

const orgProjInviteSuccess = data => {
  return {
    type: organizationTypes.ORGANIZATION_PROJECT_INVITE_SUCCESS,
    payload: data
  };
};

const orgProjInviteFailure = error => {
  return {
    type: organizationTypes.ORGANIZATION_PROJECT_INVITE_FAILURE,
    payload: error
  };
};

export const organizationProjectInvite = (id, params) => {
  return dispatch => {
    dispatch(organizationRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.organization
      .projectInvite(id, params)
      .then(response => {
        dispatch(orgProjInviteSuccess(response.data.data));
      })
      .catch(error => {
        dispatch(orgProjInviteFailure(error.message));
      });
  };
};

const organizationTypeRequest = () => {
  return { type: organizationTypes.ORGANIZATION_TYPE_LOADING };
};

const organizationTypeSuccess = types => {
  return { type: organizationTypes.ORGANIZATION_TYPE_SUCCESS, payload: types };
};

const organizationTypeFailure = error => {
  return { type: organizationTypes.ORGANIZATION_TYPE_FAILURE, payload: error };
};

export const organizationTypeFetch = () => {
  return dispatch => {
    dispatch(organizationTypeRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.organization
      .types()
      .then(response => {
        dispatch(organizationTypeSuccess(response.data.data));
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(organizationTypeFailure(errorMsg));
      });
  };
};

const orgDiscAddFailure = error => {
  return {
    type: organizationTypes.ORGANIZATION_DISC_CREATE_FAILURE,
    payload: error
  };
};
const orgDiscAddSuccess = () => {
  return { type: organizationTypes.ORGANIZATION_DISC_CREATE_SUCCESS };
};
export const organizationDiscAdd = params => {
  return dispatch => {
    dispatch(organizationRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.organization
      .discAdd(params)
      .then(() => {
        dispatch(orgDiscAddSuccess());
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(orgDiscAddFailure(errorMsg));
      });
  };
};

const orgDiscUpdateSuccess = () => {
  return { type: organizationTypes.ORGANIZATION_DISC_UPDATE_SUCCESS };
};
const orgDiscUpdateFailure = error => {
  return {
    type: organizationTypes.ORGANIZATION_DISC_UPDATE_FAILURE,
    payload: error
  };
};
export const organizationDiscUpdate = (params, id) => {
  return dispatch => {
    dispatch(organizationRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.organization
      .discUpdate(params, id)
      .then(() => {
        dispatch(orgDiscUpdateSuccess());
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(orgDiscUpdateFailure(errorMsg));
      });
  };
};

const orgTemplateRequest = () => {
  return { type: organizationTypes.ORGANIZATION_MEETING_TEMPLATE_REQUEST };
};
const orgTemplateFailure = error => {
  return {
    type: organizationTypes.ORGANIZATION_MEETING_TEMPLATE_FAILURE,
    payload: error
  };
};
const orgTemplateSuccess = data => {
  return {
    type: organizationTypes.ORGANIZATION_MEETING_TEMPLATE_SUCCESS,
    payload: data
  };
};
const orgTemplateSubmitSuccess = () => {
  return {
    type: organizationTypes.ORGANIZATION_MEETING_TEMPLATE_SUBMIT_SUCCESS
  };
};

export const orgMeetingTemplatesFetch = orgId => {
  return dispatch => {
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.organization
      .getOrgMeetingTemplates(orgId)
      .then(response => {
        dispatch(orgTemplateSuccess(response.data.data));
      })
      .catch(error => {
        dispatch(orgTemplateFailure(error.message));
      });
  };
};

export const orgMeetingTemplateSubmit = (params, id) => {
  return dispatch => {
    dispatch(orgTemplateRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    const promise = id
      ? api.organization.updateMeetingTemplate(id, params)
      : api.organization.createMeetingTemplate(params);
    promise
      .then(() => {
        dispatch(orgTemplateSubmitSuccess());
      })
      .catch(error => {
        dispatch(orgTemplateFailure(error.message));
      });
  };
};

export const orgMeetingTemplateDelete = id => {
  return dispatch => {
    dispatch(orgTemplateRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.organization
      .deleteMeetingTemplate(id)
      .then(() => {
        dispatch(orgTemplateSubmitSuccess());
      })
      .catch(error => {
        dispatch(orgTemplateFailure(error.message));
      });
  };
};

export const orgMeetingAgendaDelete = id => {
  return dispatch => {
    dispatch(orgTemplateRequest());
    const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
    api.organization
      .deleteMeetingTemplateAgenda(id)
      .then(() => {
        dispatch(orgTemplateSubmitSuccess());
      })
      .catch(error => {
        dispatch(orgTemplateFailure(error.message));
      });
  };
};

export const clearOrganizationMessages = () => {
  return { type: organizationTypes.ORGANIZATION_CLEAR_MESSAGES };
};
