import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Typography } from '@material-ui/core';
import { CostTable } from '../../components';
import { WysWyg } from '../../../components';
import { useStyles } from '../../../styles';
import { eo_impact, koe_eo_settlement } from '../../../components/data';
import { isObjectEmpty } from 'common/helper';
import * as Constants from 'common/constants';
import classNames from 'classnames';
import { getWyswygViewData } from 'views/Object/components/helper';

const Eo = props => {
  const {
    createdBy,
    displayObjectView,
    isSenderOrg,
    objectInfo,
    recipientName
  } = props;
  const classes = useStyles();
  const intl = useIntl();
  const objectEo = objectInfo.metadata;

  let hasPrice = false,
    hasSchedule = false,
    impact_description = '',
    impact_price = '',
    impact_schedule = '',
    comments = [],
    description = [],
    impact_price_description = [],
    impacts = [],
    settlements = [];
  if (!isObjectEmpty(objectEo)) {
    if (objectEo.comment) comments = objectEo.comment;
    if (objectEo.new_comment_text && !isSenderOrg)
      comments = getWyswygViewData(comments, objectEo.new_comment);
    if (objectEo.description) description = objectEo.description;
    if (objectEo.new_description_text && isSenderOrg)
      description = getWyswygViewData(description, objectEo.new_description);
    if (objectEo.total_cost_nok) impact_price = objectEo.total_cost_nok;
    if (objectEo.impact_total_schedule_days)
      impact_schedule = objectEo.impact_total_schedule_days;
    if (objectEo.impact_description)
      impact_description = objectEo.impact_description;
    if (objectEo.impact_price_description)
      impact_price_description = objectEo.impact_price_description;
    if (objectEo.impact && objectEo.impact.length > 0)
      impacts = eo_impact
        .filter(ei => objectEo.impact.includes(ei.value))
        .map(i => {
          if (i.name === Constants.IMPACT_PRICE) hasPrice = true;
          if (i.name === Constants.IMPACT_SCHEDULE) hasSchedule = true;
          return intl.formatMessage({
            defaultMessage: i.name,
            id: 'object.' + i.name.toUpperCase()
          });
        });
    if (objectEo.settlement && objectEo.settlement.length > 0)
      settlements = koe_eo_settlement
        .filter(es => objectEo.settlement.includes(es.value))
        .map(s =>
          intl.formatMessage({
            defaultMessage: s.name,
            id: 'object.' + s.name.toUpperCase()
          })
        );
  }

  return (
    <div>
      <Typography component="div">
        <Box
          fontSize="h4.fontSize"
          fontWeight="fontWeightBold"
          m={1}
          textAlign="left">
          {objectInfo.name} {objectInfo.title}
        </Box>
        <Box className={classes.noMarginBottom} m={1} textAlign="left">
          <FormattedMessage
            defaultMessage="Created by"
            id="common.CREATED_BY"
          />
          : {createdBy}
        </Box>
        <Box className={classes.noMarginBottom} m={1} textAlign="left">
          <FormattedMessage id="object.IMPACT" defaultMessage="Impact" />:{' '}
          {impacts.join(', ')}
        </Box>
        {hasSchedule ? (
          <Box
            className={classNames(classes.noMarginBottom, classes.noMarginTop)}
            m={1}
            textAlign="left">
            <FormattedMessage
              id="common.TOTAL_SCHEDULE_IMPACT"
              defaultMessage="Total schedule impact (days)"
            />
            : {impact_schedule}
          </Box>
        ) : null}
        {hasPrice ? (
          <Box
            className={classNames(classes.noMarginBottom, classes.noMarginTop)}
            m={1}
            textAlign="left">
            <FormattedMessage
              id="common.TOTAL_COST"
              defaultMessage="Total cost (NOK)"
            />
            : {impact_price} <br />
          </Box>
        ) : null}
        <Box
          className={classNames(classes.noMarginBottom, classes.noMarginTop)}
          m={1}
          textAlign="left">
          <FormattedMessage
            id="object.SETTLEMENT"
            defaultMessage="Settlement"
          />
          : {settlements.join(', ')}
        </Box>
        <Box fontWeight="fontWeightBold" m={1} textAlign="left">
          <FormattedMessage
            id="common.DESCRIPTION"
            defaultMessage="Description"
          />
          :
        </Box>
      </Typography>
      {description.map((description, index) => (
        <div
          className={
            index > 0 ? classes.revisionEditorView : classes.paddingBottom
          }
          key={`div_description_${index}`}>
          <WysWyg
            defaultValue={description}
            displayObjectView={displayObjectView}
            key={`description_${index}`}
            name={`description_${index}`}
            readOnly
          />
        </div>
      ))}
      {impacts.length > 0 ? (
        <div>
          <Typography component="div">
            <Box fontWeight="fontWeightBold" m={1} textAlign="left">
              <FormattedMessage
                defaultMessage="Description of Impact"
                id="object.DESCRIPTION_OF_IMPACT"
              />
              :
            </Box>
          </Typography>
          <WysWyg
            defaultValue={impact_description}
            displayObjectView={displayObjectView}
            name="impact_description"
            readOnly
          />
        </div>
      ) : null}
      {hasPrice ? <CostTable data={impact_price_description} /> : null}
      <Typography component="div">
        <Box fontWeight="fontWeightBold" m={1} textAlign="left">
          <FormattedMessage
            defaultMessage="{name} Comments"
            id="object.ORG_NAME_COMMENTS"
            values={{ name: recipientName }}
          />
          :
        </Box>
      </Typography>
      {(comments.length > 0 ? comments : ['']).map((comment, index) => (
        <div
          className={
            index > 0 ? classes.revisionEditorView : classes.paddingBottom
          }
          key={`div_comment_${index}`}>
          <WysWyg
            defaultValue={comment}
            displayObjectView={displayObjectView}
            key={`comment_${index}`}
            name={`comment_${index}`}
            readOnly
          />
        </div>
      ))}
    </div>
  );
};

Eo.propTypes = {
  createdBy: PropTypes.string,
  displayObjectView: PropTypes.func,
  isSenderOrg: PropTypes.bool,
  objectInfo: PropTypes.object,
  recipientName: PropTypes.string
};

export default Eo;
