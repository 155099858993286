import Str8line from './AuthApi';
import {
  userRequest,
  userSuccess,
  userFailure,
  userOtpSuccess,
  userUpdateSuccess
} from '../user/UserAction';
import { ADMIN_INITIAL_STATE } from '../admin/AdminTypes';
import { ORGANIZATION_INITIAL_STATE } from '../organization/OrganizationTypes';
import { USER_LOGOUT } from '../user/UserTypes';
import { getUserFromToken } from '../../common/helper';
import { LS_STR8LINE_TOKEN } from 'common/constants';

export const login = info => {
  return dispatch => {
    dispatch(userRequest());
    const str8line = new Str8line();
    str8line
      .login(info.email, info.password)
      .then(resp => {
        const { jwt = null, user } = resp;

        if (jwt) {
          localStorage.setItem(LS_STR8LINE_TOKEN, jwt);

          const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));

          api.user
            .info(getUserFromToken())
            .then(resp => dispatch(userSuccess(resp.data.data)))
            .catch(error => {
              const errorMsg = error.message;
              dispatch(userFailure(errorMsg));
            });
        } else {
          dispatch(userSuccess(user));
          dispatch(userOtpSuccess());
        }
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(userFailure(errorMsg));
      });
  };
};

export const loginWithToken = token => {
  return dispatch => {
    dispatch(userRequest());
    const str8line = new Str8line();
    str8line
      .loginWithToken(token)
      .then(resp =>
        dispatch(
          userSuccess({
            ...resp.user,
            jwt: resp.jwt
          })
        )
      )
      .catch(error => {
        const errorMsg = error.message;
        dispatch(userFailure(errorMsg));
      });
  };
};

export const loginWithSsoToken = (
  token,
  generateErrorTextAndLogout,
  idpName
) => {
  return dispatch => {
    dispatch(userRequest());
    const str8line = new Str8line();
    str8line
      .loginWithToken(token, idpName)
      .then(resp => {
        localStorage.setItem(LS_STR8LINE_TOKEN, resp.jwt);
        dispatch(
          userSuccess({
            ...resp.user,
            jwt: resp.jwt
          })
        );
      })
      .catch(error => {
        const errorMsg = generateErrorTextAndLogout(error);
        dispatch(userFailure(errorMsg));
      });
  };
};

export const otp = info => {
  return dispatch => {
    dispatch(userRequest());
    const str8line = new Str8line();
    str8line
      .otp(info)
      .then(() => {
        const api = new Str8line(localStorage.getItem(LS_STR8LINE_TOKEN));
        api.user
          .info(getUserFromToken())
          .then(response => {
            dispatch(userSuccess(response.data.data));
          })
          .catch(error => {
            const errorMsg = error.message;
            dispatch(userFailure(errorMsg));
          });
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(userFailure(errorMsg));
      });
  };
};

export const forgotPassword = email => {
  return dispatch => {
    dispatch(userRequest());
    const str8line = new Str8line();
    str8line
      .forgot(email)
      .then(() => {
        dispatch(userUpdateSuccess());
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(userFailure(errorMsg));
      });
  };
};

export const logout = () => {
  return dispatch => {
    localStorage.clear();
    sessionStorage.clear();
    dispatch({ type: ADMIN_INITIAL_STATE });
    dispatch({ type: ORGANIZATION_INITIAL_STATE });
    dispatch({ type: USER_LOGOUT });
  };
};
