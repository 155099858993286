import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
  Box,
  Grid,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import {
  BOQ,
  COMMUNICATION,
  DAILY,
  DAILY_REPORT,
  DOCUMENTATION,
  NOTIFICATIONS_THRESHOLD,
  REAL_TIME_REPORT
} from 'common/constants';
import { HelpPopover } from 'components';
import { configurationCreate, configurationUpdate } from '../../../../../redux';
import { useStyles } from '../../../styles';

const Notification = ({ notifications, user }) => {
  const [states, setStates] = useState({
    allDaily: [false],
    allRealTime: [
      { communication: false, documentation: false, malebrev: false }
    ],
    projects: []
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const { projects } = states;

  const notificationsState = projects => {
    const { allDaily, allRealTime } = projects.reduce(
      (acc, curr) => {
        const { daily_summary_report, real_time } = curr.notification.settings;
        acc.allRealTime.push(real_time);
        acc.allDaily.push(daily_summary_report);

        return acc;
      },
      {
        allDaily: [],
        allRealTime: []
      }
    );

    setStates(prevStates => ({
      ...prevStates,
      allDaily,
      allRealTime,
      projects
    }));
  };

  const handleSwitch = (evt, type, project) => {
    const item = projects.findIndex(proj => proj.id === project.id);
    const setting = {
      ...(type !== DAILY
        ? {
            ...projects[item].notification.settings,
            [REAL_TIME_REPORT]: {
              ...projects[item].notification.settings[REAL_TIME_REPORT],
              [type]: evt.target.checked
            }
          }
        : {
            ...projects[item].notification.settings,
            [DAILY_REPORT]: evt.target.checked
          })
    };

    dispatch(
      configurationUpdate({
        id: project.notification.id,
        user_id: project.user_id,
        project_id: project.id,
        settings: {
          notifications: setting
        }
      })
    );

    projects[item] = {
      ...projects[item],
      notification: {
        ...projects[item].notification,
        settings: setting
      }
    };

    notificationsState(projects);
  };

  const handleSwitchAll = (evt, type) => {
    const settings = projects.map(project => ({
      ...(type !== DAILY
        ? {
            ...project.notification.settings,
            [REAL_TIME_REPORT]: {
              ...project.notification.settings[REAL_TIME_REPORT],
              [type]: evt.target.checked
            }
          }
        : {
            ...project.notification.settings,
            [DAILY_REPORT]: evt.target.checked
          })
    }));

    dispatch(
      configurationCreate({
        user_id: user.id,
        configuration: projects.map((project, idx) => ({
          project_id: project.id,
          settings: {
            notifications: settings[idx]
          }
        }))
      })
    );
  };

  useEffect(() => {
    notificationsState(notifications);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  return (
    <>
      <div className={classes.padding2X}>
        <Typography align="left" variant="h4">
          <FormattedMessage
            defaultMessage="Notification Settings"
            id="admin.NOTIFICATIONS_TITLE"
          />
        </Typography>
      </div>
      <div className={classes.userRoot}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TableContainer
              className={classes.tableContainerNotif}
              component={Paper}>
              <Table data-testid="table" size="small" stickyHeader>
                <colgroup>
                  <col width="25%" />
                  <col width="50%" />
                  <col width="25%" />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage
                        defaultMessage="Projects"
                        id="project.PROJECTS"
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="center">
                        <FormattedMessage
                          defaultMessage="Real-time notifications"
                          id="admin.REALTIME_ALERT"
                        />
                        <HelpPopover
                          message={
                            <Typography>
                              <FormattedMessage
                                defaultMessage="You will be notified for every update in real time"
                                id="admin.REALTIME_INFO"
                              />
                            </Typography>
                          }
                        />
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="center">
                        <FormattedMessage
                          defaultMessage="Daily summary report"
                          id="admin.DAILY_ALERT"
                        />
                        <HelpPopover
                          message={
                            <Typography>
                              <FormattedMessage
                                defaultMessage="You will receive a daily summary report of all changes"
                                id="admin.DAILY_INFO"
                              />
                            </Typography>
                          }
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell />
                    <TableCell align="center">
                      <Box display="flex">
                        <Typography className={classes.notificationItem}>
                          <FormattedMessage
                            defaultMessage="Communication"
                            id="object.COMMUNICATION"
                          />
                        </Typography>

                        <Typography className={classes.notificationItem}>
                          <FormattedMessage
                            defaultMessage="Documentation"
                            id="object.DOCUMENTATION"
                          />
                        </Typography>
                        <Typography className={classes.notificationItem}>
                          <FormattedMessage
                            defaultMessage="Bill of Quantities"
                            id="object.BILL_OF_QUANTITIES"
                          />
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                  {states.projects.length >= NOTIFICATIONS_THRESHOLD ? (
                    <TableRow className={classes.notificationAllRow}>
                      <TableCell data-testid="table-cell-all-projects">
                        <FormattedMessage
                          defaultMessage="All projects"
                          id="admin.PROJECTS_NOTIFICATION"
                        />
                        <HelpPopover
                          message={
                            <Typography>
                              <FormattedMessage
                                defaultMessage="Turn notifications on/off for all projects"
                                id="admin.PROJECTS_NOTIFICATION_INFO"
                              />
                            </Typography>
                          }
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Box display="flex">
                          <div className={classes.notificationItem}>
                            <Switch
                              checked={states.allRealTime.every(
                                item => item[COMMUNICATION]
                              )}
                              color="primary"
                              name={REAL_TIME_REPORT}
                              onChange={evt =>
                                handleSwitchAll(evt, COMMUNICATION)
                              }
                            />
                          </div>

                          <div className={classes.notificationItem}>
                            <Switch
                              checked={states.allRealTime.every(
                                item => item[DOCUMENTATION]
                              )}
                              color="primary"
                              name={REAL_TIME_REPORT}
                              onChange={evt =>
                                handleSwitchAll(evt, DOCUMENTATION)
                              }
                            />
                          </div>
                          <div className={classes.notificationItem}>
                            <Switch
                              checked={states.allRealTime.every(
                                item => item[BOQ]
                              )}
                              color="primary"
                              name={REAL_TIME_REPORT}
                              onChange={evt => handleSwitchAll(evt, BOQ)}
                            />
                          </div>
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <Switch
                          data-testid="radio-daily-report"
                          checked={states.allDaily.every(item => {
                            return item;
                          })}
                          color="primary"
                          name={DAILY_REPORT}
                          onChange={evt => handleSwitchAll(evt, DAILY)}
                        />
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {states.projects.map(project => (
                    <TableRow key={project.id}>
                      <TableCell>{project.name}</TableCell>
                      <TableCell align="center">
                        <Box display="flex">
                          <div className={classes.notificationItem}>
                            <Switch
                              checked={
                                project.notification.settings[REAL_TIME_REPORT][
                                  COMMUNICATION
                                ]
                              }
                              color="primary"
                              name={REAL_TIME_REPORT}
                              onChange={evt =>
                                handleSwitch(evt, COMMUNICATION, project)
                              }
                            />
                          </div>

                          <div className={classes.notificationItem}>
                            <Switch
                              checked={
                                project.notification.settings[REAL_TIME_REPORT][
                                  DOCUMENTATION
                                ]
                              }
                              color="primary"
                              name={REAL_TIME_REPORT}
                              onChange={evt =>
                                handleSwitch(evt, DOCUMENTATION, project)
                              }
                            />
                          </div>
                          <div className={classes.notificationItem}>
                            <Switch
                              checked={
                                project.notification.settings[REAL_TIME_REPORT][
                                  BOQ
                                ]
                              }
                              color="primary"
                              name={REAL_TIME_REPORT}
                              onChange={evt => handleSwitch(evt, BOQ, project)}
                            />
                          </div>
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <Switch
                          checked={project.notification.settings[DAILY_REPORT]}
                          color="primary"
                          name={DAILY_REPORT}
                          onChange={evt => handleSwitch(evt, DAILY, project)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

Notification.propTypes = {
  notifications: PropTypes.array,
  user: PropTypes.object
};

export default Notification;
