import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { clearDocumentMessages } from 'redux/document/DocumentAction';
import { clearExport } from 'redux/export/ExportAction';
import { clearObjectMessages } from 'redux/object/ObjectAction';
import { clearOrganizationMessages } from 'redux/organization/OrganizationAction';
import { clearProjectMessages } from 'redux/project/ProjectAction';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    maxHeight: 'calc(100% - 100px)',
    padding: theme.spacing(2, 4),
    outline: 'none',
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      '@media (orientation: landscape)': {
        maxHeight: '80%',
        minHeight: 'initial'
      }
    }
  },
  inline: {
    display: 'inline'
  }
}));

const Popup = props => {
  const { button, children, className, dataTestId, setHasPopup } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
    if (setHasPopup) setHasPopup(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (setHasPopup) setHasPopup(false);
    dispatch(clearObjectMessages());
    dispatch(clearDocumentMessages());
    dispatch(clearProjectMessages());
    dispatch(clearOrganizationMessages());
    dispatch(clearExport());
  };

  const buttonDisplay = React.Children.map(button, child =>
    React.cloneElement(child, { onClick: handleOpen })
  );
  const body = React.Children.map(children, child =>
    React.cloneElement(child, { ...child.props, onPopupClose: handleClose })
  );

  return (
    <div className={className ? className : classes.inline}>
      {buttonDisplay}
      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        className={classes.modal}
        onClose={handleClose}
        open={open}>
        <div className={classes.paper} data-testid={dataTestId}>
          {body}
          <Popup />
        </div>
      </Modal>
    </div>
  );
};

Popup.propTypes = {
  button: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  setHasPopup: PropTypes.func
};

export default Popup;
