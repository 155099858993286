import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ERROR_401,
  LS_ORGANIZATION_ID,
  LS_ORGANIZATION_NAME
} from 'common/constants';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Drawer,
  Slide,
  Snackbar,
  Typography
} from '@material-ui/core';
import {
  AddCircleOutlined as AddCircleOutlinedIcon,
  Clear as ClearIcon
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { BreadCrumbs, OrgChart, Popup } from 'components';
import { clearObjectFilter } from 'common/helper';
import {
  projectFetch,
  setSelectedProjectId,
  singleProjectFetch
} from '../../redux';
import { NewProject, ProjectTable } from './components';
import { useProjectStyles } from './styles';

const Project = props => {
  const { history } = props;
  const classes = useProjectStyles();
  const [states, setStates] = useState({
    anchor: 'right',
    fetchedAndLoaded: false,
    fetching: false,
    selectedProject: null,
    // showArchivedProjects: false,
    showOrgChartDrawer: false
  });
  const dispatch = useDispatch();
  const intl = useIntl();

  // commenting this for now since we'll be supporting multiple orgs per user in the future
  // force to org list if orgCode is not set
  // if (!localStorage.getItem(LS_ORGANIZATION_ID)) history.push('/organizations');
  // const orgId = localStorage.getItem(LS_ORGANIZATION_ID);

  const {
    object,
    organization: { organizations: orgs, orgProjUpdated },
    project: { loading, projects, projectSelected, updated: projectUpdated },
    user: {
      info: { organizations: userOrgs },
      updated: userUpdated
    }
  } = useSelector(state => state);
  const organizations = userOrgs && userOrgs.length > 0 ? userOrgs : orgs;

  if (
    !organizations ||
    (organizations.length === 0 && !localStorage.getItem(LS_ORGANIZATION_ID))
  )
    history.push('/organizations');

  const org = organizations[0];
  const orgId = org?.id || localStorage.getItem(LS_ORGANIZATION_ID);
  const orgName = org?.name || localStorage.getItem(LS_ORGANIZATION_NAME);
  const isOrgAdmin = !!org?.admin;

  // const projectsArchived = projects.filter(project => project.archived);
  const projectsUnarchived = projects.filter(project => !project.archived);
  const goToObjects = (event, id) => {
    event.preventDefault();
    // Setting selected project on global state so it can be accessed easily
    /*  TODO: research about redux-persist to support browser back and refresh */
    dispatch(setSelectedProjectId(id));
    history.push('/objects');
  };
  const handleClick = (event, type, data) => {
    event.stopPropagation();

    const opts = {
      close: () =>
        setStates(prevStates => ({
          ...prevStates,
          selectedProject: null,
          showOrgChartDrawer: false
        })),
      members: () =>
        setStates(prevStates => ({
          ...prevStates,
          selectedProject: data,
          showOrgChartDrawer: true
        }))
    };

    return opts[type]();
  };

  const setFetchState = (fetched, fetching) =>
    setStates(states => ({
      ...states,
      fetchedAndLoaded: fetched,
      fetching: fetching
    }));

  clearObjectFilter();

  useEffect(() => {
    setFetchState(false, true);
    dispatch(projectFetch(orgId));
  }, [orgId, dispatch]);

  useEffect(() => {
    if (projectUpdated || userUpdated) {
      setFetchState(false, true);
      dispatch(projectFetch(orgId));
    }
  }, [orgId, projectUpdated, userUpdated, dispatch]);

  useEffect(() => {
    if (states.selectedProject) {
      setFetchState(false, true);
      dispatch(singleProjectFetch(states.selectedProject));
    }
  }, [orgProjUpdated, states.selectedProject, dispatch]);

  useEffect(() => {
    if (!loading && states.fetching && !states.fetchedAndLoaded)
      setFetchState(true, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return states.fetchedAndLoaded ? (
    <div
      className={clsx(classes.root, classes.content, {
        [classes.contentShiftRight]: states.showOrgChartDrawer
      })}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        key={'objectNotFoundError'}
        open={!_.isEmpty(object.fetchError) && object.fetchError === ERROR_401}
        TransitionComponent={Slide}>
        <Alert severity="error">
          <FormattedMessage
            defaultMessage="Object not found!"
            id="error.OBJECT_NOT_FOUND"
          />
        </Alert>
      </Snackbar>
      {// Content Starts Here
      projectsUnarchived.length === 0 ? (
        isOrgAdmin ? ( // Create Project Button
          <div className={classes.emptyResultContainer}>
            <Popup
              button={
                <Button className={classes.emptyResultButton}>
                  <AddCircleOutlinedIcon
                    className={classes.addIcon}
                    color="primary"
                    fontSize="large"
                  />
                  <Typography component="div">
                    <Box className={classes.textLarge} textAlign="center">
                      <FormattedMessage
                        defaultMessage="Create new project"
                        id="project.CREATE_PROJECT"
                      />
                    </Box>
                  </Typography>
                </Button>
              }>
              <NewProject />
            </Popup>
          </div>
        ) : (
          <div className={classes.centered}>
            <Typography variant="h1">
              {intl.formatMessage(
                {
                  defaultMessage:
                    "You are currently not part of any of your organization's projects.{br}" +
                    'Contact your systems administrator to be added to relevant projects.',
                  id: 'project.NON_ADMIN_NO_PROJECT_MESSAGE'
                },
                {
                  br: <br />
                }
              )}
            </Typography>
          </div>
        )
      ) : (
        // Title + Add Button
        <>
          <BreadCrumbs history={history} orgId={orgId} orgName={orgName} />
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            className={classes.button}>
            <span className={classes.textUpperCase}>
              <Typography display="inline" variant="h3">
                <FormattedMessage
                  defaultMessage="Projects"
                  id="project.PROJECTS"
                />
              </Typography>
            </span>
            {isOrgAdmin ? (
              <Popup
                button={
                  <Button
                    arial-label="Add Project"
                    color="primary"
                    startIcon={<AddCircleOutlinedIcon fontSize="small" />}
                    variant="contained">
                    <Typography className={classes.buttonLabel} variant="body2">
                      <FormattedMessage
                        defaultMessage="Create project"
                        id="project.CREATE_PROJECT"
                      />
                    </Typography>
                  </Button>
                }>
                <NewProject />
              </Popup>
            ) : null}
          </Box>
          <ProjectTable
            goToObjects={goToObjects}
            handleClick={handleClick}
            projects={projectsUnarchived}
          />
          {
            // https://trello.com/c/IE13C2BY/702-minor-iteration-remove-show-archived-projects-in-the-main-project-list
            // {projectsArchived.length ? (
            //   <>
            //     <div className={classes.showButton}>
            //       <Button
            //         color="primary"
            //         onClick={() =>
            //           setStates({
            //             ...states,
            //             showArchivedProjects: !states.showArchivedProjects
            //           })
            //         }
            //         variant="contained">
            //         <Typography
            //           className={classes.showButtonLabel}
            //           variant="body1">
            //           <FormattedMessage
            //             defaultMessage={
            //               states.showArchivedProjects
            //                 ? 'Hide Archived Projects'
            //                 : 'Show Archived Projects'
            //             }
            //             id={
            //               states.showArchivedProjects
            //                 ? 'project.HIDE_ARCHIVED_PROJECTS'
            //                 : 'project.SHOW_ARCHIVED_PROJECTS'
            //             }
            //           />
            //         </Typography>
            //       </Button>
            //     </div>
            //     {states.showArchivedProjects ? (
            //       <ProjectTable
            //         goToObjects={goToObjects}
            //         handleClick={handleClick}
            //         projects={projectsArchived}
            //       />
            //     ) : null}
            //   </>
            // ) : null}
          }
        </>
      )}
      <Drawer
        anchor={states.anchor}
        classes={{
          paper: classes.drawerPaperRight
        }}
        open={states.showOrgChartDrawer}
        variant="persistent">
        <Card className={classes.drawerViewRoot}>
          <CardHeader
            action={
              <div style={{ backgroundColor: 'white' }}>
                <Button
                  onClick={event => handleClick(event, 'close')}
                  size="small"
                  startIcon={<ClearIcon />}
                  variant="contained">
                  <FormattedMessage defaultMessage="Close" id="common.CLOSE" />
                </Button>
              </div>
            }
            style={{ backgroundColor: 'white' }}
          />
          {states.selectedProject ? (
            <CardContent>
              <OrgChart
                organizations={projectSelected.organization_hierarchy}
                projectId={states.selectedProject}
              />
            </CardContent>
          ) : null}
        </Card>
      </Drawer>
    </div>
  ) : null;
};

Project.propTypes = {
  history: PropTypes.object
};

export default withRouter(Project);
