import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  AddCircle as AddCircleIcon,
  Delete as DeleteIcon,
  Refresh as RefreshIcon,
  Save as SaveIcon
} from '@material-ui/icons';
import { Alert, Autocomplete } from '@material-ui/lab';
import {
  ACTION_DELETE,
  ACTION_GET,
  ACTION_POST,
  ACTION_PUT,
  BUCKET_MENGDEBESKRIVELSE,
  MAX_BOQ_FILE_SIZE,
  STATUS_PUBLISH
} from 'common/constants';
import {
  formatBytes,
  getFilenameWithoutID,
  isObjectEmpty,
  valueInArray
} from 'common/helper';
import { formatBoQTable } from 'common/boqParser';
import { actionOnS3File } from 'common/ursa';
import { BoQPreview, Popup, Uploader } from 'components';
import { billOfQuantity } from 'redux/project/ProjectAction';
import { useStyles } from '../../../styles';

const BillOfQuantities = ({
  myOrg,
  organizations,
  projectArchived,
  projectId
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    project: {
      billOfQuantities,
      boqSelected,
      deleted: boqDeleted,
      error: boqError,
      updated: boqUpdated
    }
  } = useSelector(state => state);
  const [states, setStates] = useState({
    boqIdx: null,
    childOrgs: [],
    isValid: false,
    replacedFiles: [],
    rows: []
  });
  const initValues = {
    content: {},
    contractor_organization: null,
    file: null,
    published: false,
    touched: false
  };

  useEffect(() => {
    if (projectId) dispatch(billOfQuantity(ACTION_GET, projectId));
  }, [projectId, dispatch]);

  useEffect(() => {
    if (boqUpdated && states.replacedFiles.length > 0) {
      // Delete all replacedFiles
      states.replacedFiles.forEach(file => {
        actionOnS3File(ACTION_DELETE, file, null, BUCKET_MENGDEBESKRIVELSE);
      });
      setStates(states => ({ ...states, replacedFiles: [] }));
    }
    if (boqDeleted || boqUpdated)
      dispatch(billOfQuantity(ACTION_GET, projectId));
    // eslint-disable-next-line
  }, [boqDeleted, boqUpdated, dispatch]);

  useEffect(() => {
    setStates(states => {
      const boqOrgIds = billOfQuantities.map(
        boq => boq.contractor_organization?.id
      );
      return {
        ...states,
        childOrgs: states.childOrgs.filter(
          co => !valueInArray(boqOrgIds, co.id)
        ),
        rows: billOfQuantities.length > 0 ? billOfQuantities : [initValues]
      };
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billOfQuantities]);

  useEffect(() => {
    if (!isObjectEmpty(boqSelected)) {
      setStates(states => {
        const rows = states.rows.map(row => {
          if (
            row.contractor_organization?.id ===
            boqSelected.contractor_organization.id
          )
            return boqSelected;
          else return row;
        });
        return { ...states, rows: rows };
      });
    }
  }, [boqSelected]);

  useEffect(() => {
    if (organizations && myOrg) {
      setStates(states => ({
        ...states,
        childOrgs: organizations.filter(
          org => org.parent_organization?.id === myOrg.id
        )
      }));
    }
  }, [myOrg, organizations]);

  const isValid = rows => {
    switch (rows.length) {
      case 0:
        return false;
      case 1:
        return rows[0].file && rows[0].contractor_organization;
      default:
        return !rows.some(
          r =>
            r.touched &&
            ((r.file && !r.contractor_organization) ||
              (!r.file && r.contractor_organization))
        );
    }
  };

  const handleAddRow = () =>
    setStates(states => ({
      ...states,
      rows: [...states.rows, initValues]
    }));

  const handleButtonClick = (type, idx) => {
    switch (type) {
      case STATUS_PUBLISH:
        const publish = window.confirm(
          intl.formatMessage({
            id: 'admin.BOQ_PUBLISH_MESSAGE'
          })
        );
        if (publish) {
          const row = states.rows[idx];
          const params = {
            id: row.id,
            client_organization_id: myOrg.id,
            content: row.content,
            contractor_organization_id: row.contractor_organization.id,
            contract_sum: formatBoQTable(row.content).reduce(
              (acc, curr) => acc + curr.contractSum,
              0
            ),
            file: row.file,
            project_id: projectId,
            published: true
          };
          dispatch(
            billOfQuantity(
              row.id ? ACTION_PUT : ACTION_POST,
              projectId,
              params,
              row.id
            )
          );
        }
        break;
      default:
        return;
    }
  };

  const handleDataChange = (event, value, index) => {
    event.persist();

    setStates(({ childOrgs, rows, ...rest }) => {
      const prevValue = rows[index].contractor_organization;
      if (value) childOrgs = childOrgs.filter(f => f.id !== value.id);
      if (prevValue && !childOrgs.some(co => co.id === prevValue.id))
        childOrgs.push(prevValue);

      rows[index] = {
        ...rows[index],
        contractor_organization: value,
        touched: !!(value || rows[index].file)
      };
      return {
        ...rest,
        childOrgs: childOrgs,
        isValid: isValid(rows),
        rows: rows
      };
    });
  };

  const handleDelete = index => {
    const del = window.confirm(
      intl.formatMessage(
        { id: 'object.DELETE_ITEM_CONFIRMATION' },
        { name: intl.formatMessage({ id: 'object.ITEM' }) }
      ) +
        '\n' +
        intl.formatMessage({ id: 'common.ACTION_CANNOT_UNDONE' })
    );
    if (del) {
      const {
        contractor_organization: delCo,
        file: delFile,
        id: delId
      } = states.rows.splice(index, 1)[0];
      if (delFile) {
        if (delId)
          dispatch(billOfQuantity(ACTION_DELETE, projectId, null, delId));
        else
          actionOnS3File(
            ACTION_DELETE,
            delFile,
            null,
            BUCKET_MENGDEBESKRIVELSE
          );
      }

      setStates(states => ({
        ...states,
        rows: states.rows,
        isValid: isValid(states.rows),
        childOrgs:
          delCo && !states.childOrgs.some(co => co.id === delCo.id)
            ? states.childOrgs.concat(delCo)
            : states.childOrgs
      }));
    }
  };

  const handleSaveFile = ({ content, files, rank }) => {
    const file = Object.values(files)[0]?.file;

    setStates(states => {
      const prevFile = states.rows[rank].file;
      if (prevFile) states.replacedFiles.push(prevFile);
      states.rows[rank].file = file;
      states.rows[rank].content = content;
      return {
        ...states,
        boqIdx: rank,
        isValid: isValid(states.rows),
        replacedFiles: states.replacedFiles,
        rows: states.rows
      };
    });
  };

  const handleSave = () => {
    const boq = states.rows.reduce((acc, row) => {
      if (!row.file && !row.contractor_organization) return acc;
      return [
        ...acc,
        {
          id: row.id,
          content: row.content,
          contractor_organization_id: row.contractor_organization.id,
          file: row.file,
          project_id: projectId,
          published: row.published
        }
      ];
    }, []);

    dispatch(
      billOfQuantity(ACTION_POST, projectId, { bill_of_quantities: boq })
    );
  };

  const MyPopup = ({ button, index }) => (
    <Popup button={button}>
      <Uploader
        bucket={BUCKET_MENGDEBESKRIVELSE}
        dropZoneProps={{
          accept: 'application/xml,text/xml',
          maxFiles: 1,
          maxFileSize: MAX_BOQ_FILE_SIZE,
          multiple: false
        }}
        inputContent={intl.formatMessage(
          {
            defaultMessage:
              'Drag File or Click to Browse{br}(max {max_file_size}, XML only)',
            id: 'attachment.UPLOADER_XML_LABEL'
          },
          { br: <br />, max_file_size: formatBytes(MAX_BOQ_FILE_SIZE) }
        )}
        message={
          <FormattedMessage defaultMessage="Add file" id="admin.ADD_FILE" />
        }
        nextRank={index}
        onSubmit={handleSaveFile}
        uploadPath={`${projectId}/`}
        validateBoQ
      />
    </Popup>
  );

  return (
    <div className={classes.padding3}>
      {boqError ? (
        <Alert className={classes.marginBottom2} severity="error">
          {boqError}
        </Alert>
      ) : null}
      <TableContainer component={Paper}>
        <Table size="small" stickyHeader>
          {projectArchived ? (
            <colgroup>
              <col width="33%" />
              <col width="57%" />
            </colgroup>
          ) : (
            <colgroup>
              <col width="33%" />
              <col width="47%" />
              <col width="20%" />
            </colgroup>
          )}
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Contractor"
                  id="common.CONTRACTOR"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="File" id="common.FILE" />
              </TableCell>
              {projectArchived ? null : (
                <TableCell>
                  <Box display="flex" justifyContent="flex-end">
                    <Tooltip
                      arrow
                      title={
                        <FormattedMessage
                          defaultMessage="Add bill of quantity"
                          id="help.ADD_BILL_OF_QUANTITY"
                        />
                      }>
                      <IconButton
                        className={classes.addIcon}
                        onClick={handleAddRow}
                        size="small">
                        <AddCircleIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    {boqError ? null : (
                      <Tooltip
                        arrow
                        title={
                          <FormattedMessage
                            defaultMessage="Save changes"
                            id="common.SAVE_CHANGES"
                          />
                        }>
                        <span>
                          <IconButton
                            className={classes.saveIcon}
                            disabled={!states.isValid}
                            onClick={handleSave}
                            size="small">
                            <SaveIcon fontSize="small" />
                          </IconButton>
                        </span>
                      </Tooltip>
                    )}
                  </Box>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {states.rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Autocomplete
                    disabled={projectArchived || row.published}
                    filterSelectedOptions
                    getOptionLabel={option => option.name}
                    onChange={(evt, val) => handleDataChange(evt, val, index)}
                    options={states.childOrgs}
                    renderInput={params => (
                      <TextField
                        {...params}
                        fullWidth
                        label={
                          <FormattedMessage
                            defaultMessage="Select Contractor"
                            id="admin.SELECT_CONTRACTOR"
                          />
                        }
                        size="small"
                        variant="outlined"
                      />
                    )}
                    size="small"
                    value={row.contractor_organization || null}
                  />
                </TableCell>
                <TableCell>
                  <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between">
                    {row.file ? (
                      <>
                        <Typography>
                          {getFilenameWithoutID(row.file)}
                        </Typography>
                        {boqError && index === states.boqIdx ? null : (
                          <Box display="flex">
                            <Popup
                              button={
                                <Button
                                  className={classes.viewBtnMarginLeft}
                                  color="primary"
                                  size="small"
                                  variant="contained">
                                  <FormattedMessage
                                    defaultMessage="Preview"
                                    id="object.PREVIEW"
                                  />
                                </Button>
                              }>
                              <BoQPreview
                                boq={formatBoQTable(
                                  row.content,
                                  row.content?.projectKey
                                )}
                                isModal
                              />
                            </Popup>
                            {projectArchived ||
                            row.published ||
                            !isValid([row]) ? null : (
                              <Button
                                className={classes.viewBtnMarginLeft}
                                color="primary"
                                onClick={() =>
                                  handleButtonClick(STATUS_PUBLISH, index)
                                }
                                size="small"
                                variant="contained">
                                <FormattedMessage
                                  defaultMessage="Publish"
                                  id="object.PUBLISH"
                                />
                              </Button>
                            )}
                          </Box>
                        )}
                      </>
                    ) : null}
                    {!row.file && row.contractor_organization ? (
                      <Box display="flex">
                        <MyPopup
                          button={
                            <Button
                              aria-label="Create new"
                              className={classes.marginLeft2}
                              color="primary"
                              startIcon={<AddCircleIcon fontSize="small" />}
                              variant="contained">
                              <Typography
                                className={classes.buttonLabel}
                                variant="body2">
                                <FormattedMessage
                                  defaultMessage="Upload"
                                  id="common.UPLOAD"
                                />
                              </Typography>
                            </Button>
                          }
                          index={index}
                        />
                      </Box>
                    ) : null}
                  </Box>
                </TableCell>
                {projectArchived ? null : (
                  <TableCell align="right">
                    {row.file && !row.published ? (
                      <Tooltip
                        arrow
                        title={
                          <FormattedMessage
                            defaultMessage="Revise"
                            id="attachment.REVISE"
                          />
                        }>
                        <span>
                          <MyPopup
                            button={
                              <IconButton size="small">
                                <RefreshIcon fontSize="small" />
                              </IconButton>
                            }
                            index={index}
                          />
                        </span>
                      </Tooltip>
                    ) : null}
                    {row.published ? null : (
                      <Tooltip
                        arrow
                        title={
                          <FormattedMessage
                            defaultMessage="Delete"
                            id="common.DELETE"
                          />
                        }>
                        <span>
                          <IconButton
                            className={classes.deleteIcon}
                            onClick={() => handleDelete(index)}
                            size="small">
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </span>
                      </Tooltip>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

BillOfQuantities.propTypes = {
  myOrg: PropTypes.object,
  organizations: PropTypes.array,
  projectArchived: PropTypes.bool,
  projectId: PropTypes.string
};

BillOfQuantities.defaultProps = {
  projectArchived: false
};

export default BillOfQuantities;
