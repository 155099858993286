import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import {
  Clear as ClearIcon,
  Edit as EditIcon,
  Save as SaveIcon
} from '@material-ui/icons';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core';
import { isCurrentOrg } from 'common/helper';
import { organizationProjectUpdate } from 'redux/organization/OrganizationAction';
import { useStyles } from '../../../styles';
import { ORG_TYPE_CLIENT } from 'common/constants';

const MemberList = ({ data, projectArchived, projId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    organizations: []
  });

  const setOrganizations = orgs => {
    setState(state => ({ ...state, organizations: orgs }));
  };

  const handleEdit = (index, edit) => {
    const organizations = state.organizations;
    organizations[index].edit = edit;
    setOrganizations(organizations);
  };

  const updateOrg = (index, value) => {
    const organizations = state.organizations;
    organizations[index].parent_organization = value;
    setOrganizations(organizations);
  };

  const handleSave = index => {
    const updatedOrg = state.organizations[index];
    handleEdit(index, false);
    dispatch(
      organizationProjectUpdate(updatedOrg.id, projId, {
        parent_organization_id: updatedOrg.parent_organization
          ? updatedOrg.parent_organization.id
          : ''
      })
    );
  };

  useEffect(() => {
    setOrganizations(data);
  }, [data]);

  return (
    <div className={classes.projectContainer}>
      <TableContainer component={Paper}>
        <Table size="small" stickyHeader>
          {projectArchived ? (
            <colgroup>
              <col width="20%" />
              <col width="20%" />
              <col width="20%" />
              <col width="20%" />
              <col width="20%" />
            </colgroup>
          ) : (
            <colgroup>
              <col width="20%" />
              <col width="15%" />
              <col width="20%" />
              <col width="20%" />
              <col width="15%" />
              <col width="10%" />
            </colgroup>
          )}
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage defaultMessage="Name" id="common.NAME" />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="ORG TYPE"
                  id="admin.ORG_TYPE"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Contract Counterpart"
                  id="admin.CONTRACT_COUNTERPART"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="ORG ID" id="admin.ORG_ID" />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="STANDARD"
                  id="admin.STANDARD"
                />
              </TableCell>
              {projectArchived ? null : <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {state.organizations.map((org, index) => (
              <TableRow key={org.id}>
                <TableCell>{org.name}</TableCell>
                <TableCell>
                  <FormattedMessage
                    defaultMessage={org.organization_type.name}
                    id={'common.' + org.organization_type.name.toUpperCase()}
                  />
                </TableCell>
                <TableCell>
                  {org.edit ? (
                    <Autocomplete
                      filterSelectedOptions
                      getOptionLabel={option => option.name}
                      onChange={(e, value) => updateOrg(index, value)}
                      options={data.filter(d => d.id !== org.id)}
                      renderInput={params => (
                        <TextField
                          {...params}
                          fullWidth
                          size="small"
                          variant="outlined"
                        />
                      )}
                      size="small"
                      value={org.parent_organization || null}
                    />
                  ) : org.parent_organization ? (
                    org.parent_organization.name
                  ) : (
                    ''
                  )}
                </TableCell>
                <TableCell>{org.org_tag}</TableCell>
                <TableCell>{org.standard ? org.standard.name : ''}</TableCell>
                {projectArchived ? null : (
                  <TableCell align="right">
                    {org.edit ? (
                      <>
                        <IconButton
                          onClick={() => handleEdit(index, false)}
                          size="small">
                          <ClearIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          className={classes.saveIcon}
                          onClick={() => handleSave(index)}
                          size="small">
                          <SaveIcon fontSize="small" />
                        </IconButton>
                      </>
                    ) : isCurrentOrg(org.id) &&
                      org.organization_type.name !== ORG_TYPE_CLIENT ? (
                      <IconButton
                        onClick={() => handleEdit(index, true)}
                        size="small">
                        <EditIcon fontSize="small" />
                      </IconButton>
                    ) : null}
                    {
                      // TODO : NEED API FOR THESE
                      // TODO : Support deleting org in project
                      //   <Grid item>
                      //     <IconButton onClick={handleDelete} size="small">
                      //       <DeleteIcon />
                      //     </IconButton>
                      //   </Grid>
                    }
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

MemberList.propTypes = {
  data: PropTypes.array,
  projectArchived: PropTypes.bool,
  projId: PropTypes.string
};

MemberList.defaultProps = {
  projectArchived: false
};

export default MemberList;
