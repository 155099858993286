import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import HelpIcon from '@material-ui/icons/Help';
import { formatCategories, hasError } from 'common/helper';
import {
  ANCESTRY,
  DEFAULT_REVISION_TYPE,
  NAME,
  REGEX_SPACE_ALL,
  REVISION,
  UNDERSCORE
} from 'common/constants';
import { useStyles } from 'views/Object/styles';
import { useDispatch, useSelector } from 'react-redux';
import { revisionTypesFetch } from 'redux/object/ObjectAction';

const CategoryInfo = props => {
  const {
    categories,
    categoryError,
    errors,
    fnCancel,
    fnChange,
    fnSave,
    isOpen,
    selected_category
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const {
    object: { revisionTypes = [] }
  } = useSelector(state => state);

  useEffect(() => {
    if (revisionTypes.length === 0) {
      dispatch(revisionTypesFetch());
    } else if (isOpen && !selected_category) {
      fnChange(
        null,
        revisionTypes.find(type => type.action_name === DEFAULT_REVISION_TYPE),
        REVISION
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, revisionTypes, selected_category]);

  const ancestry_options = formatCategories(categories, selected_category?.id);

  return (
    <Fragment>
      {categoryError ? (
        <Alert className={classes.marginBottom1} severity="error">
          {categoryError}
        </Alert>
      ) : null}
      <Grid item md={7} xs={7}>
        <TextField
          error={hasError(errors, NAME)}
          fullWidth
          helperText={hasError(errors, NAME) ? errors.name[0] : null}
          label={
            <FormattedMessage
              defaultMessage="Category Name"
              id="object.CATEGORY_NAME"
            />
          }
          onChange={e => fnChange(e, e.target.value, NAME)}
          required
          size="small"
          value={selected_category?.name || ''}
          variant="outlined"
        />
      </Grid>
      <Grid item md={5} xs={5}>
        <div className={classes.floatRight}>
          <Button
            color="primary"
            onClick={fnCancel}
            size="small"
            variant="contained">
            <FormattedMessage defaultMessage="Cancel" id="common.CANCEL" />
          </Button>
          <Button
            className={classes.marginLeft1}
            color="primary"
            disabled={!selected_category?.name || hasError(errors, NAME)}
            onClick={fnSave}
            size="small"
            variant="contained">
            <FormattedMessage
              defaultMessage="Save category"
              id="object.SAVE_CATEGORY"
            />
          </Button>
        </div>
      </Grid>
      <Grid item md={6} xs={6}>
        <Autocomplete
          getOptionLabel={option => {
            return option.name
              ? intl.formatMessage({
                  defaultMessage: option.name,
                  id: 'object.' + option.name.toUpperCase()
                })
              : '';
          }}
          id={ANCESTRY}
          name={ANCESTRY}
          onChange={(e, v) => fnChange(e, v, ANCESTRY)}
          options={ancestry_options}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              label={
                <FormattedMessage
                  defaultMessage="Nest category under"
                  id="object.NEST_CATEGORY_UNDER"
                />
              }
              size="small"
              variant="outlined"
            />
          )}
          renderOption={option => (
            <Typography
              style={{
                marginLeft: option.indent_count
              }}>
              {intl.formatMessage({
                defaultMessage: option.name,
                id: 'object.' + option.name.toUpperCase()
              })}
            </Typography>
          )}
          size="small"
          value={selected_category?.ancestry || null}
        />
      </Grid>
      <Grid item md={6} xs={6}>
        <Autocomplete
          classes={{ option: classes.autocompleteOption }}
          getOptionLabel={option =>
            intl.formatMessage({
              defaultMessage: option.action_name,
              id:
                'object.REVISION_' +
                option.action_name
                  .replace(REGEX_SPACE_ALL, UNDERSCORE)
                  .toUpperCase()
            })
          }
          id={REVISION}
          name={REVISION}
          onChange={(e, v) => (v ? fnChange(e, v, REVISION) : null)}
          options={revisionTypes}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              label={
                <FormattedMessage
                  defaultMessage="Revision/Version logic"
                  id="object.REVISION_TAG"
                />
              }
              required
              size="small"
              variant="outlined"
            />
          )}
          renderOption={option => {
            const id =
              'object.REVISION_' +
              option.action_name
                .replace(REGEX_SPACE_ALL, UNDERSCORE)
                .toUpperCase();
            return (
              <>
                <Typography>
                  {option.action_name
                    ? intl.formatMessage({
                        defaultMessage: option.action_name,
                        id: id
                      })
                    : ''}
                </Typography>
                <div>
                  <Tooltip
                    title={intl.formatMessage({
                      defaultMessage: '',
                      id: id + '_HELP'
                    })}>
                    <IconButton className={classes.hidden} size="small">
                      <HelpIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </div>
              </>
            );
          }}
          value={selected_category?.revision_type || null}
        />
      </Grid>
    </Fragment>
  );
};

CategoryInfo.propTypes = {
  categories: PropTypes.array,
  categoryError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  errors: PropTypes.object,
  fnCancel: PropTypes.func.isRequired,
  fnChange: PropTypes.func.isRequired,
  fnSave: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  selected_category: PropTypes.object
};

CategoryInfo.defaultProps = {
  categories: [],
  errors: {},
  isOpen: false,
  selected_category: {}
};

export default CategoryInfo;
