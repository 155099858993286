import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  Typography,
  Paper,
  List,
  ListItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  DialogContent,
  DialogActions,
  Backdrop,
  CircularProgress
} from '@material-ui/core';
import {
  settingFetch,
  settingUpdate,
  userDelete,
  userUpdate
} from '../../redux';
import { setupOrg } from 'common/helper';
import QueryString from 'query-string';
import {
  LS_STR8LINE_TOKEN,
  NAME,
  PASSWORD,
  PASSWORD_CONFIRMATION
} from 'common/constants';

const nameSchema = {
  [NAME]: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="{name} is required"
          id="error.REQUIRED"
          values={{
            name: <FormattedMessage defaultMessage="Name" id="common.NAME" />
          }}
        />
      )
    }
  }
};

const schema = {
  [PASSWORD]: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="{name} is required"
          id="error.REQUIRED"
          values={{
            name: (
              <FormattedMessage defaultMessage="Password" id="main.PASSWORD" />
            )
          }}
        />
      )
    },
    length: {
      maximum: 128
    }
  },
  [PASSWORD_CONFIRMATION]: {
    presence: {
      allowEmpty: false,
      message: (
        <FormattedMessage
          defaultMessage="{name} is required"
          id="error.REQUIRED"
          values={{
            name: (
              <FormattedMessage
                defaultMessage="Password Confirmation"
                id="main.PASSWORD_CONFIRMATION"
              />
            )
          }}
        />
      )
    },
    equality: {
      attribute: 'password',
      message: (
        <FormattedMessage
          defaultMessage="{name} is not the same as {otherName}"
          id="error.NOT_SAME"
          values={{
            name: (
              <FormattedMessage
                defaultMessage="Password Confirmation"
                id="main.PASSWORD_CONFIRMATION"
              />
            ),
            otherName: (
              <FormattedMessage defaultMessage="Password" id="main.PASSWORD" />
            )
          }}
        />
      )
    },
    length: {
      maximum: 28
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 56
  },
  form: {
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  },
  contentPaper: {
    height: 'max-content',
    padding: 64
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  passwordButton: {
    margin: theme.spacing(2, 0)
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0
  },
  link: {
    color: '#dd882e',
    textDecoration: 'none'
  }
}));

const Password = ({ history }) => {
  const classes = useStyles();
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    isAgree: false,
    confirmDeletion: false
  });
  const [boolStates, setBoolState] = useState({
    deleteInProgress: false,
    showDeletedDialog: false
  });
  const {
    user: {
      deleted: userDeleted,
      error: userError,
      info: {
        id: userInfoId,
        jwt: userInfoJwt,
        name: userInfoName,
        organizations: userInfoOrgs
      },
      loading: userLoading,
      setting: userSettingsGlobal,
      updated: userUpdated
    }
  } = useSelector(state => state);
  const dispatch = useDispatch();

  const params = QueryString.parse(window.location.search);
  const activateUser = params.type === 'activate';

  useEffect(() => {
    if (userInfoId && userInfoJwt)
      dispatch(settingFetch(userInfoId, userInfoJwt));
  }, [dispatch, userInfoId, userInfoJwt]);

  useEffect(() => {
    if (userInfoName)
      setFormState(formState => ({
        ...formState,
        values: { ...formState.values, name: userInfoName }
      }));
  }, [userInfoName]);

  useEffect(() => {
    const formSchema = {
      ...schema,
      ...(activateUser ? nameSchema : {})
    };
    const errors = validate(formState.values, formSchema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values, activateUser]);

  useEffect(() => {
    if (userDeleted)
      setBoolState(b => ({
        ...b,
        showDeletedDialog: true
      }));
  }, [userDeleted]);

  useEffect(() => {
    if (userUpdated && !userError) {
      localStorage.setItem(LS_STR8LINE_TOKEN, userInfoJwt);

      setupOrg(userInfoOrgs && userInfoOrgs[0]);
      history.replace('/');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userUpdated]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSetPassword = event => {
    event.preventDefault();

    dispatch(
      userUpdate(formState.values, {
        id: userInfoId,
        str8line_token: userInfoJwt
      })
    );

    if (activateUser) {
      dispatch(
        settingUpdate(
          {
            ...userSettingsGlobal,
            settings: { ...userSettingsGlobal.settings, consent: true }
          },
          userInfoJwt
        )
      );
    }
  };

  const hasError = field => {
    switch (field) {
      case NAME:
      case PASSWORD:
        return !!(
          (formState.touched[NAME] ||
            formState.touched[PASSWORD] ||
            formState.touched[PASSWORD_CONFIRMATION]) &&
          formState.errors[field]
        );
      default:
        return !!(formState.touched[field] && formState.errors[field]);
    }
  };

  const handleRadioChange = event =>
    setFormState(s => ({
      ...s,
      isAgree: event.target.value
    }));

  const closeDialog = () =>
    setFormState(f => ({
      ...f,
      isAgree: false
    }));

  const closeConfirmDialog = () =>
    setFormState(f => ({
      ...f,
      confirmDeletion: false
    }));

  const confirmUserDeletion = () =>
    setFormState(f => ({
      ...f,
      confirmDeletion: true
    }));

  const deleteUser = event => {
    event.preventDefault();
    setBoolState(b => ({
      ...b,
      deleteInProgress: true
    }));
    dispatch(userDelete(userInfoId, userInfoJwt));
  };

  const getButton = () => {
    let btn = (
      <Button
        className={classes.passwordButton}
        color="primary"
        data-testid="button-password"
        disabled={!formState.isValid || userLoading}
        fullWidth
        size="large"
        type="submit"
        variant="contained">
        <FormattedMessage
          defaultMessage="Set Password"
          id="main.SET_PASSWORD"
        />
      </Button>
    );

    if (activateUser) {
      const bullets = [
        <FormattedMessage defaultMessage="Name" id="common.NAME" />,
        <FormattedMessage
          defaultMessage="Email Address"
          id="common.EMAIL_ADDRESS"
        />,
        <FormattedMessage
          defaultMessage="Company related information"
          id="common.COMPANY_RELATED_INFO"
        />,
        <FormattedMessage defaultMessage="Browser" id="common.BROWSER" />
      ];

      btn = (
        <div style={{ paddingTop: 12 }}>
          <Typography>
            <FormattedMessage
              defaultMessage="Welcome as a Str8line user. In order to deliver the best possible user experience we need to collect a limited amount of your user data.{br}The following data will be collected:"
              id="main.DATA_COLLECTION"
              values={{ br: <br /> }}
            />
          </Typography>
          <List>
            {bullets.map((item, i) => {
              return (
                <ListItem className={classes.listItem} key={`listitem_${i}`}>
                  <Typography>• {item}</Typography>
                </ListItem>
              );
            })}
          </List>
          <Typography align="justify">
            <FormattedMessage
              defaultMessage="The purpose of collecting these data is to send information and notifications such as password restore e-mails, system-generated notifications, etc. We also use the information to improve your user experience."
              id="main.DATA_PURPOSE"
            />
          </Typography>
          <br />
          <Typography align="justify">
            <FormattedMessage
              defaultMessage="We strive to keep your information safe and protected at all times. Should you wish to remove your account with all the connected data, you can do this by accessing the “My Account” menu."
              id="main.DATA_PROTECTION"
            />
          </Typography>
          <br />
          <Typography align="justify">
            <FormattedMessage
              defaultMessage="Do you accept these terms of use?"
              id="main.CONSENT_DATA_COLLECTION"
            />
          </Typography>
          <RadioGroup
            name="agree"
            onChange={handleRadioChange}
            row
            value={formState.isAgree}>
            <FormControlLabel
              control={
                <Radio color="primary" data-testid="radio-consent-yes" />
              }
              label={<FormattedMessage defaultMessage="Yes" id="common.YES" />}
              value="yes"
            />
            <FormControlLabel
              control={<Radio color="primary" data-testid="radio-consent-no" />}
              label={<FormattedMessage defaultMessage="No" id="common.NO" />}
              value="no"
            />
          </RadioGroup>
          <Button
            className={classes.passwordButton}
            color="primary"
            disabled={
              !formState.isValid || userLoading || formState.isAgree !== 'yes'
            }
            size="large"
            type="submit"
            variant="contained">
            <FormattedMessage defaultMessage="Sign up" id="main.SIGN_UP" />
          </Button>
        </div>
      );
    }

    return btn;
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.contentBody}>
          <form className={classes.form} onSubmit={handleSetPassword}>
            <Paper className={classes.contentPaper} elevation={4}>
              {activateUser ? (
                <>
                  <Typography
                    classes={{ title: classes.title }}
                    data-testid="content-welcome"
                    gutterBottom
                    variant="h4">
                    <FormattedMessage
                      defaultMessage="Welcome to Str8line!"
                      id="main.WELCOME"
                    />
                  </Typography>
                  <TextField
                    className={classes.textField}
                    data-testid="input-name"
                    error={hasError(NAME)}
                    fullWidth
                    helperText={
                      hasError(NAME) ? formState.errors[NAME][0] : null
                    }
                    label={
                      <FormattedMessage
                        defaultMessage="Name"
                        id="common.NAME"
                      />
                    }
                    name="name"
                    onChange={handleChange}
                    value={formState.values.name || ''}
                    variant="outlined"
                  />
                </>
              ) : (
                <Typography data-testid="header-password" variant="h2">
                  <FormattedMessage
                    defaultMessage="Password"
                    id="main.PASSWORD"
                  />
                </Typography>
              )}
              <TextField
                className={classes.textField}
                data-testid="input-password"
                error={hasError(PASSWORD)}
                fullWidth
                helperText={
                  hasError(PASSWORD) ? formState.errors[PASSWORD][0] : null
                }
                label={
                  <FormattedMessage
                    defaultMessage="Password"
                    id="main.PASSWORD"
                  />
                }
                name="password"
                onChange={handleChange}
                type="password"
                value={formState.values.password || ''}
                variant="outlined"
              />
              <TextField
                className={classes.textField}
                data-testid="input-password-confirmation"
                error={hasError(PASSWORD_CONFIRMATION)}
                fullWidth
                helperText={
                  hasError(PASSWORD_CONFIRMATION)
                    ? formState.errors[PASSWORD_CONFIRMATION][0]
                    : null
                }
                label={
                  <FormattedMessage
                    defaultMessage="Password Confirmation"
                    id="main.PASSWORD_CONFIRMATION"
                  />
                }
                name="password_confirmation"
                onChange={handleChange}
                type="password"
                value={formState.values.password_confirmation || ''}
                variant="outlined"
              />
              {getButton()}
            </Paper>
          </form>
          <Dialog
            data-testid="dialog-delete"
            onClose={closeDialog}
            open={activateUser && formState.isAgree === 'no'}>
            <DialogContent dividers>
              <Typography gutterBottom>
                <FormattedMessage
                  defaultMessage="To log in you must allow STR8LINE to collect the neccesary user information. Should you not wish to register, your e-mail will be deleted from our systems. Would you like to continue the registration process?"
                  id="main.PROCEED_SIGNUP"
                />
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                color="secondary"
                data-testid="button-no"
                onClick={confirmUserDeletion}>
                <FormattedMessage
                  defaultMessage="No, delete account"
                  id="main.STOP_REGISTRATION"
                />
              </Button>
              <Button
                autoFocus
                color="primary"
                data-testid="button-yes"
                onClick={closeDialog}>
                <FormattedMessage
                  defaultMessage="Yes, continue registration"
                  id="main.CONTINUE_REGISTRATION"
                />
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            data-testid="dialog-confirm-delete"
            onClose={closeConfirmDialog}
            open={formState.confirmDeletion}>
            <DialogContent dividers>
              <Typography gutterBottom>
                <FormattedMessage
                  defaultMessage="Are you certain you want do delete your account? All your user data will be deleted from our system. Do you wish to proceed with deleting?"
                  id="main.DELETE_ACCOUNT_REGISTRATION"
                />
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                color="secondary"
                data-testid="button-no"
                onClick={closeConfirmDialog}>
                <FormattedMessage defaultMessage="Cancel" id="common.CANCEL" />
              </Button>
              <Button
                autoFocus
                color="primary"
                data-testid="button-yes"
                onClick={deleteUser}>
                <FormattedMessage defaultMessage="Delete" id="common.DELETE" />
              </Button>
            </DialogActions>
          </Dialog>
          <Backdrop
            className={classes.backdrop}
            open={boolStates.deleteInProgress}>
            <div>
              <CircularProgress color="primary" />
            </div>
          </Backdrop>
          <Dialog
            onClose={() => history.push('/sign-in')}
            open={boolStates.showDeletedDialog}>
            <DialogContent dividers>
              <Typography gutterBottom>
                <FormattedMessage
                  defaultMessage="We're sorry to see you go. Rest assured your account has been deleted. If you change your mind, feel free to sign up again."
                  id="main.GOODBYE_MSG"
                />
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                color="primary"
                onClick={() => history.push('/sign-in')}>
                <FormattedMessage defaultMessage="Ok" id="common.OK" />
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

Password.propTypes = {
  history: PropTypes.object
};

export default withRouter(Password);
