import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import {
  AddCircle as AddCircleIcon,
  CancelRounded as CancelRoundedIcon,
  CheckCircleRounded as CheckCircleRoundedIcon,
  RemoveCircle as RemoveCircleIcon
} from '@material-ui/icons';
import { StyledCell } from 'components';
import _ from 'lodash';
import classNames from 'classnames';
import { formatCurrency, hasError } from 'common/helper';
import { getNewDelmalebrev } from '../../components/helper';
import { useStyles } from '../../styles';
import { validateBoQObject } from '../../validations/validator';
import {
  AREA,
  DELMALEBREV,
  NUMBER_OF_UNITS,
  REVISION_VALUES,
  STATUS,
  STATUS_APPROVED,
  STATUS_REJECTED,
  VALUES
} from 'common/constants';
import Decimal from 'decimal.js';

const BoqTable = ({
  data,
  disableEdit,
  errors,
  isCreatorOrg,
  isRevision,
  setFormState,
  showApprovalStatus
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const {
    object: { status: objectStatus }
  } = useSelector(state => state);
  const valsKey = isRevision ? REVISION_VALUES : VALUES;

  useEffect(() => {
    if (data.length === 0) addItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDelmalebrevTotalData = (delmalebrev, contractQuantity) => {
    const decContractQty = new Decimal(contractQuantity);

    return delmalebrev.reduce(
      (acc, item) => {
        const decItemSum = new Decimal(item.sum);
        const decItemNoOfUnits = new Decimal(item.number_of_units);

        if (item.status === STATUS_APPROVED) {
          const decApprovedSum = new Decimal(acc.approvedSum);
          acc.approvedSum = decApprovedSum.plus(decItemSum).toNumber();

          const decApprovedQuantity = new Decimal(acc.approvedQuantity);
          acc.approvedQuantity = decApprovedQuantity
            .plus(decItemNoOfUnits)
            .toNumber();
        }

        const decMeasuredSum = new Decimal(acc.measuredSum);
        acc.measuredSum = decMeasuredSum.plus(decItemSum).toNumber();

        let decMeasuredQty = new Decimal(acc.measuredQuantity);
        decMeasuredQty = decMeasuredQty.plus(decItemNoOfUnits);
        acc.measuredQuantity = decMeasuredQty.toNumber();

        acc.contractPercentage = contractQuantity // should be a non-zero number
          ? decMeasuredQty
              .dividedBy(decContractQty)
              .times(100)
              .toNumber()
          : 0;
        return acc;
      },
      {
        approvedQuantity: 0,
        approvedSum: 0,
        contractPercentage: 0,
        measuredQuantity: 0,
        measuredSum: 0
      }
    );
  };

  const addItem = event => {
    if (event) event.persist();
    setFormState(formState => {
      let { delmalebrev = [] } = formState[valsKey].metadata;
      delmalebrev = [...delmalebrev, getNewDelmalebrev()];
      return {
        ...formState,
        [valsKey]: {
          ...formState[valsKey],
          metadata: {
            ...formState[valsKey].metadata,
            delmalebrev: delmalebrev
          }
        },
        prevValues: {
          ...formState.prevValues,
          metadata: {
            ...formState.prevValues.metadata,
            delmalebrev: event
              ? formState.prevValues.metadata.delmalebrev
              : _.cloneDeep(delmalebrev)
          }
        }
      };
    });
  };

  const delItem = (event, index) => {
    event.persist();
    setFormState(formState => {
      const { contractQuantity, delmalebrev } = formState[valsKey].metadata;
      delmalebrev.splice(index, 1);
      return {
        ...formState,
        [valsKey]: {
          ...formState[valsKey],
          metadata: {
            ...formState[valsKey].metadata,
            delmalebrev: delmalebrev,
            ...getDelmalebrevTotalData(delmalebrev, contractQuantity)
          }
        }
      };
    });
  };

  const handleChange = (event, index, field, value) => {
    event.persist();
    let newValue = value || event.target.value;

    setFormState(formState => {
      let { metadata } = formState[valsKey];
      const { contractQuantity, delmalebrev, unitPrice } = metadata;

      // clicking on the same status will clear it
      if (field === STATUS && delmalebrev[index][field] === newValue)
        newValue = '';

      if (field === NUMBER_OF_UNITS) {
        const decUnitPrice = new Decimal(unitPrice ?? 0);
        const decNewValue = new Decimal(newValue || 0);
        delmalebrev[index].sum = decNewValue.times(decUnitPrice).toNumber();

        newValue = decNewValue.toNumber();

        // having status here means it was set from previous revision
        // if field is edited, reset the status
        if (!!delmalebrev[index].status) delmalebrev[index].status = '';
      }

      // having status here means it was set from previous revision
      // if field is edited, reset the status
      if (field === AREA && !!delmalebrev[index].status)
        delmalebrev[index].status = '';

      delmalebrev[index][field] = newValue;

      if (field === STATUS || field === NUMBER_OF_UNITS) {
        metadata = {
          ...metadata,
          ...getDelmalebrevTotalData(delmalebrev, contractQuantity)
        };
      }

      return {
        ...formState,
        [valsKey]: {
          ...formState[valsKey],
          metadata: { ...metadata, delmalebrev: delmalebrev }
        },
        errors: validateBoQObject(objectStatus, formState[valsKey]) || {}
      };
    });
  };

  return (
    <TableContainer>
      <Table size="small">
        <colgroup>
          {isCreatorOrg ? <col /> : null}
          <col width="22%" />
          <col width="25%" />
          <col width="18%" />
          <col width="20%" />
          <col width="15%" />
        </colgroup>
        <TableHead>
          <TableRow>
            {isCreatorOrg ? (
              <StyledCell header>
                <IconButton onClick={addItem} size="small">
                  <AddCircleIcon
                    className={classes.addItemIcon}
                    fontSize="small"
                  />
                </IconButton>
              </StyledCell>
            ) : null}
            <StyledCell header>
              <FormattedMessage
                defaultMessage="Description"
                id="common.DESCRIPTION"
              />
            </StyledCell>
            <StyledCell header>
              <FormattedMessage defaultMessage="Area" id="object.AREA" />
            </StyledCell>
            <StyledCell header>
              <FormattedMessage
                defaultMessage="Number of units"
                id="object.NUMBER_OF_UNITS"
              />
            </StyledCell>
            <StyledCell header>
              <FormattedMessage defaultMessage="Sum" id="object.SUM" />
            </StyledCell>
            <StyledCell header>
              <FormattedMessage
                defaultMessage="Approval"
                id="object.APPROVAL"
              />
            </StyledCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={`${DELMALEBREV}_${index}`}>
              {isCreatorOrg ? (
                <StyledCell align="center">
                  {item.locked || data.length === 1 ? null : (
                    <IconButton onClick={e => delItem(e, index)} size="small">
                      <RemoveCircleIcon
                        className={classes.deleteIcon}
                        fontSize="small"
                      />
                    </IconButton>
                  )}
                </StyledCell>
              ) : null}
              <StyledCell>
                <Typography>{`${DELMALEBREV} ${index + 1}`}</Typography>
              </StyledCell>
              <StyledCell
                className={
                  hasError(errors, `${AREA}_${index}`)
                    ? classes.tableCellBorderError
                    : null
                }>
                <TextField
                  disabled={!isCreatorOrg || item.locked}
                  fullWidth
                  InputProps={{ disableUnderline: true }}
                  onChange={e => handleChange(e, index, AREA)}
                  placeholder={intl.formatMessage({
                    defaultMessage: 'Area',
                    id: 'object.AREA'
                  })}
                  size="small"
                  value={item.area || ''}
                />
              </StyledCell>
              <StyledCell
                className={
                  hasError(errors, `${NUMBER_OF_UNITS}_${index}`)
                    ? classes.tableCellBorderError
                    : null
                }>
                <TextField
                  disabled={!isCreatorOrg || item.locked}
                  fullWidth
                  InputProps={{ disableUnderline: true }}
                  name={NUMBER_OF_UNITS}
                  onChange={e => handleChange(e, index, NUMBER_OF_UNITS)}
                  placeholder={intl.formatMessage({
                    defaultMessage: 'Number of units',
                    id: 'object.NUMBER_OF_UNITS'
                  })}
                  size="small"
                  type="number"
                  value={item.number_of_units || 0}
                />
              </StyledCell>
              <StyledCell>
                <Typography>{formatCurrency(item.sum, intl.locale)}</Typography>
              </StyledCell>
              <StyledCell
                className={
                  hasError(errors, `${STATUS}_${index}`)
                    ? classes.tableCellBorderError
                    : null
                }>
                {isCreatorOrg ||
                item.locked ||
                disableEdit ||
                !showApprovalStatus ? (
                  <div>
                    <CancelRoundedIcon
                      className={classNames(
                        classes.paddingRightHalf,
                        item.status === STATUS_REJECTED &&
                          (showApprovalStatus || item.locked)
                          ? classes.delItemIcon
                          : classes.grayIcon
                      )}
                    />
                    <CheckCircleRoundedIcon
                      className={
                        item.status === STATUS_APPROVED &&
                        (showApprovalStatus || item.locked)
                          ? classes.addItemIcon
                          : classes.grayIcon
                      }
                    />
                  </div>
                ) : (
                  <div>
                    <IconButton
                      className={
                        item.status === STATUS_REJECTED
                          ? classes.delItemIcon
                          : classes.grayIcon
                      }
                      onClick={e =>
                        handleChange(e, index, STATUS, STATUS_REJECTED)
                      }
                      size="small">
                      <CancelRoundedIcon />
                    </IconButton>
                    <IconButton
                      className={
                        item.status === STATUS_APPROVED
                          ? classes.addItemIcon
                          : classes.grayIcon
                      }
                      onClick={e =>
                        handleChange(e, index, STATUS, STATUS_APPROVED)
                      }
                      size="small">
                      <CheckCircleRoundedIcon />
                    </IconButton>
                  </div>
                )}
              </StyledCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

BoqTable.propTypes = {
  data: PropTypes.array,
  disableEdit: PropTypes.bool,
  errors: PropTypes.object,
  isCreatorOrg: PropTypes.bool,
  isRevision: PropTypes.bool,
  setFormState: PropTypes.func,
  showApprovalStatus: PropTypes.bool
};

BoqTable.defaultProps = { data: [] };

export default BoqTable;
