import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography
} from '@material-ui/core';
import {
  CHAIRED_BY,
  DATA_TYPE_STRING,
  GUESTS,
  HYPHEN,
  MINUTES_BY
} from 'common/constants';
import { isObjectEmpty } from 'common/helper';
import Item from './Item';
import {
  formatNumberDisplay,
  getAgendaItemNumber,
  getFromDeletedUsers,
  getMeetingNo
} from 'views/Object/components/helper';
import { useStyles } from '../../../styles';

const Meeting = ({ displayObjectView, objectInfo }) => {
  const classes = useStyles();
  const {
    agenda: objAgenda,
    distribution,
    metadata = {},
    object_users,
    participants,
    deleted_users
  } = objectInfo;
  const meetingNo = metadata.meeting_no
    ? formatNumberDisplay(metadata.meeting_no, 5)
    : getMeetingNo(objectInfo.name);
  const meeting_date = metadata.meeting_date || '';
  const agenda = objAgenda || [];

  let chaired_by = object_users.find(
    user => user.object_user_role === CHAIRED_BY
  );
  chaired_by = chaired_by
    ? chaired_by.user.name || chaired_by.user.email
    : getFromDeletedUsers(deleted_users, metadata.chaired_by).name;

  let minutes_by = object_users.find(
    user => user.object_user_role === MINUTES_BY
  );
  minutes_by = minutes_by
    ? minutes_by.user.name || minutes_by.user.email
    : getFromDeletedUsers(deleted_users, metadata.minutes_by).name;

  const groupUsers = list =>
    Object.values(list)
      .flat()
      .reduce((acc, curr) => {
        const isCurrString = typeof curr === DATA_TYPE_STRING;
        const key = isCurrString ? GUESTS : curr.organization.name;
        const user = isCurrString ? curr : curr.name || curr.email;

        (acc[key] = acc[key] || []).push(user);

        return acc;
      }, {});
  const distributionList = groupUsers(distribution);
  const participantList = groupUsers(participants);

  return (
    <div>
      <Typography component="div">
        <Box
          fontSize="h4.fontSize"
          fontWeight="fontWeightBold"
          m={1}
          textAlign="left">
          {objectInfo.name} {objectInfo.title}
        </Box>
        <Box m={1} textAlign="left">
          <FormattedMessage defaultMessage="Date" id="common.DATE" />{' '}
          {meeting_date}
        </Box>
        <Box m={1} textAlign="left">
          <FormattedMessage
            defaultMessage="Meeting no."
            id="object.MEETING_NUMBER2"
          />{' '}
          {meetingNo}
        </Box>
        <Box m={1} textAlign="left">
          <FormattedMessage
            defaultMessage="Chaired by"
            id="object.CHAIRED_BY"
          />{' '}
          {chaired_by}
        </Box>
        <Box m={1} textAlign="left">
          <FormattedMessage
            defaultMessage="Minutes by"
            id="object.MINUTES_BY"
          />{' '}
          {minutes_by}
        </Box>
      </Typography>
      <Grid className={classes.paddingTop} container>
        <Grid item md={6} xs={12}>
          <Typography component="div">
            <Box fontWeight="fontWeightBold" m={1} textAlign="left">
              <FormattedMessage
                defaultMessage="Participants"
                id="object.PARTICIPANTS"
              />
              :{' '}
              {isObjectEmpty(participantList) ? (
                <FormattedMessage defaultMessage="None" id="common.NONE" />
              ) : null}
            </Box>
            {Object.entries(participantList).map(([k, v], i) => (
              <Box key={`participants_${i}`} m={1} textAlign="left">
                <b>
                  <u>{k}</u>
                </b>
                <br />
                {v.join('\n')}
              </Box>
            ))}
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography component="div">
            <Box fontWeight="fontWeightBold" m={1} textAlign="left">
              <FormattedMessage
                defaultMessage="Distribution"
                id="object.DISTRIBUTION"
              />
              :{' '}
              {isObjectEmpty(distributionList) ? (
                <FormattedMessage defaultMessage="None" id="common.NONE" />
              ) : null}
            </Box>
            {Object.entries(distributionList).map(([k, v], i) => (
              <Box key={`distribution_${i}`} m={1} textAlign="left">
                <b>
                  <u>{k}</u>
                </b>
                <br />
                {v.join('\n')}
              </Box>
            ))}
          </Typography>
        </Grid>
      </Grid>
      {agenda.map((a, i) => (
        <Card className={classes.marginTop1} key={i}>
          <CardHeader
            className={classNames(classes.grayBackground, classes.padding1)}
            title={(metadata.meeting_no
              ? ''
              : meetingNo
                  .concat(HYPHEN)
                  .concat(formatNumberDisplay(i + 1))
                  .concat(' ')
            ).concat(a.title)}
            titleTypographyProps={{ variant: 'h6' }}
          />
          {a.comment.map((item, itemIdx) => {
            const itemNo = item.item_no || getAgendaItemNumber(i, itemIdx);
            return (
              <CardContent
                className={classNames(
                  classes.noPaddingCardContent,
                  classes.paddingBottom
                )}
                key={`agenda_${i}_comment_${itemIdx}`}>
                <Item
                  displayObjectView={displayObjectView}
                  item={item}
                  itemNo={itemNo}
                  label={`agenda_${i}_comment_${itemIdx}`}
                />
                {item.sub_item.map((subItem, subItemIdx) => {
                  const subItemNo =
                    subItem.item_no ||
                    getAgendaItemNumber(i, itemIdx, subItemIdx);
                  return (
                    <Item
                      displayObjectView={displayObjectView}
                      isSubItem
                      item={subItem}
                      itemNo={subItemNo}
                      key={`agenda_${i}_comment_${itemIdx}_sub${subItemIdx}`}
                      label={`agenda_${i}_comment_${itemIdx}_sub${subItemIdx}`}
                    />
                  );
                })}
              </CardContent>
            );
          })}
        </Card>
      ))}
    </div>
  );
};

Meeting.propTypes = {
  displayObjectView: PropTypes.func,
  objectInfo: PropTypes.object
};

export default Meeting;
